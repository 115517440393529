import { User } from './../models/user.model';
import { UserPolicyAgreements } from "../models/userPolicyAgreements.model"
import { allApis } from "./allApis";

export const userPolicyAgreementsApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        agreeEventPolicy: builder.mutation<UserPolicyAgreements, UserPolicyAgreements>({
            query: (userPolicyAgreements) => ({
                url: `userPolicyAgreements`,
                method: 'POST',
                body: userPolicyAgreements
            })
        }),
        createUserTerms: builder.mutation<UserPolicyAgreements, Partial<UserPolicyAgreements>>({
            query: (userPolicyAgreements) => ({
                url: `userPolicyAgreements/terms`,
                method: 'POST',
                body: userPolicyAgreements
            }),
            invalidatesTags: ['UserPolicyAgreements']
        }),
        checkUserTermsPolicy: builder.query<boolean, void>({
            query: () => "userPolicyAgreements/checkUserTerms",
            providesTags: ['UserPolicyAgreements']
        }),
        checkEventCreationPolicy: builder.query<boolean, void>({
            query: () => "userPolicyAgreements/checkEventPolicy"
        }),
        updateUserTermsPolicy: builder.mutation<User, UserPolicyAgreements>({
            query: (cUser) => ({
                url: `userPolicyAgreements/updateUserPolicy`,
                method: 'PUT',
                body: cUser
            }),
            invalidatesTags: ['CurrentUser']
        }),

    })
});

export const { useCheckUserTermsPolicyQuery, useCreateUserTermsMutation, useCheckEventCreationPolicyQuery, useAgreeEventPolicyMutation, useUpdateUserTermsPolicyMutation } = userPolicyAgreementsApi;