import React from 'react'
import { defaultAvatar } from './room/audio_conference/CustomAudioParticipantPlaceholder'
import { UserType } from './livekitTypes'
import { useMeetingEndTimerHook } from './livekitHooks'
import { TrackLoop, TrackReferenceOrPlaceholder } from '@livekit/components-react'
import PermissionsToasterComp from './room/PermissionsToasterComp'

export default function MinimizedComp({
  meetingTitle,
  profileImg,
  tracks,
  onMessage,
}: {
  meetingTitle: string
  profileImg: string
  tracks: TrackReferenceOrPlaceholder[]
  onMessage?: (type: string, message: string) => void
}) {
  const [isNullPresent, setIsNullPresent] = React.useState(false)
  let metaData: any
  const { showAlertFiveMin, twoMinAlert, showPing } = useMeetingEndTimerHook()

  React.useEffect(() => {
    if (profileImg && (profileImg.includes('null') || profileImg.includes('undefined'))) {
      setIsNullPresent(true)
    }
  }, [profileImg])

  if (showAlertFiveMin && onMessage) {
    onMessage(`Meeting will end in 5 minutes`, 'info')
  }

  if (twoMinAlert && onMessage) {
    onMessage(`Meeting will end in 2 minutes`, 'info')
  }

  return (
    <>
      <TrackLoop tracks={tracks}>
        <PermissionsToasterComp />
      </TrackLoop>
      <div className="inline-block bg-white relative rounded-xl w-full">
        <div className="w-20 h-20 rounded-full bg-white z-50 absolute -top-1 -left-10">
          {isNullPresent ? (
            <div className="bg-gray-200 flex w-20 h-20 justify-center items-center rounded-full">
              <img
                className={` rounded-full h-full w-full ${
                  metaData?.userType === UserType.HOST ? 'outline outline-yellow-400' : ''
                } ${metaData?.userType === UserType.CO_HOST ? 'outline outline-orange-400' : ''}`}
                src={defaultAvatar}
                alt="Avatar"
              />
            </div>
          ) : (
            <img
              className={` rounded-full h-full w-full ${
                metaData?.userType === UserType.HOST ? 'outline outline-yellow-400' : ''
              } ${metaData?.userType === UserType.CO_HOST ? 'outline outline-orange-400' : ''}`}
              src={profileImg}
              alt="img"
            />
          )}
        </div>
        <div className="flex pl-12 pr-2 h-full w-full justify-center items-center py-1">
          <div className="flex flex-col gap-2 w-full justify-start ">
            <div className="flex w-full gap-2 justify-center items-center">
              <div
                id="PIP-btn_animatePing"
                className={`${showPing ? 'animate-ping w-3 h-3' : 'w-3 h-3'} rounded-full bg-red-600`}
              />
              <div id="PIP-meetingTitle" className="text-base font-normal w-full truncate text-black">
                {meetingTitle}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
