/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useGetLedgerQuery } from "../Services/ledgerApi";
import { useGetAllInvitedGuestsQuery } from "../Services/meetingParticipantApi";
import { useCreateInviteForAllGuestsMutation } from "../Services/meetingRequestApi";
import { useGetSettingValue } from "../Services/settingReducer";
import { getCurrentUserDetail } from "../Services/userReducer";
import { useAppDispatch, useAppSelector } from "../Store/hooks";
import { MeetingParticipant } from "../models/meetingParticipant.model";
import { userApi } from "../Services/userApi";
import { t } from 'i18next';

export const usePaymentSuccessHook = (transactionId?: string) => {
    const userDetail = useAppSelector(getCurrentUserDetail);
    const imageURL = useGetSettingValue('IMAGE_URL');
    const dispatch = useAppDispatch()

    const [invitedGuests, setInvitedGuests] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showToastMessage, setShowToastMessage] = useState(false);
    const [notify, setNotify] = useState(false);
    const [loading, setLoading] = useState(true);
    const [remainingCount, setRemainingCount] = useState(0);

    const [uniqueEmailError, setUniqueEmailError] = useState([]);

    const [emptyInvitedGuests, setEmptyInvitedGuests] = useState(false);

    const [invitedUserEmails, setInvitedUserEmails] = useState<string[]>([])
    const [invitedNonUserEmails, setInvitedNonUserEmails] = useState<string[]>([])
    const [newEmails, setNewEmails] = useState<string[]>([])

    const [createInviteForAllGuests, { isLoading: inviteLoading }] = useCreateInviteForAllGuestsMutation<any>();

    const [serverError, setServerError] = useState<any>();

    const { data: ledger, isSuccess: ledgerSuccess } = useGetLedgerQuery(transactionId ? transactionId : '', { skip: !transactionId });
    const {
        data: getAllInvitedGuestList, isSuccess: getAllInvitedGuestListSuccess, refetch
    } = useGetAllInvitedGuestsQuery(ledger ? ledger.id : '', { skip: !ledger });

    const uniqueText = useMemo(() => {
        const filteredEmails = newEmails.filter((element: string, index: number) => {
         return newEmails.indexOf(element) === index
         })
         return filteredEmails
        }, [newEmails])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const invitedEmailVal: any[] = [];

                getAllInvitedGuestList?.forEach(async (invitedGuest: MeetingParticipant) => {
                    if (invitedGuest?.user?.sub) {
                        const res = await dispatch(userApi.endpoints.getUserDetail.initiate(invitedGuest.user.sub));
                        if (res.data) {
                            invitedEmailVal.push(res.data.email);
                            setInvitedUserEmails([...invitedEmailVal]);
                        }
                    }
                })
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();

        const alreadyInvited = getAllInvitedGuestList?.map((guest: MeetingParticipant) => {
            return guest?.invitedUser?.inviteEmail;
        }).filter((email: string) => email);

        setInvitedNonUserEmails(alreadyInvited || []);
    }, [getAllInvitedGuestList]);

    useEffect(() => {
        if (ledgerSuccess && ledger) {
            if (!remainingCount) {
                setRemainingCount(ledger.guestCount - 1);
            }

            if (!getAllInvitedGuestList || getAllInvitedGuestList.length === 0) {
                return;
            }

            if (getAllInvitedGuestList.length !== (ledger.guestCount - 1)) {
                if (invitedGuests) {
                    setInvitedGuests(false);
                }

                const remainingInvite = ledger.guestCount - 1 - getAllInvitedGuestList.length;

                if (remainingCount !== remainingInvite) {
                    setRemainingCount(remainingInvite);
                }
            } else {
                if (!invitedGuests) {
                    setInvitedGuests(true);
                }
            }
        }
    }, [getAllInvitedGuestList]);

    useEffect(() => {
        if (notify) {
            setNotify(false);
            clearTags();
        }
    }, [notify]);

    // Unique Email Validation
    useEffect(() => {
        if (uniqueText) {
            const uniqueEmailsError = [] as any;

            uniqueText.forEach((email: string, index) => {
                if (invitedUserEmails.includes(email) || invitedNonUserEmails.includes(email)) {
                    uniqueEmailsError[index] = { message: t('emailAlreadyInvited')};
                }
                if (userDetail?.email === email) {
                    uniqueEmailsError[index] = { message: t('youCannotInviteYourself')};
                }
                if(!uniqueText.includes(email)) {
                    uniqueEmailsError[index] = { message: t('emailNotValid')};
                }
                return;
            })
            setUniqueEmailError(uniqueEmailsError);
        }

    }, [uniqueText, invitedUserEmails, invitedNonUserEmails]);


    if (userDetail && imageURL && ledgerSuccess && getAllInvitedGuestListSuccess) {
        if (loading) {
            setLoading(false);
        }
    }

    const clearTags = () => {
        setNewEmails([]);
    };

    const onSubmit = () => {

        if (!notify) {
            setNotify(true);
        }

        if (!emptyInvitedGuests) {
            setEmptyInvitedGuests(true);
        }

        if (!uniqueText || uniqueText.length === 0) {
            return;
        }

        const args = {
            transactionId: transactionId,
            guestList: uniqueText
        };
        createInviteForAllGuests(args).then((res: any) => {
            const onlyErrors = res.data?.filter((d: any) => d && d.error);
            setServerError(onlyErrors);
            
            if (serverError) {
                setErrorMessage(serverError);
            }

            else {
                if(onlyErrors.length === 0)
                {
                if (!showToastMessage) {
                    setShowToastMessage(true);
                }

                if (!invitedGuests) {
                    setInvitedGuests(true);
                }

                refetch();
            }}
        })
            .catch((error) => {
                console.error("Error submitting the form:", error);
            });
    }

    return {
        imageURL,
        onSubmit,

        invitedGuests,
        invitedUserEmails,
        invitedNonUserEmails,

        errorMessage,
        notify,
        getAllInvitedGuestList,
        inviteLoading,
        uniqueEmailError,
        showToastMessage,
        setShowToastMessage,
        loading,
        ledger,

        newEmails,
        setNewEmails,
        emptyInvitedGuests,
        serverError,
        setServerError,
        uniqueText
        };
}