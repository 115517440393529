import Icon from "../icon/icon";
import { ToolTip } from "../tooltip/tooltip";

interface TagProps {
  text: string;
  remove: any;
  disabled?: boolean;
  className?: string;
  error: any;
  keyId?: number;
}

export default function Tag({ text, remove, disabled, className, error, keyId}: TagProps) {
  const handleOnRemove = (e: any) => {
    e.stopPropagation();
    remove(text);
  };
  
  return (
    <div className={`py-2 px-2 text-sm rounded-md flex flex-row items-center justify-between mb-2 ${!!error ? 'bg-red-500' : 'bg-primary'}`}>
          {error ?
         <ToolTip tip={error.message} keyId={`error-${keyId}`}>
           <span id={`invite-${keyId}`} data-testid={`invite-${keyId}`}className="flex justify-between">{text}
             {error && <Icon icon="QUESTION" size="small" className="ml-5" stroke={"#fff"}/>}</span>
         </ToolTip>
         :
         <span data-testid={`invite-${keyId}`} id={`invite-${keyId}`}>{text}</span>
       }
         {!disabled && (
        <div className="cursor-pointer " id={text} data-testid={text} onClick={handleOnRemove}>
          <Icon icon="CLOSE" size="x-small" className="ml-3" />
        </div>
      )}
    </div>
  );
}