import { useTranslation } from 'react-i18next'
import { useEffect, useState } from "react";
import useWindowDimensions from "../../Components/base/carousel/whirligig/utils";
import Icon from "../../Components/base/icon/icon";
import { Meeting } from "../../models/meeting.model";
import { MeetingDetails, VideoType } from "../../models/meetingDetails.model";
import { getMyEventById } from "../../Services/myEventsReducer";
import { useAppSelector } from "../../Store/hooks";
import NewIcon from "../../Components/base/icon/newIcons";

export function DisplayEventTypeIcons({ meetingDetail, meeting }: { meetingDetail: MeetingDetails, meeting?: Meeting }) {
    const { t } = useTranslation()
    const { width } = useWindowDimensions();
    const [meetingDetailDisplay, setMeetingDetailDisplay] = useState(meetingDetail);

    const meetingDetailFromState = useAppSelector((state) => getMyEventById(state, meetingDetail.id))

    useEffect(() => {
        if (meetingDetailFromState) {
            setMeetingDetailDisplay(meetingDetailFromState)
        }
    }, [meetingDetailFromState])

    const style = {
        eventDetailText: `items-center flex mr-2 ml-0.5 font-normal text-xs mb-2`,
    }


    return <>
        {meetingDetailDisplay?.videoType === VideoType.Audio && !meetingDetailDisplay.isFreeAudioMeeting && (
            <>
                <NewIcon icon="AUDIO" size={"x-small"} height="small" />
                <span data-testid='paidAudio_event' className={style.eventDetailText}>
                    {t('audio')}
                </span>
            </>
        )}
        {meetingDetailDisplay?.videoType === VideoType.Audio && meetingDetailDisplay.isFreeAudioMeeting && (
            <>
                <NewIcon icon="AUDIO" size={"x-small"} height="small" />
                <span data-testid='freeAudio_event' className={style.eventDetailText}>
                    {t('freeAudioEvent')}
                </span>
            </>
        )}

        {meetingDetailDisplay?.videoType === VideoType.Both && (
            <>
                <NewIcon icon="VIDEO_CAM" size={"x-small"} height="small" />
                <span data-testid='video_event' className={style.eventDetailText}>
                    {t('video')}
                </span>
            </>
        )}

        {meetingDetailDisplay?.isAnonymous && (
            <>
                <Icon icon="ANONYMOUS" size={"x-small"} height="small" />
                <span id={`txt_anonymous${meetingDetailDisplay?.id}`} data-testid='event_anonymous' className={style.eventDetailText}>
                    {t('anonymous')}
                </span>
            </>
        )}

        {meeting?.language && (
            <>
                <Icon icon="LANG_B" size={"x-small"} height="small" />
                <span data-testid={`icon_lang${meeting.id}`} className={style.eventDetailText}>
                    {meeting.language}
                </span>
            </>
        )}
    </>
}
