import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { WebCommandTypes } from '../API/socketTypes'
import { SendWebCommand } from '../API/webCommands'
import { useGetAllAvatarQuery, useNickNamesListQuery } from '../Services/allOthersApi'
import { useGetAllCountriesQuery } from '../Services/countryApi'
import { useDeleteImageMutation, useUploadUserImageMutation } from '../Services/imageApi'
import { useGetSettingValue } from '../Services/settingReducer'
import { useDeleteUserVideoMutation, useGetCurrentUserDetailsQuery, useUpdateUserMutation, useUploadUserVideoMutation, userApi } from '../Services/userApi'
import { useAppDispatch } from '../Store/hooks'
import { anonymousProfileValidationSchema, userInfoValidationSchema } from '../Utils/validation'
import { Gender, User, Verified } from '../models/user.model'
import { UserDetail } from '../models/userDetail.model'
import { changeCurrentUserDetail, getCurrentUser, getCurrentUserDetail} from './../Services/userReducer'
import { useAppSelector } from './../Store/hooks'

export function formatBytes(bytes: number, decimals?: number): string {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

type AutoCompleteType = {
  label: string
  value: string
}
export const useUploadCoverVideo = ({ toggleVideoModalOpen }: { toggleVideoModalOpen?: (value: boolean) => void }) => {
  const dispatch = useAppDispatch()

  const [selectedFile, setSelectedFile] = useState<any>()
  const [videoError, setVideoError] = useState(false)
  const currentUser = useAppSelector(getCurrentUser)

  const videoSize = useGetSettingValue('EVENT_VIDEO_SIZE')

  const videoSizeFormat = formatBytes(Number(videoSize))

  const [uploadUserVideo, { isLoading: uploadUserVideoLoading, isSuccess: isUploadUserVideoSuccess }] = useUploadUserVideoMutation()
  const [deleteUserVideo, { isLoading: deleteUserVideoLoading, isSuccess: isDeleteUserVideoSuccess }] = useDeleteUserVideoMutation()

  const uploadVideo = async () => {
    if (currentUser.userVideo) {
      deleteVideo()
    }
    uploadUserVideo({ file: selectedFile, id: currentUser.id }).then(
      (response: any) => {
        setSelectedFile(undefined)
        SendWebCommand({ type: WebCommandTypes.ProfileUpdate, fromUserId: currentUser.id });
        dispatch(userApi.util.invalidateTags(['CurrentUser']))
        close()
      },
    )
  }

  const deleteSelectedVideo = () => {
    setSelectedFile(undefined)
  }

  const deleteVideo = async () => {
    deleteUserVideo(currentUser)
      .then((response: any) => {
        SendWebCommand({ type: WebCommandTypes.ProfileUpdate, fromUserId: currentUser.id });
        dispatch(userApi.util.invalidateTags(['CurrentUser']))
        close()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onFileSelected = (event: any) => {
    setVideoError(false)
    const file = event.target.files[0]

    if (!file.type.startsWith('video')) {
      setVideoError(true)
    } else if (videoSize < file.size) {
      setVideoError(true)
    } else {
      setSelectedFile(file)
    }
  }

  const close = () => {
    toggleVideoModalOpen && toggleVideoModalOpen(false)
    deleteSelectedVideo()
  }

  return {
    close,
    selectedFile,
    onFileSelected,
    deleteVideo,
    uploadVideo,
    videoError,
    videoSizeFormat,
    deleteSelectedVideo,
    deleteUserVideoLoading,
    uploadUserVideoLoading,
    isLoading: uploadUserVideoLoading || deleteUserVideoLoading,
    isDeleteUserVideoSuccess,
    isUploadUserVideoSuccess,
    setSelectedFile,
    setVideoError
  }
}

export const useUploadUserImage = () => {
  const currentUser = useAppSelector(getCurrentUser)
  const imageURL = useGetSettingValue('IMAGE_URL')
  const imageSizeData = useGetSettingValue('EVENT_IMAGE_SIZE')

  const [deleteImage, { isLoading: profileImageRemoveLoading, isSuccess: profileImageDeleteSuccess }] = useDeleteImageMutation()
  const [addImage, { isLoading: profileImageAddLoading, isSuccess: profileImageAddSuccess }] = useUploadUserImageMutation()

  useEffect(() => {
    if (profileImageAddSuccess || profileImageDeleteSuccess) {
      SendWebCommand({ type: WebCommandTypes.ProfileUpdate, fromUserId: currentUser.id });
    }
  }, [profileImageDeleteSuccess, profileImageAddSuccess, currentUser])

  return {
    deleteImage,
    addImage,
    profileImageRemoveLoading,
    profileImageAddLoading,
    profileImageDeleteSuccess,
    profileImageAddSuccess,
    isLoading: profileImageRemoveLoading || profileImageAddLoading,
    imageURL,
    imageSizeData,
  }
}

export const useUserAnonymousEditHook = () => {


  type AnonymousEditFormType = {
    nickName: string,
    avatar: string,
    catchPhrase: string,
    anonymous: boolean,
  }

  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUser)

  const [autoCompletionNicknames, setAutoCompletionNickNames] = useState<AutoCompleteType[]>([])

  const { data: avatarList, isSuccess: avatarListSuccess, isLoading: avatarListLoading } = useGetAllAvatarQuery()
  const { data: nickNames, isSuccess: nickNameSuccess, isLoading: nickNamesLoading } = useNickNamesListQuery()

  const [updateUser, { error: profileUpdateError, isSuccess: profileUpdateSuccess, isLoading: profileUpdateLoading }] = useUpdateUserMutation()

  const { register, control, handleSubmit, setValue, getValues, formState: { errors, isValid, isDirty }, watch, trigger } = useForm<AnonymousEditFormType>({ mode: "onChange", resolver: yupResolver(anonymousProfileValidationSchema), defaultValues: { ...currentUser } });

  useEffect(() => {
    if (currentUser) {
      setValue('avatar', currentUser.avatar)
    }
  }, [currentUser, setValue])

  useEffect(() => {
    if (nickNameSuccess && nickNames) {
      setAutoCompletionNickNames(
        nickNames?.map(nickName => {
          const nickNameData = {
            label: nickName.name,
            value: nickName.name
          };

          return nickNameData;
        })
      )
    }
  }, [nickNames, nickNameSuccess])

  useMemo(() => {
    //nick name is a required one if user enables anonymous profile.
    trigger("nickName")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('anonymous')])

  const submit = (data: AnonymousEditFormType) => {
    const { nickName, avatar, catchPhrase, anonymous } = data

    const updatedCurrentUser = {
      ...currentUser,
      nickName,
      avatar,
      catchPhrase,
      anonymous
    }

    updateUser(updatedCurrentUser).then((response: any) => {
      dispatch(userApi.util.invalidateTags(['CurrentUser']))
      SendWebCommand({ type: WebCommandTypes.ProfileUpdate, fromUserId: currentUser.id });
    }).catch((e) => { console.error(e) })
  }

  return {
    avatarList,
    avatarListSuccess,
    profileUpdateError,
    profileUpdateSuccess,
    profileUpdateLoading,
    updateUser,
    avatarListLoading,
    nickNamesLoading,
    autoCompletionNicknames,
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    errors,
    isDirty,
    watch,
    submit,
    trigger,
    isValid
  }
}

export interface UserInfoFormType {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: Date;
  gender: Gender;
  phoneNumber?: string;
  country: string;
  city: string;
  facebookName: string;
  twitterName: string;
  instagramName: string;
  linkedInName: string;
  selfIntro: string;
  isNative?: boolean
}


export const useUserInfoEditHook = () => {
  const dispatch = useAppDispatch()

  const { isLoading: cUserLoading, isFetching: cUserFetching } = useGetCurrentUserDetailsQuery()
  const currentUser = useAppSelector(getCurrentUser)
  const currentUserDetail = useAppSelector(getCurrentUserDetail)

  const [countryCodeList, setCountryCodeList] = useState<AutoCompleteType[]>([])
  const [countryNameList, setCountryNameList] = useState<AutoCompleteType[]>([])
  const [navigateLink, setNavigateLink] = useState<string>();
  const [disableVerification, setDisableVerification] = useState(true);
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();

  const ageLimit = Number(useGetSettingValue('AGE_LIMIT'));

  const { data: countries, isLoading: countriesLoading, isSuccess: countrySuccess } = useGetAllCountriesQuery();
  const [updateUser, { isLoading: profileUpdateLoading }] = useUpdateUserMutation();

  const { register, control, handleSubmit, setValue, getValues, watch, formState: { errors, isDirty, isValid }, clearErrors, trigger } = useForm<UserInfoFormType>({ defaultValues: getDefaultValues(), mode: "onChange", resolver: yupResolver(userInfoValidationSchema) });

  const isLoading = countriesLoading
  const isCurrentUserLoading = cUserLoading || cUserFetching
  const phoneVerified = currentUser.phoneVerified === Verified.Complete && (currentUserDetail.phone_number.replace(/\+/g, '')) === `${watch('phoneNumber')?.replace(/\+/g, '')}`
  const emailVerified = currentUser.emailVerified === Verified.Complete
  const maxCharacters = watch('selfIntro')?.trim().length > 1500;


  useEffect(() => {
    if (countrySuccess && countries) {
      setCountryCodeList(
        countries?.map(country => {
          const countryData = {
            label: country.displayCountryCode,
            value: country.displayCountryCode
          };

          return countryData;
        })
      )

      setCountryNameList(
        countries?.map(country => {
          const countryData = {
            label: country.displayName,
            value: country.displayName
          };

          return countryData;
        })
      )
    }
  }, [countries, countrySuccess])

  function getDefaultValues() {
    return {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUserDetail.email,
      dateOfBirth: currentUserDetail?.birthdate ? new Date(currentUserDetail?.birthdate) : new Date(),
      gender: currentUser.gender,
      phoneNumber: currentUserDetail.phone_number,
      country: currentUser.country,
      city: currentUser.city,
      facebookName: currentUser.facebookName,
      twitterName: currentUser.twitterName,
      instagramName: currentUser.instagramName,
      linkedInName: currentUser.linkedInName,
      selfIntro: currentUser.selfIntro,
      ageLimit: ageLimit,
    }
  }

  const getMaxDob = () => {
    let maxDob = new Date();
    maxDob.setFullYear(maxDob.getFullYear() - ageLimit);
    return maxDob;
  }

  const getMinDob = () => {
    let minDob = new Date();
    minDob.setFullYear(minDob.getFullYear() - 122.5);
    return minDob;
  }

  const resetPassword = () => {
    Auth.forgotPassword(currentUserDetail.email).then((res) => {
      if (res.CodeDeliveryDetails.DeliveryMedium === 'EMAIL') {
        if (!navigateLink) {
          setNavigateLink('/resetPassword/' + currentUserDetail.email + '/email');
        }
      } else {
        if (!navigateLink) {
          setNavigateLink('/resetPassword/' + currentUserDetail.email + '/phone');
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const changePhoneNumber = () => {

    if (disableVerification) {
      setDisableVerification(false);
    }
  }

  const revertPhoneNumber = () => {

    setDisableVerification(true)
    setShowVerificationInput(false)
  }

  const sendOTP = async (values: any) => {
    let currentUserDetailCopy = { ...currentUserDetail }
    let currentUserCopy = { ...currentUser }

    const formattedPhoneNumber = values.phoneNumber.includes('+') ? values.phoneNumber : '+' + values.phoneNumber;

    if (formattedPhoneNumber === currentUserDetailCopy.phone_number) {
      revertPhoneNumber()
      return
    }

    currentUserDetailCopy.phone_number = formattedPhoneNumber;
    if (!showVerificationInput) {
      setShowVerificationInput(true);
    }
    currentUserDetailCopy.phone_number = values.phoneNumber.includes('+') ? values.phoneNumber : '+' + values.phoneNumber;

    await Auth.currentAuthenticatedUser().then((resultCurrent) => {
      const { identities, username, ...updatedUserDetail } = currentUserDetailCopy as any;
      resultCurrent.attributes = updatedUserDetail;
      if (!resultCurrent.attributes.address) {
        delete resultCurrent.attributes.address;
      }
      if (!resultCurrent.attributes.birthdate) {
        delete resultCurrent.attributes.birthdate;
      }
      Auth.updateUserAttributes(resultCurrent, resultCurrent.attributes).then(() => {

        dispatch(changeCurrentUserDetail(resultCurrent.attributes));
        currentUserCopy.phoneVerified = Verified.NotComplete;
        updateUser(currentUserCopy)
      });
    });

    return;
  }

  const saveProfile = (values: UserInfoFormType) => {
    const { firstName, lastName, city, country, gender, dateOfBirth, selfIntro, facebookName, linkedInName, instagramName, twitterName } = values;

    const updatedCurrentUser: User = {
      ...currentUser,
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      country: country,
      city: city,
      selfIntro: selfIntro,
      facebookName: facebookName,
      linkedInName: linkedInName,
      instagramName: instagramName,
      twitterName: twitterName,
    }

    const userDetailCopy: UserDetail = {
      ...currentUserDetail,
      birthdate: values.isNative ? format(new Date(values.dateOfBirth), 'yyyy-MM-dd') : new Date(dateOfBirth).toLocaleDateString('fr-CA')
    }

    updateUser(updatedCurrentUser).then(() => {
      SendWebCommand({ type: WebCommandTypes.ProfileUpdate, fromUserId: currentUser.id });

      Auth.currentAuthenticatedUser().then((resultCurrent) => {
        const { identities, username, attributes, ...updatedUserDetail } = userDetailCopy as any;
        resultCurrent.attributes = updatedUserDetail;

        Auth.updateUserAttributes(resultCurrent, resultCurrent.attributes).then(() => {
          dispatch(changeCurrentUserDetail(resultCurrent.attributes));
        });
      });

      if (!showToastMessage) {
        setShowToastMessage('profileUpdatedSuccessfully');
      }
    })
  }


  return {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    errors,
    isDirty,
    clearErrors,
    getMaxDob,
    getMinDob,
    isLoading,
    countryCodeList,
    countryNameList,
    watch,
    profileUpdateLoading,
    resetPassword,
    setNavigateLink,
    navigateLink,
    disableVerification,
    showVerificationInput,
    changePhoneNumber,
    revertPhoneNumber,
    sendOTP,
    phoneVerified,
    emailVerified,
    isCurrentUserLoading,
    showToastMessage,
    setShowToastMessage,
    saveProfile,
    trigger,
    isValid,
    countries,
    maxCharacters
  }

}
