/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AccordionBorder, AccordionItem } from '../../Components/base/accordion/accordion'
import Icon from '../../Components/base/icon/icon'
import { toastSuccess } from '../../Components/toast'
import { useAttendingEventInvitationHooks, useMeetingJoinHooks } from '../../Hooks/AttendingHooks'
import { Meeting, MeetingType } from '../../models/meeting.model'
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder'
import CardTitle from '../EventsCard/CardTitle'
import { DisplayEventTypeIcons } from '../EventsCard/DisplayEventTypeIcons'
import { EventCard } from '../EventsCard/EventCard'
import { JoinButton } from './AttendingEventsJoinButton'
import { AttendingEventsRemainder } from './AttendingEventsRemainder'
import { DisplayFormattedDateAndTime } from '../../Components/formattedDateAndTime'

export function EventInvitation({ meeting }: { meeting: Meeting }) {
  const { t } = useTranslation()
  const { handleInviteRequest, updateSuccess } = useAttendingEventInvitationHooks()
  const { joinTime } = useMeetingJoinHooks({ meeting })
  const truncatedTitle = {
    meetingTitle: meeting.meetingDetails.title.length > 25 ? meeting.meetingDetails.title.substring(0, 25) + '...' : meeting.meetingDetails.title
  }

  useEffect(() => {
    if (updateSuccess === 'Accepted') {
      toastSuccess(t('requestAcceptedSuccessfully'))
    } else if (updateSuccess === 'Rejected') {
      toastSuccess(t('requestDeclinedSuccessfully'))
    }
  }, [updateSuccess])

  return (
    <AccordionBorder>
      <AccordionItem toggle="panel-1" color="card">
        <EventCard
          keyId={meeting.id}
          link={
            meeting.type === MeetingType.Type1
              ? `/s/meeting/${meeting.meetingDetails.id}`
              : undefined
          }>
          <EventCard.Image>
            <CardImagePlaceHolder
              keyId={meeting.id}
              images={meeting?.meetingDetails?.image}
            />
          </EventCard.Image>
          <EventCard.Title>
            {' '}
            <CardTitle
              key={meeting.meetingDetails.id}
              keyId={meeting.id}
              meetingTitle={truncatedTitle.meetingTitle}
            />
          </EventCard.Title>
          <EventCard.SubTitle>
            <DisplayFormattedDateAndTime
              displayFromTime={meeting.fromDateTime}
              displayToTime={meeting.toDateTime}
              displayDate={meeting.fromDateTime}
            />
            {' '}
            -
            {' '}
            {`${t('inviteFrom')} ${
              meeting.meetingParticipant[0].transaction.user.firstName +
              ' ' +
              meeting.meetingParticipant[0].transaction.user.lastName
            }`}
          </EventCard.SubTitle>
          <EventCard.Icons>
            <DisplayEventTypeIcons
              meetingDetail={meeting.meetingDetails}
              meeting={meeting}></DisplayEventTypeIcons>
          </EventCard.Icons>
          <EventCard.Actions>
            <div className="grid grid-cols-6 w-full">
              <div
                className={`text-center col-span-5`}
                onClick={e => {
                  e.stopPropagation()
                }}>
                {meeting.meetingParticipant[0].inviteStatus === 'Accepted' &&
                joinTime === true ? (
                  <JoinButton meeting={meeting} />
                ) : (
                  <>
                    <span
                      data-testid={'txt_attending'}
                      className="text-xs uppercase mb-1"
                      onClick={e => e.stopPropagation()}>
                      {t('attending')}?
                    </span>
                    <span className="flex md:flex-col lg:flex-row text-xs font-semibold uppercase justify-center cursor-pointer">
                      <ParticipantAccepted />
                      <span className="block md:hidden lg:block">|</span>
                      <ParticipantRejected />
                    </span>
                  </>
                )}
              </div>
              <div className="flex justify-end mx-auto">
                {meeting.meetingParticipant[0].inviteStatus === 'Accepted' &&
                joinTime !== true ? (
                  <AttendingEventsRemainder meeting={meeting} />
                ) : (
                  <div className="opacity-0">
                    <Icon size="small" icon="REMINDER" />
                  </div>
                )}
              </div>
            </div>
          </EventCard.Actions>
        </EventCard>
      </AccordionItem>
    </AccordionBorder>
  )

  function ParticipantAccepted() {
    return (
      <>
        {meeting.meetingParticipant[0].inviteStatus === "Accepted" ?
          <span
            id={`btn_accepted${meeting.id}`} data-testid={`btn_accepted${meeting.id}`} className="flex items-center mr-1 cursor-default text-green-600">
            {t('accepted')}
            {/* <Icon size="small" height="small" icon="TICK_GREEN" /> */}
          </span>
          :
          <span
            data-testid={`btn_accept${meeting.id}`}
            id={`btn_accept${meeting.id}`}
            onClick={() => handleInviteRequest(
              meeting.meetingParticipant[0].id,
              'Accepted'
            )}
            className="flex items-center mr-1 cursor-pointer hover:text-green-600">
            {t('accept')}
          </span>}{' '}
      </>
    )
  }

  function ParticipantRejected() {
    return (
      <>
        {meeting.meetingParticipant[0].inviteStatus === "Rejected" ?
          <span
            id={`btn_declined${meeting.id}`}
            data-testid={`btn_declined${meeting.id}`}
            className="flex items-center ml-1 cursor-default text-red-600">
            {t('declined')}
            {/* <Icon size="small" height="small" icon="CLOSE_RED" /> */}
          </span>
          :
          <span
            onClick={() => handleInviteRequest(
              meeting.meetingParticipant[0].id,
              'Rejected'
            )}
            data-testid={`btn_decline${meeting.id}`}
            id={`btn_decline${meeting.id}`} className="flex items-center ml-1 cursor-pointer hover:text-red-600">
            {t('decline')}
            {/* <Icon icon="CLOSE" stroke="red" /> */}
          </span>
        }
      </>
    )
  }
}
