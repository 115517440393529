import { getPreference } from '../Services/userReducer';
import { store } from '../Store';
import { getCurrencyList  } from '../Services/currencyListReducer';

export function getUTCToUserTimeZone(timeZone, dateTimeUTC) {
  return dateTimeUTC
}

export function getUserTimeZone() {
  return getPreference(store.getState())?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getUserPreferenceCurrency() {
  return getPreference(store.getState())?.currency;
}

export function getCurrency() {
  return getCurrencyList(store.getState());
}
