import { useState } from "react";
import { useGetIPAddressQuery } from "../Services/allOthersApi";
import { useGetCognitoKeysQuery, useGetIPGeolocationKeyQuery, useGetReCaptchaQuery } from "../Services/envApi";
import { useGetInviteByTokenQuery } from "../Services/inviteApi";
import { useGetSettingValue } from "../Services/settingReducer";
import { useGetUserDetailByEmailQuery } from "../Services/userApi";
import { Gender } from "../models/user.model";

interface Initial {
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    password: string,
    dateOfBirth: string,
    gender: string,
    ageLimit?: number
}

export const useSignUpHook = (token?: string) => {
    const { data: invite, isSuccess: inviteSuccess } = useGetInviteByTokenQuery(token ? token : '', { skip: !token });
    const { data: siteKey, isSuccess: siteKeySuccess } = useGetReCaptchaQuery();
    const { data: key, isSuccess: keySuccess } = useGetIPGeolocationKeyQuery();
    const { data: ipAddressData, isSuccess: ipAddressSuccess } = useGetIPAddressQuery(key, { skip : !key });
    console.log("useSignUpHook",ipAddressData)
    const { isSuccess: cognitoSuccess } = useGetCognitoKeysQuery()

    const { data: userData, isSuccess: userDataSuccess } = useGetUserDetailByEmailQuery(invite ? invite.inviteEmail : '', { 
        skip : !invite 
    });

    const ageLimit = Number(useGetSettingValue('AGE_LIMIT'));

    const [val, setVal] = useState<Initial>();
    const [loading, setLoading] = useState(true);
    const [showScreen, setShowScreen] = useState(false);

    if (!inviteSuccess || !siteKeySuccess || !keySuccess || !ipAddressSuccess || !userDataSuccess || !cognitoSuccess) {
        if (loading) {
            setLoading(false);
        }
    }

    if (inviteSuccess) {
        if (invite && !val?.email) {
            setVal({
                email: invite?.inviteEmail,
                firstName: '',
                lastName: '',
                phoneNumber: '',
                password: '',
                dateOfBirth: '',
                gender: Gender.Male,
                ageLimit: ageLimit
            });
        }

        if (!showScreen) {
            setShowScreen(true);
        }
    }

    return { loading, invite, val, userData, siteKey, ipAddressData, showScreen };
}