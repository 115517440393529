import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { AccordionBorder, AccordionItem, AccordionPanel } from "../../Components/base/accordion/accordion"
import { Ledger } from "../../models/ledger.model"
import CardImagePlaceHolder from "../EventsCard/CardImagePlaceHolder"
import CardTitle from "../EventsCard/CardTitle"
import { EventCard } from "../EventsCard/EventCard"
import DisplayTransactionDate from "./DisplayTransactionDate"
import DisplayTransactionDescription from "./DisplayTransactionDescription"
import DisplayTransactionStatus from "./DisplayTransactionStatus"
import StatusRed from "./StatusRed"
import TransactionAmount from "./TransactionAmount"
import { DisplayFormattedDate, DisplayFormattedTime } from "../../Components/formattedDateAndTime"

export default function FailedCard({ transaction }: { transaction: Ledger }) {
    const { t } = useTranslation()
    const { transactionType } = useParams()

    if (transactionType === 'debit' || transactionType === 'credit') {
        return <></>
    }

    return (
        <AccordionBorder>
            <AccordionItem toggle={`panel-${transaction.id}`} color="card">
                <EventCard>
                    <EventCard.Image><CardImagePlaceHolder images={transaction?.meeting ? transaction?.meeting?.meetingDetails?.image : transaction?.meetingRequest?.guest?.image} /></EventCard.Image>
                    <EventCard.Title>
                        <CardTitle
                            keyId={transaction.id}
                            meetingTitle={
                                (transaction?.meeting?.meetingDetails?.title &&
                                    transaction?.meeting?.meetingDetails?.title.length > 40)
                                    ? `${transaction?.meeting?.meetingDetails?.title.slice(0, 40)}...`
                                    : transaction.meeting
                                        ? transaction?.meeting?.meetingDetails?.title
                                        : transaction.transactionDescription
                            }
                        />           
                    </EventCard.Title>
                    {transaction.meeting ? <EventCard.SubTitle>
                        <div className="font-normal">  {t('eventOn')}{' '}
                          <DisplayFormattedDate displayDate={transaction.display_meetingDate}/> - 
                          <DisplayFormattedTime displayFromTime={transaction.meeting.fromDateTime} displayToTime={transaction.meeting.toDateTime} />
                        </div>
                    </EventCard.SubTitle> :
                        <EventCard.SubTitle>
                            <div className="font-normal">
                                {t('requestedOn')}{' '}{transaction.meetingRequest.display_date} - {transaction.meetingRequest.display_time}
                            </div>
                        </EventCard.SubTitle>
                    }
                    <EventCard.SubTitle1>
                        <DisplayTransactionDate transaction={transaction} />
                    </EventCard.SubTitle1>

                    <EventCard.Actions>
                        <div className="flex md:flex-col text-end items-end gap-3 md:gap-0">
                            <TransactionAmount transaction={transaction} symbol={' '} />
                            <StatusRed>{t('failed')}</StatusRed>
                        </div>
                    </EventCard.Actions>
                </EventCard>
            </AccordionItem>
            <AccordionPanel id={`panel-${transaction.id}`}>
                <div className="w-full">
                    <hr />
                    <div className="flex flex-col w-full md:w-8/12 mx-auto items-center justify-between text-sm md:text-base">
                        <div className={`flex flex-row text-black w-full justify-between items-center text-normal`}>
                            <DisplayTransactionStatus transaction={transaction} ></DisplayTransactionStatus>
                            <div className="flex flex-col md:col-span-2 capitalize"><DisplayTransactionDescription transaction={transaction} /></div>
                        </div>
                    </div>
                </div>
            </AccordionPanel>
        </AccordionBorder>
    )
}