/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup'
import IMask from 'imask'
import {useEffect, useState} from 'react'
import DatePicker, {registerLocale} from 'react-datepicker'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {IMaskInput} from 'react-imask'
import {Button} from '..'
import {useGetAllMeetingCategoryQuery} from '../../Services/allOthersApi'
import {
  useGetLanguageForFilterQuery,
  useGetPriceForFilterQuery,
} from '../../Services/meetingApi'
import {getPreference} from '../../Services/userReducer'
import {useAppSelector} from '../../Store/hooks'
import {tr} from '../../Utils/helper'
import {meetingFilterFormValidationSchema} from '../../Utils/validation'
import {
  CreatorLocalFilter,
  CreatorServerFilter,
} from '../../models/creatorFilter.model'
import Chip from '../Chip'
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
} from '../base/accordion/accordion'
import Field from '../base/field/field'
import Icon from '../base/icon/icon'
import NewIcon from '../base/icon/newIcons'
import Loading from '../base/loading/loading'
import {ToolTip} from '../base/tooltip/tooltip'
import CreatorEvents from './creatorEvents'
import CreatorsImage from './creatorsImage'
import {
  changeCreatorFilter,
  changeCreatorServerFilter,
  getCreatorFilter,
  getCreatorServerFilter,
} from '../../Services/creatorReducer'
import {useDispatch} from 'react-redux'
import {useCurrencyHook} from '../../Hooks/CurrencyHooks'
import {enGB, ja} from 'date-fns/locale'
import {DisplayFormattedDate} from '../formattedDateAndTime'

export const CreatorEventsFilter = () => {
  const {t, i18n} = useTranslation()
  const dispatch = useDispatch()
  const preference = useAppSelector(getPreference)
  const creatorLocalFilter = useAppSelector(getCreatorFilter)
  const creatorServerFilter = useAppSelector(getCreatorServerFilter)
  const accordionToggleIds = [
    'filter_date',
    'filter_time',
    'filter_category',
    'filter_price',
    'filter_language',
  ]
  const style = {
    accordionToggle: `px-4 py-0 focus:outline-none font-medium transition ease-in duration-200  rounded-md
    bg-transparent text-gray-400 text-normal my-1 group`,
  }
  const date = new Date()
  const maxDate = new Date(date.setMonth(date.getMonth() + 6))
  const {data: meetingCategoryList, isLoading: meetingCategoryListLoading} =
    useGetAllMeetingCategoryQuery()
  const {data: languageList, isLoading: languageListLoading} =
    useGetLanguageForFilterQuery()
  const {data: priceList, isLoading: priceListLoading} =
    useGetPriceForFilterQuery(preference.currency)

  const {
    register,
    setValue,
    reset,
    getValues,
    watch,
    trigger,
    formState: {errors},
  } = useForm<CreatorLocalFilter & CreatorServerFilter>({
    mode: 'onChange',
    resolver: yupResolver(meetingFilterFormValidationSchema),
  })

  const [disableFreeEvent] = useState<boolean>(false)
  const [clearAllFilter, setClearAllFilter] = useState<boolean>()
  const [showOtherCategory, setShowOtherCategory] = useState<boolean>(false)
  // const [otherCategory, setOtherCategory] = useState<string>("")
  // const [otherCategory, setOtherCategory] = useState<string>('')

  registerLocale('en', enGB)
  registerLocale('ja', ja)

  const input_startTime = document.getElementById('input_startTime')
  const input_endTime = document.getElementById('input_endTime')

  const {formatCurrency, formatCurrencySymbol} = useCurrencyHook()

  function highlightWeekends(date: Date) {
    if (date.getDay() === 0) {
      return 'weekend-calender-text-color left-rounded-calender'
    }
    if (date.getDay() === 6) {
      return 'weekend-calender-text-color right-rounded-calender'
    }
    return ''
  }

  useEffect(() => {
    if (preference?.meetingLanguage) {
      setValue('language', preference.meetingLanguage)
      setValue('_startFromTime', '')
      setValue('_startToTime', '')
    }
  }, [preference])

  useEffect(() => {
    const subscription = watch((value: any) => {
      dispatch(changeCreatorFilter(value as CreatorLocalFilter))
      dispatch(
        changeCreatorServerFilter({
          language: value.language,
          fromDateTime: value.fromDateTime,
          toDateTime: value.toDateTime,
        } as CreatorServerFilter),
      )
    })

    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (creatorServerFilter.fromDateTime && creatorServerFilter.toDateTime) {
      const dateFilter = document.getElementById('filter_date')
      if (dateFilter) {
        dateFilter.click()
        dateFilter.className = style.accordionToggle
      }
    }
  }, [creatorServerFilter.fromDateTime, creatorServerFilter.toDateTime])

  useEffect(() => {
    setValue('otherCategory', showOtherCategory)
    setClearAllFilter(true)
  }, [showOtherCategory])

  const clearFilter = () => {
    const localFilter = {
      title: '',
      meetingCategory: undefined,
      otherCategory: false,

      minPrice: priceList?.min,
      maxPrice: priceList?.max,

      fromDateTime: undefined,
      toDateTime: undefined,

      _startFromTime: '',
      _startToTime: '',
      freeEvent: false,
      freeLiveEvent: undefined,
    }

    const serverFilter = {
      language: undefined,

      fromDate: undefined,
      toDate: undefined,
    }

    if (input_startTime) {
      ;(input_startTime as any).value = ''
    }

    if (input_endTime) {
      ;(input_endTime as any).value = ''
    }
    const filter = {...localFilter, ...serverFilter} as CreatorLocalFilter &
      CreatorServerFilter
    reset(filter)
    setShowOtherCategory(false)
    dispatch(changeCreatorFilter(localFilter))
    dispatch(changeCreatorServerFilter(serverFilter))
  }

  const onDateRangeSelected = (dates: any) => {
    const [start, end] = dates
    if (start && !end) {
      setValue('fromDateTime', start.toISOString())
      setValue('toDateTime', undefined)
    } else {
      setValue('fromDateTime', start.toISOString())
      end.setDate(end.getDate())
      setValue('toDateTime', end.toISOString())
    }
  }

  const resetOnlyPriceFilter = () => {
    setValue('minPrice', priceList?.min)
    setValue('maxPrice', priceList?.max)
    trigger('maxPrice')
    trigger('minPrice')
  }

  const startDate = creatorServerFilter.fromDateTime
    ? new Date(creatorServerFilter.fromDateTime)
    : ''

  const endDate = creatorServerFilter.toDateTime
    ? new Date(creatorServerFilter.toDateTime)
    : ''

  const handleRemoveDate = () => {
    setValue('fromDateTime', '')
    setValue('toDateTime', '')
  }

  const handleRemoveTime = () => {
    setValue('_startFromTime', '')
    setValue('_startToTime', '')

    if (input_startTime) {
      ;(input_startTime as any).value = ''
    }

    if (input_endTime) {
      ;(input_endTime as any).value = ''
    }
  }

  const handleRemovePrice = () => {
    setValue('minPrice', 0)
    setValue('maxPrice', priceList?.max)
    setValue('freeEvent', false)
  }

  const handleAddCategoryList = (categoryName: string) => {
    const selectedCategory = creatorLocalFilter.meetingCategory ?? []
    const found = selectedCategory?.find(
      (category: any) => category === categoryName,
    )
    if (!found) {
      setValue('meetingCategory', [...selectedCategory, categoryName])
    }
  }

  const handleRemoveCategory = (item: string) => {
    setValue(
      'meetingCategory',
      creatorLocalFilter.meetingCategory?.filter(
        (category: any) => category !== item,
      ),
    )
  }

  const handleAddLanguageList = (language: string) => {
    const selectedLangs = creatorServerFilter.language ?? []
    const found = selectedLangs?.find((lang: any) => lang === language)
    if (!found) {
      setValue('language', [...selectedLangs, language])
    }
  }

  const handleRemoveLanguage = (item: any) => {
    setValue(
      'language',
      creatorServerFilter.language?.filter((lang: any) => lang !== item),
    )
  }

  const getAsNumber = (value: any) => {
    if (!value) return 0
    if (value === '') return 0
    if (typeof value === 'number') return value

    return Number(value.replace(/,/g, ''))
  }

  const isPriceFilterAdded = () => {
    if (errors?.maxPrice || errors?.minPrice) {
      return false
    }
    const min = getAsNumber(watch('minPrice') ?? 0)
    const max = getAsNumber(watch('maxPrice') ?? 0)
    if (min === 0 && max === 0) return false
    if (
      min === 0 &&
      (max === priceList?.max || max === parseInt(priceList?.max))
    )
      return false

    return min >= 0 && max > min
  }

  const accordionActivator = (e: any) => {
    e.currentTarget.className = e.currentTarget.className.replace(
      /my-1/g,
      'my-1 text-black ',
    )
    accordionToggleIds.forEach((id: string) => {
      if (id !== e.currentTarget.id) {
        let x = document.getElementById(id as string)
        if (x) x.className = style.accordionToggle
      }
    })
  }

  const meridiemUpdate = (
    e: any,
    timeHead: '_startFromTime' | '_startToTime',
  ) => {
    let value = getValues(timeHead) as string
    if (e.currentTarget.innerText === 'AM') {
      if (value?.indexOf('M') !== -1) {
        e.currentTarget.innerText = 'PM'
        setValue(timeHead, watch(timeHead)?.replace('AM', 'PM'))
      } else {
        setValue(timeHead, watch(timeHead) + ':PM')
      }
    } else {
      e.currentTarget.innerText = 'AM'
      if (value?.indexOf('M') !== -1) {
        setValue(timeHead, watch(timeHead)?.replace('PM', 'AM'))
      } else {
        setValue(timeHead, watch(timeHead) + ':AM')
      }
    }
    trigger(['_startFromTime', '_startToTime'])
  }

  const updateStartAndEndTime = (mask: string) => {
    const startMeridiem = document.getElementById('startMeridiem')?.innerText
    let obj = document.getElementById('endMeridiem') as HTMLElement

    if (mask) {
      setValue('_startFromTime', mask + ' ' + startMeridiem)
      const time = mask.split(':')
      const h = Number(time[0])
      const m = Number(time[1])
      let finalTime = ''
      if ((h && m) || h === 0 || m === 0) {
        if (h < 9) {
          if (m < 10) {
            finalTime = '0' + (h + 1) + ':0' + m
          } else {
            finalTime = '0' + (h + 1) + ':' + m
          }
        } else if (h >= 10 && h < 12) {
          if (m < 10) {
            finalTime = h + 1 + ':0' + m
          } else {
            finalTime = h + 1 + ':' + m
          }
        } else {
          if (h === 9) {
            finalTime = h + 1 + ':' + (m < 10 ? '0' + m : m)
          } else if (m < 10) {
            finalTime = '01:0' + m
          } else {
            finalTime = '01:' + m
          }
        }
        if (obj !== null && obj !== undefined && startMeridiem && finalTime) {
          ;(input_endTime as any).value = finalTime
          let meridiem = startMeridiem
          if (h === 12) {
            obj.innerText = meridiem = startMeridiem === 'AM' ? 'PM' : 'AM'
          } else {
            obj.innerText = startMeridiem
          }
          setValue('_startToTime', finalTime + ' ' + meridiem)
        }
      }
    }
    trigger(['_startFromTime', '_startToTime'])
  }

  useEffect(() => {
    if (
      getValues('fromDateTime') ||
      getValues('toDateTime') ||
      creatorLocalFilter._startFromTime ||
      creatorLocalFilter._startToTime ||
      getValues('freeEvent') ||
      isPriceFilterAdded() ||
      (creatorServerFilter?.language?.length as number) > 0 ||
      (creatorLocalFilter?.meetingCategory?.length as number) > 0 ||
      getValues('title')
    ) {
      setClearAllFilter(true)
    } else {
      setClearAllFilter(false)
    }
  }, [
    getValues('fromDateTime'),
    getValues('toDateTime'),
    errors?._startFromTime,
    creatorLocalFilter._startFromTime,
    creatorLocalFilter._startToTime,
    getValues('freeEvent'),
    isPriceFilterAdded(),
    creatorServerFilter?.language?.length,
    creatorLocalFilter?.meetingCategory?.length,
    getValues('title'),
  ])

  useEffect(() => {
    isPriceFilterAdded()
  }, [getValues('minPrice'), getValues('maxPrice')])

  if (
    meetingCategoryListLoading ||
    languageListLoading ||
    !priceList ||
    priceListLoading
  ) {
    return <Loading />
  }

  let filterCategoriesCount = 0

  if ((creatorLocalFilter?.meetingCategory?.length as number) > 0) {
    filterCategoriesCount = creatorLocalFilter?.meetingCategory
      ?.length as number
  }
  if (creatorLocalFilter?.otherCategory) {
    filterCategoriesCount += 1
  }

  return (
    <>
      <CreatorsImage />
      <div className="border-t lg:pl-[7.5%] py-1">
        <div className="flex flex-col items-start justify-between md:flex-row gap-2">
          <Accordion>
            <div className="flex flex-col">
              <div className="flex flex-row items-center flex-wrap px-2 lg:px-0 mt-2">
                {clearAllFilter ? (
                  <ToolTip tip="clearAllFilters">
                    <div
                      className="h-[20px] w-[30px]"
                      id="clearFilters"
                      data-testid="clearFilters"
                      role="button"
                      onClick={(e: any) => {
                        clearFilter()
                        accordionActivator(e)
                      }}>
                      <NewIcon
                        icon="FILTER_RESET"
                        size="small"
                        height="small"
                      />
                    </div>
                  </ToolTip>
                ) : (
                  <div className="h-[20px] w-[30px]" />
                )}

                {/* Date Filter */}
                <div className="flex flex-row">
                  <AccordionItem toggle="dateFilter" color="filter">
                    <Button
                      id="filter_date"
                      data-testid="filter_date"
                      size="filter"
                      color="transparent"
                      className={`my-1 text-gray-400`}
                      onClick={accordionActivator}>
                      {t('date')}
                    </Button>

                    {startDate && endDate && (
                      <Chip
                        addCatId="dateFilter"
                        removeCatId="removeDateFilter"
                        isSelected={true}
                        value="Date"
                        onClickClose={handleRemoveDate}>
                        <>
                          <DisplayFormattedDate displayDate={startDate} />{' '}-{' '}
                          <DisplayFormattedDate displayDate={endDate} />
                        </>
                      </Chip>
                    )}
                  </AccordionItem>
                </div>

                {/* Time Filter */}
                <div className="flex flex-row">
                  <AccordionItem toggle="timeFilter" color="filter">
                    <Button
                      id="filter_time"
                      data-testid="filter_time"
                      size="filter"
                      color="transparent"
                      className={`my-1 text-gray-400`}
                      onClick={e => {
                        if (
                          (input_startTime as any)?.value === '__:__' &&
                          (input_endTime as any)?.value === '__:__'
                        ) {
                          ;(input_startTime as any).value = (
                            input_endTime as any
                          ).value = ''
                        }
                        accordionActivator(e)
                      }}>
                      {t('time')}
                    </Button>

                    {creatorLocalFilter._startFromTime &&
                      creatorLocalFilter._startToTime && (
                        <Chip
                          addCatId="timeFilter"
                          removeCatId="resetTimeFilter"
                          isSelected={true}
                          value={`${creatorLocalFilter._startFromTime} - ${creatorLocalFilter._startToTime}`}
                          onClickClose={handleRemoveTime}>
                         {/* TODO Time Filter Format */}
                        </Chip>
                      )}
                  </AccordionItem>
                </div>

                {/* Price Filter */}
                <div className="flex flex-row">
                  <AccordionItem toggle="priceFilter" color="filter">
                    <Button
                      id="filter_price"
                      data-testid="filter_price"
                      size="filter"
                      color="transparent"
                      className={`my-1 text-gray-400`}
                      onClick={accordionActivator}>
                      {t('price')}
                    </Button>
                  </AccordionItem>
                  {/* {getValues('freeEvent') &&
                    <Button size="sm" color="filteredData" className="flex items-center my-2">
                      {t('freeEvent')} <Icon icon="CLOSE" onClick={resetPriceFilter} />
                    </Button>
                  }
                  {isPriceFilterAdded() && <Button size="sm" color="filteredData" className="flex items-center my-2">
                    {watch('minPrice')} - {watch('maxPrice')} <Icon icon="CLOSE" onClick={resetPriceFilter} />
                  </Button>} */}

                  {creatorLocalFilter.freeEvent && (
                    <Chip
                      addCatId="priceFilter"
                      removeCatId="resetPriceFilter"
                      isSelected={true}
                      value={(t('freeEvents'))}
                      onClickClose={handleRemovePrice}
                    />
                  )}

                  {!creatorLocalFilter.freeEvent && <DisplayFilterPrice />}
                </div>

                {/* Language Filter */}
                <div className="flex flex-row flex-wrap group relative">
                  <AccordionItem toggle="languageFilter" color="filter">
                    <Button
                      id="filter_language"
                      data-testid="filter_language"
                      size="filter"
                      color="transparent"
                      className={`my-1 text-gray-400`}
                      style={{position: 'relative'}}
                      onClick={accordionActivator}>
                      {t('language')}
                      {creatorServerFilter?.language &&
                        languageList &&
                        (creatorServerFilter.language.length as number) > 0 && (
                          <div
                            id="lang_count"
                            data-testid="lang_count"
                            className="absolute w-4 h-4 bg-BeeMG-yellow rounded-full flex items-center justify-center text-xs font-semibold right-1 -top-1 text-black group-hover:bg-red-500 group-hover:text-white">
                            {
                              creatorServerFilter.language.filter(language =>
                                languageList.some(
                                  lang => lang.language === language,
                                ),
                              ).length
                            }
                          </div>
                        )}
                    </Button>
                  </AccordionItem>
                </div>

                {/* Category Filter */}
                <div className="flex flex-row flex-wrap group relative">
                  <AccordionItem toggle="categoryFilter" color="filter">
                    <Button
                      id="filter_category"
                      data-testid="filter_category"
                      size="filter"
                      color="transparent"
                      className={`my-1 text-gray-400`}
                      style={{position: 'relative'}}
                      onClick={accordionActivator}>
                      {t('category')}
                      {filterCategoriesCount > 0 && (
                        <div
                          data-testid="category_count"
                          className="absolute w-4 h-4 bg-BeeMG-yellow rounded-full flex items-center justify-center text-xs font-semibold right-1 -top-1 text-black group-hover:bg-red-500 group-hover:text-white">
                          {filterCategoriesCount}
                        </div>
                      )}
                    </Button>
                  </AccordionItem>
                </div>
              </div>

              {/* date */}
              <div>
                <div>
                  <AccordionPanel id="dateFilter">
                    <div className="mx-1 md:w-full ml-[2%] mt-4">
                      <DatePicker
                        locale={i18n.language}
                        minDate={new Date()}
                        maxDate={maxDate}
                        monthsShown={window.innerWidth > 640 ? 2 : 1}
                        onChange={onDateRangeSelected}
                        startDate={
                          creatorServerFilter.fromDateTime
                            ? new Date(creatorServerFilter.fromDateTime)
                            : undefined
                        }
                        endDate={
                          creatorServerFilter.toDateTime
                            ? new Date(creatorServerFilter.toDateTime)
                            : undefined
                        }
                        selectsRange
                        inline
                        dayClassName={highlightWeekends}
                        calendarStartDay={0}
                        renderCustomHeader={({
                          monthDate,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: 'flex',
                              justifyContent: 'center',
                            }}>
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}>
                              {'<'}
                            </button>
                            <span className="react-datepicker__current-month min-w-scheduleCard flex justify-center mx-2">
                              <DisplayFormattedDate
                                displayDate={monthDate}
                                dateFormat="MMMM yyyy"
                              />
                            </span>
                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}>
                              {'>'}
                            </button>
                          </div>
                        )}
                      />
                    </div>
                  </AccordionPanel>

                  {/* time */}
                  <AccordionPanel id="timeFilter">
                    <div className="mx-1 md:w-full mt-4">
                      <span className="flex flex-row items-center">
                        <div className="flex flex-col gap-4 md:flex-row md:ml-10 mt-1">
                          <div className="col-span-6 sm:col-span-3 mx-2">
                            <div className="flex items-center gap-x-0.5">
                              <div className="relative items-center">
                                <span className="md:w-auto xs:w-16 sm:w-16 mr-2 font-medium">
                                  {' '}
                                  {t('from')}{' '}
                                </span>
                                <IMaskInput
                                  mask="H:M"
                                  {...register('_startFromTime')}
                                  id="input_startTime"
                                  data-testid="input_startTime"
                                  unmask={true}
                                  placeholder="_ _:_ _"
                                  className={`text-base tracking-wide relative rounded-lg mr-5 px-3 md:py-2 py-1 ${
                                    watch('_startFromTime') === undefined ||
                                    watch('_startFromTime')?.startsWith('_') ||
                                    watch('_startFromTime') === '' ||
                                    watch('_startFromTime')?.includes(':')
                                      ? 'w-28'
                                      : 'w-24'
                                  } bg-white text-gray-700 placeholder-gray-400  focus:border-transparent uppercase  outline-none`}
                                  lazy={false}
                                  overwrite={true}
                                  autoComplete="off"
                                  defaultValue={undefined}
                                  onAccept={(value: any, mask: any, e: any) =>
                                    updateStartAndEndTime(mask.value)
                                  }
                                  blocks={{
                                    H: {
                                      mask: IMask.MaskedRange,
                                      from: 1,
                                      to: 12,
                                      maxLength: 2,
                                    },
                                    M: {
                                      mask: IMask.MaskedRange,
                                      from: 0,
                                      to: 59,
                                      maxLength: 2,
                                    },
                                  }}
                                />
                                <div
                                  className="absolute uppercase right-7 top-2.5 select-none"
                                  id="startMeridiem"
                                  data-testid="startMeridiem"
                                  onClick={e =>
                                    meridiemUpdate(e, '_startFromTime')
                                  }>
                                  AM
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <div className="flex items-center">
                              <div className="relative items-center">
                                <span className="md:w-auto xs:w-16 sm:w-16 md:mr-2 lowercase font-medium">
                                  {' '}
                                  {t('to')}{' '}
                                </span>
                                <IMaskInput
                                  mask="H:M"
                                  {...register('_startToTime')}
                                  id="input_endTime"
                                  data-testid="input_endTime"
                                  unmask={true}
                                  placeholder="_ _:_ _"
                                  className={`text-base tracking-wide relative rounded-lg mr-5 px-3 md:py-2 py-1 ${
                                    watch('_startToTime') === undefined ||
                                    watch('_startToTime')?.startsWith('_') ||
                                    watch('_startToTime') === '' ||
                                    watch('_startToTime')?.includes(':')
                                      ? 'w-28'
                                      : 'w-24'
                                  } bg-white text-gray-700 placeholder-gray-400 focus:border-transparent uppercase  outline-none`}
                                  lazy={false}
                                  overwrite={true}
                                  autoComplete="off"
                                  defaultValue={undefined}
                                  onAccept={(value: any, mask: any, e: any) => {
                                    const endMeridiem =
                                      document.getElementById(
                                        'endMeridiem',
                                      )?.innerText
                                    setValue(
                                      '_startToTime',
                                      mask.value + ' ' + endMeridiem,
                                    )
                                    trigger(['_startFromTime', '_startToTime'])
                                  }}
                                  blocks={{
                                    H: {
                                      mask: IMask.MaskedRange,
                                      from: 1,
                                      to: 12,
                                      maxLength: 2,
                                    },
                                    M: {
                                      mask: IMask.MaskedRange,
                                      from: 0,
                                      to: 59,
                                      maxLength: 2,
                                    },
                                  }}
                                />
                                <div
                                  className="absolute uppercase right-7 top-2.5 select-none"
                                  id="endMeridiem"
                                  data-testid="endMeridiem"
                                  onClick={e =>
                                    meridiemUpdate(e, '_startToTime')
                                  }>
                                  AM
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                      <div className="text-left w-full mt-1.5 md:ml-12">
                        {errors._startFromTime && (
                          <span
                            className="text-red-500 text-sm"
                            id="error-message">
                            {t((errors._startFromTime as any)?.message)}
                          </span>
                        )}
                      </div>
                      {!errors?._startFromTime &&
                        creatorLocalFilter._startFromTime &&
                        creatorLocalFilter._startToTime &&
                        !watch('_startFromTime')?.includes('_') &&
                        !watch('_startToTime')?.includes('_') && (
                          <div
                            id="timeRange"
                            className="flex flex-row items-center gap-x-3 md:ml-12 mt-3">
                            <Icon icon="CLOCK_BLACK" size="small" />
                            {creatorLocalFilter._startFromTime} {t('to')}{' '}
                            {creatorLocalFilter._startToTime}
                            <div className="flex items-center text-sm font-semibold">
                              {preference.timezone}
                            </div>
                          </div>
                        )}
                    </div>
                  </AccordionPanel>

                  {/* price */}
                  <AccordionPanel id="priceFilter">
                    <div className="flex flex-col pl-12 mt-4">
                      <div className="flex h-12 items-center">
                        <label
                          className="pl-2 mr-12 font-medium"
                          htmlFor="freeEvent">
                          {t('freeEvents')}
                        </label>
                        <Field
                          {...register('freeEvent')}
                          id="filter_searchByFreeEvent"
                          data-testid="filter_searchByFreeEvent"
                          type="checkbox"
                          disabled={disableFreeEvent}
                          name="freeEvent"
                          defaultChecked={creatorLocalFilter.freeEvent}
                          onChange={e => {
                            const freeEvent = (e.target as any).checked
                            setValue('freeEvent', freeEvent)
                            dispatch(
                              changeCreatorFilter({
                                ...creatorLocalFilter,
                                freeEvent,
                              }),
                            )
                            if (watch('freeEvent')) {
                              resetOnlyPriceFilter()
                            }
                          }}
                        />
                      </div>
                      <div className="flex flex-row items-center">
                        <div className="flex flex-row ml-2 mt-1">
                          <div className="w-44 col-span-6 sm:col-span-3 mr-2 flex items-center">
                            <div className="font-bold mr-2">
                              {formatCurrencySymbol(preference.currency)}
                            </div>
                            <Field
                              {...register('minPrice')}
                              type="text"
                              defaultValue={priceList?.min}
                              disabled={
                                creatorLocalFilter.freeEvent ||
                                watch('freeEvent')
                              }
                              onChange={(e: any) => {
                                setValue(
                                  'minPrice',
                                  getAsNumber(e.target.value),
                                )

                                trigger(['minPrice', 'maxPrice'])
                              }}
                              id="filter_searchByMinPrice"
                              data-testid="filter_searchByMinPrice"
                              placeholder={t('Minimum price')}
                              onKeyPress={value => {
                                if (!/[0-9]/.test(value.key)) {
                                  value.preventDefault()
                                }
                              }}
                              className={`${
                                errors?.minPrice ? 'border-red-500' : ''
                              }`}
                            />
                          </div>
                          <div className="w-44 col-span-6 sm:col-span-3 flex items-center">
                            <div className="font-bold mr-2">
                              {formatCurrencySymbol(preference.currency)}
                            </div>
                            <Field
                              {...register('maxPrice')}
                              name="maxPrice"
                              type="text"
                              defaultValue={priceList?.max}
                              disabled={
                                creatorLocalFilter.freeEvent ||
                                watch('freeEvent')
                              }
                              onChange={(e: any) => {
                                setValue(
                                  'maxPrice',
                                  getAsNumber(e.target.value),
                                )

                                trigger(['minPrice', 'maxPrice'])
                              }}
                              id="filter_searchByMaxPrice"
                              data-testid="filter_searchByMaxPrice"
                              placeholder={t('Maximum price')}
                              onKeyPress={value => {
                                if (!/[0-9]/.test(value.key)) {
                                  value.preventDefault()
                                }
                              }}
                              className={`${
                                errors?.maxPrice ? 'border-red-500' : ''
                              } ${
                                creatorLocalFilter.freeEvent
                                  ? ' bg-gray-200 '
                                  : ' '
                              }`}
                            />
                          </div>
                        </div>

                        <div
                          className="text-left"
                          style={{color: 'red !important'}}>
                          {(errors?.minPrice || errors?.maxPrice) && (
                            <div className="flex items-center ml-2 text-red-500 text-xs">
                              <div className="mr-2">
                                <Icon
                                  icon="ALERT"
                                  size="medium"
                                  className="stroke-red-500"
                                  color="red"
                                />
                              </div>
                              <div>
                                {errors?.minPrice && (
                                  <div
                                    id="error_minPrice"
                                    className="font-medium">
                                    {tr(errors.minPrice.message)}
                                  </div>
                                )}
                                {errors?.maxPrice && (
                                  <div
                                    id="error_maxPrice"
                                    className="font-medium">
                                    {tr(errors.maxPrice.message, {
                                      maxPrice: priceList?.max,
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </AccordionPanel>

                  <AccordionPanel id="languageFilter">
                    <div className="md:ml-6 mx-1 w-full p-5 mt-4 flex flex-wrap gap-1">
                      {languageList &&
                        [...languageList]
                          .sort((a, b) => a.language.localeCompare(b.language))
                          .map((languages: any, index: number) => (
                            <Chip
                              isSelected={creatorServerFilter.language?.includes(
                                languages.language,
                              )}
                              onClickClose={() =>
                                handleRemoveLanguage(languages.language)
                              }
                              value={languages.language}
                              key={index}
                              onClick={() => {
                                handleAddLanguageList(languages.language)
                              }}
                              addCatId={`input_${languages.language
                                .split(' ')
                                .join('')}Language`}
                              removeCatId={`remove_${languages.language
                                .split(' ')
                                .join('')}Language`}
                            />
                          ))}
                    </div>
                  </AccordionPanel>

                  <AccordionPanel id="categoryFilter">
                    <div className="md:ml-6 mx-1 w-full p-5 mt-4 flex flex-wrap gap-1">
                      {meetingCategoryList &&
                        [...meetingCategoryList]
                          .sort((a, b) =>
                            a.categoryName.localeCompare(b.categoryName),
                          )
                          ?.map((category: any, index: number) => (
                            <Chip
                              isSelected={creatorLocalFilter.meetingCategory?.includes(
                                category.categoryName,
                              )}
                              onClickClose={() =>
                                handleRemoveCategory(category.categoryName)
                              }
                              value={t(`meetingCategories.${category.categoryName}`)}
                              key={index}
                              onClick={() => {
                                handleAddCategoryList(category.categoryName)
                              }}
                              addCatId={`input_${category.categoryName
                                .split(' ')
                                .join('')}Category`}
                              removeCatId={`remove_${category.categoryName
                                .split(' ')
                                .join('')}Category`}
                            />
                          ))}
                      <div className="h-4">
                        <Chip
                          addCatId="btn_addOthers"
                          removeCatId="btn_removeOthers"
                          isSelected={showOtherCategory}
                          onClick={() =>
                            setShowOtherCategory(!showOtherCategory)
                          }
                          value={t('others')}
                        />
                      </div>
                    </div>
                  </AccordionPanel>
                </div>
              </div>
              <div className="h-0.4 ml-[3%] self-start my-4 bg-gray-200 border w-[95%] mr-auto" />
            </div>
          </Accordion>

          {/* Title search Filter */}
          <div className="min-w-[13rem] mt-2 mx-3">
            <Field
              {...register('title')}
              name="title"
              type="text"
              placeholder={t('customSearch')}
              id="filter_searchByTitle"
              data-testid="filter_searchByTitle"
              icon={<NewIcon icon="SEARCH_GLASS" stroke="#535353" />}
            />
          </div>
        </div>
      </div>
      <CreatorEvents
        creatorLocalFilter={
          Object.keys(errors).length ? undefined : creatorLocalFilter
        }
        creatorServerFilter={
          Object.keys(errors).length ? undefined : creatorServerFilter
        }
      />
    </>
  )

  function DisplayFilterPrice() {
    const minPrice = getAsNumber(creatorLocalFilter.minPrice)
    const maxPrice = getAsNumber(creatorLocalFilter.maxPrice)
    const priceMax = getAsNumber(priceList?.max)

    if (minPrice === 0 && maxPrice === 0) {
      return null
    }

    if (minPrice === 0 && maxPrice === priceMax) {
      return null
    }

    return (
      <>
        {minPrice === 0 && maxPrice > 0 && (
          <Chip
            addCatId="priceFilter"
            removeCatId="remove_priceFilter"
            isSelected={true}
            value={`${t('under')} ${formatCurrency(
              creatorLocalFilter.maxPrice ?? 0,
              preference?.currency,
            )}`}
            onClickClose={handleRemovePrice}
          />
        )}

        {minPrice > 0 && maxPrice > 0 && (
          <Chip
            addCatId="priceFilter"
            removeCatId="remove_priceFilter"
            isSelected={true}
            value={`${formatCurrency(
              creatorLocalFilter.minPrice,
              preference?.currency,
            )} to ${formatCurrency(
              creatorLocalFilter.maxPrice,
              preference?.currency,
            )}`}
            onClickClose={handleRemovePrice}
          />
        )}
      </>
    )
  }
}
