import { useEffect, useState } from 'react';
import { useGetSettingValue } from '../Services/settingReducer'
import { useAppDispatch } from '../Store/hooks'
import { feedbackApi } from '../Services/feedbackApi'
import { Feedback } from '../models/feedback.model'


export const useFeedbackHooks = ({ meetingDetailsId }: { meetingDetailsId: string }) => {

  const [minLimit, setMinLimit] = useState<number>(3)
  const limit = useGetSettingValue('EVENT_FEEDBACK_LIMIT')
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (limit) {
      setMinLimit(parseInt(limit))
    }
  }, [limit])

  const getFeedback = () => {
    const args = {
      meetingDetailsId: meetingDetailsId,
      limit: limit,
      skip: feedbacks.length,
    }
    const res = dispatch(
      feedbackApi.endpoints.getByMeetingDetailsId.initiate(args),
    )
    res.then((val: any) => {
      const listed = [...feedbacks, ...val?.data?.results]
      setFeedbacks(listed)
    })
  }

  useEffect(() => {
    if (meetingDetailsId && minLimit && minLimit > feedbacks.length) {
      getFeedback()
    }
  }, [meetingDetailsId, minLimit, feedbacks.length])

  const increaseLimit = () => {
    setMinLimit(minLimit + parseInt(limit))
  }

  const decreaseLimit = () => {
    const newLimit = Number(limit)

    setMinLimit(newLimit)
    setFeedbacks(feedbacks.slice(0, newLimit))
  }
  
  

  return {
    minLimit,
    increaseLimit,
    feedbacks,
    limit,
    decreaseLimit
  }
}


