import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MeetingDetails } from '../../../models/meetingDetails.model'
import { CreatorCard } from '../../../Components/Creators/CreatorCard'
import DisplayName from '../../../Components/base/user-name/DisplayName'
import { useViewHostProfileHook } from '../../../Hooks/ViewHostProfileHook'

const HostEvents = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const { eventDetailsData, hostData } = useViewHostProfileHook(id ? id : '')

  if (!eventDetailsData || eventDetailsData.length === 0 || !hostData) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-3 justify-center items-center w-full">
      <div data-testid={`hostName-${eventDetailsData?.length}`} className="font-medium text-xl inline-flex leading-none"><DisplayName user={hostData} hostAnonymous={hostData?.anonymous} />{`'s ${t('events')}`}</div>
      <div className="w-full flex flex-row gap-x-3 flex-wrap items-center justify-center">
        {eventDetailsData?.map((meetingDetail: MeetingDetails, index: number) => (
          <div key={index.toString()}>
            <CreatorCard meeting={meetingDetail.meetings[0]} meetingDetails={meetingDetail} eventsTitle={meetingDetail.title} keyId={index} fromHostEvent={true} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HostEvents;
