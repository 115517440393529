import { RoomContext, TrackReferenceOrPlaceholder, useLiveKitRoom } from '@livekit/components-react'
import {
  ConnectionState,
  type AudioCaptureOptions,
  type RoomConnectOptions,
  type RoomOptions,
  type ScreenShareCaptureOptions,
  type VideoCaptureOptions,
  MediaDeviceFailure,
  Room,
  // RoomEvent,
  // Track,
  // LocalAudioTrack,
} from 'livekit-client'
import * as React from 'react'
import { CustomRoomContext } from './CustomRoomContext'
import { UserLayoutPosition } from '../liveKitHelpers'
// import { KrispNoiseFilter, isKrispNoiseFilterSupported } from '@livekit/krisp-noise-filter'

/** @public */
export interface LiveKitRoomProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onError'> {
  /**
   * URL to the LiveKit server.
   * For example: `wss://<domain>.livekit.cloud`
   * To simplify the implementation, `undefined` is also accepted as an intermediate value, but only with a valid string url can the connection be established.
   */
  serverUrl: string | undefined
  /**
   * A user specific access token for a client to authenticate to the room.
   * This token is necessary to establish a connection to the room.
   * To simplify the implementation, `undefined` is also accepted as an intermediate value, but only with a valid string token can the connection be established.
   *
   * @see https://docs.livekit.io/cloud/project-management/keys-and-tokens/#generating-access-tokens
   */
  token: string | undefined
  /**
   * Publish audio immediately after connecting to your LiveKit room.
   * @defaultValue `false`
   * @see https://docs.livekit.io/client-sdk-js/interfaces/AudioCaptureOptions.html
   */
  audio?: AudioCaptureOptions | boolean
  /**
   * Publish video immediately after connecting to your LiveKit room.
   * @defaultValue `false`
   * @see https://docs.livekit.io/client-sdk-js/interfaces/VideoCaptureOptions.html
   */
  video?: VideoCaptureOptions | boolean
  /**
   * Publish screen share immediately after connecting to your LiveKit room.
   * @defaultValue `false`
   * @see https://docs.livekit.io/client-sdk-js/interfaces/ScreenShareCaptureOptions.html
   */
  screen?: ScreenShareCaptureOptions | boolean
  /**
   * If set to true a connection to LiveKit room is initiated.
   * @defaultValue `false`
   */
  connect?: boolean
  /**
   * Options for when creating a new room.
   * When you pass your own room instance to this component, these options have no effect.
   * Instead, set the options directly in the room instance.
   *
   * @see https://docs.livekit.io/client-sdk-js/interfaces/RoomOptions.html
   */
  options?: RoomOptions
  /**
   * Define options how to connect to the LiveKit server.
   *
   * @see https://docs.livekit.io/client-sdk-js/interfaces/RoomConnectOptions.html
   */
  connectOptions?: RoomConnectOptions
  onConnected?: () => void
  onDisconnected?: () => void
  onError?: (error: Error) => void
  onMediaDeviceFailure?: (failure?: MediaDeviceFailure) => void
  /**
   * Optional room instance.
   * By passing your own room instance you overwrite the `options` parameter,
   * make sure to set the options directly on the room instance itself.
   */
  room?: Room

  simulateParticipants?: number | undefined
  toasterMsg?: string
  onMessage?: (message: string, type: 'error' | 'info' | 'success') => void
}

export interface LiveKitConfig {
  meetingEndTime: string
  waitingTimeForHostOrCoHost: number // in seconds example 5*60 min
  alertsBeforeMeetingEnds: number[] // in seconds example [5*60, 2*60, 1*60, 10]
  isAudioOnly: boolean
}

// type RoomContextState = {
//   room: Room;
//   connectionState: ConnectionState;
//   participants: Participant[];
//   audioTracks: AudioTrack[];
// };

/**
 * The LiveKitRoom component provides the room context to all its child components.
 * It is generally the starting point of your LiveKit app and the root of the LiveKit component tree.
 * It provides the room state as a React context to all child components, so you don't have to pass it yourself.
 *
 * @example
 * ```tsx
 * <LiveKitRoom
 *  token='<livekit-token>'
 *  serverUrl='<url-to-livekit-server>'
 *  connect={true}
 * >
 *     ...
 * </LiveKitRoom>
 * ```
 * @public
 */
export function CustomLiveKitRoom(props: React.PropsWithChildren<LiveKitRoomProps & LiveKitConfig>) {
  const [config] = React.useState<LiveKitConfig>(props)
  const [isMinimized, setIsMinimized] = React.useState(false)
  const [stagedParticipants, setStagedParticipants] = React.useState<string[]>([])
  const [isUnmuteAllowed, setIsUnmuteAllowed] = React.useState(false)

  const [unpinnedLayoutPositions, setUnpinnedLayoutPositions] = React.useState<UserLayoutPosition[]>([])
  const [stagedLayoutPositions, setStagedLayoutPositions] = React.useState<UserLayoutPosition[]>([])
  const [otherLayoutPositions, setOtherLayoutPositions] = React.useState<UserLayoutPosition[]>([])
  const [isHostOrCohostAvailable, setIsHostOrCohostAvailable] = React.useState(false)
  const [gridDimensions, setGridDimensions] = React.useState({ width: 0, height: 0 })
  const [shouldLeaveMeeting, setShouldLeaveMeeting] = React.useState(false)
  const [handRaisedParticipants, setHandRaisedParticipants] = React.useState<string[]>([])
  const [isParticipantHandRaised, setIsParticipantHandRaised] = React.useState(false)
  const [isHandRaised, setIsHandRaised] = React.useState(false)
  const [isHostEnded, setIsHostEnded] = React.useState(false)
  const [allTracks, setAllTracks] = React.useState<TrackReferenceOrPlaceholder[]>([])
  const [allTracksSids, setAllTracksSids] = React.useState<string[]>([])

  const [viewType, setViewType] = React.useState<'grid' | 'hostHighlighted'>('hostHighlighted')

  const { room } = useLiveKitRoom({
    ...props,
    audio: false,
    video: false,
    onConnected: () => {
      if (room && room.localParticipant) {
        room.localParticipant.setCameraEnabled(false)
        room.localParticipant.setMicrophoneEnabled(false)
      }

      if (props.onConnected) {
        props.onConnected()
      }
    },
    options: {
      ...props.options,
    },
  })

  // room?.on(RoomEvent.LocalTrackPublished, async (trackPublication) => {
  //   console.log('inside krisp')

  //   if (trackPublication.source === Track.Source.Microphone && trackPublication.track instanceof LocalAudioTrack) {
  //     console.log('inside krisp if')

  //     if (!isKrispNoiseFilterSupported()) {
  //       console.warn('enhanced noise filter is currently not supported on this browser')
  //       return
  //     }
  //     console.log('inside krisp if 2')

  //     // once instantiated the filter will begin initializing and will download additional resources
  //     const krispProcessor = KrispNoiseFilter()
  //     console.log('enabling LiveKit enhanced noise filter')
  //     await trackPublication.track.setProcessor(krispProcessor)

  //     // once you want to stop the krisp processor, simply call
  //     // await trackPublication.track.stopProcessor()
  //   }
  // })

  React.useEffect(() => {
    if (room?.state === ConnectionState.Connected) {
      // eslint-disable-next-line no-unused-expressions
      props.toasterMsg
    }
  }, [props.toasterMsg, room])

  return (
    <CustomRoomContext.Provider
      value={{
        isAudioOnly: props.isAudioOnly,
        isMinimized,
        title: 'This is the title',
        isUnmuteAllowed,
        unpinnedLayoutPositions,
        waitingTimeForHostOrCoHost: config.waitingTimeForHostOrCoHost,
        alertsBeforeMeetingEnds: config.alertsBeforeMeetingEnds,
        meetingEndTime: config.meetingEndTime,
        // meetingEndTime: '2024-08-30T12:40:00Z',
        stagedParticipants,
        stagedLayoutPositions,
        otherLayoutPositions,
        isHostOrCohostAvailable,
        gridDimensions,
        shouldLeaveMeeting,
        handRaisedParticipants,
        isParticipantHandRaised,
        isHandRaised,
        isHostEnded,
        viewType,
        allTracks,
        allTracksSids,

        setUnpinnedLayoutPositions,
        setIsMinimized,
        setIsUnmuteAllowed,
        setStagedParticipants,
        setStagedLayoutPositions,
        setOtherLayoutPositions,
        onMessage: props.onMessage,
        setIsHostOrCohostAvailable,
        setGridDimensions,
        setShouldLeaveMeeting,
        setHandRaisedParticipants,
        setIsParticipantHandRaised,
        setIsHandRaised,
        setIsHostEnded,
        setViewType,
        setAllTracks,
        setAllTracksSids,
        // setTitle: () => {},
      }}
    >
      <>
        {room && (
          <RoomContext.Provider value={room}>
            <>{props.children}</>
          </RoomContext.Provider>
        )}
      </>
    </CustomRoomContext.Provider>
  )
}
