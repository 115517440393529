/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { updateAppOffline } from "../../Services/utilReducer";
import { useAppDispatch } from "../../Store/hooks";
import { getIsAppOffline } from './../../Services/utilReducer';
import { useAppSelector } from './../../Store/hooks';

export const useCheckOnlineStatus = () => {
  const dispatch = useAppDispatch()
  const isAppOffline = useAppSelector(getIsAppOffline)

  useEffect(() => {

    function changeStatus() {
      dispatch(updateAppOffline(!navigator.onLine));
    }

    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);

    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };

  }, []);

  return { isAppOffline }
}