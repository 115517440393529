import { useEffect, useState } from "react"
import { useGetAllCurrencyQuery } from "../Services/allOthersApi"
import { useGetUserPreferenceQuery } from "../Services/preferenceApi"
import { getToken } from "../Services/tokenReducer"
import { useGetCurrentUserDetailsQuery } from "../Services/userApi"
import { useCheckUserTermsPolicyQuery } from "../Services/userPolicyAgreementsApi"
import { useAppSelector } from "../Store/hooks"

export const useLoginCheckHook = () => {

  const { 
    data: userData, isSuccess: userSuccess, isLoading: userLoading, isError, refetch: refetchCurrentUser 
  } = useGetCurrentUserDetailsQuery();

  const { isLoading: preferenceLoading, refetch: refetchPreference } = useGetUserPreferenceQuery()
  const { isLoading: currencyListLoading, refetch: refetchCurrencyList } = useGetAllCurrencyQuery();

  const { 
    data: userPolicyAgreementsData, 
    isSuccess: userPolicyAgreementsSuccess, 
    isLoading: userPolicyAgreementsLoading, 
    refetch: refetchUserPolicyAgreements 
  } = useCheckUserTermsPolicyQuery();

  const token = useAppSelector(getToken)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (token) {
      refetchCurrentUser()
      refetchPreference()
      refetchUserPolicyAgreements();
      refetchCurrencyList();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    setLoading(userLoading || preferenceLoading || userPolicyAgreementsLoading || currencyListLoading)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading || preferenceLoading || userPolicyAgreementsLoading || currencyListLoading])


  if (isError) {
    // ToDo: Show 'notRegisteredUser'
  }

  return { userSuccess, userData, userPolicyAgreementsData, userPolicyAgreementsSuccess, loading };

}