import InterestedAndSuggestions from './InterestedAndSuggestions'
import Loading from '../Components/base/loading/loading';
import NoSession from '../Components/NoSession';
import { SuggestionsHooks } from '../Hooks/SuggestionsHook';

function Suggestions() {

  const { meetingMaybeFiltered, meetingMaybe, maybeLoading } = SuggestionsHooks()

  if (maybeLoading) {
    return <Loading />
  }

  if (!meetingMaybe) {
    return <NoSession type="events" className="h-[60vh] items-center justify-center" />
  }

  return <>
    {meetingMaybeFiltered && <InterestedAndSuggestions meetings={meetingMaybeFiltered} />}
  </>
}

export default Suggestions
