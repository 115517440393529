import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { settingApi, useGetAllSettingsQuery } from "./setting.service";

export function useGetSettingValue(key: string): string {
    const { data } = useGetAllSettingsQuery(true, { pollingInterval: 1000 * 60 * 60 * 5 });

    if (data) {
        return data[key];
    }

    return '';
}

interface SettingState {
    settings: {};
}

const initialStateValue: SettingState = {
    settings: {}
};

export const settingSlice = createSlice({
    name: "settings",
    initialState: initialStateValue,
    reducers: {
        resetSettings: () => initialStateValue
    },
    extraReducers: (builder) => {
        builder.addMatcher(settingApi.endpoints.getAllSettings.matchFulfilled, (state, action) => {
            state.settings = action.payload;
        });
    }
});

export const getSettings = (state: RootState): any => state.settings.settings;
export const getSettingByKey = (state: RootState, key: string): string | undefined => state.settings.settings[key];

export const { resetSettings } = settingSlice.actions;
export default settingSlice.reducer;
