import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { toastError } from '../../../Components/toast';
import { useUpdateEndInfoApi } from '../../../Hooks/livekitHooks';
import { useLocalParticipantContext } from '../../../Services/livekit/contexts/liveKitContext';
import { isHostOrCohost } from '../../../Services/livekit/functions/liveKitHelpers';
import { getCurrentMeetingUser, getMeetingInfo } from "../../../Services/livekitReducer";
import { videoMeetingApi } from '../../../Services/videoMeetingApi';
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { MeetingType } from '../../../models/meeting.model';
import { getIsMeetingMinimized, getMeetingConnected, getMeetingJoined, resetLiveKitState, setShowMeeting } from './../../../Services/livekitReducer';

export enum LeaveType {
  END = 'END',
  LEAVE = 'LEAVE'
}

/**
 * LiveKit Leave End Hook,
 * - handles how the leave and end behavior should be done 
 * - WEB ONLY, because navigation is different for the react & react native
 */
export const useLeaveLiveKitHook = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentMeetingUser = useAppSelector(getCurrentMeetingUser)
  const meetingInfo = useAppSelector(getMeetingInfo)
  const isMinimized = useAppSelector(getIsMeetingMinimized);

  const { updateEndTime, updateLeaveTime } = useUpdateEndInfoApi()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLocalParticipant, room, setRoom] = useLocalParticipantContext()
  const isJoinedAnonymous = currentMeetingUser?.isJoinedAnonymous

  async function handleLeaveMeeting(type = LeaveType.LEAVE) {

    if (meetingInfo) {
      const args = {
        meetingId: meetingInfo.meetingId,
        participantId: currentMeetingUser.participantId,
      }

      if (isHostOrCohost(currentMeetingUser)) {
        if (type === LeaveType.END) {
          updateEndTime(args);
        } else {
          updateLeaveTime(args);
        }
      } else {
        updateLeaveTime(args);
      }

      if (isHostOrCohost(currentMeetingUser) || (meetingInfo.isVideoMeeting && meetingInfo.meetingType === MeetingType.ChatVideoRequest)) {
        if (!isMinimized) {
          navigate(-1);
        }
      } else {
        navigate(`/s/feedback/${meetingInfo.meetingId}/${isJoinedAnonymous ? '1' : '0'}`)
      }

      // dispatch(setShowMeeting(false))
      dispatch(videoMeetingApi.util.invalidateTags(['VideoMeeting']));
      dispatch(resetLiveKitState())

      if (room) {
        room.disconnect()
        room.localParticipant.trackPublications.forEach((track) => track.track?.stop())
      }

      setRoom(undefined)
      setLocalParticipant(undefined)

    }
  }

  return { handleLeaveMeeting }
}

/**
 * LiveKit Join Hook,
 * - handles the join navigation of a meeting, prevents multiple join clicks if meeting is already in progress
 * - WEB ONLY, because navigation is different for the react & react native
 */
export const useHandleMeetingNavigation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()

  const meetingJoined = useAppSelector(getMeetingJoined)
  const meetingConnected = useAppSelector(getMeetingConnected)


  const joinMeeting = (meetingId: string) => {
    if (meetingJoined || meetingConnected) {
      toastError(t('meetingOngoingDisconnectToJoin'))
      return;
    }

    navigate('/s/ameeting/' + meetingId)
  }

  return { joinMeeting }
}