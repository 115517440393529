import { ToolTip } from '../../Components/base/tooltip/tooltip'
import { useTranslation } from 'react-i18next'
import Field from '../../Components/base/field/field'
import Button from '../../Components/base/button/button'
import { useNavigate } from 'react-router-dom'

interface _BeemgSwitchProps {
  errors: any
  register: any
  checked: boolean | undefined
  htmlFor?: string | undefined
  beemgSwitchText: string
  name: string
  disabled?: boolean
  registerValue: string
  onClick?: any
  showAnonymous?: boolean
  navigateTo?: any
  buttonText?: string
  toolTipText?: string
}

export default function BeemgSwitch(props: _BeemgSwitchProps) {
  const { errors, register, checked, htmlFor, beemgSwitchText, name, disabled, registerValue, onClick, showAnonymous, navigateTo, buttonText, toolTipText } = props
  const { t } = useTranslation()
  const navigate = useNavigate();
  
  return (
    <>
      <div className={`${showAnonymous ? "flex flex-col md:flex-row w-full" : 'flex flex-row items-center w-full'}`}>
        {toolTipText && (
          <div className='w-full justify-start'>
            <ToolTip tip={toolTipText ?? ''} keyId="share">
              <div className="flex-row flex items-center justify-between">
                <label htmlFor={htmlFor}>{t(beemgSwitchText)}</label>
                <Field
                  {...register(registerValue)}
                  error={errors}
                  name={name}
                  type="checkbox"
                  defaultChecked={checked}
                  readOnly={disabled}
                  onClick={onClick}
                />
                {showAnonymous && buttonText && (
                  <div className='ml-8 md:ml-0'>
                    <Button size="sm" rounded color="join" onClick={() => {navigate(navigateTo)}}>
                      <p id="btn_anonymousProfile" data-testid="btn_anonymousProfile" className="text-xs md:text-sm ">{t(buttonText)}</p>
                    </Button>
                  </div>
                )}
              </div>
            </ToolTip>
          </div>
        )}
        {!toolTipText && (
          <div className='flex flex-col w-full'>
            <div className="flex items-center justify-between">
                <label htmlFor={htmlFor}>{t(beemgSwitchText)}</label>
                <Field
                  {...register(registerValue)}
                  error={errors}
                  name={name}
                  type="checkbox"
                  defaultChecked={checked}
                  readOnly={disabled}
                  onClick={onClick}
                />
            </div>
            {showAnonymous && buttonText && (
              <div className="items-start">
                <Button size="sm" rounded color="join" onClick={() => {navigate(navigateTo)}}>
                  <p id="btn_anonymousProfile" data-testid="btn_anonymousProfile" className="text-xs md:text-sm">{t(buttonText)}</p>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}