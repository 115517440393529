import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../Store'
import { UserStatusMessage } from '../API/socketTypes'

interface OnlineUsersStateType {
  onlineUsers: UserStatusMessage[]
}

const initialStateValue: OnlineUsersStateType = {
  onlineUsers: []
}

export const onlineUserSlice = createSlice({
  name: 'onlineUsers',
  initialState: initialStateValue,
  reducers: {
    resetOnlineUsers: () => initialStateValue,
    updateOnlineUsers: (state, action) => {
      const isExists = !!state.onlineUsers.find(value => value.userId === action.payload.userId)

      if (!isExists) {
        state.onlineUsers.push(action.payload)
        return;
      }

      state.onlineUsers.forEach(value => {
        if (value.userId === action.payload.userId) {
          value.isOnline = action.payload?.isOnline
          value.lastSeen = action.payload?.lastSeen
        }
      })

    },
  },
})

export const getOnlineUsers = (state: RootState): UserStatusMessage[] => state.onlineUsers.onlineUsers
export const { resetOnlineUsers, updateOnlineUsers } = onlineUserSlice.actions

export default onlineUserSlice.reducer