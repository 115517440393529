import { Favorite } from './../models/favorite.model';
import { allApis } from './allApis';

export const favoriteApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        // getFavoriteMeeting: builder.query<Favorite, string>({
        //     query: (meetingId) => `favorite/meeting/${meetingId}`,
        //     providesTags: ['Favorite']
        // }),
        // delete: builder.mutation<boolean, Favorite>({
        //     query: (favorite) => {
        //         return {
        //             url: `favorite/${favorite.id}`,
        //             method: 'DELETE'
        //         };
        //     }
        // }),
        updateFavoriteMeeting: builder.mutation<Favorite, Partial<Favorite>>({
            query: (favorite) => {
                return {
                    url: `favorite/meeting`,
                    method: 'PUT',
                    body: favorite,
                };
            },
            invalidatesTags: (result, error, args) => [{ type: 'MeetingByID', id: args.meeting?.id }, { type: 'MeetingByID', id: 'favorite' }, 'MeetingFavorite', 'MeetingDetails']
        })
    })
});

export const { useUpdateFavoriteMeetingMutation } = favoriteApi;