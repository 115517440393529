import { t } from 'i18next'
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import Button from '../../Components/base/button/button'
import Icon from '../../Components/base/icon/icon'
import Loader from '../../Components/base/loader/loader'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import { toastSuccess } from '../../Components/toast'
import VideoDisplay from '../../Components/VideoDisplay'
import { useEventDetailsHook } from '../../Hooks/MyEvents/EventDetailsHook'
import { MeetingDetails } from '../../models/meetingDetails.model'
import { useGetSettingValue } from '../../Services/settingReducer'
import { ImageVideoContext } from '../../Hooks/MyEvents/ImageVideoContextHook'

function formatBytes(bytes: number, decimals?: number): string {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function EventVideo ({ meetingDetails }: { meetingDetails: MeetingDetails })
  {
    const {
      uploadEventVideo,
      deleteEventVideo,
      uploadEventVideoLoading,
      deleteUploadEventVideoLoading,
    } = useEventDetailsHook({ meetingDetailId: meetingDetails.id ?? '' })

    let {images, isVideoInDB, isVideoSelected, setIsTriggerSaveImageAndVideo, isSaveTriggerWeb, setIsSaveTriggerWeb,isTriggerSaveImageAndVideo, setIsImageInDB, setSelectedImage, setRemoveImage, isButtonLoadingWeb, setIsButtonLoadingWeb, setIsVideoInDB, setIsVideoSelected} =  useContext(ImageVideoContext)

    
    const videoSize = useGetSettingValue('EVENT_VIDEO_SIZE')

    const [videoError, setVideoError] = useState(false)
    const [selectedFile, setSelectedFile] = useState<any>()
    const [videoForDelete, setVideoForDelete] = useState<any>()
    const [dbVideo, setDbVideo] = useState<string>('')
    const videoSizeFormat = formatBytes(Number(videoSize))

    useEffect(() => {
      if (meetingDetails.video) {
        setDbVideo(meetingDetails.video)
      } else {
        setDbVideo('')
      }
    }, [meetingDetails.video])

    const uploadVideo = useCallback(() => {
      if (!uploadEventVideoLoading && selectedFile && meetingDetails) {
        uploadEventVideo({ file: selectedFile, id: meetingDetails.id }).then(
          (response: any) => {
            setSelectedFile(undefined)
              setIsTriggerSaveImageAndVideo(false)
              setIsSaveTriggerWeb(false)
              toastSuccess(t('eventVideoUploadedSuccessfully'))
          },
        )
      }
    }, [
      uploadEventVideoLoading,
      selectedFile,
      meetingDetails,
      isTriggerSaveImageAndVideo,
    ])

    const saveVideoProcess = useCallback(() => {
      if (videoForDelete) {
        deleteEventVideo(meetingDetails).then((response: any) => {
            toastSuccess(t('eventVideoDeletedSuccessfully'))
            setDbVideo('')
            setVideoForDelete(undefined)
            setSelectedFile(undefined)
            setVideoError(false)
            uploadVideo()
        })
      } else {
        uploadVideo()
      }
    }, [videoForDelete, deleteEventVideo, meetingDetails, deleteUploadEventVideoLoading, uploadVideo])

    useEffect(() => {
      if (dbVideo) {
       setIsVideoInDB(true)
      }
      else if(!dbVideo) {
        setIsVideoInDB(false)
      }
      if (selectedFile) {
        setIsVideoSelected(true)
      }
      else if(!selectedFile) {
        setIsVideoSelected(false)
      }
    }, [dbVideo, selectedFile])

    const deletebleVideo = useCallback(() => {
      if (dbVideo) {
        setVideoForDelete(dbVideo)
        setDbVideo('')
        setSelectedFile(undefined)
        
      } else {
        setDbVideo('')
      }
    }, [dbVideo])

    const deleteVideo = () => {
      setSelectedFile(undefined)
      setIsButtonLoadingWeb(false)
      setIsTriggerSaveImageAndVideo(false)
    }

    const onFileSelected = (event: any) => {
      setVideoError(false)
      const file = event.target.files[0]

      if (!file) {
        return;
      }
      if (!file.type.startsWith('video')) {
        setVideoError(true)
      } else if (videoSize < file.size) {
        setVideoError(true)
      } else {
        setSelectedFile(file)
      }
    }

    useEffect(()=>{
      if(isTriggerSaveImageAndVideo) {
        saveVideoProcess()
      }
    },[isTriggerSaveImageAndVideo])

    return (
      <>
        {selectedFile?.name && !dbVideo && !videoError && (
          <>
            <ToolTip
              tip={`Filename: ${selectedFile?.name}  Filetype: ${selectedFile?.type} Size in bytes: ${selectedFile?.size}`}
              keyId={'uploadVideo' + meetingDetails.id}>
              <div className="relative">
                {uploadEventVideoLoading || deleteUploadEventVideoLoading ? (
                  <div className=" z-0 absolute flex flex-col ml-24 mt-14 justify-center ">
                    <Loader />
                  </div>
                ) : (
                  <Button
                    id={'btn_videoEdit' + meetingDetails.id}
                    onClick={() => deleteVideo()}
                    className="flex absolute top-0 right-0 z-10 cursor-pointer "
                    disabled={
                      uploadEventVideoLoading || deleteUploadEventVideoLoading
                    }
                    color="close"
                    rounded={true}
                    size="circle">
                    <Icon icon="CLOSE" size="large" className="pb-4" />
                  </Button>
                )}
              </div>
              <video
                id={'video_preview' + meetingDetails.id}
                controls
                muted
                loop
                autoPlay
                className="h-40 w-64 md:h-40 md:w-64 lg:h-40 lg:w-64 rounded-xl border-gray-400 flex flex-col items-center justify-center cursor-pointer"
                style={{objectFit: 'cover'}}>
                <source src={URL.createObjectURL(selectedFile)} />
              </video>
            </ToolTip>
          </>
        )}
        {!dbVideo && !selectedFile?.name && (
          <>
            <label htmlFor={'file-upload-video' + meetingDetails.id}>
              <div className="relative">
                <input
                  className={`space-y-1 text-center max-w-16 text-lg text-black font-medium absolute opacity-0 py-2`}
                  type="file"
                  id={'file-upload-video' + meetingDetails.id}
                  accept="video/*"
                  title=" "
                  onChange={onFileSelected}
                />
              </div>
              {!videoError && (
                <ToolTip tip={t('uploadEventVideo')} keyId="uploadVideo">
                  <div className="h-40 w-64 md:h-40 md:w-64 lg:h-40 lg:w-64 border-2 border-dashed rounded-xl border-gray-400 flex flex-col items-center justify-center cursor-pointer">
                    {uploadEventVideoLoading ||
                    deleteUploadEventVideoLoading ? (
                      <Loader />
                    ) : (
                      <>
                        <Icon icon="ADD" size="small" />
                        <span className="text-xs">{t('addVideo')}</span>
                      </>
                    )}
                  </div>
                </ToolTip>
              )}
              {videoError && (
                <div className="h-40 w-64 md:h-40 md:w-64 lg:h-40 lg:w-64 border-2 border-dashed rounded-xl border-red-500 flex flex-col items-center justify-center cursor-pointer">
                  <small
                    id={'btn_videoErrorMessage' + meetingDetails.id}
                    className="text-xs text-red-500 px-2">
                    * {t('warning!SizeExceedsThan')} {videoSizeFormat}
                  </small>
                </div>
              )}
            </label>
          </>
        )}

        {dbVideo && !videoError && (
          <>
            <div className="relative">
              <Button
                id={'btn_videoEdit' + meetingDetails.id}
                disabled={
                  uploadEventVideoLoading || deleteUploadEventVideoLoading
                }
                onClick={() => {
                  if (!deleteUploadEventVideoLoading) {
                    deletebleVideo()
                  }
                }}
                className="flex absolute -top-20 right-2 z-10 cursor-pointer"
                color="close"
                rounded={true}
                size="circle"
                style={{marginTop: '-0rem', marginRight: '-17rem'}}>
                {uploadEventVideoLoading || deleteUploadEventVideoLoading ? (
                  <div className="absolute top-0 right-0 w-5 h-5 z-10 cursor-pointer">
                    {' '}
                    <Loader />{' '}
                  </div>
                ) : (
                  <Icon icon="CLOSE" size="large" className="pb-4" />
                )}
              </Button>
            </div>

            <VideoDisplay videoFile={dbVideo} />
          </>
        )}
      </>
    )
  
  }
export default EventVideo
