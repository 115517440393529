import { MeetingNotificationList } from "../models/meetingNotficationList.model";
import { allApis } from "./allApis";

export const meetingNotficationListApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllRemainder: builder.query<MeetingNotificationList[], void>({
            query: () => `meetingNotificationList`,
            transformResponse: (response: MeetingNotificationList[]) => response.sort((a, b) => (a.notificationTimingInMinutes > b.notificationTimingInMinutes) ? 1 : -1),          
        }),
    })
})
 export  const { useGetAllRemainderQuery } = meetingNotficationListApi;