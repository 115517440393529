import { useState } from "react";
import { useCreateLaunchNotificationMutation } from "../Services/allOthersApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { launchNotificationValidationSchema } from "../Utils/validation";

export const useNotifyHook = () => {
    const [createLaunchNotification] = useCreateLaunchNotificationMutation<any>()

    const [serverError, setServerError] = useState<string>('');
    const [registrationDone, setRegistrationDone] = useState(false);

    const { 
        register, handleSubmit, formState: {errors}, reset, control,
    } = useForm({ mode: "onChange", resolver: yupResolver(launchNotificationValidationSchema) });
    

    const onSubmit = (values: any) => {
        if (serverError) {
            setServerError('');
        }
      
        const launchNotification = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            countryCode: values.phoneNo.slice(0, -10),
            phoneNo: values.phoneNo.slice(-10)
        };

        createLaunchNotification(launchNotification).then((res: any) => {
            if (res?.error?.data?.message) {
                if (!serverError){
                    setServerError(res.error?.data.message)
                }
            } else {
                reset();

                if (!registrationDone) {
                    setRegistrationDone(true);
                }
        
                if (serverError) {
                    setServerError('')
                }
            }
        })
    };

    return { 
        serverError, 
        onSubmit, 
        handleSubmit, 
        register, 
        errors, 
        control, 
        registrationDone, 
    }
}