import { User } from '../models/user.model';
import { UserDetail } from "../models/userDetail.model";
import { Pagination } from "../models/pagination.model";
import { UserFilter } from "../models/userFilter.model";
import { Image } from "../models/image.model";
import { allApis } from "./allApis";
import { Config } from '../Config';
import { store } from '../Store';



const userResponseTransform = (user: User) => {
    if (user.image?.length > 0) {
        user.userImage = user.image[0].webpImageName;
    }
    return user;
}


export const userApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        createUser: builder.mutation<any, Partial<User>>({
            query: (user) => ({
                url: `users`,
                method: 'POST',
                body: user
            }),
            invalidatesTags: ['CurrentUser']
        }),
        createUserSSO: builder.mutation<any, Partial<User>>({
            query: (user) => ({
                url: `users/sso`,
                method: 'POST',
                body: user
            }),
            invalidatesTags: ['CurrentUser']
        }),
        getNearUsers: builder.query<Pagination<User>, Partial<UserFilter>>({
            query: (filter) => ({
                url: `users/all/nearUsers`,
                params: filter
            }),
            providesTags: ['CurrentUser', 'AvatarImageUpload']
        }),
        getRecentUsers: builder.query<Pagination<User>, Partial<UserFilter>>({
            query: (filter) => ({
                url: `users/all/recentUsers`,
                params: filter
            })
        }),
        getUsersLikeYou: builder.query<Pagination<User>, Partial<UserFilter>>({
            query: (filter) => ({
                url: `users/all/usersLikeYou`,
                params: filter
            }),
            providesTags: ['CurrentUser', 'AvatarImageUpload']
        }),
        uploadAvatarFile: builder.mutation<User, any>({
            query: (args) => {
                const { file, id } = args;
                let testData: FormData = new FormData();
                testData.append('file', file, file.name);

                return {
                    url: `users/file/avatar/upload/${id}`,
                    method: 'POST',
                    body: testData
                };
            },
            invalidatesTags: ['CurrentUser']
        }),
        uploadUserVideo: builder.mutation<Image, any>({
            query: (args) => {
              const {file, id} = args
              const token = store.getState().token.value
              let data: FormData = new FormData()
              data.append('file', file, file.name)

              return {
                url : `users/videoUpload/${id}`,
                method: 'POST',
                body: data,
              }
            },
            invalidatesTags: ['CurrentUser'],
          }),

        getCurrentUserDetails: builder.query<User, void>({
            query: () => {
                return `users/get/currentUser`;
            },
            transformResponse: (res: any) => userResponseTransform(res),
            providesTags: ['CurrentUser', 'RazorpayAccount', 'AvatarImageUpload']
        }),
        getById: builder.query<User, string>({
            query: (id) => `users/${id}`
        }),
        getUserDetail: builder.query<any, string>({
            query: (sub) => `users/cognito/userDetail/${sub}`,
            providesTags: ['RazorpayAccount', 'AvatarImageUpload']
        }),
        getUserDetailByEmail: builder.query<UserDetail, string>({
            query: (email) => `users/userDetail/${email}`
        }),
        getUserDetails: builder.query<User, string>({
            query: (userId) => `users/userProfile/${userId}`
        }),
        // getAllUserCity: builder.query<any[], string>({
        //     query: (country) => `users/all/city/${country}`
        // }),
        getUserByUserId: builder.query<User, string>({
            query: (userId) => `users/userId/${userId}`
        }),
        verifyPhone: builder.query<User, string>({
            query: (sub) => `users/verifyPhone/${sub}`
        }),
        // getDetail: builder.query<UserDetail, void>({
        //     query: () => `users/cognito/userDetail`
        // }),
        // getAllUserCountry: builder.query<any[], void>({
        //     query: () => `users/all/country`
        // }),
        // getOnlineUsers: builder.query<User[], void>({
        //     query: () => `users/online/users`
        // }),
        verifyEmail: builder.query<User, void>({
            query: () => `users/verify/email`
        }),
        signUpMail: builder.query<boolean, void>({
            query: () => `users/mail/signUp`
        }),
        updateUser: builder.mutation<User, Partial<User>>({
            query: (user) => {
                return {
                    url: `users/${user.id}`,
                    method: 'PUT',
                    body: user
                };
            },
            invalidatesTags: ['CurrentUser']
        }),
        deleteUserVideo: builder.mutation<boolean, User>({
            query: (user) => {
                return {
                    url: `users/${user.id}/${user.userVideo}`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: ['CurrentUser']
        }),
        updateCognitoPhoneNumber: builder.mutation<User, any>({
            query: (args) => {
                const { email, phoneNumber } = args;
                return {
                    url: `users/cognito/${email}/${phoneNumber}`,
                    method: 'PUT'
                };
            }
        }),
    })
});

export const {
    useGetCurrentUserDetailsQuery,
    useGetNearUsersQuery,
    useGetRecentUsersQuery,
    useGetUserByUserIdQuery,
    useGetUsersLikeYouQuery,
    useUpdateUserMutation,
    useUploadUserVideoMutation,
    useDeleteUserVideoMutation,
    useGetUserDetailByEmailQuery,
    useCreateUserMutation,
    useVerifyPhoneQuery,
    useGetUserDetailQuery,
    useGetUserDetailsQuery,
    useCreateUserSSOMutation,
    useUploadAvatarFileMutation,
    useGetByIdQuery,
    useUpdateCognitoPhoneNumberMutation,
} = userApi;
