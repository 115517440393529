import { useGetAllMaybeMeetingsQuery } from "../Services/meetingApi";
import { getCurrentUser } from "../Services/userReducer";
import { useAppSelector } from "../Store/hooks";


export const SuggestionsHooks = () => {
    const { data: meetingMaybe, isSuccess: maybeSuccess, isLoading: maybeLoading } = useGetAllMaybeMeetingsQuery();
    const currentUser = useAppSelector(getCurrentUser)
    const meetingMaybeFiltered = meetingMaybe?.filter(meeting => meeting.meetingDetails.host.id !== currentUser.id)
    
    return {meetingMaybeFiltered, meetingMaybe,  maybeSuccess, maybeLoading};
}
