/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../Components/base/loading/loading'
import { toastError } from '../../Components/toast'
import { useGetAllChatUsersQuery } from '../../Services/messageApi'
import { useGetByIdQuery, useGetCurrentUserDetailsQuery } from '../../Services/userApi'
import { getCurrentUser } from '../../Services/userReducer'
import { clearEmptyChatUser, clearUnreadMessages, getChatUsersList, getCurrentChatUser, setCurrentChatUser } from '../../Store/Chat/chat'
import { isNotEmptyArray } from '../../Utils/helper'
import ChatProfileAndSearch from './ChatProfileAndSearch'
import ChatUserEvents from './ChatUserEvents'
import ChatWindow from './ChatWindow'
import EmptyChatView from './EmptyChatView'

function ChatBox() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const [skipLoadNewChatUser, setSkipLoadNewChatUser] = useState<boolean>(true)
  const chatUsersList = useSelector(getChatUsersList)
  const currentUser = useSelector(getCurrentUser)
  const currentChatUser = useSelector(getCurrentChatUser)

  useGetCurrentUserDetailsQuery()
  const { isLoading: chatUsersLoading, isSuccess, error: chatUsersError } = useGetAllChatUsersQuery()
  const { data: newUser, isLoading: newUserLoading, isError: isNewUserError, error: newUserError } = useGetByIdQuery(id ? id : ' ', { skip: skipLoadNewChatUser })

  useEffect(() => {
    const isPresentUser = chatUsersList.find((value: any) => value?.chatUser?.id === id)

    if (id && id === currentUser.id) {
      //if tries chat with currentUser, navigate to default.
      navigate('/s/chat-box')
    }
    else if (isPresentUser) {
      //already chatted with the user and exists in chatUsersList.
      dispatch(setCurrentChatUser(isPresentUser.chatUser))
      dispatch(clearUnreadMessages(isPresentUser.chatUser?.id))
    }
    else if (!id && isNotEmptyArray(chatUsersList)) {
      //no id but already chats exists, defaults to first chat.
      dispatch(setCurrentChatUser(chatUsersList[0]?.chatUser))
      dispatch(clearUnreadMessages(chatUsersList[0]?.chatUser?.id))
    }
    else if ((id && !isNotEmptyArray(chatUsersList)) || (id && isNotEmptyArray(chatUsersList) && !isPresentUser)) {
      //chat not exist or chat empty, so find the user by id and initiate chat.
      setSkipLoadNewChatUser(false)
    }

    if (newUser && newUser.id === id) {
      dispatch(setCurrentChatUser(newUser))
    }
  }, [id, chatUsersList, currentChatUser, newUser])

  useEffect(() => {
    // if user not found, navigate to default.
    if (isNewUserError && newUserError) {
      toastError(t("User not found"));
      navigate('/s/chat-box')
    }
  }, [isNewUserError, newUserError])

  useEffect(() => {
    return () => {
      dispatch(clearEmptyChatUser())
      dispatch(setCurrentChatUser(undefined))
    }
  }, [])

  return (
    <div className='w-screen h-[80vh]'>
      {(chatUsersLoading || newUserLoading) && (
        <div className='flex flex-1 h-full w-full justify-center items-center'>
          <Loading />
        </div>
      )}
      {(!(chatUsersLoading || newUserLoading)) &&
        (<div className='flex flex-row items-start justify-start px-5 lg:px-16 mt-4 h-full w-full'>

          {((isSuccess && !isNotEmptyArray(chatUsersList)) || chatUsersError) &&
            <div className="w-full h-full items-center justify-center flex flex-1">
              <EmptyChatView />
            </div>
          }

          {isSuccess && chatUsersList && isNotEmptyArray(chatUsersList) && currentChatUser &&
            <>
              <ChatProfileAndSearch />
              <div className='flex flex-col w-full h-full border-gray-100 md:border-l'>
                <ChatWindow />
              </div>
              <ChatUserEvents />
            </>
          }
        </div>)
      }
    </div>
  )
}

export default ChatBox
