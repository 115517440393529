import { useTranslation } from 'react-i18next'
import Field from '../../Components/base/field/field'

interface invitedGuestsProps {
  key: String
  invitedEmail: string
  index: number
}

export default function InvitedGuest(props: invitedGuestsProps) {
  const { invitedEmail, index } = props
  const { t } = useTranslation()
  return (
    <div className="w-full justify-center" key={index}>
      {invitedEmail &&
        <Field
          maxLength={50}
          name={`data.email`} type="email" placeholder={t('emailID')} style={{ textAlign: 'center' }}
          disabled={true}
          defaultValue={invitedEmail} 
          data-testid="data.email"/>
      }
    </div>
  )
}
