import { TFunctionDetailedResult } from "i18next";
import i18n from "../Translations"

export const isNotEmptyArray = (array?: any[]): boolean => {
  return array !== undefined && array.length !== 0
}

export const tr = (key?: string, options?: any): string | TFunctionDetailedResult<string> => {
  return key ? i18n.t(key, options) : '';
};


export const uid = (): string => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export const getTimezoneOffsetString = ({ timezoneUtc }: { timezoneUtc: string }): string => {
  const offsetInMinutes = parseInt(timezoneUtc);

  let hours = Math.floor(Math.abs(offsetInMinutes) / 60);
  let minutes = Math.abs(offsetInMinutes % 60);

  let hoursString = ("0" + Math.abs(hours)).slice(-2);
  let minutesString = ("0" + minutes).slice(-2);
  let offsetString = "UTC " + (offsetInMinutes < 0 ? "+" : "-") + hoursString + ":" + minutesString;

  return offsetString;
}