import { User } from '../../models/user.model'
import Dot from '../../Components/Dot'
import { UserDetail } from '../../models/userDetail.model'
import { DisplayFormattedDate } from '../../Components/formattedDateAndTime'

interface UserPersonalDetailsProps {
  user: User
  userDetail: UserDetail
}

const UserPersonalDetails = (props: UserPersonalDetailsProps) => {
  const { user, userDetail } = props;

  return (
    <div className="flex flex-col gap-y-2 items-center justify-center font-normal">
      <div className='flex flex-row gap-x-3 items-center flex-wrap justify-center'>
        <span id="userEmail" data-testid="userEmail">{userDetail?.email}</span>
        {userDetail.phone_number && <span><Dot /></span>}
        <span id="userPhoneNo" data-testid="userPhoneNo">{userDetail?.phone_number}</span>
      </div>

      <div className='flex flex-row gap-x-3 items-center flex-wrap'>
        <span id="userDOB" data-testid="userDOB"><DisplayFormattedDate displayDate={userDetail.birthdate} dateFormat='yyyy-MM-dd'/></span>
        {user.gender && <span><Dot /></span>}
        <span id="userGender" data-testid="userGender">{user?.gender}</span>
        {user.city && <span><Dot /></span>}
        <span id="userCity" data-testid="userCity">{user?.city}</span>
        {user.country && <span><Dot /></span>}
        <span id="userCountry" data-testid="userCountry">{user?.country}</span>
      </div>
    </div>
  )
}

export default UserPersonalDetails;