import { useParticipantContext } from '@livekit/components-react'
import React, { useEffect, useState } from 'react'

export default function AudioLevelIndicator() {
  const { audioLevel, isSpeaking } = useParticipantContext()
  const [audioLevelScale, setAudioLevelScale] = useState<number>(1)

  useEffect(() => {
    if (audioLevel === 0) {
      setAudioLevelScale(1)
    } else {
      setAudioLevelScale(audioLevel + 1)
    }
  }, [audioLevel])

  return (
    <>
      <div
        className={`absolute transition-all ease-in-out justify-center items-center flex top-0 left-0 right-0 bottom-0 rounded-full overflow-hidden ${
          isSpeaking ? 'outline outline-yellow-400 outline-1 opacity-40  animate-audioLevel ' : ''
        }`}
      />
      <div
        className="bg-orange-400 bg-opacity-60 absolute transition-all ease-in-out duration-300 delay-300 opacity-40 justify-center items-center flex top-0 left-0 right-0 bottom-0 rounded-full overflow-hidden"
        style={{ transform: `scale(${audioLevelScale})` }}
      />
    </>
  )
}
