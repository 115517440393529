import { allApis } from './allApis';

export const envApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getReCaptcha: builder.query<string, void>({
            query: () => `env/reCaptcha`
        }),
        getIPGeolocationKey: builder.query<string, void>({
            query: () => `env/ipGeolocationKey`
        }),
        getCognitoKeys: builder.query<any, void>({
            query: () => `env/cognito`
        }),
        // getZoomKeys: builder.query<any, void>({
        //     query: () => `env/zoom`
        // }),
        // getBaseKeys: builder.query<any, void>({
        //     query: () => `env/baseURL`
        // }),
    })
});

export const { useGetIPGeolocationKeyQuery, useGetCognitoKeysQuery, useGetReCaptchaQuery } = envApi;