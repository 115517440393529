import { changeRedirectUrl } from "../Services/rediretUrlReducer";
import { store } from "../Store";

export const updateRedirectURL = (redirectURL: string) => {
    if (redirectURL && redirectURL.startsWith('/s/')) {
        store.dispatch(changeRedirectUrl(redirectURL));
    }
}

export const getLocation = () => {
    return window.location.pathname
}