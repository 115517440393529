import Spinner from "../spinner/spinner";

function Loader() {
    return (
        <div className="flex justify-center" id="load_data" data-testid="load_data">
            <Spinner color="#f59e0b" className="h-12" />
        </div>
    )
}

export default Loader;