import { Outlet, useLocation } from "react-router-dom";
import { Verified } from "../models/user.model";
import { getCurrentUser } from "../Services/userReducer";
import { useAppSelector } from "../Store/hooks";
import { getSignUpToken} from "../Services/signUpReducer";


const UserFlow = () => {
    const location = useLocation()
    const currentPath = location.pathname
    const currentUser = useAppSelector(getCurrentUser)
    const token = useAppSelector(getSignUpToken);

    return (
        // commented out for now, will be used later

        // <>
        //     {token && (
        //     <div className=" flex my-2 items-center">
        //         <div className="flex-grow border-t border-gray-400"></div>
        //         {(currentPath.startsWith('/signUp/') || currentPath === '/termsAndConditions')? <Icon icon="CLOSE_RED" /> : <Icon icon="TICK_GREEN" />}
        //         <span className="flex-shrink mx-4 text-gray-400">{t("signUp")}</span>
        //         <div className="flex-grow border-t border-gray-400"></div>
        //         <div className="flex-grow border-t border-gray-400"></div>
        //         { (currentPath !== '/verifyPhone' && !currentUser) || (currentPath === '/verifyPhone' && !currentUser) || (currentPath !== '/verifyPhone' && currentUser?.phoneVerified === Verified.NotComplete) || (currentPath === '/verifyPhone' && currentUser?.phoneVerified === Verified.NotComplete) ? <Icon icon="CLOSE_RED" /> : <Icon icon="TICK_GREEN" />}
        //         <span className="flex-shrink mx-4 text-gray-400">{t("phoneNumberVerification")}</span>
        //         <div className="flex-grow border-t border-gray-400"></div>
        //         <div className="flex-grow border-t border-gray-400"></div>
        //         { (currentPath !== '/verifyEmail' && !currentUser) || (currentPath !== '/verifyEmail' && currentUser?.emailVerified === Verified.NotComplete) || (currentPath === '/verifyEmail' && currentUser?.emailVerified === Verified.NotComplete)? <Icon icon="CLOSE_RED" /> : <Icon icon="TICK_GREEN" />}
        //         <span className="flex-shrink mx-4 text-gray-400">{t("emailVerification")}</span>
        //         <div className="flex-grow border-t border-gray-400"></div>
        //         <div className="flex-grow border-t border-gray-400"></div>
        //         {(currentPath !== '/verifyEmail' && !currentUser) || (currentPath !== '/s/faceVerify' && currentUser?.faceVerified === Verified.NotComplete) ||  (currentPath === '/faceVerify' && currentUser?.faceVerified === Verified.NotComplete) ? <Icon icon="CLOSE_RED" /> : <Icon icon="TICK_GREEN" />}
        //         <span className="flex-shrink mx-4 text-gray-400">{t("faceVerification")}</span>
        //         <div className="flex-grow border-t border-gray-400"></div>
        //     </div>
        //     )}
        //     <Outlet />
        // </>

        <>
            {token && (
                <div className=" flex  items-center">
                    {(currentPath.startsWith('/signUp/') || currentPath === '/termsAndConditions')}
                    {(currentPath !== '/verifyPhone' && !currentUser) || (currentPath === '/verifyPhone' && !currentUser) || (currentPath !== '/verifyPhone' && currentUser?.phoneVerified === Verified.NotComplete) || (currentPath === '/verifyPhone' && currentUser?.phoneVerified === Verified.NotComplete)}
                    {(currentPath !== '/verifyEmail' && !currentUser) || (currentPath !== '/verifyEmail' && currentUser?.emailVerified === Verified.NotComplete) || (currentPath === '/verifyEmail' && currentUser?.emailVerified === Verified.NotComplete)}
                    {(currentPath !== '/verifyEmail' && !currentUser) || (currentPath !== '/s/faceVerify' && currentUser?.faceVerified === Verified.NotComplete) || (currentPath === '/faceVerify' && currentUser?.faceVerified === Verified.NotComplete)}
                </div>
            )}
            <Outlet />
        </>
    )

}

export default UserFlow;