import { CommonEntity } from './commonEntity.model';
import { Favorite } from './favorite.model';
import { Feedback } from './feedback.model';
import { MeetingDetails } from './meetingDetails.model';
import { MeetingNotificationRemainder } from './meetingNotificationRemainder.model';
import { MeetingParticipant } from './meetingParticipant.model';
import { User } from './user.model';

export enum MeetingType {
    Type1 = 'Type 1', // guest-host - Event Payment
    Type2 = 'Type 2', // guest-guest - Paid Request
    ChatVideoRequest = 'Chat Video Request' // Video Meeting Request
}
  
export enum Paid {
    Yes = 'Yes',
    No = 'No'
}

export enum Request {
    SentRequests = 'Send requests',
    ReceivedRequests = 'Received requests',
    ScheduledEvents = 'Scheduled events'
}

export interface Meeting extends CommonEntity {
    user: User;
    user2: User;
    meetingId: string;
    language: string;
    fromDateTime: string; // From UTC datetime
    toDateTime: string; // To UTC datetime
    startAt: string; //HH:mm (24h) UTC
    endAt: string; //HH:mm (24h) UTC
    actualStartAt: string; // UTC datetime
    actualEndAt: string; // UTC datetime
    expectedEndAt: string; // UTC datetime
    meetingURL: string;
    zoomMeetingID: string;
    zoomEncryptedPassword: string;
    costUSD: number;
    costLocal: number;
    paidToHost: Paid;
    request: Request;
    cost: number;
    seatsAvailable: number;
    currency: string;
    zoomAccount: any; //ZoomAccount;
    noOfParticipants: number;
    minimumNoOfParticipants: number;
    favorite: 'Yes' | 'No';
    favorites: Favorite[];
    duration: string;
    feedback: Feedback[];
    meetingParticipant: MeetingParticipant[];
    meetingDetails: MeetingDetails;
    type: MeetingType;
    meetingNotificationRemainder: MeetingNotificationRemainder[];
    dateCheck: string;
    domain: string;
    participantCount: number;
    completeCost: number;
    commissionPercentage: number;
    applicationFeeAmount: number;
    actualPayment: number;
    actualPaymentInJPY: number;
    isAutoDelete: boolean;
    reason: string;
    joinedCount: string;
    paidStatus: boolean;
    invitedUserStatus: string;

    baseAmount: number;
    

    // Display Fields
    user_fromDateTime: string; // for User Timezone
    user_toDateTime: string; // for User Timezone
    display_MonthDate: string;
    display_date: string;
    display_time: string;
    display_FromDateTime: string;
    display_FromTime: string;
    display_ToDateTime: string;
    display_startAt: string;
    display_endAt: string;
    display_actualStartAt: string;
    display_actualEndAt: string;
    display_expectedEndAt: string;
    display_imageUrl: string;
    display_hostName: string;
    display_createdAt: string;
    display_startAt1Date: string;
    display_startAt2Date: string;
    display_startAt3Date: string;
    display_startAt1Time: string;
    display_startAt2Time: string;
    display_startAt3Time: string;
    costLocal_formatted?: number;
    cost_formatted?: number;
}