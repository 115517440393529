import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AccordionBorder, AccordionItem } from '../../Components/base/accordion/accordion'
import Button from '../../Components/base/button/button'
import useWindowDimensions from '../../Components/base/carousel/whirligig/utils'
import Field from '../../Components/base/field/field'
import Loading from '../../Components/base/loading/loading'
import { ModalHeader, ModalFooter, Modal, ModalBody, ModalCloseButton } from '../../Components/base/modal/modal'
import { toastSuccess } from '../../Components/toast'
import useToggle from '../../Components/_utils/useToggle'
import { useAttendingHooks, useCancellationHooks, useMeetingJoinHooks } from '../../Hooks/AttendingHooks'
import { Meeting } from '../../models/meeting.model'
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder'
import CardTitle from '../EventsCard/CardTitle'
import { DisplayEventTypeIcons } from '../EventsCard/DisplayEventTypeIcons'
import { EventCard } from '../EventsCard/EventCard'
import TextLink from '../EventsCard/TextLink'
import { JoinButton } from './AttendingEventsJoinButton'
import { AttendingEventsRemainder } from './AttendingEventsRemainder'
import { StartMeetingButton } from './AttendingEventsStartMeetingButton'
import { CancellationPolicyModal } from './PartialCancellationPolicyModal'
import NewIcon from '../../Components/base/icon/newIcons'
import { DisplayFormattedDateAndTime } from '../../Components/formattedDateAndTime'
import { DelayDisplay } from '../../Components/DelayDisplay'


export function Schedule({ meeting }: { meeting: Meeting }) {

  const { t } = useTranslation()
  const { toggle, isOpen } = useToggle()
  const { width } = useWindowDimensions()
  const { joinTime, remainderJoinTime, requestCancelTime, showStartMeeting } = useMeetingJoinHooks({ meeting })
  const { handleCancellationRequest, requestedCancellation, cancellationDataSuccess, paidCancellationSuccess, paidCancellationLoading, meetingTitle, currentUser, register, trigger, errors, isValid   } = useCancellationHooks({ meeting })
  const {attendingEventPageRefetch} = useAttendingHooks()

  const truncatedTitle = {
    meetingTitle: meeting.meetingDetails.title.length > 25 ? meeting.meetingDetails.title.substring(0, 25) + '...' : meeting.meetingDetails.title
  }

  useEffect(()=>{
    attendingEventPageRefetch()
  },[joinTime])

  useEffect(() => {
    if (paidCancellationSuccess) {
      toastSuccess(t('cancellationProcessedSuccessfully'))
    }
  }, [paidCancellationSuccess, t])

  if (!cancellationDataSuccess) {
    return <></>
  }

  return (
    <>
      <AccordionBorder>
        <AccordionItem color="card" pointing={meeting.type === 'Type 1'? true : false}>
          <EventCard keyId={meeting.id} link={meeting.type && meeting.type === 'Type 1' ? `/s/meeting/${meeting.meetingDetails.id}` : ''}  >
            {meeting.type === 'Type 1' && <EventCard.Image><CardImagePlaceHolder images={meeting?.meetingDetails?.image} /></EventCard.Image>}
            {meeting.type === 'Type 2'&&  meeting?.user?.id === currentUser.id ? <EventCard.Image><CardImagePlaceHolder images={meeting?.user2?.image} /></EventCard.Image>: meeting?.user2?.id === currentUser.id  && <EventCard.Image><CardImagePlaceHolder images={meeting?.user?.image} /></EventCard.Image>}
            <EventCard.Title><CardTitle keyId={meeting.id} key={meeting.meetingDetails.id} meetingTitle={truncatedTitle.meetingTitle} /></EventCard.Title>
            <EventCard.SubTitle>
              <DisplayFormattedDateAndTime displayFromTime={meeting.fromDateTime} displayToTime={meeting.toDateTime} displayDate={meeting.fromDateTime} />
            </EventCard.SubTitle>
            <EventCard.Icons><DisplayEventTypeIcons meetingDetail={meeting.meetingDetails} meeting={meeting} /></EventCard.Icons>
            <EventCard.Actions>
              <div className="grid grid-cols-6 w-full">
                <div className={`text-center justify-center items-center mx-auto col-span-5 w-20`} onClick={(e) => {
                  e.stopPropagation()
                }}>
                  {((joinTime) && requestedCancellation !== 'REQUESTED' && requestedCancellation !== 'CANCELLED') ?
                    (((joinTime && !showStartMeeting) ? (<JoinButton meeting={meeting} />) : (joinTime && showStartMeeting && <StartMeetingButton meeting={meeting} />))) :
                    (requestedCancellation === 'REQUESTED' ? (
                      <>
                      <NewIcon size="medium" icon="AWAITCANCEL" className="mb-1 justify-center items-center mx-auto " stroke='#535353'/>
                      <span id={`chk_cancellationRequested${meeting.id}`} className="flex text-xs text-center cursor-not-allowed">
                        {t('awaitingCancellation')}
                      </span>
                      </>
                    ) : (
                      <>
                        <DelayDisplay delayTime={1000}>
                        <>
                        {requestedCancellation === 'REQUEST' && !requestCancelTime && !paidCancellationLoading &&
                          <span id={`btn_cancelRequest${meeting.id}`} data-testid={`btn_cancelRequest${meeting.id}`} onClick={toggle}>
                            <TextLink type="cancelRequest">
                              {t('requestForCancellation')}
                            </TextLink>
                          </span>
                        }
                        </>
                      </DelayDisplay>
                      </>)
                    )}
                  {paidCancellationLoading && <Loading />}
                  {requestedCancellation === 'CANCELLED' && !requestCancelTime &&
                    <span id={`txt_requestCancelled${meeting.id}`}> {t('cancelled')}</span>
                  }
                  {/* //TODO: check cancellation policy from admin & move to hook */}

                </div>
                <div className='flex justify-end mx-auto'>
                  {(!joinTime || remainderJoinTime) && requestedCancellation !== 'REQUESTED' && !showStartMeeting &&
                    <AttendingEventsRemainder meeting={meeting} />
                  }
                </div>
              </div>
            </EventCard.Actions>
          </EventCard>
        </AccordionItem>
      </AccordionBorder>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        closeOnClickOutside={false}
        animate={true}
        position="semiLarge">
        <ModalCloseButton toggle={toggle} />
        <ModalHeader>{t('requestForCancellation')}</ModalHeader>
        <ModalBody>
          <span> {t('specificReasonsforCancellationIfAny')} </span>
          <div className='h-2'/>
          <Field
            {...register('reason')}
            name="reason"
            type="textarea"
            id="input_reason"
            data-testid="input_reason"
            placeholder={t(`reason`)+ ' *'}
            error={errors?.reason?.message}
          />

          <CancellationPolicyModal meeting={meeting} width={width} /> </ModalBody>
        <ModalFooter>
          <Button data-testid="btn_ok" id="btn_ok"  onClick={e => {
            if(!errors?.reason?.message && isValid){
              toggle(e)
              handleCancellationRequest(meeting)
            }
            trigger('reason')
          }}
            color="footerButton" autoFocus>
            {t('yes')}
          </Button>
          <Button id="btn_cancel" onClick={toggle} color="footerButton"> {t('close')} </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
