import {HTMLAttributes, ReactNode} from 'react'
import { Link } from 'react-router-dom'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  type: string
  link?: string
}

const style = {
  view: `text-xs uppercase hover:underline cursor-pointer`,
  request: `uppercase text-black-500 hover:underline text-xs`,
  cancelRequest: `hover:underline text-xs cursor-pointer`,
  addSession: `font-medium text-sm pl-10 cursor-pointer uppercase hover:underline text-black w-52`,
}

export function TextLink({children, type, link}: Props) {
  return (
    <div>
      {(() => {
        if (type === 'view' && link) {
          return <div className={style.view}><Link data-testid='lnk_viewEvent' to={link}>{children}</Link></div>
        } else if (type === 'request') {
          return <div className={style.request}>{children}</div>
        } else if (type === 'cancelRequest') {
          return <div data-testid="btn_cancelRequest" id="btn_cancelRequest" className={style.cancelRequest}>{children}</div>
        } else if (type === 'addSession' && link) {
          return <div className={style.addSession}><Link to={link}>{children}</Link></div>
        }
      })()}
    </div>
  )
}
export default TextLink
