import {Meeting} from './../models/meeting.model'
import {VideoMeeting} from './../models/videoMeeting.model'
import {VideoMeetingLogs} from '../models/videoMeetingLogs.model'
import {allApis} from './allApis'

export const videoMeetingApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getMeetingToken: builder.query<
      VideoMeeting,
      {anonymous: boolean; meetingId: string}
    >({
      query: args => {
        const {meetingId, anonymous} = args

        return `videoMeeting/${meetingId}?anonymous=${anonymous}`
      },
      providesTags: ['VideoMeeting'],
    }),

    // getLivekitToken: builder.query<VideoMeeting, { force?: boolean; meetingId: string }>({
    //   query: args => {
    //     const { meetingId } = args

    //     return `videoMeeting/token/${meetingId}`
    //   },
    //   providesTags: ['VideoMeeting']
    // }),

    updateStartTimeAPI: builder.mutation<
      VideoMeeting,
      Partial<VideoMeetingLogs>
    >({
      query: videoLogs => {
        const {meeting_id} = videoLogs

        return {
          url: `videoMeeting/start/${meeting_id}`,
          method: 'PUT',
          body: videoLogs,
        }
      },
      invalidatesTags: ['MeetingDetails', 'AttendingMeeting'],
    }),

    updateJoinTime: builder.mutation<Meeting | void, Partial<VideoMeetingLogs>>(
      {
        query: videoLogs => {
          const {meeting_id} = videoLogs

          return {
            url: `videoMeeting/join/${meeting_id}`,
            method: 'PUT',
            body: videoLogs,
          }
        },
        invalidatesTags: ['MeetingDetails', 'AttendingMeeting'],
      },
    ),

    updateEndTime: builder.mutation<VideoMeeting, any>({
      query: args => {
        const {participantId, meetingId} = args
        const requestBody = {jitsi_id: participantId}

        return {
          url: `videoMeeting/end/${meetingId}`,
          method: 'PUT',
          body: requestBody,
        }
      },
    }),

    updateLeaveTime: builder.mutation<VideoMeeting, any>({
      query: args => {
        const {participantId, meetingId} = args
        const requestBody = {jitsi_id: participantId}

        return {
          url: `videoMeeting/leave/${meetingId}`,
          method: 'PUT',
          body: requestBody,
        }
      },
    }),

    createConnectivityLogs: builder.mutation<VideoMeeting, any>({
      query: args => {
        const {meetingId, data} = args

        return {
          url: `videoMeeting/connectivityLogs/${meetingId}`,
          method: 'POST',
          body: data,
        }
      },
    }),
  }),
})

export const {
  useGetMeetingTokenQuery,
  // useGetLivekitTokenQuery,
  useUpdateJoinTimeMutation,
  useUpdateEndTimeMutation,
  useUpdateLeaveTimeMutation,
  useUpdateStartTimeAPIMutation,
  useCreateConnectivityLogsMutation,
} = videoMeetingApi
