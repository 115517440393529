import { ToolTip } from "./base/tooltip/tooltip";
import Icon from '../Components/base/icon/icon'

export function HelpLink() {
    return <>
        <a href="https://help.beemg.com/" target={"_blank"} rel="noreferrer">
            <span id="chk_helpNavigate">
                <div>
                    <ToolTip tip={'help'} keyId="help">
                        <Icon
                            icon="QUESTION"
                            size="medium"
                            height="large"
                        />
                    </ToolTip>
                </div>
            </span>
        </a>
    </>
}