import toast from 'react-hot-toast'
import Icon from './base/icon/icon'

export function toastSuccess(message) {
  toast.success(message, {
    id: message ? message.toString() : undefined,
    duration: 6000,
    position: 'top-center',
    style: {backgroundColor: '#008000', color: '#FFFFFF'},
  })
}

export function toastError(message) {
  toast.error(message, {
    id: message ? message.toString() : undefined,
    duration: 6000,
    position: 'top-center',
    style: {backgroundColor: '#FF0000', color: '#FFFFFF'},
  })
}

/**
 * ToastInfo
 * @returns toast with Icon and Unique ID
 * @param string message
 * @param string icon
 */
export function toastInfo(message, icon) {
  toast(message, {
    id: message ? message.toString() : undefined,
    duration: 6000,
    icon: icon ? <Icon icon={icon} size={'large'} /> : undefined,
    position: 'top-center',
    style: {backgroundColor: '#2E96B4', color: 'white'},
  })
}
