import { LocalParticipant, Room } from "livekit-client";
import * as React from "react";
import { useState, useEffect } from "react";

export type UseLocalParticipant = [
  LocalParticipant | undefined,
  React.Dispatch<React.SetStateAction<LocalParticipant | undefined>>,
  Room | undefined,
  React.Dispatch<React.SetStateAction<Room | undefined>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  string | null,
  React.Dispatch<React.SetStateAction<string | null>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
];

export const useLocalparticipant = (newLocalParticipant: LocalParticipant | undefined, newRoom: Room | undefined): UseLocalParticipant => {
  const [localParticipant, setLocalParticipant] = useState<LocalParticipant | undefined>();
  const [room, setRoom] = useState<Room | undefined>();
  const [isPip, setIsPip] = useState(false);
  const [selectedAudioOutput, setSelectedAudioOutput] = useState<string | null>(null);
  const [showPingAnimation, setShowPingAnimation] = useState(false);


  return [localParticipant, setLocalParticipant, room, setRoom,  isPip, setIsPip, selectedAudioOutput, setSelectedAudioOutput, showPingAnimation, setShowPingAnimation];
};

interface Props {
  children: React.ReactNode;
}

// Generate context
const LocalParticipantContext = React.createContext<UseLocalParticipant>(undefined!);

// Generate provider
const LiveKitProvider = ({ children }: Props) => {
  const [localParticipant, setLocalParticipant, room, setRoom, isPip, setIsPip,selectedAudioOutput, setSelectedAudioOutput, showPingAnimation, setShowPingAnimation ] = useLocalparticipant(undefined, undefined);

  return (
    <LocalParticipantContext.Provider value={[localParticipant, setLocalParticipant, room, setRoom,isPip, setIsPip,selectedAudioOutput, setSelectedAudioOutput, showPingAnimation, setShowPingAnimation]}>
      {children}
    </LocalParticipantContext.Provider>
  );
};

// Custom context hook
const useLocalParticipantContext = () => {
  return React.useContext(LocalParticipantContext);
};

export { LiveKitProvider, useLocalParticipantContext };