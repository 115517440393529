import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { userApi } from "../Services/userApi";
import { useAppDispatch } from "../Store/hooks";
import { emailVerificationValidationSchema } from "../Utils/validation";

export const useVerifyEmailHook = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();


    const {
        control, register, handleSubmit, formState: { errors }, setValue, watch, reset
    } = useForm({ resolver: yupResolver(emailVerificationValidationSchema) })

    const [errorMessage, setErrorMessage] = useState<string>();
    const [showToastMessage, setShowToastMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [limitCheck, setLimitCheck] = useState(false);
    const [verifiedEmail, setVerifiedEmail] = useState(false);

    useEffect(()=>{
        if(errorMessage){
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('code')])

    const onSubmit = (values: any) => {
        setIsLoading(true)
        if (errorMessage) {
            setErrorMessage('');
        }

        Auth.verifyCurrentUserAttributeSubmit('email', values.code).then(() => {
            dispatch(userApi.endpoints.verifyEmail.initiate()).then((res) => {
                dispatch(userApi.endpoints.getCurrentUserDetails.initiate(undefined, { forceRefetch: true }));
                if (res) {
                    setVerifiedEmail(true)
                }
            })
        }).catch(() => {
            if (!errorMessage) {
                setErrorMessage('wrongCodeEntered');
                setIsLoading(false)
            }
        });
    }

    const resendEmail = () => {
        Auth.currentAuthenticatedUser().then(user => { 
            Auth.verifyUserAttribute(user, 'email').then(()=>{
            if (!showToastMessage) {
                reset()
                setShowToastMessage(true);
            }
        }          
        ).catch((err) => {
            setLimitCheck(true);
        })
    });   
    }

    return {
        errorMessage,
        handleSubmit,
        control,
        register,
        errors,
        t,
        onSubmit,
        isLoading,
        limitCheck,
        resendEmail,
        showToastMessage,
        setShowToastMessage,
        setValue,
        verifiedEmail,
        setVerifiedEmail,
    };
}
