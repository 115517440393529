import { PageTitle } from "../Components/pageTitle"
import { useGetSettingValue } from "../Services/settingReducer"

function Version() {

  const apiVersion = useGetSettingValue('VERSION')
  const batchVersion = useGetSettingValue('BATCH_VERSION')
  const chatVersion = useGetSettingValue('CHAT_VERSION')


  return (
    <PageTitle title = 'Version'>
      <div className="font-semibold">Version</div>
      <div><span className="font-light">Web version:</span> <span className="font-semibold">{process.env.REACT_APP_VERSION}</span></div>
      <div><span className="font-light">API version: </span> <span className="font-semibold">{apiVersion}</span></div>
      <div><span className="font-light">Batch version: </span> <span className="font-semibold">{batchVersion}</span></div>
      <div><span className="font-light">Chat version: </span> <span className="font-semibold">{chatVersion}</span></div>
    </PageTitle>
  )
}

export default Version
