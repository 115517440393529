import * as React from 'react'
import { ParticipantTileProps, useParticipantTile, useEnsureTrackRef } from '@livekit/components-react'
import IndividualParticipantPlaceholder from './IndividualParticipantPlaceholder'
import { useCustomRoomContext } from '../CustomRoomContext'

/**
 * The ParticipantTile component is the base utility wrapper for displaying a visual representation of a participant.
 * This component can be used as a child of the `TrackLoop` component or by spreading a track reference as properties.
 *
 * @example
 * ```tsx
 * <ParticipantTile source={Track.Source.Camera} />
 *
 * <ParticipantTile {...trackReference} />
 * ```
 * @public
 */
export function Moderator({
  trackRef,
  // participant,
  children,
  // source = Track.Source.Microphone,
  onParticipantClick,
  // publication,
  disableSpeakingIndicator,
  ...htmlProps
}: ParticipantTileProps) {
  // const maybeTrackRef = useMaybeTrackRefContext()
  const trackReference = useEnsureTrackRef(trackRef)
  const { viewType } = useCustomRoomContext()
  // const p = useEnsureParticipant(participant)
  // const trackReference: TrackReferenceOrPlaceholder = React.useMemo(() => {
  //     return {
  //         participant: trackRef?.participant ?? maybeTrackRef?.participant ?? p,
  //         source: trackRef?.source ?? maybeTrackRef?.source ?? source,
  //         publication: trackRef?.publication ?? maybeTrackRef?.publication ?? publication,
  //     }
  // }, [maybeTrackRef, p, publication, source, trackRef])

  const { elementProps } = useParticipantTile<HTMLDivElement>({
    // participant: trackReference.participant,
    htmlProps,
    // source: trackReference.source,
    // publication: trackReference.publication,
    disableSpeakingIndicator,
    onParticipantClick,
  })

  return (
    <div
      {...elementProps}
      className={`rounded-md p-1 object-cover ${
        viewType === 'grid' ? 'aspect-[1/1.2] h-[90%]' : 'aspect-[1/1.2] h-[80%]'
      }`}
    >
      <IndividualParticipantPlaceholder
        participant={trackReference.participant}
        trackRef={trackReference}
        source={trackReference.source}
        publication={trackReference.publication}
        isModerator={true}
      >
        {children}
      </IndividualParticipantPlaceholder>
    </div>
  )
}
