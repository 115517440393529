import {createContext, useState} from 'react'

interface ImageModalState {
  orderNumber: number
  isImageInDB: boolean
  isImageSelected: boolean
}
interface ImageVideoContextProps {
  images: ImageModalState[]
  isVideoInDB: boolean
  isVideoSelected: boolean
  setIsVideoSelected: (isVideoSelected: boolean) => void
  setIsVideoInDB: (isVideoInDB: boolean) => void
  setImages: (images: ImageModalState[]) => void
  isTriggerSaveImageAndVideo: boolean
  setIsTriggerSaveImageAndVideo: (isTiggerSave: boolean) => void
  setSelectedImage: (orderNumber: number) => void
  setIsImageInDB: (orderNumber?: number) => void
  setRemoveImage: (orderNumber?: number) => void
  imageVideoUploadLoading: boolean
  setImageVideoUploadLoading: (isSuccess: boolean) => void
  isSaveTriggerWeb: boolean
  setIsSaveTriggerWeb: (isSaveTriggerWeb: boolean) => void
  isButtonLoadingWeb: boolean
  setIsButtonLoadingWeb: (isButtonloadingWeb: boolean) => void
}

export const ImageVideoContext = createContext<ImageVideoContextProps>(
  {} as ImageVideoContextProps,
)

export function ImageVideoContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const initialImages = [
    {orderNumber: 1, isImageSelected: false, isImageInDB: false},
    {orderNumber: 2, isImageSelected: false, isImageInDB: false},
    {orderNumber: 3, isImageSelected: false, isImageInDB: false},
    {orderNumber: 4, isImageSelected: false, isImageInDB: false},
    {orderNumber: 5, isImageSelected: false, isImageInDB: false},
  ]

  const [images, setImages] = useState<ImageModalState[]>(initialImages)
  const [isVideoInDB, setIsVideoInDB] = useState<boolean>(false)
  const [isVideoSelected, setIsVideoSelected] = useState<boolean>(false)
  const [isTriggerSaveImageAndVideo, setIsTriggerSaveImageAndVideo] = useState<boolean>(false)
  const [imageVideoUploadLoading, setImageVideoUploadLoading] = useState<boolean>(false)
  const [isSaveTriggerWeb, setIsSaveTriggerWeb] = useState<boolean>(false)
  const [isButtonLoadingWeb, setIsButtonLoadingWeb] = useState<boolean>(false)
  
  const setSelectedImage = (orderNumber?: number) => {
    const newImages = [...images]
    const image = newImages.find(image => image.orderNumber === orderNumber)
    if (image) {
      image.isImageInDB = false
      image.isImageSelected = true
    }
    setImages(newImages)
  }
  

  const setIsImageInDB = (orderNumber?: number) => {
    const newImages = [...images]
    const image = newImages.find(image => image.orderNumber === orderNumber)
    if (image) {
      image.isImageInDB = true
      image.isImageSelected = false
    }
    setImages(newImages)
  }

  const setRemoveImage = (orderNumber?: number) => {
    const newImages = [...images]
    const image = newImages.find(image => image.orderNumber === orderNumber)
    if (image) {
      image.isImageInDB = false
      image.isImageSelected = false
    }
    setImages(newImages)
  }


  return (
    <ImageVideoContext.Provider
      value={{
        images,
        setImages,
        isVideoInDB,
        setIsVideoInDB,
        isVideoSelected,
        setIsVideoSelected,
        isTriggerSaveImageAndVideo,
        setIsTriggerSaveImageAndVideo,
        setSelectedImage,
        setIsImageInDB,
        setRemoveImage,
        imageVideoUploadLoading,
        setImageVideoUploadLoading,
        isSaveTriggerWeb,
        setIsSaveTriggerWeb,
        isButtonLoadingWeb,
        setIsButtonLoadingWeb,
      }}>
      {children}
    </ImageVideoContext.Provider>
  )
}
