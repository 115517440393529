export const Config = {
  API_URL: '/api',
  WS_URL: '/socket',
  LIVEKIT_TOKEN: 'https://dev.api.livekit.beemyguest.jp',
  LIVEKIT_WSS: 'wss://dev.livekit.beemyguest.jp'
}

// export const Config = {
//   API_URL: 'https://api.dev.demo.beemyguest.jp/',
//   WEB_URL: 'https://dev.web.beemyguest.jp/',
//   WS_URL: 'wss://dev.websocket.beemyguest.jp/',
//   LIVEKIT_TOKEN: 'https://dev.api.livekit.beemyguest.jp',
//   LIVEKIT_WSS: 'wss://dev.livekit.beemyguest.jp'
// }