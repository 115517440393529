import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Icon from "../../Components/base/icon/icon";
import { Meeting } from "../../models/meeting.model";
import { getPreference } from "../../Services/userReducer";
import { useCurrencyHook } from "../../Hooks/CurrencyHooks";

export function DisplayEventCost({ meeting, keyId }: { meeting?: Meeting; keyId?: number }) {
    const preference = useSelector(getPreference)
    const { t } = useTranslation()
    const style = {
        eventDetailText: `items-center flex mr-2 ml-0.5 font-normal text-xs mb-2`,
    }

    const { formatCurrency } = useCurrencyHook();

    return <>
        {!meeting?.meetingDetails?.isFreeAudioMeeting && meeting?.costLocal && (
            <>
                <span id={`chk_cost${keyId}`} data-testid={`chk_cost${keyId}`}className={style.eventDetailText}>
                    {formatCurrency(meeting.costLocal_formatted, preference.currency)}
                </span>
            </>
        )}
        {meeting?.meetingDetails?.isFreeAudioMeeting &&  (
            <>
                <Icon icon="CURRENCY" size="x-small" height="small" />
                <span id={`chk_cost${keyId}`} className={style.eventDetailText}>
                    {t('freeEvent')}
                </span>
            </>
        )}
    </>
}
