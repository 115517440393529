import LogoGIF from "../../../Assets/Images/beemg_logo.gif"

function Loading() {
    return (
        <div className="flex justify-center" id="load_data" data-testid="load_data">
            <img src={LogoGIF} alt="loading..." className="h-14 w-14" style={{ objectFit: 'contain' }} />
        </div>
    )
}

export default Loading;