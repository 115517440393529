import { useEffect, useState } from "react"

export function DelayDisplay({children, delayTime}: {children: JSX.Element, delayTime: number}) {
    const [show, setShow] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true)
        }, delayTime)
        return () => clearTimeout(timer)
    }, [delayTime])
    return show ? children : null
}   