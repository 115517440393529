import React, { useState } from 'react'
import { useParticipants } from '@livekit/components-react'
import { UserType } from '../livekitTypes'
import { useOutsideClickDetectionHook } from '../livekitHooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faMicrophone, faMicrophoneSlash, faUsers } from '@fortawesome/free-solid-svg-icons'
import { defaultAvatar } from 'livekit/room/audio_conference/CustomAudioParticipantPlaceholder'
import { ToolTip } from 'base/tooltip/tooltip'
import { useTranslation } from 'react-i18next'

function MicIndicator({ isMicEnabled, id }: { isMicEnabled: boolean; id?: string | number }) {
  return (
    <div className="w-full flex-row flex justify-end">
      {isMicEnabled ? (
        <FontAwesomeIcon icon={faMicrophone} id={`${id}_mic`} className="mr-1" />
      ) : (
        <FontAwesomeIcon icon={faMicrophoneSlash} id={`${id}_micSlash`} />
      )}
    </div>
  )
}

const ParticipantCount = () => {
  const participants = useParticipants()
  const [searchTerm, setSearchTerm] = useState('')
  const { ref, isParticipantMenuOpen, setIsParticipantMenuOpen } = useOutsideClickDetectionHook({ initialValue: false })
  const filteredHosts = participants.filter((participant) => {
    const participantIdentity = participant.name?.toLowerCase()
    const userType = participant?.metadata && JSON.parse(participant?.metadata).userType
    return participantIdentity?.includes(searchTerm) && userType === UserType.HOST
  })

  const filteredCohosts = participants.filter((participant) => {
    const participantIdentity = participant.name?.toLowerCase()
    const userType = participant?.metadata && JSON.parse(participant?.metadata).userType
    return participantIdentity?.includes(searchTerm) && userType === UserType.CO_HOST
  })
  const { t } = useTranslation()

  const toggleParticipantMenu = () => {
    setIsParticipantMenuOpen(!isParticipantMenuOpen)
    setSearchTerm('')
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value
      .replace(/[^\w\s]/gi, '')
      .trim()
      .toLowerCase()
    setSearchTerm(term)
  }

  const filteredParticipants = participants.filter((participant) => {
    const participantIdentity = participant.name?.toLowerCase()
    const userType = participant?.metadata && JSON.parse(participant?.metadata).userType

    return participantIdentity?.includes(searchTerm) && userType === UserType.PARTICIPANT
  })

  return (
    <div ref={ref} className="relative inline-block">
      <ToolTip tip={t('participants')}>
        <div
          id="btn_participants"
          className="flex flex-row px-1 py-1 cursor-pointer lg:text-lg md:text-md sm:text-sm xs:text-xs"
          onClick={toggleParticipantMenu}
        >
          {/* <Icon icon="USERS_GROUP" /> */}
          <FontAwesomeIcon icon={faUsers} />
          <div id="participantsCount" className="lg:text-md md:text-sm sm:text-xs xs:text-xs px-1">
            {participants.length}
          </div>
        </div>
      </ToolTip>
      {isParticipantMenuOpen && (
        <div className="absolute top-full right-0 mt-1 p-4 bg-white rounded-md z-50 border transition-all duration-300 ease-in-out md:w-96 xs:w-60">
          <div className="border border-gray-200 rounded-md px-2 py-2 flex flex-row items-center justify-between mb-2">
            {/* <Icon icon="SEARCH" /> */}
            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-600" />
            <input
              id="input_searchParticipants"
              type="text"
              placeholder={t('searchParticipants')}
              value={searchTerm}
              onChange={handleSearch}
              className="ml-2 focus:outline-none w-full border-none"
            />
          </div>

          {filteredHosts.length > 0 && (
            <>
              <div id="txt_host" className="text-lg font-medium text-gray-400 mb-1">
                {t('host')}
              </div>
              <div id="hostsList" className="border px-1 py-1 rounded-md border-gray-200 mb-1">
                {/* Host list filter */}
                {filteredHosts.map((host, index) => {
                  const profileImage = host?.metadata && JSON.parse(host?.metadata)?.profileImageUrl
                  const isHostProfileNull = profileImage.includes('null')
                  const fullNameHost = host.name ? host.name.replace(/\b\w/g, (c) => c.toUpperCase()) : ''
                  const firstNameHost = fullNameHost?.split(' ')[0]

                  return (
                    <>
                      <div
                        key={host.identity}
                        id={`Host_${index}`}
                        className="hover:bg-gray-100 px-2 py-2 rounded-md w-full text-left cursor-default"
                      >
                        <div className="flex flex-row gap-x-2 items-center">
                          {!isHostProfileNull ? (
                            <img className="w-8 h-8 rounded-full" src={profileImage} alt="avatar" />
                          ) : (
                            <div className="bg-gray-200 rounded-full flex items-center justify-center w-10 h-8 p-2">
                              <img className="w-full h-full" src={defaultAvatar} alt="avatar" />
                            </div>
                          )}
                          <ToolTip tip={fullNameHost ?? ''}>
                            <div id={`HostName_${index}`} className="text-sm">
                              {firstNameHost}
                            </div>
                          </ToolTip>
                          <MicIndicator id={`Host${index}`} isMicEnabled={host.isMicrophoneEnabled} />
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </>
          )}

          {/* Co-host list filter */}
          {filteredCohosts.length > 0 && (
            <>
              <div id="txt_Co-hosts" className="text-lg font-medium text-gray-400 mb-1">
                {t('coHost')} ({filteredCohosts.length})
              </div>
              <div id="Co-hostsLists" className="border px-1 py-1 rounded-md border-gray-200">
                {filteredCohosts.map((cohost, index) => {
                  const profileImage = cohost?.metadata && JSON.parse(cohost?.metadata)?.profileImageUrl
                  const isCoHostProfileNull = profileImage && profileImage.includes('null')
                  const fullNameCoHost = cohost.name ? cohost.name.replace(/\b\w/g, (c) => c.toUpperCase()) : ''
                  const firstNameCoHost = fullNameCoHost?.split(' ')[0]

                  return (
                    <div
                      key={cohost.identity}
                      id={`Co-Host_${index}`}
                      className="hover:bg-gray-100 px-2 py-2 rounded-md w-full text-left cursor-default"
                    >
                      <div className="flex flex-row gap-x-2 items-center">
                        {!isCoHostProfileNull ? (
                          <img className="w-8 h-8 rounded-full" src={profileImage} alt="avatar" />
                        ) : (
                          <div className="bg-gray-200 rounded-full flex items-center justify-center w-10 h-8 p-2">
                            <img className="w-full h-full" src={defaultAvatar} alt="avatar" />
                          </div>
                        )}
                        <ToolTip tip={fullNameCoHost ?? ''}>
                          <div id={`co-HostsName_${index}`} className="text-sm">
                            {firstNameCoHost}
                          </div>
                        </ToolTip>
                        <MicIndicator id={`co-Hosts${index}`} isMicEnabled={cohost.isMicrophoneEnabled} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          )}

          {/* Participant list filter */}
          {filteredParticipants.length > 0 && (
            <>
              <div id="txt_participants" className="text-lg font-medium text-gray-400 mb-1">
                {t('participants')} ({filteredParticipants.length})
              </div>
              <div
                id="participantList"
                className="border px-1 py-1 rounded-md max-h-[40vh] no-scrollbar overflow-y-auto"
              >
                {filteredParticipants.map((participant, index) => {
                  const profileImage = participant?.metadata && JSON.parse(participant?.metadata)?.profileImageUrl
                  const isParticipantProfileNull = profileImage && profileImage.includes('null')
                  const fullNameParticipant = participant.name
                    ? participant.name.replace(/\b\w/g, (c) => c.toUpperCase())
                    : ''
                  const firstNameParticipant = fullNameParticipant.split(' ')[0]

                  return (
                    <div
                      key={participant.identity}
                      id={`Participant_${index}`}
                      className="hover:bg-gray-100 px-2 py-2 rounded-md w-full text-left cursor-default"
                    >
                      <div className="flex flex-row gap-x-2 items-center">
                        {!isParticipantProfileNull ? (
                          <img
                            src={profileImage}
                            alt="avatar"
                            className="rounded-full"
                            style={{ width: '36px', height: '36px' }}
                          />
                        ) : (
                          <div className="bg-gray-200 rounded-full flex items-center justify-center w-10 h-8 p-2">
                            <img className="w-full h-full" src={defaultAvatar} alt="avatar" />
                          </div>
                        )}
                        <ToolTip tip={fullNameParticipant ?? ''}>
                          <div id={`participantsName_${index}`} className="text-sm">
                            {firstNameParticipant}
                          </div>
                        </ToolTip>
                        <MicIndicator id={`participant${index}`} isMicEnabled={participant.isMicrophoneEnabled} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          )}
          {filteredParticipants.length === 0 &&
            filteredCohosts.length === 0 &&
            filteredHosts.length === 0 &&
            searchTerm !== '' && (
              <div className="text-gray-500 font-medium text-lg mt-6 text-center">{t('noResultsFound')}</div>
            )}
        </div>
      )}
    </div>
  )
}

export default ParticipantCount
