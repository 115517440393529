import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toastSuccess } from '../../Components/toast';
import { useInviteHooks } from '../../Hooks/InviteHook';
import Invite from './Invite';
import { TagsInput } from '../../Components/base/tags-input/tagInput';
import { string } from 'yup';
import { Button } from '../../Components';

interface InvitedUser {
    inviteEmail: string;
}
function StartInviting() {
    const { t } = useTranslation();
    const { serverError, setServerError, invitationsLeft, newEmails, setNewEmails, uniqueEmailError, onSubmit, sendInviteLoading, showToastMessage, setShowToastMessage } = useInviteHooks();
    let formRef: any;

    useEffect(() => {
        if (showToastMessage) {
            toastSuccess(t('invitedSuccessfully'));
            setShowToastMessage(false);
        }
    }, [showToastMessage]);

    return (
      <>
        {serverError && <Invite serverError={serverError} />}
        <div className="w-full justify-center items-center">
          <div className="relative flex flex-col gap max-w-80 mt-2 mb-2 mx-auto justify-center items-center">
            {newEmails.length === 0 && invitationsLeft !== 0 &&  (
              <div className="text-red-500" data-testid="pleaseEnterEmailsAndPressEnter">
                {t('pleaseEnterEmailsAndPressEnter')}
              </div>
            )}
            <TagsInput
              placeHolder={`${t('enterEmailsToInvite')} *`}
              value={newEmails}
              error={uniqueEmailError}
              separators={[',', ';', ' ']}
              disabled={invitationsLeft - newEmails.length <= 0}
              beforeAddValidate={(newTag, tags) => {
                try {
                  string().email('invalid').validateSync(newTag);
                  return true;
                } catch (error) {
                  return false;
                }
              }}
              onExisting={(email) => {
                console.log(email, 'email exists');
              }}
              onChange={(tags) => {
                setNewEmails(tags);
              }}
              onKeyUp={(e) => {
                setServerError(undefined)

              }}
              name="inviteEmail"
            />

            {serverError && serverError?.map((e: any, index: number) => {
                return <div id={`invite_errMsg${index}`} key={index} data-testid={`invite_errMsg${index}`} className="text-red-500 mt-2 text-sm">{e.inviteEmail} : {t(e.error)}</div>
                              })}

            {invitationsLeft - newEmails.length < 0 && (
              <div className='text-red-500 text-sm' data-testid="countExceed" id="countExceed">
                {t('inviteCountExceeded')} {t('by')} {newEmails.length - invitationsLeft}
              </div>
            )}

            {invitationsLeft - newEmails.length >= 0 &&
              <><h1 className="text-red-500 text-sm mt-2" id="chk_inviteListLength" data-testid="chk_inviteListLength">
                  {invitationsLeft - newEmails.length > 0 ?
                    <>{invitationsLeft - newEmails.length} {t('moreInvitationsLeft')}</>
                  :
                    <>{t('noMoreInvitationsLeft')}</>
                  }
                </h1>
              </>
            }

          </div>
          <div className="flex justify-center">
            <Button
              id="btn_notify"
              data-testid="btn_notify"
              disabled={sendInviteLoading || newEmails.length === 0 || invitationsLeft - newEmails.length < 0 || invitationsLeft === 0 || uniqueEmailError.length !== 0}             
              className="justify-center flex flex-row py-1 px-2 w-52 bg-gray-200 rounded hover:bg-primary"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
                    >
              {t('invite')}
            </Button>
          </div>
        </div>
      </>
    );
}

export default StartInviting;
