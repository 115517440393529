/* eslint-disable react-hooks/exhaustive-deps */
import { isAfter } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAllBlockedChatsQuery } from '../Services/blockChatApi';
import {
  useGetAllChatUsersQuery,
  useGetMessagesByUserQuery,
  useGetTodayMeetingRequestCountQuery,
  useGetUnReadMessageCountQuery,
  useUpdateSeenMutation,
} from '../Services/messageApi';
import { useGetSettingValue } from '../Services/settingReducer';
import { getCurrentUser } from '../Services/userReducer';
import { ChatMessage, ChatUser, ChatUserData } from '../Store/Chat/ChatModel';
import { useAppDispatch, useAppSelector } from '../Store/hooks';
import { isNotEmptyArray } from '../Utils/helper';
import { Meeting } from '../models/meeting.model';
import { meetingApi } from './../Services/meetingApi';
import { isSocketConnected, isSocketLogined } from './../Services/socketReducer';
import { getIsAppOffline } from './../Services/utilReducer';
import { ChatMessageType } from './../Store/Chat/ChatModel';
import {
  calculateMeetingDuration,
  convertChatTime,
  getChatUsersList,
  getCurrentChatUser,
  getMessages,
  getOfflineQueue,
  getTodayMeetingRequestCount,
  sendChatMessage,
  setMaxCharExceeded,
  updateVideoRequestMeeting,
} from './../Store/Chat/chat';
import { Accept } from './../models/meetingRequest.model';

export const useUnreadMessageCount = () => {
  useGetAllChatUsersQuery()
  const { data: chatUnreadAPIcount } = useGetUnReadMessageCountQuery(null, { pollingInterval: 5000 })
  const [unreadChatCount, setUnreadChatCount] = useState<number>(0)
  const chatUserList = useSelector(getChatUsersList) as ChatUserData[]
  useEffect(() => {
    if (isNotEmptyArray(chatUserList)) {
      let total: number = 0

      if (chatUnreadAPIcount && typeof chatUnreadAPIcount === 'number') {
        total += chatUnreadAPIcount;
      }

      setUnreadChatCount(total)
    }
  }, [chatUserList, chatUnreadAPIcount])

  return {
    unreadChatCount,
  }
}

export const useChatErrorAndAllowed = () => {
  const dispatch = useDispatch()

  const chatVideoLimit = useGetSettingValue('CHAT_VIDEO_REQUEST_PER_DAY')
  const maxTextSize = useGetSettingValue('CHAT_TEXT_SIZE')
  const chatAcceptTimeLimit = useGetSettingValue('CHAT_ACCEPT_VIDEO_REQUEST_TIME_LIMIT(MINUTES)')

  const todayMeetingRequest = useSelector(getTodayMeetingRequestCount)
  const currentUser = useSelector(getCurrentUser)
  const currentChatUser = useSelector(getCurrentChatUser)
  const { refetch: refetchTodayMeetingRequest } =
    useGetTodayMeetingRequestCountQuery()

  const [allowMessage, setAllowMessage] = useState<boolean>(false)
  const [showUpdatePreferences, setShowUpdatePreferences] = useState<boolean>(false)
  const [showCannotSend, setShowCannotSend] = useState<boolean>(false)
  const [showVideoLimitExceeded, setShowVideoLimitExceeded] = useState<boolean>(false)
  const [videoLimitExceeded, setVideoLimitExceeded] = useState<boolean | undefined>()
  const [hasActiveVideoRequest, setHasActiveVideoRequest] = useState<boolean | undefined>()

  const { useCurrentChats } = useGetCurrentChats()
  const currentChats = useCurrentChats()

  useEffect(() => {
    if (currentChats && currentChats.length > 0 && currentChatUser) {
      checkIfHavingActiveVideoRequest(currentChats);

      let interval: any ;

      interval = setInterval(() => {
        checkIfHavingActiveVideoRequest(currentChats);
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [currentChats, currentChatUser])

  useEffect(() => {
    setVideoLimitExceeded(parseInt(chatVideoLimit) <= todayMeetingRequest)
    if (currentUser?.allowMessage && currentChatUser && !currentChatUser.allowMessage) {
      setShowCannotSend(true)
    } else {
      setShowCannotSend(false)
    }
    setShowUpdatePreferences(!currentUser?.allowMessage)
    setShowVideoLimitExceeded(
      currentUser?.allowMessage &&
      currentChatUser?.allowMessage &&
      videoLimitExceeded,
    )
    if (currentUser?.allowMessage && currentChatUser?.allowMessage) {
      setAllowMessage(true)
    } else {
      setAllowMessage(false)
    }
  }, [
    currentChatUser,
    currentUser,
    chatVideoLimit,
    todayMeetingRequest,
    videoLimitExceeded,
  ])

  const handleMessageSize = (message: string) => {
    if (maxTextSize) {
      if (message.length > parseInt(maxTextSize)) {
        dispatch(setMaxCharExceeded(true))
      } else {
        dispatch(setMaxCharExceeded(false))
      }
    }
  }

  const checkIfHavingActiveVideoRequest = (chats: ChatMessage[]) => {

    const videoRequests = chats.filter(chat => chat.type === ChatMessageType.Video);

    if (videoRequests && videoRequests.length > 0) {
      const videoRequestAcceptTimeLimit = new Date(Date.now() - parseInt(chatAcceptTimeLimit) * 60 * 1000)

      const sentVideoRequests = videoRequests.filter(chat => chat.fromUser?.id === currentUser.id);
      const activeSentVideoRequests = sentVideoRequests.find(videoRequest => (isAfter(new Date(videoRequest.dateSend), videoRequestAcceptTimeLimit) && (!videoRequest.meetingRequest.accept || videoRequest.meetingRequest.accept === Accept.Yes)));

      if (activeSentVideoRequests) {
        setHasActiveVideoRequest(true)
      } else {
        setHasActiveVideoRequest(false)
      }
    }
  }

  return {
    videoLimitExceeded,
    allowMessage,
    showUpdatePreferences,
    showCannotSend,
    showVideoLimitExceeded,
    refetchTodayMeetingRequest,
    handleMessageSize,
    maxTextSize,
    chatVideoLimit,
    todayMeetingRequest,
    hasActiveVideoRequest
  }
}

export const useGetCurrentChats = () => {
  const allMessages = useSelector(getMessages)
  const currentUser = useSelector(getCurrentUser)
  const currentChatUser = useSelector(getCurrentChatUser) as ChatUser
  const [unreadMessage, setUnreadMessage] = useState<ChatMessage | undefined>()

  useEffect(() => {
    if ((allMessages && allMessages.length > 0) && currentChatUser) {
      const currentReceivedMessages = allMessages.filter((message) => message.fromUser?.id === currentChatUser?.id && message.toUser?.id === currentUser?.id)
      setUnreadMessage(currentReceivedMessages.find((message) => message.dateSeen === null));
    }
  }, [currentChatUser, allMessages])

  const useCurrentChats = (): ChatMessage[] =>
    useMemo(() => getCurrentMessages(), [currentChatUser, allMessages])

  const getCurrentMessages = () =>
    allMessages?.filter(
      (item: ChatMessage) =>
        (item.fromUser?.id === currentUser?.id &&
          item.toUser?.id === currentChatUser?.id) ||
        (item.fromUser?.id === currentChatUser?.id &&
          item.toUser?.id === currentUser?.id)
    )

  const checkLoadMore = (limit: number) => {
    return getCurrentMessages().length > limit / 2
  }

  return {
    useCurrentChats,
    checkLoadMore,
    unreadMessage
  }
}

export const useVideoMeetingStatus = ({
  message,
}: {
  message: Partial<ChatMessage>
}) => {
  const [status, setStatus] = useState<string>()
  const [minutes, setMinutes] = useState<string>()

  const dispatch = useAppDispatch();

  let interval: any;

  useEffect(() => {
    checkMeetingStatus()
    interval = setInterval(() => {
      checkMeetingStatus()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [message])

  const calculateDuration = (meeting: Meeting) => {
    const startDate = new Date(meeting.actualStartAt)
    const endDate = new Date(meeting.expectedEndAt)
    const diff = endDate.getTime() - startDate.getTime()
    const minutes = Math.floor(diff / (1000 * 60))

    // if under a minute, returning 1;
    if (minutes === 0) {
      return "1"
    }
    return minutes.toString()
  }

  const checkMeetingStatus = () => {
    if (message.meetingRequest?.meeting) {
      getMeetingDetails()
      if (message.meetingRequest?.meeting?.actualEndAt && message.meetingRequest?.meeting?.actualStartAt) {
        setStatus(`meetingDuration`)
        setMinutes(calculateMeetingDuration(message.meetingRequest?.meeting)?.toString())
        clearInterval(interval)
      }

      if (message.meetingRequest?.meeting?.actualStartAt && !message.meetingRequest?.meeting?.actualEndAt) {
        setStatus('onGoingMeeting')
        getMeetingDetails()
      }

      if (!message.meetingRequest.meeting?.actualEndAt && message.meetingRequest.meeting?.expectedEndAt && message.meetingRequest.meeting.actualStartAt &&
        isAfter(new Date(), new Date(message.meetingRequest.meeting?.expectedEndAt))) {
        setStatus('meetingDuration')
        setMinutes(calculateDuration(message.meetingRequest.meeting))
        clearInterval(interval)
      }
    }
  }

  const getMeetingDetails = () => {
    if (message.meetingRequest?.accept === Accept.Yes && message.meetingRequest?.meeting?.id && (!message.meetingRequest.meeting.actualEndAt)) {
      dispatch(meetingApi.endpoints.getMeeting.initiate(message.meetingRequest.meeting.id, { forceRefetch: true })).unwrap().then((meeting) => {
        dispatch(updateVideoRequestMeeting(meeting));
      })
    }
  }
  return { status, minutes }
}

export const useGetCurrentChatMessages = () => {

  const [skip, setSkip] = useState(0)
  const currentChatUser = useSelector(getCurrentChatUser)
  const { isBlockedByMe, gettingAllBlockedData } = useChatUserBlockedHook()

  const args = {
    userId: currentChatUser?.id,
    limit: 20,
    skip: skip,
    seenOnly: isBlockedByMe(currentChatUser?.id)
  }

  const socketConnected = useSelector(isSocketConnected)

  const { isFetching, isLoading, isSuccess, refetch: refetchCurrentChats, isUninitialized: getMessagesUnInitialized } = useGetMessagesByUserQuery(args, { skip: !currentChatUser?.id || gettingAllBlockedData })
  const { refetch: refetchChatUsers, isUninitialized: getAllChatUsersUnInitialized } = useGetAllChatUsersQuery()


  useEffect(() => {
    if (socketConnected && !getMessagesUnInitialized && !getAllChatUsersUnInitialized) {
      refetchCurrentChats();
      refetchChatUsers();
    }
  }, [socketConnected, getMessagesUnInitialized, getAllChatUsersUnInitialized])


  return { skip, setSkip, isFetching, isLoading, isSuccess, getMessagesUnInitialized }
}

export const useCheckOfflineMessages = () => {
  const isAppOffline = useAppSelector(getIsAppOffline);
  const offlineQueue = useAppSelector(getOfflineQueue);
  const socketConnected = useAppSelector(isSocketConnected);
  const socketLogined = useAppSelector(isSocketLogined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAppOffline && socketConnected && socketLogined && offlineQueue && offlineQueue.length > 0) {
      sendPendingMessages()
    }
  }, [isAppOffline, offlineQueue, socketConnected, socketLogined])

  const sendPendingMessages = () => {
    offlineQueue.forEach((message) => {
      dispatch(sendChatMessage(message));
    })
  }
}

export const useChatSeenUpdateHook = (message: ChatMessage) => {

  const [updateSeen] = useUpdateSeenMutation()
  const [isUpdatedSeen, setIsUpdatedSeen] = useState(false)

  useEffect(() => {
    if (message && !message.dateSeen) {
      updateSeen(message).unwrap().then(() => {
        setIsUpdatedSeen(true)
      }).catch((err) => {
        setIsUpdatedSeen(false)
      });
    }
  }, [message])

  return { isUpdatedSeen }
}

export const useChatUserBlockedHook = () => {
  const { data: allBlockedData, isLoading: gettingAllBlockedData } = useGetAllBlockedChatsQuery(null);
  const currentUser = useAppSelector(getCurrentUser)

  const isBlockedByMe = (id: string): boolean => {
    const result = !!allBlockedData?.find((blockedData) => blockedData.blockedChatUser === id && blockedData.user_id === currentUser.id) ?? false;


    return result;
  }

  const isBlockedMe = (id: string): boolean => {
    const result = !!allBlockedData?.find((blockedData) => blockedData.blockedChatUser === currentUser.id && blockedData.user_id === id) ?? false;


    return result;
  }

  return { isBlockedByMe, isBlockedMe, gettingAllBlockedData }
}

export const useGetChatTimeStampHook = ({ dateSend, timezone, t }: { dateSend: string, timezone: string, t: any }) => {

  const [chatTimeStamp, setChatTimeStamp] = useState(convertChatTime(dateSend, timezone, t))

  useEffect(() => {
    let interval: any;

    if (dateSend && timezone) {
      interval = setInterval(() => {
        if (isWithinMinutes(dateSend)) {
          setChatTimeStamp(convertChatTime(dateSend, timezone, t))
        } else {
          clearInterval(interval);
        }
      }, 60000)
    }
    return () => clearInterval(interval);
  }, [dateSend, timezone])

  const isWithinMinutes = (dateSend: string) => {
    const nowDateTime = new Date()
    const chatDateTime = new Date(dateSend)
    const difference = nowDateTime.valueOf() - chatDateTime.valueOf()
    return difference <= 3.6e6;
  }

  return { chatTimeStamp }
}

export const useAcceptedRequestHook = () => {

  const [hasAcceptedRequest, setHasAcceptedRequest] = useState<boolean | undefined>()
  const chatAcceptTimeLimit = useGetSettingValue('CHAT_ACCEPT_VIDEO_REQUEST_TIME_LIMIT(MINUTES)')

  const currentUser = useAppSelector(getCurrentUser)
  const currentChatUser = useAppSelector(getCurrentChatUser)
  const { useCurrentChats } = useGetCurrentChats()
  const currentChats = useCurrentChats()

  useEffect(() => {
    if (currentChats && currentChats.length > 0 && currentChatUser) {
      checkIfHavingAcceptedVideoRequest(currentChats);

      let interval: any;

      interval = setInterval(() => {
        checkIfHavingAcceptedVideoRequest(currentChats);
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [currentChats, currentChatUser])


  const checkIfHavingAcceptedVideoRequest = (chats: ChatMessage[]) => {

    const videoRequests = chats.filter(chat => chat.type === ChatMessageType.Video);

    if (videoRequests && videoRequests.length > 0) {
      const videoRequestAcceptTimeLimit = new Date(Date.now() - parseInt(chatAcceptTimeLimit) * 60 * 1000)

      const currentVideoRequests = videoRequests.filter(chat => ((chat.fromUser?.id === currentUser.id) || (chat.fromUser?.id === currentChatUser.id)));
      const activeSentVideoRequests = currentVideoRequests.find(videoRequest => (isAfter(new Date(videoRequest.dateSend), videoRequestAcceptTimeLimit) && (videoRequest?.meetingRequest?.accept === Accept.Yes)));

      if (activeSentVideoRequests) {
        setHasAcceptedRequest(true)
      } else {
        setHasAcceptedRequest(false)
      }
    }
  }

  return { hasAcceptedRequest }
}