import {format} from 'date-fns'
import {enGB, ja} from 'date-fns/locale'
import {useTranslation} from 'react-i18next'

export function DisplayFormattedDate({
  displayDate,
  dateFormat = 'MMM dd, yyyy',
}: {
  displayDate: string | Date
  dateFormat?: string
}) {
  const { i18n } = useTranslation()
  const displayDateObj = new Date(displayDate)

  return (
    <>
      {format(displayDateObj, dateFormat, {
        locale: i18n.language === 'en' ? enGB : ja,
      })}
    </>
  )
}

export function DisplayFormattedMonth({
  displayDate,
  monthFormat = 'MMM',
}: {
  displayDate: string | Date;
  monthFormat?: string;
}) {
  const { i18n } = useTranslation();
  const displayDateObj = new Date(displayDate);

  return (
    <>
      {format(displayDateObj, monthFormat, {
        locale: i18n.language === 'en' ? enGB : ja,
      })}
    </>
  );
}

export function DisplayFormattedTime({
  displayFromTime,
  displayToTime,
}: {
  displayFromTime: string
  displayToTime?: string
}) {
  const { i18n } = useTranslation()
  const displayFromTimeObj = new Date(displayFromTime)
  const displayToTimeObj = displayToTime ? new Date(displayToTime) : null

  return (
    <>
      {format(displayFromTimeObj, 'h:mm a', {
        locale: i18n.language === 'en' ? enGB : ja,
      })}
      {displayToTimeObj && (
        <>
          {' - '}
          {format(displayToTimeObj, 'h:mm a', {
            locale: i18n.language === 'en' ? enGB : ja,
          })}
        </>
      )}
    </>
  )
}

export function DisplayFormattedDateAndTime({
  displayDate,
  dateFormat = 'MMM dd, yyyy',
  displayFromTime,
  displayToTime,
}: {
  displayDate: string | Date
  dateFormat?: string
  displayFromTime: string
  displayToTime?: string
}) {
  const { i18n } = useTranslation()
  const displayDateObj = new Date(displayDate)
  const displayFromTimeObj = new Date(displayFromTime)
  const displayToTimeObj = displayToTime ? new Date(displayToTime) : null

  return (
    <>
      {format(displayFromTimeObj, 'h:mm a', {
        locale: i18n.language === 'en' ? enGB : ja,
      })}
      {displayToTimeObj && (
        <>
          {' - '}
          {format(displayToTimeObj, 'h:mm a', {
            locale: i18n.language === 'en' ? enGB : ja,
          })}
        </>
      )}{' '}
      {format(displayDateObj, dateFormat, {
        locale: i18n.language === 'en' ? enGB : ja,
      })}
    </>
  )
}

export function FormatDateAndTime(
  dateTime: Date,
  dateFormat: string,
  timeFormat: string,
): string {
  const {i18n} = useTranslation()
  const formattedDate = format(dateTime, dateFormat, {
    locale: i18n.language === 'en' ? enGB : ja,
  })
  const formattedTime = format(dateTime, timeFormat, {
    locale: i18n.language === 'en' ? enGB : ja,
  })

  return `${formattedDate} ${formattedTime}`
}
