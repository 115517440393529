import { FocusToggle, ParticipantContextIfNeeded, useEnsureTrackRef } from '@livekit/components-react'
import React from 'react'
import { CustomAudioParticipantPlaceholder } from './CustomAudioParticipantPlaceholder'
import type { TrackReferenceOrPlaceholder } from '@livekit/components-core'
import { Participant, Track, TrackPublication } from 'livekit-client'

interface ParticipantTileProps {
  participant: Participant | undefined
  trackRef: TrackReferenceOrPlaceholder | undefined
  children?: React.ReactNode
  source?: Track.Source
  publication: TrackPublication | undefined
  isModerator: boolean
}

export default function IndividualParticipantPlaceholder(props: ParticipantTileProps) {
  const { trackRef, children, isModerator } = props
  //   const maybeTrackRef = useMaybeTrackRefContext()
  const trackReference = useEnsureTrackRef(trackRef)
  //   const p = useEnsureParticipant(participant)
  // const trackReference: TrackReferenceOrPlaceholder = React.useMemo(() => {
  //     return {
  //         participant: trackRef?.participant ?? maybeTrackRef?.participant ?? p,
  //         source: trackRef?.source ?? maybeTrackRef?.source ?? source,
  //         publication: trackRef?.publication ?? maybeTrackRef?.publication ?? publication,
  //     }
  // }, [maybeTrackRef, p, publication, source, trackRef])

  return (
    <>
      <ParticipantContextIfNeeded participant={trackReference.participant}>
        {children ?? (
          <>
            <CustomAudioParticipantPlaceholder isModerator={isModerator} />
          </>
        )}
        <FocusToggle />
      </ParticipantContextIfNeeded>
    </>
  )
}
