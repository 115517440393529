import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useGetSettingValue } from '../Services/settingReducer';
import React from 'react';
import { getCurrentUser } from '../Services/userReducer';
import { useAppSelector } from '../Store/hooks';
import { Verified } from '../models/user.model';

function Home() {
  const { t } = useTranslation()
  const baseImageURL = useGetSettingValue('IMAGE_URL');
  const currentUser = useAppSelector(getCurrentUser);

  if (currentUser?.phoneVerified === Verified.NotComplete) {
    return <></>
  }

  const style = {
    textOverlay1: `absolute w-full bottom-10 pl-12 xs:pl-5  text-white font-bold text-3xl md:text-4xl text-left gap-3 group-hover:text-6xl`,
  }

  return (
    <div className="flex flex-col md:flex-row h-full px-10">
      <div className="overflow-hidden md:w-1/2 h-full group" id="btn_globalTalent">
        <Link to="/s/creator">
          <div className="mx-auto relative h-full">
            <div className='bg-black absolute w-full h-full z-10 bg-opacity-50 group-hover:bg-opacity-0' />
            <div
              className="bg-center bg-no-repeat bg-cover transform transition duration-200 hover:scale-125 h-full"
              style={{
                backgroundImage: `url(${baseImageURL}/new/creator-home.webp)`,
              }}>
            </div>
            <div className={style.textOverlay1} >
              <div data-testid="home_text1">{t('theCreators')} {t('network')}</div>
            </div>
          </div>
        </Link>
      </div>

      <div className="overflow-hidden md:w-1/2 h-full group" id="btn_connecting">
        <Link to="/s/collaborator">
          <div className="mx-auto relative h-full">
            <div className='bg-black absolute w-full h-full z-10 bg-opacity-50 group-hover:bg-opacity-0' />
            <div
              className="bg-center bg-no-repeat bg-cover transform transition duration-200 hover:scale-125 h-full"
              style={{
                backgroundImage: `url(${baseImageURL}/new/collaborator-home.webp)`,
              }}></div>
            <div className={style.textOverlay1} >
              <div data-testid="home_text2">{t('connecting')} {t('collaborators')}</div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default React.memo(Home)
