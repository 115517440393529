import { User } from "../../models/user.model";

const UserDescription = ({ user }: { user: User }) => {

  return (
    <div data-testid="hostDescription" id="hostDescription" className="whitespace-normal break-words text-justify font-normal">
      <span
        className="text-base"
        dangerouslySetInnerHTML={{
          __html: user?.selfIntro ? user?.selfIntro : '',
        }}>
      </span>
    </div>
  )
}

export default UserDescription;
