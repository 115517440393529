/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Chip from "../../../Components/Chip";
import Icon from "../../../Components/base/icon/icon";
import Loading from "../../../Components/base/loading/loading";
import { sortingCategories, useCategoryListForSelectHook, useUpdateCategoryHook } from "../../../Hooks/SelectCategoriesHook";
import { getUserCategories } from "../../../Services/userReducer";
import { useAppSelector } from "../../../Store/hooks";
import { Category } from "../../../models/category.model";
import { UserCategory } from "../../../models/userCategory.model";
import { useTranslation } from 'react-i18next';

function DisplaySelectedCategories({ removeCategory }: { removeCategory: (cat: UserCategory) => void }) {
  const userCategories = useAppSelector(getUserCategories)
  const sortedCategories = sortingCategories(userCategories)
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-row gap-1 flex-wrap">
        {sortedCategories?.map((userCategory) => {

          if (userCategory.category) {
            return (
              <Chip
                isSelected={true}
                value={t(`userCategories.${userCategory.category.name}`)}
                key={userCategory.id}
                removeCatId={`remove_${userCategory.category?.name}`}
                onClickClose={() => removeCategory(userCategory)}
              />
            )
          }
          return null;
        }
        )}

      </div>
    </>
  )
}

function SubCategoryDisplay({ categories, setSelected }: { categories: Category[], setSelected: (cat: Category) => void }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-1 flex-wrap">
      {categories?.map((category) =>
        <Chip
          key={category.id}
          onClick={() => setSelected(category)}
          addCatId={`category_${category?.name.split(' ')[0]}`}
          value={t(`userCategories.${category.name}`)}
        />
      )}
    </div>
  )
}

function CategoryListForSelect({
  parentCategories, setSelectedUserCategory
}: { parentCategories?: Category[], setSelectedUserCategory: (userCat: UserCategory) => void }) {

  const { t } = useTranslation();
  const {
    isLoading, categories, displayCategoriesNames, setCategorySelected, resetCategorySelected, loadCategoryId, selectedUserCategory
  } = useCategoryListForSelectHook();

  const translatedCategoriesNames = displayCategoriesNames.map(name => t(`userCategories.${name}`));
  useEffect(() => {
    if (selectedUserCategory) {
      setSelectedUserCategory(selectedUserCategory);
    }
  }, [selectedUserCategory]);

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="flex flex-col gap-3">
      {displayCategoriesNames.length > 0 && (
        <div data-testid={`displayCategory_${translatedCategoriesNames}`}>{translatedCategoriesNames.join(' -> ')} {' ->'}</div>
      )}
      {parentCategories && !loadCategoryId && (
        <SubCategoryDisplay categories={parentCategories} setSelected={setCategorySelected} />
      )}
      {categories && loadCategoryId && (
        <>
          <div className="flex flex-row">
            <SubCategoryDisplay categories={categories} setSelected={setCategorySelected} />
            <span className="ml-2 cursor-pointer"><Icon icon="CLOSE" onClick={() => resetCategorySelected()} /></span>
          </div>
        </>
      )}
    </div>
  )
}

function UpdateCategory() {
  const { isLoading, currentUser, parentCategories, removeUserCategory, setSelectedUserCategory } = useUpdateCategoryHook();

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="flex flex-col gap-8">
        {currentUser?.userCategory && <DisplaySelectedCategories removeCategory={removeUserCategory} />}
        <CategoryListForSelect parentCategories={parentCategories} setSelectedUserCategory={setSelectedUserCategory} />
      </div>
    </>
  )
}

export default UpdateCategory;
