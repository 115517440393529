import { useTranslation } from 'react-i18next'
import { Button } from '../Components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import InitialPage from './InitialPage'
import NewIcon from '../Components/base/icon/newIcons'
import { getCurrentUser } from '../Services/userReducer'
import { useAppSelector } from '../Store/hooks'
import { useEffect } from 'react'
import { getToken } from '../Services/tokenReducer'

function InitialPageContent(props: any) {
  const { t } = useTranslation()
  return (
    <span className="flex items-center flex-col p-3 rounded-lg md:opacity-90 bg-none gap-y-4">
      <NewIcon icon="BEEMG_LOGO" size="xxl-large" stroke='none' />
      <span className="text-3xl sm:text-normal font-semibold flex flex-wrap text-center items-center justify-center">
        <span>{t('create')}.&nbsp;</span>
        <span>{t('connect')}.&nbsp;</span>
        <span>{t('collaborate')}</span>
      </span>
      <div className="flex flex-col gap-y-3">
        <div>
          <Link to="/signIn">
            <Button
              className="w-[20rem] rounded-lg normal-case"
              color="save"
              id="btn_login">
              {t('login')}
            </Button>
          </Link>
        </div>
        <div>
          <Link to="/notify">
            <Button
              className="w-[20rem] rounded-lg normal-case"
              color="save"
              id="btn_registerForPublicLaunch">
              {t('registerForPublicLaunch')}
            </Button>
          </Link>
        </div>
      </div>
    </span>
  )
}

function Sign() {
  const currentUser = useAppSelector(getCurrentUser)
  const token = useAppSelector(getToken)
  const { pathname } = useLocation();
  const navigate = useNavigate()
  
  useEffect(() => {
    if (token &&currentUser && pathname !== '/s/home') {
      navigate('/s/home')
    }
  }, [token, currentUser, pathname])

  return (
    <InitialPage>
      <span
        className="flex justify-center items-center" >
        <InitialPageContent />
      </span>
    </InitialPage>
  )
}

export default Sign
