import { useState, useEffect } from "react";
import { Pagination } from "../models/paginationReact.model";

interface PageNumberDisplay {
    displayStartPageNumber: number;
    displayEndPageNumber: number;
}

export interface IPaginationElement {
    pagination: Pagination & PageNumberDisplay;
    nextPage: () => void;
    prevPage: () => void;
    goToPage: (pageNumber: number) => void;
    firstPage: () => void;
    lastPage: () => void;
}


function useFetchDataHooks<T>(useQuery: any, filter?: T) {

    const [pagination, setPagination] = useState<Pagination & PageNumberDisplay>({
        pageIndex: 0,
        pageSize: 5,
        totalElements: 0,
        totalPages: 0,
        sortBy: "",
        displayStartPageNumber: 0,
        displayEndPageNumber: 0
    });

    const { data: pageData, isFetching: isPageLoading, refetch: pageDataRefetch,isSuccess:pageDataSuccess } = useQuery({ ...filter, pageSize: pagination.pageSize, pageIndex: pagination.pageIndex });

    useEffect(() => {
        if (pageData?.page) {
            const totalPages = Math.ceil(pageData?.page?.totalElements / pageData?.page?.pageSize);

            let displayStartPageNumber = pageData?.page?.pageIndex - 1 > 0 ? pageData?.page?.pageIndex - 1 : 1;
            let displayEndPageNumber = pageData?.page?.pageIndex + 3 < totalPages? pageData?.page?.pageIndex + 3 : totalPages;
            const diff = displayEndPageNumber - displayStartPageNumber;
            if (diff < 4) {
                if (displayStartPageNumber - (4 - diff) > 0) {
                    displayStartPageNumber = displayStartPageNumber - (4 - diff);
                }
                else {
                    displayEndPageNumber = displayEndPageNumber + (4 - diff);
                }
            }


            setTimeout(() => {
                setPagination({ ...pagination, totalPages, totalElements: pageData?.page?.totalElements, displayStartPageNumber, displayEndPageNumber });
            }, 50);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageData]);

    return {
        pageData, isPageLoading,pageDataRefetch, setPagination, pageDataSuccess,pagination: { pagination } as IPaginationElement
    };
}

export default useFetchDataHooks;