import { useEffect, useState } from 'react';
import {
    useGetAllMeetingsQuery
} from '../Services/meetingApi';
import { CreatorLocalFilter, CreatorServerFilter } from './../models/creatorFilter.model';
import { Meeting } from './../models/meeting.model';
import { useGetAllMeetingCategoryQuery } from '../Services/allOthersApi';
import { MeetingCategory } from '../models/meetingCategory.model';
const isMeetingTimeInSearchTime = (eventFromTime: string, eventToTime: string, searchFromTime: string, searchToTime: string) => {
    let _eventFromTime = new Date('27 july 2022 ' + eventFromTime);
    let _eventToTime = new Date('27 july 2022 ' + eventToTime);
    let _searchFromTime = new Date('27 july 2022 ' + searchFromTime);
    let _searchToTime = new Date('27 july 2022 ' + searchToTime);
    let _searchFromTime_1: Date;
    let _searchToTime_1: Date;
    let _searchFromTime_2: Date;
    let _searchToTime_2: Date;

    if (_eventFromTime > _eventToTime) {
        _eventToTime.setDate(_eventToTime.getDate() + 1)
    }

    if (_searchFromTime > _searchToTime) {
        _searchFromTime.setDate(_searchFromTime.getDate() - 1)
    }
    _searchFromTime_1 = new Date(_searchFromTime.getTime());
    _searchFromTime_1.setDate(_searchFromTime.getDate() - 1);
    _searchToTime_1 = new Date(_searchToTime.getTime());
    _searchToTime_1.setDate(_searchToTime.getDate() - 1);

    _searchFromTime_2 = new Date(_searchFromTime.getTime());
    _searchFromTime_2.setDate(_searchFromTime.getDate() + 1);
    _searchToTime_2 = new Date(_searchToTime.getTime());
    _searchToTime_2.setDate(_searchToTime.getDate() + 1);

    if ((_searchFromTime <= _eventFromTime && _searchToTime >= _eventToTime) ||
        (_searchFromTime_1 <= _eventFromTime && _searchToTime_1 >= _eventToTime) ||
        (_searchFromTime_2 <= _eventFromTime && _searchToTime_2 >= _eventToTime)
    ) {
        return true;
    }

    return false;
}

export const useCreatorHooks = (serverFilter?: CreatorServerFilter, localFilter?: CreatorLocalFilter) => {

     const { data: meetingCategoryList } = useGetAllMeetingCategoryQuery()

    let formattedServerFilter = Object.assign({}, serverFilter);
    if (formattedServerFilter?.toDateTime) {
        let dt = new Date(formattedServerFilter?.toDateTime);
        dt.setDate(dt.getDate() + 1);
        formattedServerFilter.toDateTime = dt.toISOString();
    }

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFiltering, setIsFiltering] = useState<boolean>(true)

    const [upcomingEvents, setUpcomingEvents] = useState<Meeting[]>([])
    const [filteredFavoriteMeeting, setFilteredFavoriteMeeting] = useState<Meeting[]>([])
    const [filteredCurrentMeeting, setFilteredCurrentMeeting] = useState<Meeting[]>([])
    const [filteredNext6hrsMeeting, setFilteredNext6hrsMeeting] = useState<Meeting[]>([])
    const [filteredNextDayMeeting, setFilteredNextDayMeeting] = useState<Meeting[]>([])
    const [filteredWeekendMeeting, setFilteredWeekendMeeting] = useState<Meeting[]>([])
    const [filteredFestiveMeeting, setFilteredFestiveMeeting] = useState<Meeting[]>([])
    const [filteredTrendingMeeting, setFilteredTrendingMeeting] = useState<Meeting[]>([])
    const [filteredCountryMeeting, setFilteredCountryMeeting] = useState<Meeting[]>([])
    const [filteredPopularGroupMeeting, setFilteredPopularGroupMeeting] = useState<Meeting[]>([])
    const [filteredKidsMeeting, setFilteredKidsMeeting] = useState<Meeting[]>([])
    const [sortedMeetings, setSortedMeetings] = useState<'FILTERED' | 'UNFILTERED'>()

    const [meetingCategories, setMeetingCategories] = useState<string[]>([])

    const { data: meetingFavorite, isLoading: favoriteLoading, refetch: meetingFavoriteRefetch, isFetching: meetingFavoriteRefetching } = useGetAllMeetingsQuery({ type: 'favorite' , formattedServerFilter })
    const { data: meetingCurrent, isLoading: currentLoading, refetch: meetingCurrentRefetch, isFetching: meetingCurrentRefetching, } = useGetAllMeetingsQuery({ type: 'currentEvent' , formattedServerFilter }, { pollingInterval: 2.5 * 60 * 1000 })
    const { data: meetingNext6hrs, isLoading: next6hrsLoading, refetch: meetingNext6HrsRefetch, isFetching: meetingNext6HrsRefetching, } = useGetAllMeetingsQuery({ type: '6Hours' , formattedServerFilter }, { pollingInterval: 1 * 60 * 1000 })
    const { data: meetingWeekend, isLoading: weekendLoading } = useGetAllMeetingsQuery({ type: 'weekend' , formattedServerFilter })
    const { data: meetingFestival, isLoading: festivalLoading } = useGetAllMeetingsQuery({ type: 'festiveSeason' , formattedServerFilter })
    const { data: meetingNextDay, isLoading: nextDayLoading } = useGetAllMeetingsQuery({ type: 'nextDay' , formattedServerFilter })
    const { data: meetingTrending, isLoading: trendingLoading } = useGetAllMeetingsQuery({ type: 'trending' , formattedServerFilter })
    const { data: meetingByCountry, isLoading: popularLoading } = useGetAllMeetingsQuery({ type: 'country' , formattedServerFilter })
    const { data: meetingPopularGroup, isLoading: popularGroupLoading } = useGetAllMeetingsQuery({ type: 'popularGroup' , formattedServerFilter })
    const { data: meetingKidsOnly, isLoading: kidsOnlyLoading } = useGetAllMeetingsQuery({ type: 'kidsOnly' , formattedServerFilter })
    const { data: meetingUpcoming, isLoading: upcomingLoading } = useGetAllMeetingsQuery({ type: 'upcomingEvents' , formattedServerFilter })

    useEffect(() => {
        if (meetingCategoryList) {
            setMeetingCategories(meetingCategoryList.map((item: MeetingCategory) => item.categoryName))
        }
    }, [meetingCategoryList])

    const noSession = filteredFavoriteMeeting.length === 0 &&
        filteredCurrentMeeting.length === 0 &&
        filteredNext6hrsMeeting.length === 0 &&
        filteredNextDayMeeting.length === 0 &&
        filteredWeekendMeeting.length === 0 &&
        filteredFestiveMeeting.length === 0 &&
        filteredTrendingMeeting.length === 0 &&
        filteredCountryMeeting.length === 0 &&
        filteredPopularGroupMeeting.length === 0 &&
        filteredKidsMeeting.length === 0 &&
        upcomingEvents.length === 0


    const filterMeetings = (meetings: Meeting[]) => {
        setIsFiltering(true)
        return meetings.filter((meeting: Meeting) => {
            if (!localFilter) return true;

            // title
            if (localFilter.title && !meeting.meetingDetails.title?.toLowerCase().includes(localFilter?.title?.trim()?.toLowerCase())) {
                return false;
            }
            // category
            if (localFilter.meetingCategory && localFilter?.meetingCategory.length > 0) {
                let foundCategory = false;
                localFilter.meetingCategory?.map((category: string) => {
                    if (meeting.meetingDetails.categories?.includes(category)) {
                        foundCategory = true;
                    }
                    return true;
                })
                if (!foundCategory) {
                    return false;
                }
            }

            // otherCategory
            else if (localFilter.otherCategory && meetingCategoryList) {
                const cats = meeting.meetingDetails.categories?.filter((item) => item && !meetingCategories.includes(item))
                if (cats?.length === 0) {
                    return false;
                }
                // to do
                // else {
                //     return false;
                // }                  
            }

            // freeEvent
            if (localFilter?.freeEvent) {
                if (!meeting.meetingDetails.isFreeAudioMeeting) {
                    return false;
                }
            }
            // minPrice and maxPrice
            if (!localFilter?.freeEvent && localFilter.minPrice !== undefined && localFilter.maxPrice !== undefined) {
                let min = typeof localFilter.minPrice !== 'number'
                        ? localFilter.minPrice
                        : parseInt(localFilter.minPrice?.toString()?.replace(/,/g, ''))
                let max = typeof localFilter.maxPrice !== 'number'
                        ? localFilter.maxPrice
                        : parseInt(localFilter.maxPrice?.toString()?.replace(/,/g, ''))
                if (meeting.costLocal < min || meeting.costLocal > max) {
                  return false
                }
              }
            // startFromTime and startToTime
            if (localFilter?._startFromTime && localFilter?._startToTime) {
                let meetingStartTime = meeting.display_FromTime.split('-')[0].toString().trim()
                let meetingEndTime = meeting.display_FromTime?.split('-')[1].toString().trim()
                if (!isMeetingTimeInSearchTime(meetingStartTime, meetingEndTime, localFilter?._startFromTime, localFilter?._startToTime)) {
                    return false;
                }
            }
            setTimeout(() => setIsFiltering(false), 1000)
            return true;
        });
    }

    useEffect(() => {
        setIsFiltering(true)
        if((meetingFavorite && meetingCurrent && meetingNext6hrs && meetingNextDay && meetingWeekend && meetingFestival && meetingTrending && meetingByCountry && meetingPopularGroup && meetingKidsOnly))
        {
            if (localFilter && Object.keys(localFilter).length ) {
                setSortedMeetings('FILTERED')
                setFilteredFavoriteMeeting(filterMeetings(meetingFavorite?.results ?? []));
                setFilteredCurrentMeeting(filterMeetings(meetingCurrent?.results ?? []));
                setFilteredNext6hrsMeeting(filterMeetings(meetingNext6hrs?.results ?? []));
                setFilteredNextDayMeeting(filterMeetings(meetingNextDay?.results ?? []));
                setFilteredWeekendMeeting(filterMeetings(meetingWeekend?.results ?? []));
                setFilteredFestiveMeeting(filterMeetings(meetingFestival?.results ?? []));
                setFilteredTrendingMeeting(filterMeetings(meetingTrending?.results ?? []));
                setFilteredCountryMeeting(filterMeetings(meetingByCountry?.results ?? []));
                setFilteredPopularGroupMeeting(filterMeetings(meetingPopularGroup?.results ?? []));
                setFilteredKidsMeeting(filterMeetings(meetingKidsOnly?.results ?? []));
            } else {
                setSortedMeetings('UNFILTERED')
                setFilteredFavoriteMeeting(meetingFavorite?.results ?? [])
                setFilteredCurrentMeeting(meetingCurrent?.results ?? [])
                setFilteredNext6hrsMeeting(meetingNext6hrs?.results ?? [])
                setFilteredNextDayMeeting(meetingNextDay?.results ?? [])
                setFilteredWeekendMeeting(meetingWeekend?.results ?? [])
                setFilteredFestiveMeeting(meetingFestival?.results ?? [])
                setFilteredTrendingMeeting(meetingTrending?.results ?? [])
                setFilteredCountryMeeting(meetingByCountry?.results ?? [])
                setFilteredPopularGroupMeeting(meetingPopularGroup?.results ?? [])
                setFilteredKidsMeeting(meetingKidsOnly?.results ?? [])
            }
        }
       
        setTimeout(() => setIsFiltering(false), 1000)

     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingFavorite, meetingCurrent, meetingNextDay, meetingNext6hrs, meetingWeekend, meetingFestival, meetingTrending, meetingByCountry, meetingKidsOnly, meetingPopularGroup, meetingUpcoming, localFilter])

    useEffect(() => {
        if (meetingUpcoming) {
            setIsFiltering(true)

            const filteredUpcomingEvents = [] as Meeting[];
            for (const itemData of meetingUpcoming?.results) {
                const itsFoundInEvent =
                    filteredFavoriteMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredCurrentMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredNext6hrsMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredNextDayMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredWeekendMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredFestiveMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredTrendingMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredCountryMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredPopularGroupMeeting?.find((item: Meeting) => item.id === itemData.id) ||
                    filteredKidsMeeting?.find((item: Meeting) => item.id === itemData.id)

                if (!itsFoundInEvent) {
                    filteredUpcomingEvents.push(itemData)
                }
            }
            if (localFilter && Object.keys(localFilter).length) {
                setUpcomingEvents(filterMeetings(filteredUpcomingEvents))
            } else {
                setUpcomingEvents((filteredUpcomingEvents))
            }
            setTimeout(() => setIsFiltering(false), 1000)
        }

     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingUpcoming, localFilter, sortedMeetings])

    if (!favoriteLoading && !currentLoading && !next6hrsLoading && !nextDayLoading && !weekendLoading && !festivalLoading && !trendingLoading && !popularLoading && !popularGroupLoading && !kidsOnlyLoading && !upcomingLoading) {
        if (isLoading) {
            setIsLoading(false)
        }
    }

    return {
        isLoading,
        filteredFavoriteMeeting,
        filteredCurrentMeeting,
        filteredNext6hrsMeeting,
        filteredNextDayMeeting,
        filteredWeekendMeeting,
        filteredFestiveMeeting,
        filteredTrendingMeeting,
        filteredCountryMeeting,
        filteredPopularGroupMeeting,
        filteredKidsMeeting,
        favoriteLoading,
        currentLoading,
        next6hrsLoading,
        nextDayLoading,
        weekendLoading,
        festivalLoading,
        trendingLoading,
        popularLoading,
        popularGroupLoading,
        kidsOnlyLoading,
        upcomingEvents,
        upcomingLoading,
        meetingCurrentRefetch,
        meetingNext6HrsRefetch,
        meetingFavoriteRefetch,
        meetingFavoriteRefetching,
        meetingCurrentRefetching,
        meetingNext6HrsRefetching,
        isFiltering,
        noSession
    }
}
