import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import NoSession from "../Components/NoSession"
import { Accordion } from "../Components/base/accordion/accordion"
import Icon from "../Components/base/icon/icon"
import Loading from "../Components/base/loading/loading"
import { useAttendingHooks } from "../Hooks/AttendingHooks"
import { Accept } from "../models/meetingRequest.model"
import { EventInvitation } from "./AttendingEvents/AttendingEventsEventInvitation"
import { FreeEvent } from "./AttendingEvents/AttendingEventsFreeEvent"
import { ReceivedRequest } from "./AttendingEvents/AttendingEventsReceivedRequest"
import { RequestAccepted } from "./AttendingEvents/AttendingEventsRequestAccepted"
import { RequestPending } from "./AttendingEvents/AttendingEventsRequestPending"
import { Schedule } from "./AttendingEvents/AttendingEventsSchedule"
import { ScheduleWithGuests } from "./AttendingEvents/AttendingEventsScheduleWithGuests"
import { AttendedEvents } from "./AttendingEvents/AttendedPastEvents"
import { Menu, Transition } from '@headlessui/react'

function AttendingEvents() {

  const { t } = useTranslation()
  const [currentFilter, setCurrentFilter] = useState<string>()

  const { scheduledData, scheduledLoading, scheduledError, checkFilter, scheduledDataEvents, setScheduledDataEvents, attendedData } = useAttendingHooks()
  const style = {
    events: `block w-full mb-1 rounded-md text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer`,
    menu: `z-50 mt-1 absolute flex-1 bg-white block w-[12.6rem] flex flex-col p-2 py-3 gap-1 rounded-lg border border-gray-200 `,
    sortItem: "block rounded-md px-2 py-1 w-full text-left text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer",
    item: `flex flex-row w-full flex-nowrap gap-1 text-left`,
  }

  useEffect(() => {
    if (scheduledData && !scheduledError) {
      setScheduledDataEvents(scheduledData);
    } else {
      setScheduledDataEvents([]);
    }
  }, [scheduledData, scheduledError]);

  if (scheduledLoading) {
    return <Loading />
  }

  if (scheduledDataEvents.length === 0) {
    return <NoSession type="events" className="h-[60vh] items-center justify-center" />
  }

  return (
    <div className="block lg:w-11/12 w-full px-3 mb-48 md:mb-36 lg:mb-28">
      <div className="flex items-center lg:pl-10 pl-2 pb-2 relative">
        {scheduledDataEvents.length > 0 &&
          <Menu>
            <div>
              <Menu.Button
                id="dropMenu_lang_attendingEvents"
                key={'attendingEvents'}
                className=" text-gray-400 font-normal flex items-center cursor-pointer">
                <Icon icon="FILTER" size="medium" height="medium" />
                <p className="px-2">{t(currentFilter ?? '')}</p>
              </Menu.Button>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              ></Transition>

              <Menu.Items className={style.menu}>
                <Menu.Item>
                  <div
                    className={style.sortItem}
                    onClick={() => {
                      checkFilter('latest')
                      setCurrentFilter('latestEvents')
                    }}>
                    <span id="open_latestEvents" className={style.item}>
                      {currentFilter === 'latestEvents' && (
                        <Icon icon="TICK_GREEN" />
                      )}
                      {t('latestEvents')}
                    </span>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    className={style.sortItem}
                    onClick={() => {
                      checkFilter('scheduled')
                      setCurrentFilter('scheduledEvents')
                    }}>
                    <span id="open_scheduledEvents" className={style.item}>
                      {currentFilter === 'scheduledEvents' && (
                        <Icon icon="TICK_GREEN" />
                      )}
                      {t('scheduledEvents')}
                    </span>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </div>
          </Menu>}
      </div>
      <Accordion>
        {meetingComp(scheduledDataEvents)}
      </Accordion>
    </div>
  );
}

export default AttendingEvents


export const meetingComp = (events: any[], pastEvents?: boolean) => events?.map((meeting: any) => {

  if (
    meeting?.request === 'Scheduled events' &&
    meeting.type === 'Type 1' &&
    meeting?.meetingParticipant[0]?.inviteStatus === null &&
    (meeting?.meetingParticipant[0].transaction?.guestCount >= 1) === false &&
    meeting?.meetingDetails?.isFreeAudioMeeting === true && !pastEvents
  ) {
    return <FreeEvent key={meeting.id} meeting={meeting} />

  } else if (
    meeting?.request === 'Send requests' &&
    meeting?.accept === Accept.No && !pastEvents
  ) {
    return <RequestPending key={meeting.id} meeting={meeting} />

  } else if (
    meeting?.request === 'Send requests' &&
    meeting?.accept === Accept.Yes && !pastEvents
  ) {
    return <RequestAccepted key={meeting.id} meetingRequest={meeting} />

  } else if (meeting?.request === 'Received requests' && !pastEvents) {
    return <ReceivedRequest key={meeting.id} meetingRequest={meeting} />

  } else if (
    meeting?.request === 'Scheduled events' &&
    (meeting.type === 'Type 1' || 'Type 2') &&
    meeting?.meetingParticipant[0]?.inviteStatus === null &&
    meeting?.meetingParticipant[0]?.isCohost === false &&
    meeting?.meetingDetails?.isFreeAudioMeeting === false &&
    meeting?.meetingParticipant[0]?.transaction?.guestCount > 1 && !pastEvents

  ) {
    return <ScheduleWithGuests key={meeting.id} meeting={meeting} />
  } else if (
    meeting?.request === 'Scheduled events' &&
    (meeting.type === 'Type 1' || 'Type 2') &&
    meeting?.meetingParticipant[0]?.inviteStatus === null &&
    meeting?.meetingDetails?.isFreeAudioMeeting === false && !pastEvents

  ) {
    return <Schedule key={meeting.id} meeting={meeting} />
  } else if (
    meeting?.meetingParticipant[0]?.inviteStatus !== null && !pastEvents
  ) {
    return <EventInvitation key={meeting.id} meeting={meeting} />
  } else if (pastEvents) {
    return <AttendedEvents key={meeting.id} meeting={meeting} />
  }
  return <></>
})
