/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '../../Components/base/carousel/whirligig/utils'
import Loading from '../../Components/base/loading/loading'
import {
  getDisplayName,
    getJoinTimeError,
  getMeetingInfo,
  getShowMeeting,
  resetLiveKitState,
  setShowMeeting,
} from '../../Services/livekitReducer'
import {getCurrentUser} from '../../Services/userReducer'
import {useAppDispatch, useAppSelector} from '../../Store/hooks'
import {Meeting} from '../../models/meeting.model'
import {PreJoin} from 'beemg-livekit'
import {useGetSettingValue} from '../../Services/settingReducer'
import {useLocation, useNavigate} from 'react-router-dom'
import {useGetMeetingQuery} from '../../Services/meetingApi'
import {VideoType} from '../../models/meetingDetails.model'

interface Props {
  meetingId: string
}

const LiveKitJoinView = (props: Props) => {
  const {meetingId} = props
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const mobile_view = 640 // ToDo
  const {width} = useWindowDimensions()

  const [isSmallDevice, setIsSmallDevice] = useState(false)
  const baseImageURL = useGetSettingValue('IMAGE_URL')
  const navigate = useNavigate()
  // const {meetingTitle} = useGetMeetingTitle(meetingId)
  const {data: meeting} = useGetMeetingQuery(meetingId ?? '', {
    skip: !meetingId,
  })
  const currentUser = useAppSelector(getCurrentUser)
  const joinTimeError = useAppSelector(getJoinTimeError)
  // const isAnonymous = useAppSelector(getIsAnonymous)
  const displayName = useAppSelector(getDisplayName)
  const showMeeting = useAppSelector(getShowMeeting)
  const meetingInfo = useAppSelector(getMeetingInfo)

  const location = useLocation()
  const meetingIds = location.pathname.split('/').pop()

  /*
  export interface OnJoinProps {
  isAnonymous: boolean
  audio: {
    enabled: boolean
    deviceId: string
  }
  video: {
    enabled: boolean
    deviceId: string
  }
}
*/

  const onJoin = async (joinProps: any) => {
    dispatch(
      setShowMeeting({
        showMeeting: true,
        meetingId: meetingId,
        isAnonymous: joinProps.isAnonymous,
        displayName: displayName,
      }),
      )
      console.log('inside on join', joinProps.isAnonymous)
  }

  useEffect(() => {
    if (width < mobile_view) {
      setIsSmallDevice(true)
    } else {
      setIsSmallDevice(false)
    }
  }, [width])

  if (isSmallDevice) {
    return (
      <div className="flex justify-center h-full items-center">
        <div className="flex flex-col justify-center text-2xl p-4 font-normal">
          {t('iPadMeetingError')}
        </div>
      </div>
    )
  }

  if (!meeting) {
    return (
      <div className="flex justify-center h-[85vh] items-center">
        <Loading />
      </div>
    )
  }

  if (meetingIds !== meetingInfo.meetingId) {
    dispatch(resetLiveKitState())
  }

  if (!!joinTimeError) {
    return <ErrorView getMeetingError={joinTimeError} meeting={meeting} />
  }

  if (showMeeting) {
    return null
  }

  return (
    <>
      <PreJoin
        anonymousName={currentUser.nickName}
        avatar={baseImageURL + '/' + currentUser.avatar}
        isAudioOnly={
          meeting?.meetingDetails?.videoType === VideoType.Audio || 
          meeting?.meetingDetails?.isFreeAudioMeeting
            ? true
            : false
        }
        isHost={true}
        meetingTitle={meeting.meetingDetails.title}
        name={currentUser.firstName + ' ' + currentUser.lastName}
        onClickJoin={(onJoinProps: any) => {
          onJoin(onJoinProps)
        }}
        profileImg={baseImageURL + '/' + currentUser.image[0]?.webpImageName}
        getTranslation={(key: any) => {
          return t(key)
        }}
        navigateTo={navigate}
      />
    </>
  )
}

export default React.memo(LiveKitJoinView)

function ErrorView({
  meeting,
  getMeetingError,
}: {
  meeting: Meeting | undefined
  getMeetingError: any
}) {
  const {t} = useTranslation()

  return (
    <div  className="flex flex-1 flex-col justify-center items-center h-[85vh] gap-10">
      {meeting?.meetingDetails && (
        <div id="txt_title" className="self-center text-5xl">
          {meeting?.meetingDetails.title}
        </div>
      )}
      <div id="txt_waitForHost" className="text-3xl">
        {t(getMeetingError)}
      </div>{' '}
      {/* check for 500 status*/}
      {getMeetingError === 'BeeMG-ERR056' && <Loading />}
    </div>
  )
}
