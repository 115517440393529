import useCheckOnlineHook from '../Hooks/CheckOnlineHook'

interface OnlineStatusProps {
  id: string
  className?: string
}

const OnlineStatus = (props: OnlineStatusProps) => {
  const { id, className } = props
  const { isOnline } = useCheckOnlineHook(id)

  return (
    <>
      {isOnline !== null && (
        <div className={`rounded-full w-3 h-3 shadow-lg z-10 border-white border-[1.5px] ${isOnline ? 'bg-chat-accepted' : 'bg-chat-declined'} ${className}`} />
      )}
    </>
  )
}

export default OnlineStatus
