import { Auth } from 'aws-amplify';

export function login(email: string, password: string) {
    return new Promise((resolve, reject) => {
    
        Auth.signIn(email, password).then(() => {
            Auth.currentAuthenticatedUser().then((resultCurrent) => {
                resolve(resultCurrent);
            });
        }).catch(error => {
            if (error.message.indexOf('User is not confirmed') > -1) {
                Auth.resendSignUp(email).then().catch(errorObject => console.log(errorObject));
            }

            reject(error);
        });
    });
}

