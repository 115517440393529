import { Menu, Transition } from "@headlessui/react"
import { Fragment } from "react"
import Button from "../../Components/base/button/button"
import Icon from "../../Components/base/icon/icon"
import NewIcon from "../../Components/base/icon/newIcons"
import { ToolTip } from "../../Components/base/tooltip/tooltip"
import { useMeetingRemainderHooks } from "../../Hooks/AttendingHooks"
import { Meeting } from "../../models/meeting.model"
import { MeetingNotificationList } from "../../models/meetingNotficationList.model"
import { MeetingNotificationRemainder } from "../../models/meetingNotificationRemainder.model"

const style = {
  reminderIcon: `min-w-rightIcon flex flex-col items-center justify-center`,
}

export const AttendingEventsRemainder = ({ meeting }: { meeting: Meeting }) => {

  const { remainders,
    remainderSuccess,
    meetingRemainderSuccess,
    remList,
    addNewRemainder,
    removeRemainder
  } = useMeetingRemainderHooks({ meeting })

  if (!remainderSuccess && !meetingRemainderSuccess) {
    return <></>
  }

  if ((remainders === undefined || remainders.length <= 0) && (remList === undefined || remList.length <= 0)) {
    return <></>
  }

  return (
    <span data-testid="add_remainder" id={`btn_remainder${meeting.id}`} className={style.reminderIcon} onClick={e => e.stopPropagation()} >
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center">
            <ToolTip tip="setRemainder" keyId="setRemainder">
              <i className="z-30 cursor-pointer">
                {((remainders && remainders.length > 0) && (remList && remList.length >= 0)) ? <NewIcon size="small" icon="NOTIFYEVENT" stroke="#FDB614" /> :
                  <NewIcon size="small" icon="NOTIFYEVENT" stroke="#535353" />}
              </i>
            </ToolTip>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-40 absolute right-0 mt-2 py-2 px-2 origin-top-right w-50 divide-y divide-gray-100 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {remainders && remainders?.map((remainder: MeetingNotificationRemainder, index: number) => (
              <div className="flex flex-wrap gap-1 p-0.5" key={'div' + remainder.id}>
                <Button
                  id={`btn_removeRemainder${index}`}
                  key={remainder.id}
                  data-testid={`btn_removeRemainder${index}`}
                  size="sm"
                  color="userCategory"
                  className="flex flex-wrap gap-1 w-full p-0.5"
                  onClick={() => removeRemainder(remainder.id)}
                >
                  <span>{remainder.meetingNotificationList.notificationTimingInDays} </span>
                  <span><Icon icon="CLOSE" /> </span>
                </Button>
              </div>
            ))}
            <div className=" mt-2 px-1 py-1 ">
              {remList?.map((data: MeetingNotificationList, index: number) => (
                <Menu.Item key={data.id}>
                  {({ active }) => (
                    <>
                      <button
                        id={`btn_addRemainder${index}`}
                        data-testid={`btn_addRemainder${index}`}
                        className={`${active ? 'bg-primary w-full text-black' : 'text-gray-900'
                          }  group flex rounded-md px-6 py-1 text-sm`}
                        onClick={e => addNewRemainder(data)}
                      >
                        {data.notificationTimingInDays}
                      </button>
                    </>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  )
}