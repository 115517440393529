import { Auth } from 'aws-amplify';

export function logout() {
    return new Promise((resolve, reject) => {    
        Auth.signOut().then(() => {
            resolve(true);
        }).catch(error => {
            reject(error);
        });
    });

}

