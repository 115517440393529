import { meetingDetailsApi } from './meetingDetailsApi';
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from '../Store';
import { MeetingDetails } from "../models/meetingDetails.model";

interface MyEventsState {
  myEvents: { [key: string]: MeetingDetails }
}

const initialStateValue: MyEventsState = {
  myEvents: {},
};

export const myEventsSlice = createSlice({
  name: "myEvents",
  initialState: initialStateValue,
  reducers: {
    resetMyEvents: () => initialStateValue
  },
  extraReducers: (builder) => {
    builder.addMatcher(meetingDetailsApi.endpoints.getAllMeetingDetails.matchFulfilled, (state, action) => {
      action.payload.data.forEach((meetingDetail) => state.myEvents[meetingDetail.id] = meetingDetail)
    });
    builder.addMatcher(meetingDetailsApi.endpoints.getMeetingDetails.matchFulfilled, (state, action) => {
      if (action.payload) {
        state.myEvents[action.payload.id] = Object.assign(state.myEvents[action.payload.id] ?? {}, action.payload)
      }
    });
  }
});

export const getAllMyEvents = (state: RootState): { [key: string]: MeetingDetails } => state.myEvents.myEvents;
export const getMyEventById = (state: RootState, id: string): MeetingDetails => state.myEvents.myEvents[id]

export const { resetMyEvents } = myEventsSlice.actions;
export default myEventsSlice.reducer;
