import { Children, HTMLAttributes, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../Components/base/carousel/whirligig/utils";
interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode,
    link?: string,
    keyId?: number | string,
}
export function EventCardRequest({ children, link, keyId }: Props) {
    const navigate = useNavigate();
    let _image, _image1, _title, _subtitle, _subTitle_1,_icons, _right, _actions;
    const { width } = useWindowDimensions();
    Children.forEach(children, (child: any) => {
        if (child?.type === EventImage) {
            return _image = child
        }
        if (child?.type === EventImage1) {
            return _image1 = child
        }
        if (child?.type === EventTitle) {
            return _title = child
        }
        if (child?.type === EventSubTitle) {
            return _subtitle = child
        }
        if (child?.type === EventSubTitle1) {
            return _subTitle_1 = child
        }
        if (child?.type === EventIcons) {
            return _icons = child
        }
        if (child?.type === EventRight) {
            return _right = child
        }
        if (child?.type === EventActions) {
            return _actions = child
        }
    })
    return (
        width > 768 ? (
            <div className='flex w-full gap-3 overflow-hidden pl-4 cursor-pointer pb-3' onClick={() => link && navigate(link)}>
                <div className="relative">
                    <span className="w-full h-full"> {_image} </span>
                    {_image1 && <span className="absolute inset-y-10 border-black">{_image1}</span>}
                </div>
                <div className='flex flex-col justify-start p-4 items-start flex-1 gap-y-0 w-3/5 truncate'>
                    {_title}
                    {_subtitle&& <span data-testid={`txt_subtitle${keyId}`} id={`txt_subtitle${keyId}`} className="text-sm font-medium">{_subtitle}</span>}
                    {_subTitle_1&& <span data-testid={`txt_subtitle${keyId}`} id={`txt_subtitle${keyId}`} className="text-sm font-normal ">{_subTitle_1}</span>}
                    {_icons&&<div className="flex whitespace-nowrap ">{_icons}</div>}
                </div>
                {_right}
                <div className="flex items-center justify-end min-w-cardRightText w-1/5 text-gray-500" data-testid={'link_viewEvent'}>{_actions}</div>
            </div>
        ) : (
            <div className='flex md:flex-row flex-col w-full overflow-hidden md:px-10 px-2 cursor-pointer' onClick={() => link && navigate(link)}>
                <div className="flex w-full gap-3">
                    <div>{_image}</div>
                    <div className='flex flex-col '>
                        {_title}
                        <span className="text-xs font-normal">{_subtitle}</span>
                        <div className="flex whitespace-nowrap mt-1 lg:mt-0">{_icons}</div>
                        {_right}
                    </div>
                </div>
                <div className="flex md:flex-row items-center justify-center gap-2 lg:text-md text-sm mt-2 md:mt-0 whitespace-normal text-center md:w-1/5 text-gray-500" data-testid={'link_Event'}>{_actions}</div>
            </div>
        )
    )
}
const EventImage = ({ children }: { children: ReactNode }) => <>{children}</>
const EventImage1 = ({ children }: { children: ReactNode }) => <>{children}</>
const EventTitle = ({ children }: { children: ReactNode }) => <>{children}</>
const EventSubTitle = ({ children }: { children: ReactNode }) => <>{children}</>
const EventSubTitle1 = ({ children }: { children: ReactNode }) => <>{children}</>
const EventIcons = ({ children }: { children: ReactNode }) => <>{children}</>
const EventRight = ({ children }: { children: ReactNode }) => <>{children}</>
const EventActions = ({ children }: { children: ReactNode }) => <>{children}</>
EventCardRequest.Image = EventImage;
EventCardRequest.Image1 = EventImage1;
EventCardRequest.Title = EventTitle;
EventCardRequest.SubTitle = EventSubTitle;
EventCardRequest.SubTitle1 = EventSubTitle1;
EventCardRequest.Icons = EventIcons;
EventCardRequest.Right = EventRight;
EventCardRequest.Actions = EventActions;