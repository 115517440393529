/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { getCurrentUser } from "../../Services/userReducer"
import { getCurrentChatUser } from "../../Store/Chat/chat"
import { ChatDateLabel, ChatMessage } from "../../Store/Chat/ChatModel"
import { useAppSelector } from "../../Store/hooks"
import DateLabel from "./DateLabel"
import ReceivedMessage from "./RecievedMessage"
import SentMessage from "./SentMessage"

interface Props {
  groupedWithDate: (ChatDateLabel | ChatMessage)[]
  unreadMessage: ChatMessage | undefined
  scrollToBottom: () => void
}

const ChatMessages = (props: Props) => {
  const { groupedWithDate, unreadMessage, scrollToBottom } = props

  const currentUser = useAppSelector(getCurrentUser)
  const currentChatUser = useAppSelector(getCurrentChatUser)

  const refs = groupedWithDate.reduce((acc: any, value) => {
    acc[value.messageId] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (unreadMessage) {
      refs[unreadMessage.messageId].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [unreadMessage])

  return (
    <>
      {
        groupedWithDate.map((msg: any,index: number) => {
          if (msg.type && msg.type === 'date') {
            return <DateLabel msg={msg} key={msg.messageId} />
          }

          if (
            msg.fromUser?.id === currentUser?.id &&
            msg.toUser?.id === currentChatUser?.id
          ) {
            if (index + 1 === groupedWithDate.length) {
              const sentTime = new Date(msg.dateSend)
              // scroll to bottom when last message is sent 
              if (sentTime.getTime() > new Date().getTime() - 1000) {
                scrollToBottom()
              }
            }
            return <SentMessage msg={msg} key={msg.messageId} />
          }
          if (
            msg.fromUser?.id === currentChatUser?.id &&
            msg.toUser?.id === currentUser?.id
          ) {
            return <ReceivedMessage msg={msg} key={msg.messageId} ref={refs[msg.messageId]} />
          }
          return null
        })
      }
    </>

  )
}

export default React.memo(ChatMessages)

