import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSettingValue } from '../../Services/settingReducer';

const CollaboratorsImage: FC = () => {
  const { t } = useTranslation()
  const baseImageURL = useGetSettingValue('IMAGE_URL');

  const style = {
    image: `inline z-0 object-cover w-full h-full`,
    textOverlay1: `w-full text-white text-2xl lg:text-4xl font-semibold text-left`,
    textOverlay2: `w-full text-white text-sm lg:text-lg text-left font-normal`,
  }

  return (
    <div className="relative w-full overflow-hidden mb-3 h-[10rem] md:h-[13rem]">
      <img
        className={style.image}
        src={`${baseImageURL}/new/collaborator-banner.webp`}
        alt="collaboratorsImage"></img>
      <div className='absolute bottom-4 flex flex-col px-4 lg:pl-[10%] '>
        <div id={`chk_collabText`} className={style.textOverlay1}>
          {t('collaborators')}
        </div>
        <div className={style.textOverlay2} >
          {t('expandYourSocialNetwork')}{' '}
          {t('andMeetLikeMindedPeople')}
        </div>
      </div>
    </div>
  )
}

export default React.memo(CollaboratorsImage)
