import { useEffect } from "react";
import { socketClient } from '../API/socketio';
import { Config } from '../Config/index';
import { useGetSettingValue } from '../Services/settingReducer';
import { isSocketConnected, isSocketLogined } from '../Services/socketReducer';
import { getToken } from "../Services/tokenReducer";
import { getCurrentUser } from "../Services/userReducer";
import { useAppSelector } from "../Store/hooks";

const useWebSocket = () => {
  const currentUser = useAppSelector(getCurrentUser);
  const token = useAppSelector(getToken);

  const socketConnected = useAppSelector(isSocketConnected);
  const socketLogined = useAppSelector(isSocketLogined);

  const webUrl = useGetSettingValue('WEB_LINK');

  const socketUrl = Config.WS_URL.startsWith("/socket") ? `${webUrl}` : Config.WS_URL;

  useEffect(() => {
    if (!socketConnected && !socketLogined && currentUser && token && webUrl) {
      socketClient.connect(socketUrl)
    } else if ((!currentUser || !token)) {
      socketClient.disconnect();
    }
  }, [socketConnected, currentUser, token, webUrl, socketLogined, socketUrl]);

  return { socketClient };
}

export default useWebSocket;
