/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Button } from '../../Components'
import Dot from '../../Components/Dot'
import NoSession from '../../Components/NoSession'
import Avatar from '../../Components/base/avatar/avatar'
import Field from '../../Components/base/field/field'
import Icon from '../../Components/base/icon/icon'
import Loading from '../../Components/base/loading/loading'
import { toastSuccess } from '../../Components/toast'
import { useEventReviewsHook } from '../../Hooks/MyEvents/EventReviewsHook'
import { formatDateTimeToTimezoneMobile, formatTimeToTimezone } from '../../Utils/datetime'
import { Feedback } from '../../models/feedback.model'
import { DisplayFormattedTime } from '../../Components/formattedDateAndTime'

export function Reviews() {
  const { meetingId } = useParams()
  const { t } = useTranslation()

  const { feedbackData, feedbackLoading, feedbackSuccess } = useEventReviewsHook({
    meetingId: meetingId ? meetingId : '',
  })

  if (feedbackLoading || !feedbackSuccess) {
    return <Loading />
  } else if (feedbackData && feedbackData?.results?.length <= 0) {
    return <NoSession>{t('noReviewsForTheMeeting')}</NoSession>
  }
  // TODO: change reply icons
  // TODO: thanks reply to translation

  return (
    <>
      <div className="text-black font-semibold text-lg ">{t('reviews')}</div>
      {feedbackData && (
        <>
          {feedbackData.results.map((feedback: Feedback, index: number) => (
            <div key={feedback.id}>
              <ReviewList
                feedback={feedback}
                feedbackArray={feedbackData.results}
              />
              {index !== feedbackData.results.length - 1 && <hr />}
            </div>
          ))}
        </>
      )}
    </>
  )
}

export const ReviewStar = ({ feedback }: { feedback: any }) => {
  if (!feedback.rating) {
    return (
      <>
        {/* FOR CREATOR CARD REVIEW STAR */}
        {feedback === 1 || feedback === 2 ? (
          <div className="text-right text-BeeMG-red pl-2">
            {Array.from({ length: feedback }, (_, i) => (
              <span key={i}>★</span>
            ))}
            {Array.from({ length: 5 - feedback }, (_, i) => (
              <span key={i}>☆</span>
            ))}
          </div>
        ) : feedback === 3 ? (
          <div className="text-right text-primary pl-2">
            {Array.from({ length: feedback }, (_, i) => (
              <span key={i}>★</span>
            ))}
            {Array.from({ length: 5 - feedback }, (_, i) => (
              <span key={i}>☆</span>
            ))}
          </div>
        ) : feedback === 4 || feedback === 5 ? (
          <div className="text-right text-BeeMG-green pl-2">
            {Array.from({ length: feedback }, (_, i) => (
              <span key={i}>★</span>
            ))}
            {Array.from({ length: 5 - feedback }, (_, i) => (
              <span key={i}>☆</span>
            ))}
          </div>
        ) : null}
      </>
    )
  }
  return (
    <>
      {/* FOR OTHER PAGES REVIEW STAR (EVENT VIEW / PAST EVENTS / RFM) */}
      {feedback.rating === 1 || feedback.rating === 2 ? (
        <div className="text-right text-BeeMG-red pl-2">
          {Array.from({ length: feedback.rating }, (_, i) => (
            <span key={i}>★</span>
          ))}
          {Array.from({ length: 5 - feedback.rating }, (_, i) => (
            <span key={i}>☆</span>
          ))}
        </div>
      ) : feedback.rating === 3 ? (
        <div className="text-right text-primary pl-2">
          {Array.from({ length: feedback.rating }, (_, i) => (
            <span key={i}>★</span>
          ))}
          {Array.from({ length: 5 - feedback.rating }, (_, i) => (
            <span key={i}>☆</span>
          ))}
        </div>
      ) : feedback.rating === 4 || feedback.rating === 5 ? (
        <div className="text-right text-BeeMG-green pl-2">
          {Array.from({ length: feedback.rating }, (_, i) => (
            <span key={i}>★</span>
          ))}
          {Array.from({ length: 5 - feedback.rating }, (_, i) => (
            <span key={i}>☆</span>
          ))}
        </div>
      ) : null}
    </>
  )
}

const ReviewList = ({
  feedback,
  feedbackArray,
}: {
  feedback: Feedback
  feedbackArray: Feedback[]
}) => {
  const { meetingId } = useParams()
  const { t } = useTranslation()

  const { showReply, setShowReply } = useEventReviewsHook({
    meetingId: meetingId ? meetingId : '',
    feedbacks: feedbackArray,
  })
  const [reviewLength, setReviewLength] = useState<number>(100)
  const [readMoreToLess, setReadMoreToLess] = useState<boolean>(true)
  const style = {
    reviewCard: `pl-2 min-w-scheduleCard overflow-hidden mt-2`,
    reviewDetailText: `flex font-normal text-xs text-justify pr-2 items-center`,
    replyText: `flex-col items-center justify-center min-w-cardRightText text-normal uppercase mb-1 py-12 lg:px-4`,
  }
  if (!feedback) {
    return <Loading />
  }

  return (
    <div id="chk_reviews" className="py-2 mb-3">
      <div className="flex flex-row w-100 mb-1 mt-4">
        <div className="w-24 pl-2 pt-2">
          <Avatar
            size="small"
            images={feedback.user?.image}
            avatar={feedback.user?.avatar}
            anonymous={feedback.user?.anonymous}
          />
        </div>

        <div className="w-full flex flex-col justify-between mx-2">
          <div className={style.reviewCard}>
            <div className="flex flex-row gap-x-2 items-center">
              <span
                id={`txt_name${feedback?.meeting?.id}`}
                className="text-normal text-black font-semibold">
                {feedback.name}
              </span>

              {/* <ReviewStar feedback={feedback}/> */}
              <span className="text-xl text-primary">★</span>
              <span id={`txt_rating${feedback?.meeting?.id}`} className="text-md text-black">{feedback.rating}</span>
            </div>
            <div id={`txt_feedbackCreatedTime${feedback?.meeting?.id}`} className="flex flex-row items-center text-xs">
              <DisplayFormattedTime displayFromTime={feedback.createdAt.toString()} />{' '}
              <span className='ml-2'><Dot /></span>
              <span id={`txt_feedbackCreatedDate${feedback?.meeting?.id}`} className='ml-2'>{formatDateTimeToTimezoneMobile(feedback.createdAt.toString())}</span>
            </div>

            <span className="flex flex-col items-start mt-2">
              <span className={style.reviewDetailText}>
                {feedback.display_createdAt}
              </span>
              <div
                data-testid={`review-${feedback?.id}`}
                id={`txt_review${feedback?.meeting?.id}`}
                className="text-sm w-full justify-start">
                {feedback.review.length > 200 &&
                  <>
                    {readMoreToLess && <div className={`text-justify`}>{feedback.review.slice(0, reviewLength)}{'...'} <span data-testid="btn_readMore" id="btn_viewMore" className='flex justify-end underline underline-offset-1 cursor-pointer' onClick={() => { setReviewLength(feedback.review?.length); setReadMoreToLess(!readMoreToLess) }}>{t('readMore')}</span></div>}
                    {!readMoreToLess && <div className={`text-justify`}>{feedback.review.slice(0, reviewLength)}<span data-testid="btn_readLess" id="btn_viewLess" className='flex justify-end underline underline-offset-1 cursor-pointer' onClick={() => { setReviewLength(100); setReadMoreToLess(!readMoreToLess) }}>{t('readLess')}</span></div>}
                  </>}
                {feedback.review.length <= 200 && (
                  <div id={`txt_feedbackReview${feedback?.meeting?.id}`} className={`text-justify`}>{feedback.review}</div>
                )}
              </div>

              {feedback && feedback.reply?.length > 0 && (
                <div className="flex gap-x-1 ">
                  <div>
                    <Icon icon="RIGHT" size="small" height="small" />
                  </div>
                  <div>
                    <span
                      id={`txt_reply${feedback?.id}`}
                      className={style.reviewDetailText}>
                      <span>{feedback.reply}</span>
                    </span>
                  </div>
                </div>
              )}
            </span>
          </div>
        </div>

        <div className={style.replyText}>
          {!showReply && (feedback.reply?.length <= 0 || feedback.reply === null) && feedback.user &&  (
            <span
              id={`btn_reply${feedback?.id}`}
              data-testid={`btn_reply${feedback?.id}`}
              className="cursor-pointer"
              onClick={() => setShowReply(!showReply)}
            >
              <Icon icon="REPLY" size="medium" height="medium" />
              <span className="text-xs">{t('reply')}</span>
            </span>
          )}
        </div>
      </div>

      {showReply && <Reply feedback={feedback} setShowReply={setShowReply} />}
    </div>
  )
}

const Reply = ({
  feedback,
  setShowReply,
}: {
  feedback: Feedback
  setShowReply: any
}) => {
  const { t } = useTranslation()
  const { meetingId } = useParams()
  const {
    replyFeedbackSuccess,
    register,
    reset,
    isValid,
    errors,
    reply,
    setReply,
    showReply,
    setValue,
    trigger,
    handleSubmit,
    handleUpdateReply,
    replyFeedbackLoading,
  } = useEventReviewsHook({ meetingId: meetingId ? meetingId : '', feedback })

  const style = {
    reviewCard: `pl-2 min-w-scheduleCard overflow-hidden mt-2`,
    reviewDetailText: `flex font-normal text-xs text-justify pr-2 items-center`,
    replyText: `flex flex-col items-center justify-center min-w-cardRightText text-normal uppercase mb-1`,
  }

  useEffect(() => {
    if (replyFeedbackSuccess) {
      toastSuccess(t('repliedSuccessfully'))
      setShowReply(false)
    }
  }, [replyFeedbackSuccess, t])

  return (
    <>
      <div className="my-1">
        {!showReply &&
          (feedback.reply?.length <= 0 || feedback.reply === null) && (
            <span
              className={style.reviewDetailText}
              data-testid={`txt_suggestion${feedback?.id}`}
              id={`txt_suggestion${feedback?.id}`}
              onClick={() => {
                setReply('Thanks')
                setValue('reply', 'Thanks')
                trigger()
                setShowReply(true)
              }}>
              <Icon icon="LOGOUT" size="small" height="small" />
              {t('thanks')}
            </span>
          )}
      </div>
      <form data-testid="btn_reply" onSubmit={handleSubmit(handleUpdateReply)}>
        <div className="flex flex-col md:flex-row justify-center md:mb-2 ">
          <div className="w-full md:w-3/4 mr-2">
            <Field
              {...register('reply')}
              name="reply"
              id={`input_reply${feedback.id}`}
              type="text"
              autoFocus
              placeholder={t('reply') + ' *'}
              defaultValue={reply}
              onInput={event =>
                setReply((event.target as HTMLInputElement).value)
              }
              error={errors?.reply?.message}
            />
          </div>
          <div className="flex justify-end gap-2 text-xs items-center md:items-stretch h-9">
            <Button
              data-testid="btn_cancel"
              id="btn_cancel"
              size="sm"
              color="save"
              onClick={() => {
                setShowReply(false)
                setReply('')
                reset()
              }}>
              {' '}
              {t('cancel')}
            </Button>
            <Button
              data-testid="btn_submit"
              id="btn_submit"
              size="sm"
              color="save"
              submit
              disabled={!isValid || replyFeedbackLoading}>
              {' '}
              {t('submit')}{' '}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

export default Reviews
