import { MeetingRequest } from '../models/meetingRequest.model'
import { formatDateToTimezone, formatTimeToTimezone } from '../Utils/datetime';
import { getCurrency, getUserPreferenceCurrency } from '../Utils/index.web';
import { allApis } from './allApis';
import { format } from '../Utils/format';

const meetingResponseTransform = (response: MeetingRequest) => {
  const preferenceCurrency = getUserPreferenceCurrency();
  const currencyList = getCurrency();
  const filteredValue = currencyList.filter(currency => currency.code === preferenceCurrency)

  if (response) {
    response.costLocal_formatted = format(filteredValue[0].formatType, response.costLocal)
    response.display_startAt1Date = formatDateToTimezone(response.startAt1DateTime)
    response.display_startAt1Time = formatTimeToTimezone(response.startAt1DateTime) + ' - ' + formatTimeToTimezone(response.endAt1DateTime)
    response.display_startAt2Date = formatDateToTimezone(response.startAt2DateTime)
    response.display_startAt2Time = formatTimeToTimezone(response.startAt2DateTime) + ' - ' + formatTimeToTimezone(response.endAt2DateTime)
    response.display_startAt3Date = formatDateToTimezone(response.startAt3DateTime)
    response.display_startAt3Time = formatTimeToTimezone(response.startAt3DateTime) + ' - ' + formatTimeToTimezone(response.endAt3DateTime)
  }

  return response
}

export const meetingRequestApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createInviteForAllGuests: builder.mutation<any, Partial<any>>({
      query: args => {
        const { transactionId, guestList } = args

        return {
          url: `meetingRequest/event/inviteAllGuests/${transactionId}`,
          method: 'POST',
          body: guestList,
        }
      },
      invalidatesTags: ['PaidInviteList'],
    }),
    
    //Not In Use
    createVideoMeetingRequest: builder.mutation<MeetingRequest, Partial<MeetingRequest>>({
      query: request => {
        return {
          url: `meetingRequest/meetingVideo`,
          method: 'POST',
          body: request,
        }
      },
    }),
    createMeetingRequest: builder.mutation<MeetingRequest, Partial<any>>({
      query: args => {
        const { request, guest } = args

        return {
          url: `meetingRequest/inviteMeetingRequest/${guest?.id}`,
          method: 'POST',
          body: request,
        }
      },
      invalidatesTags: ['MeetingRequestByUser'],
    }),

    getNotificationCount: builder.query<number, void>({
      query: () => `meetingRequest/count/notification`,
      providesTags: ['MeetingRequest', 'NotificationBadge'],
    }),

    getRequests: builder.query<MeetingRequest, string>({
      query: id => `meetingRequest/${id}`,
      transformResponse: (res: MeetingRequest) => meetingResponseTransform(res),
      providesTags: ['MeetingRequest'],
    }),

    updateRequest: builder.mutation<MeetingRequest, Partial<MeetingRequest>>({
      query: request => {
        return {
          url: `meetingRequest/${request.id}`,
          method: 'PUT',
          body: request,
        }
      },
      invalidatesTags: ['MeetingRequestByUser', 'AttendingMeeting', 'NotificationBadge'],
    }),

    deleteRequests: builder.mutation<boolean, MeetingRequest>({
      query: request => {
        return {
          url: `meetingRequest/${request.id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['MeetingRequestByUser', 'AttendingMeeting', 'NotificationBadge']
    }),

    rejectRequest: builder.mutation<boolean, Partial<MeetingRequest>>({
      query: request => {
        return {
          url: `meetingRequest/reject/${request.id}`,
          method: 'PUT',
          body: request,
        }
      },
      invalidatesTags: ['MeetingRequestByUser', 'AttendingMeeting', 'NotificationBadge']
    }),

    deleteRequestAfterAccept: builder.mutation<boolean, MeetingRequest>({
      query: request => {
        return {
          url: `meetingRequest/rejectByRequestedUser/${request.id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['MeetingRequestByUser', 'AttendingMeeting']
    }),

    cancelRequest: builder.mutation<boolean, string>({
      query: id => {
        return {
          url: `meetingRequest/cancelRequest/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['MeetingRequest', 'MeetingRequestByUser', 'AttendingMeeting'],
    }),

    getCountOfSendByUser: builder.query<Number, string>({
      query: userId => `meetingRequest/all/send/${userId}`,
      providesTags: ['MeetingRequestByUser'],
    }),
  }),
})

export const {
  useGetCountOfSendByUserQuery,
  useCreateMeetingRequestMutation,
  useCreateVideoMeetingRequestMutation,
  useGetRequestsQuery,
  useDeleteRequestsMutation,
  useUpdateRequestMutation,
  useCancelRequestMutation,
  useDeleteRequestAfterAcceptMutation,
  useRejectRequestMutation,
  useCreateInviteForAllGuestsMutation,
  useGetNotificationCountQuery
} = meetingRequestApi