import { ExistingMeetingList } from './ExistingMeetingList'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Schedule() {
  const { t } = useTranslation()

  return (
    <>
      {/* <div className='w-full'>
      <Link id="btn_addMeeting" to={`/s/events/my-events/${meetingDetailId}/schedule/new/edit${PageAndSortHook()}`}>
          <div className="bg-transparent ml-5 md:ml-2 text-black normal-case cursor-pointer text-center hover:underline">
            {t('createNewSession')} +
          </div>
        </Link>
    </div> */}
      <div className="flex flex-col lg:flex-row gap-2 mb-3 max-h-96 h-96">
        <div className="w-full lg:w-1/2 -80 p-1 overflow-y-auto overflow-x-hidden lg:order-1 order-2">
          <div className="text-black font-normal text-left text-md mb-1 md:text-lg ">{t('upcomingSessions')}</div>
          <ExistingMeetingList />
        </div>
        <div className="w-full lg:border-0 lg:w-3/4 self-start- p-2 overflow-y-auto overflow-x-hidden md:order-2 order-1">
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default Schedule
