/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Avatar from '../../Components/base/avatar/avatar'
import Loading from '../../Components/base/loading/loading'
import DisplayName from '../../Components/base/user-name/DisplayName'
import { useGetAllMeetingsByIdQuery } from '../../Services/meetingApi'
import { useGetAllParticipantByMeetingQuery } from '../../Services/meetingParticipantApi'
import { getPreference } from '../../Services/userReducer'
import { uid } from '../../Utils/helper'
import { Meeting } from '../../models/meeting.model'
import { MeetingParticipant } from '../../models/meetingParticipant.model'
import { useCurrencyHook } from '../../Hooks/CurrencyHooks'
import { DisplayFormattedDate } from '../../Components/formattedDateAndTime'

function Participants() {

  const { t } = useTranslation()
  const { meetingId } = useParams()
  const { meetingDetailId } = useParams()
  const participantsRef = useRef<any>();

  const [allMeetingParticipants, setAllMeetingParticipants] = useState<(Meeting | MeetingParticipant)[]>()

  const { data: meetingParticipants } = useGetAllParticipantByMeetingQuery(meetingId ?? '', { skip: meetingId === undefined })
  const { data: hostMeetings } = useGetAllMeetingsByIdQuery(meetingDetailId ?? '', { skip: meetingDetailId === undefined })

  useEffect(() => {
    if (meetingParticipants && hostMeetings) {
      hostMeetings.forEach(hostMeeting => {
        if (hostMeeting.id === meetingId) {
          setAllMeetingParticipants([hostMeeting, ...meetingParticipants])
        }
      })
    }
    else {
      hostMeetings?.forEach(hostMeeting => {
        if (hostMeeting.id === meetingId) {
          setAllMeetingParticipants([hostMeeting])
        }
      })
    }
  }, [meetingParticipants, hostMeetings])

  var coHosts, participants;
  if (meetingParticipants) {
    coHosts = meetingParticipants.filter(participant => participant.isCohost);
    participants = meetingParticipants.filter(participant => !participant.isCohost);
  }
  if (!meetingParticipants) {
    return <Loading />
  }

  return (
    <div className='ease-out'>
      <div data-testid='txt_Users'className='text-black font-normal text-lg '>{t('users')}</div>
      <div id="chk_participantsList" className='flex flex-wrap sm:grid-cols-1 md:grid md:grid-cols-2 gap-2 justify-items-start mt-4 max-h-96 justify-start capitalize'>
        {allMeetingParticipants && <ParticipantCardHost ref={participantsRef} participant={allMeetingParticipants[0]} keyId={uid()} />}
        {coHosts && coHosts.map((participant, index) => <ParticipantCardCoHost participant={participant} keyId={index} key={index} />)}
        {participants && participants.map((participant, index) => <ParticipantCard ref={participantsRef} participant={participant} keyId={index} key={index} />)}
      </div>
    </div>
  )
}

// export function ParticipantCardHost forwardRef(({participant, keyId}: {participant: MeetingParticipant | Meeting, keyId?: string}, ref: any)) => {
export const ParticipantCardHost = forwardRef(({ participant, keyId }: { participant: MeetingParticipant | Meeting, keyId?: string }, ref) => {
  const { t } = useTranslation()


  return (
    <div className="grid grid-cols-4 items-center py-2 h-full lg:w-64 lg:min-w-full">
      <div className="col-span-1 pt-1">
        <Avatar images={participant?.user?.image} avatar={participant?.user?.avatar} anonymous={participant?.user?.anonymous} size='small' />
      </div>
      <div className="col-span-3">
        <div className="text-sm pl-2 flex flex-col">
          <div id={`chk_participantName${keyId}`} data-testid='HostName' className="font-semibold whitespace-nowrap">
            <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
              {participant?.user?.firstName} {participant?.user?.lastName}
            </div>
          </div>
          <div className="flex flex-col mt-1">
            <span className='text-xs' data-testid='eventDateHost'><DisplayFormattedDate displayDate={participant?.createdAt} /></span>
            <span data-testid='txt_Host' className="text-orangeShade pt-1 uppercase font-semibold">{t(`host`)}</span>
          </div>
        </div>
      </div>
    </div>
  )
})
export function ParticipantCardCoHost({ participant, keyId }: { participant: MeetingParticipant, keyId?: number }) {
  const { t } = useTranslation()

  return (
    <div className="grid grid-cols-4 items-center py-2 h-full lg:w-64 lg:min-w-full">
      <div className="col-span-1 pt-2">
        <Avatar images={participant?.user?.image} avatar={participant?.user?.avatar} anonymous={participant?.user?.anonymous} size='small' />
      </div>
      <div className="col-span-3">
        <div className="text-sm pl-2 flex flex-col">
          <div id={`chk_participantName${keyId}`} data-testid={`chk_CoHost${keyId}`} className="font-semibold whitespace-nowrap">
            <DisplayName user={participant.user} hostAnonymous={false} />
          </div>
          <div className="font flex flex-col mt-1">
            <span className='text-xs' data-testid={`eventDateCoHost${keyId}`}><DisplayFormattedDate displayDate={participant?.createdAt} /></span>
            <span className="text-orangeShade pt-1 uppercase font-semibold" data-testid={`txt_coHost${keyId}`}>{t('co-Host')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export const ParticipantCard = forwardRef(({ participant, keyId }: { participant: MeetingParticipant, keyId?: number }, ref) => {
  const { t } = useTranslation()
  const preference = useSelector(getPreference)
  const meetingParticipant = (participant as MeetingParticipant)
  const { formatCurrencySymbol } = useCurrencyHook()


  return (
    <>{!meetingParticipant?.cancelationRequest &&
      <div className="grid grid-cols-4 py-2 items-center h-full lg:w-64 lg:min-w-full">
        <div className="col-span-1 pt-2">
          {participant?.user && <Avatar images={participant?.user?.image} avatar={participant?.user?.avatar} anonymous={participant?.user?.anonymous} size='small' />}
          {participant?.invitedUser !== null && <Avatar size='small' />}
        </div>
        <div className="col-span-3">
          <ul className="text-sm pl-2">
            <li
              data-testid={`chk_participantName${keyId}`}
              id={`chk_participantName${keyId}`}
              className="font-semibold truncate"
            >
              {participant?.invitedUser !== null && participant?.invitedUser?.inviteEmail}{' '}
              {participant?.user && participant?.user?.firstName + ' ' + participant?.user?.lastName}
            </li>
            {!meetingParticipant?.isCohost && meetingParticipant?.meeting?.meetingDetails?.isFreeAudioMeeting && (
              <li className="text-xs pt-1">
                <div className="flex justify-between">
                  <div><DisplayFormattedDate displayDate={meetingParticipant?.createdAt} /></div>
                </div>
              </li>
            )}
            {!meetingParticipant?.meeting?.meetingDetails?.isFreeAudioMeeting && (
              <>
                {!meetingParticipant?.isCohost && (
                  <>
                    <li>
                      {meetingParticipant.transaction?.user?.id === participant.user?.id &&
                        meetingParticipant.transaction?.guestCount > 1 && (
                          <span data-testid={`paidGuest${keyId}`} className="text-xs">
                            {t('paid')} {' '}{formatCurrencySymbol(preference.currency)}{meetingParticipant.transaction?.costLocal} (x{meetingParticipant.transaction?.guestCount})
                          </span>
                        )}
                      {meetingParticipant.transaction?.user?.id === participant.user?.id &&
                        meetingParticipant.transaction?.guestCount === 1 && (
                          <span data-testid={`paidHimSelf${keyId}`} className="text-xs">
                            {t('paid')} {' '}{formatCurrencySymbol(preference.currency)}{meetingParticipant.transaction?.costLocal}
                          </span>
                        )}
                    </li>
                    <li><span data-testid={`paidDate${keyId}`} className="text-xs">{t('on')}{' '}{participant.display_createdAt}</span></li>
                    <li>
                      {!meetingParticipant?.isCohost && (
                        <span className="flex text-xs text-gray-300 mt-1" data-testid={`paidBy${keyId}`}>
                          <span className='mr-1'>{meetingParticipant.transaction?.user?.id === participant.user?.id ? t('paidFor') : t('paidBy')}</span>
                          {meetingParticipant.transaction?.user?.id === participant.user?.id ? (
                            <span>{t('self')}</span>
                          ) : (
                            <>
                              {meetingParticipant.transaction?.user?.id.length > 15 ? (
                                <span>{meetingParticipant.transaction?.user?.firstName}</span>
                              ) : (
                                <>
                                  {meetingParticipant.transaction?.user?.firstName}{' '}
                                  {meetingParticipant.transaction?.user?.lastName}
                                </>
                              )}
                            </>
                          )}
                        </span>
                      )}

                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    }
    </>
  )
})

export default Participants
