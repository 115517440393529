
import { User } from '../../../models/user.model'

const DisplayName = ({ user, hostAnonymous, firstNameOnly = false }: { user: User, hostAnonymous?: boolean, firstNameOnly?: boolean }) => {

  return (
    <>
    <div className='capitalize'>
      {
        user && hostAnonymous === true && (user.anonymous ? user.nickName : '')
      }
      {
        user && hostAnonymous === false && (user.firstName + ' ' + (!firstNameOnly ? ((user.lastName ?? '')) : '')).substring(0, 15)+'...'
      }
      {
        user && hostAnonymous === undefined && (user.anonymous ? user.nickName : user.firstName + ' ' + (!firstNameOnly ? ((user.lastName ?? '')) : ''))
      }
      </div>
    </>
  )
}
export const DisplayNameAsString = ({ user, hostAnonymous }: { user: User, hostAnonymous?: boolean }): (string | undefined) => {
  if (user && hostAnonymous === true && user.anonymous)
    return user.nickName
  else if (user && hostAnonymous === false)
    return user.firstName + ' ' + (user.lastName ?? '')
  else if (user && hostAnonymous === undefined) {
    if (user.anonymous)
      return user.nickName
    else
      return user.firstName + ' ' + (user.lastName ?? '')
  }
}
export default DisplayName




