import { format, getUnixTime, parseISO } from 'date-fns';

export const mergeUniqueArray = (arr1, arr2) => {
    if (!arr1) {
        return arr2;
    }
    if (!arr2) {
        return arr1;
    }
    const newArr = [...arr1, ...arr2];
    return [...new Set(newArr)];
}

function groupedDays(messages) {
    return messages.reduce((acc, el, i) => {
        const messageDay = format(new Date(el.dateSend), "MMMM d, yyyy");
        if (acc[messageDay]) {
            return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
        }
        return { ...acc, [messageDay]: [el] };
    }, {});
}

export function generateGroupedMessagesWithDate(messages) {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort((x, y) => getUnixTime(parseISO(format(new Date(y), "MMMM d, yyyy"))) - getUnixTime(parseISO(format(new Date(x), "MMMM d, yyyy"))));
    const items = sortedDays.reduce((acc, date) => {
        const sortedMessages = days[date].sort((x, y) => new Date(x.dateSend) - new Date(y.dateSend));
        return acc.concat([{ type: 'date', date, messageId: date }, ...sortedMessages]);
    }, []);
    return items;
}
