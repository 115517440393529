
import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import { Meeting } from "../../models/meeting.model";
import { useHandleMeetingNavigation } from "../LiveKit/hooks/livekitWebOnlyHooks";
import { useAppSelector } from "../../Store/hooks";
import { getMeetingInfo } from "../../Services/livekitReducer";
import { toastError } from "../../Components/toast";

export function JoinButton({meeting}: {meeting: Meeting}) {
    const {t}      = useTranslation()
    const { joinMeeting } = useHandleMeetingNavigation()
    const meetingInfo = useAppSelector(getMeetingInfo)

    const handleJoinClick = () => {
        if (meetingInfo && (meetingInfo.isAudioMeeting || meetingInfo.isVideoMeeting)) {
            toastError(t('meetingOngoingDisconnectToJoin'))
        } else {
            joinMeeting(meeting.id)
        }
    }

    return (
        <Button
            id={`btn_join${meeting.id}`}
            size="sm"
            rounded
            color="join"
            onClick={handleJoinClick}
            className="whitespace-nowrap"
        >
            {t('join')}
        </Button>
    )
}
