import { useEnsureParticipant, useEnsureRoom } from '@livekit/components-react'
import { Participant, LocalParticipant } from 'livekit-client'
import React from 'react'
import { encryptionStatusObservable } from '@livekit/components-core'
import { Observable } from 'rxjs'

export function useObservableState<T>(observable: Observable<T> | undefined, startWith: T) {
  const [state, setState] = React.useState<T>(startWith)
  React.useEffect(() => {
    // observable state doesn't run in SSR
    if (typeof window === 'undefined' || !observable) return
    const subscription = observable.subscribe(setState)
    return () => subscription.unsubscribe()
  }, [observable])
  return state
}
export function useIsEncrypted(participant?: Participant) {
  const p = useEnsureParticipant(participant)
  const room = useEnsureRoom()

  const observer = React.useMemo(() => encryptionStatusObservable(room, p), [room, p])
  const isEncrypted = useObservableState(observer, p instanceof LocalParticipant ? p.isE2EEEnabled : p.isEncrypted)
  return isEncrypted
}

export const addSecondsToDate = (date: Date, seconds: number) => new Date(date.getTime() + seconds * 1000)

export const calculateTimeRemaining = (endTime: Date) => {
  const now = new Date()
  const timeLeft = Math.floor((endTime.getTime() - now.getTime()) / 1000)
  const minutes = Math.floor(timeLeft / 60)
  const seconds = timeLeft - minutes * 60
  const asString = `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  return {
    timeLeftInSeconds: timeLeft,
    timeLeftString: asString,
    minutes,
    seconds,
  }
}

export function cloneSingleChild(
  children: React.ReactNode | React.ReactNode[],
  props?: Record<string, any>,
  key?: any
) {
  return React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child) && React.Children.only(children)) {
      return React.cloneElement(child, { ...props, key })
    }
    return child
  })
}
