import { useTranslation } from "react-i18next"
import { Ledger } from "../../models/ledger.model"
import { DisplayFormattedDate, DisplayFormattedTime } from "../../Components/formattedDateAndTime"

export default function DisplayTransactionDate({ transaction }: { transaction: Ledger }) {
    const { t } = useTranslation()

    return (
        <div className="flex justify-items-center items-center">
            <span data-testid={`card_date-${transaction.id}`} id={`card_date-${transaction.id}`} className="">
                <span className='font-semibold'>{t('paidOn')}{' '}
                <DisplayFormattedTime displayFromTime={transaction?.transactionDateTime?.toString()}/>
                {','}{' '}<DisplayFormattedDate displayDate={transaction.display_createdAt}/></span>
            </span>
        </div>
    )
}