import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {toastError} from '../../Components/toast'
import {useNavigate, useParams} from 'react-router-dom'
import {useGetMeetingDetailsQuery} from '../../Services/meetingDetailsApi'
import {EventImage} from './EventImage'
import EventVideo from './EventVideo'
import Button from '../../Components/base/button/button'
import {useTranslation} from 'react-i18next'
import { ImageVideoContext } from '../../Hooks/MyEvents/ImageVideoContextHook'
import { use } from 'i18next'
import { set } from 'lodash'
import { is, tr } from 'date-fns/locale'

export function ImageVideoModel() {
  const {meetingDetailId} = useParams()
  const {t} = useTranslation()
  const navigate = useNavigate()

  const {data: meetingDetails} = useGetMeetingDetailsQuery(
    meetingDetailId ? meetingDetailId : '',
    {skip: meetingDetailId ? false : true},
  )

  const[isImageVideoError, setIsImageVideoError] = useState<boolean>(false)

  let {images, isVideoInDB, isVideoSelected, setIsTriggerSaveImageAndVideo, isSaveTriggerWeb, setIsSaveTriggerWeb, isButtonLoadingWeb, setIsButtonLoadingWeb} =  useContext(ImageVideoContext)

  /*
 * IMAGE AND VIDEO UPLOAD SCENARIOS

 * 1. If meetingDetails.id is not present then create a new meeting - [PASSED]
 * 2. If meetingDetails.id is present and 0 images and 0 video, then update the meeting - [PASSED]
 * 3. If meetingDetails.id is present and 5 images and 1 video are saved, then update the meeting - [PASSED]
 * 4. If meetingDetails.id is present and 5 images and 1 video are not saved, then update the meeting - [PASSED]
 * 5. If meetingDetails.id is present and 5 images and 1 video are uploaded, then show an error - [PASSED]
 * 6. If meetingDetails.id is present and 5 images and 1 video are removed, then update the meeting - [PASSED]  
 * 7. If meetingDetails.id is present and 5 images and 1 video are removed, and at least 1 image uploaded but not 5 images, then show an error - [PASSED]
 * 8. If meetingDetails.id is present and 5 images and 1 video are removed, and 5 images and 1 video uploaded, then update the meeting - [PASSED]
 * 9. If meetingDetails.id is present and removed 1 image and uploaded one image, then update the meeting - [PASSED]
 * 10.If meetingDetails.id is present and 5 images and 1 video already exist and removed one image and not replaced, then show an error - [PASSED]
 * 11.If meetingDetails.id is present and 5 images and 1 video already exist and all 5 images and 1 video removed and not saved and reloaded, the old images should appear - [PASSED]
 * 12.If meetingDetails.id is present and 5 images are uploaded in the other meeting, they should be placed in the same order -[PASSED]
 */



  useEffect(() => {
    
      const imageCountInDB = images.filter(image => image.isImageInDB).length // count of images in DB
      const selectedImageCount = images.filter(image => image.isImageSelected).length // count of images selected
      const videoCountInDB = isVideoInDB ? 1 : 0 // count of video in DB
      const selectedVideoCount = isVideoSelected ? 1 : 0 // count of video selected
      const totalCount =  selectedImageCount + selectedVideoCount + imageCountInDB + videoCountInDB // total count of images and video
      setIsImageVideoError(totalCount < 6 && totalCount > 0)

  
      if (isSaveTriggerWeb) {// if save button is clicked
      setIsTriggerSaveImageAndVideo(totalCount === 6 || totalCount === 0)
      setIsSaveTriggerWeb(false)
      }

  }, [images, isVideoInDB, isVideoSelected, isSaveTriggerWeb])

  return (
    <>
      <div className={`flex flex-col relative p-6`}>
        <div className="flex flex-col">
          <span className="flex-col text-center text-xl font-bold">
            {meetingDetails?.title && meetingDetails?.title.length > 25
              ? meetingDetails?.title.slice(0, 25) + '...'
              : meetingDetails?.title}
          </span>
          <span className="flex-col text-center font-semibold">
            {t('imagesandvideoupload')}
          </span>
        </div>

        {meetingDetails?.id && (
          <>
            <div className="flex flex-col md:flex-row gap-1 bg-green justify-center my-4 sm:flex-col sm:items-center ">
              <EventImage
                meetingDetails={meetingDetails}
                orderNumber={1}
                width={250}
                height={375}
                asprat={2 / 3}
              />
              <EventImage
                meetingDetails={meetingDetails}
                orderNumber={2}
                width={250}
                height={375}
                asprat={2 / 3}
              />
              <div className="flex-col">
                <EventImage
                  meetingDetails={meetingDetails}
                  orderNumber={3}
                  width={375}
                  height={250}
                  asprat={3 / 2}
                />
                <EventImage
                  meetingDetails={meetingDetails}
                  orderNumber={4}
                  width={375}
                  height={250}
                  asprat={3 / 2}
                />
              </div>
              <EventImage
                meetingDetails={meetingDetails}
                orderNumber={5}
                width={250}
                height={375}
                asprat={2 / 3}
              />
              <EventVideo meetingDetails={meetingDetails}/>
            </div>
            {isImageVideoError && (<div id="errorMessage" className="text-red-500 text-sm">{'*'}{t('pleaseUpload5ImagesAndaVideo')}</div>)}

            <div>
              <div className="flex justify-between md:flex-wrap md:flex md:flex-row md:gap-x-4 sm:flex sm:flex-wrap sm:flex-row sm:justify-between gap-y-2 sm:gap-y-5">
                <div className="flex flex-col justify-start gap-y-1">
                  <div className="text-xxs text-gray-300 font-medium">
                    {t('supportedImageFormats.JPG,.JPEG,.PNG,.BMP')}
                  </div>
                  <div className="text-xxs text-gray-300 font-medium">
                    {t('supportedVideoFormats.MP4')}
                  </div>
                </div>
                <div className="flex gap-x-4">
                  <Button
                    id="btn_cancelImageVideoModel"
                    size="md"
                    color="transparent"
                    className="text-red-500 text-sm"
                    onClick={() => {
                      navigate(
                        `${
                          '/s/events/my-events/' +
                          meetingDetailId +
                          '/eventDetails?page=1&sort=updatedAt'
                        }`,
                      )
                    }}>
                    {t('goBack')}
                  </Button>

                  <Button
                    size="md"
                    data-testid="btn_saveEvent"
                    id="btn_saveEvent"
                    color="save"
                    disabled={isImageVideoError}
                    onClick={() => {
                      setIsSaveTriggerWeb(!isSaveTriggerWeb)
                    }}>
                    {t('save')}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}


