import { MeetingNotificationRemainder } from "../models/meetingNotificationRemainder.model";
import { RefundRequest } from "../models/refundRequest.model";
import { allApis } from "./allApis";

export const refundRequestApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        
        createRefundRequest: builder.mutation<RefundRequest, Partial<RefundRequest>>({
            query: (refundRequest) => ({
                url: `refundRequest`,
                method: 'POST',
                body: refundRequest
            }),
            invalidatesTags: (result, error, refundRequest) => [{ type: 'MeetingNotificationRemainder'}],
        }),

        uploadRefundAttachments: builder.mutation<any[], any>({
            query: ({ id, files }) => {
          
              return {
                url: `refundRequest/refundRequestAttachments/${id}`,
                method: 'POST',
                body: files, 
              };
            },
          }),
          
    })
})
export  const { useCreateRefundRequestMutation , useUploadRefundAttachmentsMutation } = refundRequestApi;