import { MeetingNotificationRemainder } from "../models/meetingNotificationRemainder.model";
import { RefundCategory } from "../models/refundCategory.model";
import { allApis } from "./allApis";

export const refundCategoryApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        
        getAllRefundCategory: builder.query<RefundCategory[], void>({
            query: () => "refundCategory",
            // providesTags: ['Category']
          }),

    })
})
export  const { useGetAllRefundCategoryQuery  } = refundCategoryApi;