import { WebCommandTypes, WebCommandMessage, Message, MessageType } from './socketTypes';
import { socketClient } from './socketio';

export const SendWebCommand = ({ type, fromUserId, toUserId }: { type: WebCommandTypes, fromUserId: string, toUserId?: string }) => {

  const message = {} as Message;
  const webCommand = {} as WebCommandMessage;

  webCommand.fromUserId = fromUserId;
  webCommand.toUserId = toUserId;
  webCommand.type = type;


  message.message = webCommand;
  message.type = MessageType.WebCommand;

  socketClient.send(message)

}