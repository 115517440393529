import { useRef, useEffect } from "react";
import { getMeetingChats } from "../../Services/livekitReducer";
import { getMessages } from "../../Store/Chat/chat";
import { useAppSelector } from "../../Store/hooks";

const AlwaysScrollToBottom = () => {
    const messagesEndRef = useRef(null) as any;
    const liveKitChats = useAppSelector(getMeetingChats)
    const chatMessages = useAppSelector(getMessages)

    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ block: "end" });
    };

    useEffect(() => {
        scrollToBottom();
        setTimeout(() => {
            scrollToBottom();
        }, 50);
        return () => {
            scrollToBottom();
            setTimeout(() => {
                scrollToBottom();
            }, 50);
        };
    }, [chatMessages, liveKitChats]);

    return <div ref={messagesEndRef} />;
};

export default AlwaysScrollToBottom;