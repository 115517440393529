import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { preferenceApi } from '../Services/preferenceApi';
import { getCognitoObject } from '../Services/signUpReducer';
import { changeToken } from '../Services/tokenReducer';
import { useGetCurrentUserDetailsQuery, useUpdateUserMutation, userApi } from '../Services/userApi';
import { changeCurrentUserDetail } from '../Services/userReducer';
import { useAppDispatch } from '../Store/hooks';
import { secondsToTime } from '../Utils/datetime';
import { phoneNumberValidationSchema } from '../Utils/validation';
import { Verified } from '../models/user.model';
import { allOthersApi } from './../Services/allOthersApi';
import { getCurrentUser, getCurrentUserDetail } from './../Services/userReducer';

type InitDisplayTimer = {
  hours: number;
  minutes: number;
  seconds: number;
};

const initState: InitDisplayTimer = {
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const usePhoneVerifyHook = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const now = new Date();
  const [phoneError, setPhoneError] = useState(false);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [verifiedNumber, setVerifiedNumber] = useState(false);
  const [nextExpiryDateTime, setNextExpiryDateTime] = useState<Date>(new Date(now.getTime() + (15 * 60000)));
  const cognitoModel = useSelector(getCognitoObject) ?? JSON.parse(localStorage.getItem('SIGNIN_HELPER_COGNITOMODEL') as any);
  const currentUserDetail = useSelector(getCurrentUserDetail)

  const resendCode = () => {
    if (phoneError) {
      setPhoneError(false);
    }

    Auth.resendSignUp(cognitoModel.email).then(() => {
      setNextExpiryDateTime(new Date(now.getTime() + (15 * 60000)));


      if (!showToastMessage) {
        setShowToastMessage(true);
      }
    }).catch(error => console.log(error));
  }

  const onSubmit = (values: any) => {
    if (phoneError) {
      setPhoneError(false);
    }
    if(!nextExpiryDateTime){
      setPhoneError(true);
      return ;
    }
    Auth.confirmSignUp(cognitoModel.email, values.otp).then(() => {

      dispatch(userApi.endpoints.verifyPhone.initiate(cognitoModel.sub)).then((res) => {
        if (res) {
          setVerifiedNumber(true)
        }
      });

      Auth.signIn(cognitoModel.email, cognitoModel.password).then((result) => {
        dispatch(changeToken(result.signInUserSession.accessToken.jwtToken));

        Auth.currentAuthenticatedUser().then((resultCurrent) => {
          dispatch(preferenceApi.endpoints.getUserPreference.initiate());
          dispatch(allOthersApi.endpoints.getAllCurrency.initiate());
          dispatch(userApi.endpoints.getCurrentUserDetails.initiate());
          dispatch(changeCurrentUserDetail(resultCurrent.attributes));
          Auth.verifyUserAttribute(resultCurrent, 'email');
          

        });
      });
    }).catch(error => {
      console.log('error verifying phone number:', error);

      if (!phoneError) {
        setPhoneError(true);
      }
    });
  }

  return { resendCode, phoneError, onSubmit, nextExpiryDateTime, t, showToastMessage, setShowToastMessage, currentUserDetail, cognitoModel, verifiedNumber, setVerifiedNumber, setPhoneError };
}

export const usePhoneVerificationTimerHook = (nextExpiryDateTime: Date) => {
  const [displayTimer, setDisplayTimer] = useState(initState);
  const [displayTimerFormatted, setDisplayTimerFormatted] = useState<string>();
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    setIsExpired(false);
    let myInterval = setInterval(() => {
      if (nextExpiryDateTime) {
        const timeDiff = nextExpiryDateTime.getTime() - new Date().getTime();

        if (timeDiff <= 0) {
          clearInterval(myInterval);
          setDisplayTimer(initState);
          setIsExpired(true);
        } else {
          const diff = secondsToTime(timeDiff / 1000);
          diff.days = 0;

          if (diff.seconds === 0 && diff.minutes === 0 && diff.hours === 0) {
            clearInterval(myInterval);
          } else {
            setDisplayTimer(diff);
          }
        }
      } else {
        clearInterval(myInterval);
        setDisplayTimer(initState);
        setIsExpired(true);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [nextExpiryDateTime]);

  const setDisplayTimerFormat = ({ hours, minutes, seconds }: InitDisplayTimer): void => {
    const formatTimeUnit = (value: number): string => value.toString().padStart(2, '0');

    let formattedTime = '';

    if (hours > 0) {
      formattedTime += `${formatTimeUnit(hours)} : `;
    }

    if (hours > 0 || minutes > 0 || seconds >= 0) {
      formattedTime += `${formatTimeUnit(minutes)} : `;
    }

    formattedTime += `${formatTimeUnit(seconds)}`;

    // Check if the timer has expired
    if (hours === 0 && minutes === 0 && seconds === 0) {
      setDisplayTimerFormatted('');
      return;
    }

    setDisplayTimerFormatted(formattedTime);
  };


  useEffect(() => {
    if (displayTimer) {
      setDisplayTimerFormat(displayTimer);
    }
  }, [displayTimer]);

  return {
    displayTimerFormatted,
    isExpired,
  };
};

export const useSignedInPhoneVerifyHook = (currentUserDetail: any) => {

 
  const currentUser = useSelector(getCurrentUser)
  const dispatch = useAppDispatch();
  const [phoneError, setPhoneError] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const now = new Date()
  const [nextExpiryDateTimeVal, setNextExpiryDateTimeVal] = useState<Date>(new Date(now.getTime() + (15 * 60000)));
  const userDetailCopy = { ...currentUserDetail };
  const userCopy = { ...currentUser };

  const [updateUser, { isSuccess: profileUpdateSuccess }] = useUpdateUserMutation<any>();

  const { register, handleSubmit, control, getValues, setValue, watch, reset, formState: { errors, isValid } } = useForm<any>({
    mode: "onChange", resolver: yupResolver(phoneNumberValidationSchema)
  });
  useGetCurrentUserDetailsQuery()

  const signedUpResendCode = () => {
    setNextExpiryDateTimeVal(new Date(now.getTime() + (15 * 60000)));
    Auth.resendSignUp(currentUserDetail.email).then((res) => {
    }).catch(error => console.log(error));
  }

  
  const sendOTP1 = (values: any) => {
    const formattedPhoneNumber = values.includes('+') ? values : '+' + values;

    userDetailCopy.phone_number = formattedPhoneNumber;
    Auth.currentAuthenticatedUser().then((resultCurrent) => {
      const { identities, username, ...updatedUserDetail } = userDetailCopy;
      resultCurrent.attributes = updatedUserDetail;
      if (!resultCurrent.attributes.address) {
        delete resultCurrent.attributes.address;
      }
      if (!resultCurrent.attributes.birthdate) {
        delete resultCurrent.attributes.birthdate;
      }
      Auth.updateUserAttributes(resultCurrent, resultCurrent.attributes).then(() => {
        dispatch(changeCurrentUserDetail(resultCurrent.attributes));
       
        });
    });
  }

  const submit = (values: any) => {
    Auth.verifyCurrentUserAttributeSubmit('phone_number', values.otp).then(() => {
      dispatch(userApi.endpoints.verifyPhone.initiate(currentUser.sub)).then(() => {
        dispatch(userApi.util.invalidateTags(['CurrentUser']))
        userCopy.phoneVerified = Verified.Complete;
        updateUser(userCopy)
        setPhoneVerified(true)
      }).catch((error) => {
        userCopy.phoneVerified = Verified.NotComplete;
        console.log("error",error)
      });
  }).catch(error => {
    console.log('error verifying phone number:', error);

    if (!phoneError) {
      setPhoneError(true);
    }
  });
}
 

  return { nextExpiryDateTimeVal, sendOTP1, signedUpResendCode, submit, register, handleSubmit, control, getValues, setValue, watch, reset, errors, isValid, profileUpdateSuccess, phoneError, phoneVerified,setPhoneError }
}