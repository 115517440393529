/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../Components'
import useToggle from '../../Components/_utils/useToggle'
import Field from '../../Components/base/field/field'
import Icon from '../../Components/base/icon/icon'
import { Modal, ModalBody, ModalCloseButton, ModalFooter } from '../../Components/base/modal/modal'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import { toastSuccess } from '../../Components/toast'
import { useInviteGuestHook } from '../../Hooks/InviteGuestHook'
import { Processed } from '../../models/cancellationRequest.model'
import { InviteStatus, MeetingParticipant } from '../../models/meetingParticipant.model'

interface InvitelistProps {
  guests: MeetingParticipant[],
  transactionId: string,
  editableOption?: boolean
}

function Guests({ meetingParticipants, transactionId, editableOption }: { meetingParticipants?: MeetingParticipant[], transactionId?: string, editableOption?: boolean }) {
  const { toggle, isOpen } = useToggle()
  const { t } = useTranslation()

  const {
    reset, isDirty, isValid, partcipantData, errors, resetForm, errorMessage, notify, updateGuestSuccess, setMeetingParticipant, register, updateGuestList, updateGuestLoading
  } = useInviteGuestHook()
  const [participantList, setParticipantList] = useState<any>(meetingParticipants)

  useEffect(()=>{
      if(updateGuestSuccess === false || partcipantData?.some((user: any) => user.error) ){
        setParticipantList(meetingParticipants)
      }
  },[meetingParticipants,updateGuestSuccess]) 

  useEffect(() => {
    if (updateGuestSuccess && !partcipantData?.some((user: any) => user.error)) {
      toastSuccess(t('updatedSuccessfully'))
      setParticipantList(partcipantData)
      toggle()
      reset()
  }
  }, [updateGuestSuccess, errorMessage])
  
  return (
    <>
      {participantList?.map(
        (invitelist: MeetingParticipant, index: number) => (
            <div key={`Guest${index}`}>
              {invitelist && (invitelist.user || invitelist.invitedUser) && (
                <div id={`chk_guests${index}`} className="grid grid-cols-4 md:gap-6 gap-2 justify-items-between p-1 border-2 border-transparent hover:border-2 hover:bg-gray-50 text-xs md:text-sm text-center">
                  {invitelist?.user && (
                  <ToolTip tip={invitelist?.user?.firstName}  isCapitalize={true}> 
                      <div
                        data-testid="chk_userName"
                        id={`chk_name${index}`}
                        className="mx-0 md:mx-8 py-2 truncate capitalize">
                        {invitelist?.user?.firstName}
                      </div>
                    </ToolTip>
                  )}
                  {!invitelist?.user && invitelist?.invitedUser && (
                    <ToolTip tip={invitelist?.invitedUser?.inviteEmail}>
                      <div
                        data-testid={`chk_email${index}`}
                        id={`chk_email${index}`}
                        className="mx-0 md:mx-8 py-2 truncate ">
                        {invitelist?.invitedUser?.inviteEmail}
                      </div>
                    </ToolTip>
                  )}
                  {invitelist?.user && (
                    <div id="btn_nonreguser" className="mx-0 md:mx-8 py-2">{t('yes')}</div>
                  )}
                  {!invitelist?.user && (
                    <div id="btn_reguser" className="mx-0 md:mx-8 py-2">{t('no')}</div>
                  )}
                  {invitelist?.inviteStatus === InviteStatus.Pending && (
                    <div
                      key={`${index}-pending`}
                      data-testid="chk_status"
                      id={`chk_status${invitelist.id}`}
                      className="mx-0 md:mx-8 py-2">
                      {t(invitelist?.inviteStatus.toLowerCase())}
                    </div>
                  )}
                  {invitelist?.inviteStatus === InviteStatus.Accepted && (
                    <div
                      key={`${index}-accepted`}
                      data-testid="chk_status"
                      id={`chk_status${invitelist.id}`}
                      className="mx-0 md:mx-8 py-2 text-green-500">
                      {t(invitelist?.inviteStatus.toLowerCase())}
                    </div>
                  )}
                  {invitelist?.inviteStatus === InviteStatus.Rejected && (
                    <div
                      key={`${index}-rejected`}
                       data-testid="chk_status"
                       id={`chk_status${invitelist.id}`}
                      className="mx-0 md:mx-8 py-2 text-red-500">
                      {t(invitelist?.inviteStatus.toLowerCase())}
                    </div>
                  )}
                  <div className="mx-8 py-2 cursor-pointer border-2 border-transparent hover:border-2 hover:rounded-lg items-center">
                    <span
                      id={`btn_viewGuestsEdit${index}`}
                      data-testid={`btn_viewGuestsEdit${index}`}
                      onClick={() => {
                        if (editableOption) {
                          toggle()
                          setMeetingParticipant(invitelist)
                        }
                      }}
                      className="flex items-center justify-center">
                      {!editableOption && !invitelist?.cancelationRequest && (
                        <div id={`btn_EditIcon${invitelist.id}`}
                          className="flex justify-center opacity-50 cursor-not-allowed"
                          pointer-null="true">
                          <Icon size="small" icon="EDIT" />
                        </div>
                      )}
                      {invitelist?.inviteStatus === 'Pending' &&
                        !invitelist?.cancelationRequest &&
                        editableOption && <Icon size="small" icon="EDIT" />}
                      {invitelist?.inviteStatus === 'Rejected' &&
                        !invitelist?.cancelationRequest &&
                        editableOption && <Icon size="small" icon="EDIT" />}
                    </span>
                    {invitelist?.inviteStatus === 'Accepted' &&
                      !invitelist?.cancelationRequest && editableOption && (
                        <div id={`btn_EditIcon${invitelist.id}`}
                          className="flex justify-center opacity-50 cursor-not-allowed"
                          pointer-null="true">
                          <Icon size="small" icon="EDIT" />
                        </div>
                      )}
                    {invitelist?.cancelationRequest?.processed ===
                      Processed.Yes && (
                      <div
                        data-testid="txt_cancelled"
                        className="opacity-50 cursor-not-allowed text-xs"
                        pointer-null="true">
                        {t('cancelled')}
                      </div>
                    )}
                    {invitelist?.cancelationRequest?.processed ===
                      Processed.No && (
                      <div
                        id={`txt_cancellationRequested${index}`}
                        className="opacity-50 cursor-not-allowed text-xs"
                        pointer-null="true">
                        {t('requestedForCancellation')}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
        ),
      )}
      <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
        <div onClick={resetForm}>
          <ModalCloseButton toggle={toggle} />
        </div>
        <form>
          <ModalBody>
            <span className="flex flex-col pl-2 mt-3">
              <span className="text-2xl font-semibold">
                {t('inviteNewGuest')}....
              </span>
              <span
                data-testid="txt_newGuest"
                className="pt-3 py-2 text-xl font-semibold">
                {t('addNewGuest')}
              </span>
              <div className="pr-4">
                <Field
                  {...register('inviteEmail')}
                  data-testid="input_inviteMail"
                  id="input_email"
                  error={errors.inviteEmail?.message}
                  name="inviteEmail"
                  className="form-control"
                  type="text"
                  placeholder={t('enterValidEmailID') + ' *'}
                />
                {errorMessage && notify && (
                  <span
                    id="error_inviteGuest"
                    className="flex justify-center text-red-500">
                    {t(errorMessage)}
                  </span>
                )}
              </div>
            </span>
          </ModalBody>
          <ModalFooter>
            <span className="flex justify-center gap-1">
              <Button
                data-testid="btn_cancel"
                className="btn btn-light"
                onClick={() => {
                  toggle()
                  resetForm()
                }}>
                {t(`cancel`)}
              </Button>
              <Button
                id="btn_submit"
                submit
                disabled={!isDirty || !isValid}
                data-testid="btn_addNewGuest"
                onClick={e => {
                  updateGuestList()
                  e.preventDefault()
                }}
                className="btn btn-primary">
                <div className="flex flex-row gap-x-2 items-center">
                  {updateGuestLoading && (
                    <svg
                      className="animate-spin h-4 w-4 text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="2"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  )}
                  <span>{t(`ok`)}</span>
                </div>
              </Button>
            </span>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

const InvitedGuests: FC<InvitelistProps> = (props: InvitelistProps) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex justify-center mt-6">
        <div className="inline-block min-w-min rounded-lg overflow-hidden ">
          <div className="grid grid-cols-4 md:gap-6 gap-2 justify-items-center border-b text-xs md:text-sm font-semibold text-center">
            <div data-testid="txt_email" className="py-2">{t('email/Username')}</div>
            <div data-testid="txt_user" className="py-2">{t('registerUser')}</div>
            <div data-testid="txt_status" className="py-2">{t('confirmationStatus')}</div>
            <div data-testid="txt_edit" className="py-2"></div>
          </div>
          <Guests meetingParticipants={props.guests} transactionId={props?.transactionId} editableOption={props?.editableOption} />
        </div>
      </div>
    </>
  )
}

export default InvitedGuests
