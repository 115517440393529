import { useEffect, useState } from "react";
import { MeetingDetails } from "../../models/meetingDetails.model";
import { useGetAllMeetingDetailsQuery } from "../../Services/meetingDetailsApi";
import useFetchDataHooks from "../FetchDataHook";

export default function useMyEventsPaginationHook() {
    const [scheduleEvents, setScheduleEvents] = useState<any>([])
    const [sortingString, setSortingString] = useState<string>('')
    const [sortingList, setSortingList] = useState<any>({ title: false, upcomingMeeting: false, updatedAt: false })
    const { pageData, isPageLoading, pagination, pageDataRefetch, setPagination, pageDataSuccess } = useFetchDataHooks<MeetingDetails>(useGetAllMeetingDetailsQuery, {
        // title: 'Au',
        sortBy: sortingString
    } as MeetingDetails);

    useEffect(() => {
        if (pageData && pageDataSuccess) {
            setScheduleEvents(pageData?.data)
        }
    }, [pageData])

    return {
        scheduleEvents,
        isPageLoading,
        pagination,
        sortingList,
        sortingString,
        setSortingString,
        setSortingList,
        setPagination,
        pageData,
        pageDataRefetch
    }
}