/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import { Button } from '../../Components'
import NewIcon from '../../Components/base/icon/newIcons'
import { useGetMessagesByMeetingRequestIdQuery, useGetTodayMeetingRequestCountQuery } from '../../Services/messageApi'
import { useGetSettingValue } from '../../Services/settingReducer'
import { ChatMessage, ChatMessageType } from '../../Store/Chat/ChatModel'
import { getActiveMeetingRequestId } from '../../Store/Chat/chat'
import { useAppSelector } from '../../Store/hooks'
import { Accept } from '../../models/meetingRequest.model'
import { useHandleMeetingNavigation } from '../LiveKit/hooks/livekitWebOnlyHooks'
import VideoMeetingStatus from './VideoMeetingStatus'
import { DisplayFormattedTime } from '../../Components/formattedDateAndTime'


function SentMessage({ msg }: { msg: ChatMessage }) {


  return (
    <div className={`${msg.sent === false ? 'opacity-50' : 'opacity-100'} self-end`}>
      <div className='flex flex-row gap-x-2 items-center'>
        <div id={`sentTime-${msg?.messageId}`} className="text-xxs text-gray-400 text-left">
          <DisplayFormattedTime displayFromTime={msg.dateSend}/>
        </div>
        <RenderMessage msg={msg} />
      </div>
    </div>
  )
}

export default SentMessage

const RenderMessage = ({ msg }: { msg: ChatMessage }) => {
  return (
    <div className="max-w-[25rem]">
      {msg.type === ChatMessageType.Text && <DisplayTextMessage msg={msg} />}
      {msg.type === ChatMessageType.Video && <DisplayVideoRequest msg={msg} />}
    </div>
  )
}

function DisplayVideoRequest({ msg }: { msg: ChatMessage }) {

  const { t } = useTranslation()
  const [isExpired, setIsExpired] = useState(true)

  const chatVideoLimit = useGetSettingValue('CHAT_VIDEO_REQUEST_PER_DAY')
  const chatAcceptTimeLimit = useGetSettingValue('CHAT_ACCEPT_VIDEO_REQUEST_TIME_LIMIT(MINUTES)')

  const { data: todayMeetingRequest } = useGetTodayMeetingRequestCountQuery()

  const activeMeetingRequestId = useAppSelector(getActiveMeetingRequestId)

  useGetMessagesByMeetingRequestIdQuery(activeMeetingRequestId, {
    skip: activeMeetingRequestId !== msg?.meetingRequest?.id,
  })
  const { joinMeeting } = useHandleMeetingNavigation()

  useEffect(() => {
    let setTimerInterval: any
    if (chatAcceptTimeLimit) {
      if (new Date().valueOf() - new Date(msg?.dateSend).valueOf() > parseInt(chatAcceptTimeLimit) * 60 * 1000) {
        setIsExpired(true)
      } else {
        setIsExpired(false)
      }
      setTimerInterval = setInterval(() => {
        if (new Date().valueOf() - new Date(msg?.dateSend).valueOf() > parseInt(chatAcceptTimeLimit) * 60 * 1000) {
          setIsExpired(true)
        } else {
          setIsExpired(false)
        }
      }, 1000)
    }
    return () => {
      clearInterval(setTimerInterval)
    }
  }, [chatAcceptTimeLimit])

  return (
    <div
      className={`${(!msg.meetingRequest?.accept)
        ? 'bg-white text-gray-700'
        : (msg.meetingRequest?.accept === Accept.Yes)
          ? 'bg-chat-sent text-primary'
          : 'bg-white text-gray-700'
        } flex flex-col rounded-md px-5 py-2 my-2 max-w-[20rem] text-md border-2`}>

      <div className="flex flex-row justify-start items-center gap-x-3 text-sm">
        {msg.meetingRequest?.accept === Accept.Yes ? <NewIcon icon="VIDEO_CAM_WHITE" size="small" height="small" /> : <NewIcon icon="VIDEO_CAM" size="small" height="small" />}
        {(!isExpired && msg.meetingRequest?.accept !== Accept.Yes && msg.meetingRequest?.accept !== Accept.No) && <div id={`chk_videoRequestGiven-${msg?.messageId}`} className="block">{t('videoRequestSent')}</div>}
        {(msg.meetingRequest?.accept === Accept.Yes) && <div id={`chk_videoRequestGiven-${msg?.messageId}`} className="block">{t('videoRequestAccepted')}</div>}
        {(msg.meetingRequest?.accept === Accept.No) && <div id={`chk_videoRequestGiven-${msg?.messageId}`} className="block">{t('videoRequestDeclined')}</div>}
        {(isExpired && !msg.meetingRequest?.accept) && <div id={`chk_videoRequestGiven-${msg?.messageId}`} className="block">{t('videoRequestExpired')}</div>}
      </div>

      {((msg.meetingRequest?.meeting?.actualStartAt) || (!msg.meetingRequest?.accept && !isExpired)) && <div className='bg-white h-[0.2px] opacity-20 mt-3' />}


      {!msg.meetingRequest?.accept && !isExpired && (
        <div className="flex text-sm">
          {(
            <span id={`chk_meetLimit-${msg?.messageId}`}>
              {t('numberOfFreeMeetingsRemaining')} :
              {typeof chatVideoLimit !== 'undefined' &&
                typeof todayMeetingRequest !== 'undefined' &&
                parseInt(chatVideoLimit) - todayMeetingRequest}{' '}
            </span>
          )}
        </div>
      )}
      {msg.meetingRequest?.accept === Accept.Yes && !isExpired && (
        <div className="flex flex-row mt-3 items-center justify-center text-sm">
          {(
            <Button
              id={`join-${msg?.messageId}`}
              data-testid={`join-${msg.meetingRequest?.id}`}
              size="sm" rounded color="join"
              onClick={() => {
                joinMeeting(msg?.meetingRequest?.meeting?.id)
              }}>
              {t('join')}
            </Button>
          )}
        </div>
      )}
      <VideoMeetingStatus message={msg} />
    </div>
  )
}


function DisplayTextMessage({ msg }: { msg: ChatMessage }) {

  return (
    <div data-testid={msg?.message} className={`bg-chat-sent rounded-md px-3 py-2 my-2 text-white text-sm`}>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a className='underline' target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
        key={msg?.message}>
        {<div id={`sentMsg_${msg?.messageId}`} className="break-all text-md">{msg?.message}</div>}
      </Linkify>
    </div>
  )
}