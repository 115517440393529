import * as React from 'react'
// import { LiveKitConfig } from './CustomLivekitRoom';

type CustomAudioGridContextTypes = {
  stagedParticipants: string[]

  addStagedParticipant: (stagedParticipant: string) => void
  removeStagedParticipant: (stagedParticipant: string) => void
  clearStagedParticipants: () => void
  isStagedParticipant: (stagedParticipant: string) => boolean
  getAllStagedParticipants: () => string[]
}

/** @public */
export const CustomAudioGridContext = React.createContext<CustomAudioGridContextTypes | undefined>(undefined)

/**
 * Ensures that a room is provided via context.
 * If no room is provided, an error is thrown.
 * @public
 */
export function useCustomAudioGridContext() {
  const ctx = React.useContext(CustomAudioGridContext)
  if (!ctx) {
    throw Error('tried to access room context outside of livekit room component')
  }
  return ctx
}

/**
 * Returns the room context if it exists, otherwise undefined.
 * @public
 */
export function useMaybeCustomAudioGridContext() {
  return React.useContext(CustomAudioGridContext)
}

export function CustomAudioGridContextProvider({ children, ...props }: React.PropsWithChildren<{}>) {
  // const ctx = useCustomGridContext();
  const [stagedParticipants, setStagedParticipants] = React.useState<string[]>([])

  const addStagedParticipant = (stagedParticipant: string) => {
    if (stagedParticipant && stagedParticipant?.length > 0) {
      setStagedParticipants([...stagedParticipants, stagedParticipant])
    }
  }

  const removeStagedParticipant = (stagedParticipant: string) => {
    setStagedParticipants(stagedParticipants.filter((p) => p !== stagedParticipant).filter((p) => p !== ''))
  }

  const clearStagedParticipants = () => {
    setStagedParticipants([])
  }

  // TODO remove this code
  const isStagedParticipant = (stagedParticipant: string) => {
    return stagedParticipants.includes(stagedParticipant)
  }

  const getAllStagedParticipants = () => {
    return stagedParticipants
  }

  return (
    <CustomAudioGridContext.Provider
      value={{
        stagedParticipants,
        addStagedParticipant,
        removeStagedParticipant,
        clearStagedParticipants,
        isStagedParticipant,
        getAllStagedParticipants,
      }}
    >
      {children}
    </CustomAudioGridContext.Provider>
  )
}
