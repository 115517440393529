import { ReactComponent as ALERT_HANDSUP } from './icons-2_export_ALERT-HANDSUP-06.svg'
import { ReactComponent as HANGUP } from './icon-2_export-HANGUP-RED.svg'
import { ReactComponent as ABORT } from './icons-2_export-ABORT-23.svg'
import { ReactComponent as ADD } from './icons-2_export-ADD-27.svg'
import { ReactComponent as ALERT } from './icons-2_export-ALERT-06.svg'
import { ReactComponent as ANONYMOUS } from './icons-2_export-ANONYMOUS-33.svg'
import { ReactComponent as ATTACH } from './icons-2_export-ATTACH-21.svg'
import { ReactComponent as AUDIO_HEADSET } from './icons-2_export-AUDIO-HEADSET-61.svg'
import { ReactComponent as CALENDER_BLACK } from './icons-2_export-CALENDER-BLACK.svg'
import { ReactComponent as CALENDER_WHITE } from './icons-2_export-CALENDER-WHITE.svg'
import { ReactComponent as CALL } from './icons-2_export-CALL-60.svg'
import { ReactComponent as CAMERA } from './icons-2_export-CAMERA-66.svg'
import { ReactComponent as CIRCLE } from './icons-2_export-CIRCLE.svg'
import { ReactComponent as CLOCK_BLACK } from './icons-2_export-CLOCK-BLACK.svg'
import { ReactComponent as CLOCK_WHITE } from './icons-2_export-CLOCK-WHITE.svg'
import { ReactComponent as CLOSE } from './icons-2_export-CLOSE-26.svg'
import { ReactComponent as CLOSE_RED } from './icons-2_export-CLOSE-RED.svg'
import { ReactComponent as CURRENCY } from './icons-2_export-CURRENCY-34.svg'
import { ReactComponent as DOWN } from './icons-2_export-DOWN-39.svg'
import { ReactComponent as EDIT } from './icons-2_export-EDIT-09.svg'
import { ReactComponent as EXCHANGE } from './icons-2_export-EXCHANGE-35.svg'
import { ReactComponent as FACEBOOK } from './icons-2_export-FACEBOOK-51.svg'
import { ReactComponent as FAVOURITES } from './icons-2_export-FAVOURITES-22.svg'
import { ReactComponent as FAVOURITES_WHITE } from './icons-2_export-FAVOURITES-WHITE.svg'
import { ReactComponent as FAVOURITES_BLACK } from './icons-2_export-FAVOURITES-BLACK.svg'
import { ReactComponent as FAVOURITES_fill } from './icons-2_export-FAVOURITES-fill-50.svg'
import { ReactComponent as FILTER } from './icons-2_export-FILTER-05.svg'
import { ReactComponent as HANDSUP } from './icons-2_export-HANDSUP-68.svg'
import { ReactComponent as HANDSUP_FILLED } from './icons-2_export-HANDSUP-FILLED.svg'
import { ReactComponent as HD } from './icons-2_export-HD-62.svg'
import { ReactComponent as IMAGE } from './icons-2_export-IMAGE-29.svg'
import { ReactComponent as INFO_BLACK } from './icon-2_export-INFO-BLACK.svg'
import { ReactComponent as INFO_WHITE } from './icon-2_export-INFO-WHITE.svg'
import { ReactComponent as INSTA } from './icons-2_export-INSTA-52.svg'
import { ReactComponent as LANG_A } from './icons-2_export-LANG-A-24.svg'
import { ReactComponent as LANG_B } from './icons-2_export-LANG-B-25.svg'
import { ReactComponent as LEFT } from './icons-2_export-LEFT-36.svg'
import { ReactComponent as LINKEDIN } from './icons-2_export-LINKEDIN-54.svg'
import { ReactComponent as LIVESTREAM } from './icons-2_export-LIVESTREAM-65.svg'
import { ReactComponent as LOCKED } from './icons-2_export-LOCKED-28.svg'
import { ReactComponent as LOGOUT } from './icons-2_export-LOGOUT-13.svg'
import { ReactComponent as MAIL } from './icons-2_export-MAIL-31.svg'
import { ReactComponent as MESSAGES_video } from './icons-2_export-MESSAGES-video-59.svg'
import { ReactComponent as MIC_OFF } from './icons-2_export-MIC-OFF-15.svg'
import { ReactComponent as MIC_OFF_WHITE } from './icons-2_export-MIC-OFF-WHITE.svg'
import { ReactComponent as MIC_OFF_RED } from './icons-2_export-MIC-OFF-RED.svg'
import { ReactComponent as MIC_ON } from './icons-2_export-MIC-ON-14.svg'
import { ReactComponent as MIC_ON_RED } from './icons-2_export-MIC-ON-RED.svg'
import { ReactComponent as MOBILE } from './icons-2_export-MOBILE-20.svg'
import { ReactComponent as MYEVENTS } from './icons-2_export-MYEVENTS-55.svg'
import { ReactComponent as PRINT } from './icon-PRINT.svg'
import { ReactComponent as QUESTION } from './icons-2_export-QUESTION-40.svg'
import { ReactComponent as REMINDER } from './icons-2_export-REMINDER-10.svg'
import { ReactComponent as REMINDER_FILLED } from './icon-2_export-REMINDER-FILLED.svg'
import { ReactComponent as RIGHT } from './icons-2_export-RIGHT-37.svg'
import { ReactComponent as SCREEN } from './icons-2_export-SCREEN-19.svg'
import { ReactComponent as SCREENSHARE } from './icons-2_export-SCREENSHARE-63.svg'
import { ReactComponent as SEARCH } from './icons-2_export-SEARCH-57.svg'
import { ReactComponent as SEND } from './icons-2_export-SEND-08.svg'
import { ReactComponent as SETTINGS } from './icons-2_export-SETTINGS-12.svg'
import { ReactComponent as SHARE } from './icons-2_export-SHARE-07.svg'
import { ReactComponent as SORTING } from './icons-2_export-SORTING-41.svg'
import { ReactComponent as SOUND_MUTE } from './icons-2_export-SOUND-MUTE-01.svg'
import { ReactComponent as SOUND_ON_BLACK } from './icons-2_export-SOUND-ON-BLACK.svg'
import { ReactComponent as SOUND_ON_WHITE } from './icons-2_export-SOUND-ON-WHITE.svg'
import { ReactComponent as TICK } from './icons-2_export-TICK-32.svg'
import { ReactComponent as TICK_GREEN } from './icons-2_export-TICK-GREEN.svg'
import { ReactComponent as TRASH_DELETE } from './icons-2_export-TRASH-DELETE-17.svg'
import { ReactComponent as TWITTER } from './icons-2_export-TWITTER-53.svg'
import { ReactComponent as UP } from './icons-2_export-UP-38.svg'
import { ReactComponent as UPLOAD } from './icons-2_export-UPLOAD-30.svg'
import { ReactComponent as USER_BLACK } from './icons-2_export-USER-BLACK.svg'
import { ReactComponent as USER_WHITE } from './icons-2_export-USER-WHITE.svg'
import { ReactComponent as USERS } from './icons-2_export-USERS-04.svg'
import { ReactComponent as VIDEO_BLACK } from './icons-2_export-VIDEO-BLACK.svg'
import { ReactComponent as VIDEO_WHITE } from './icons-2_export-VIDEO-WHITE.svg'
import { ReactComponent as VIDEO_MUTED } from './icons-2_export-VIDEO-MUTED.svg'
import { ReactComponent as VIDEO_MUTED_RED } from './video-muted-red.svg'
import { ReactComponent as VIEW } from './icons-2_export-VIEW-56.svg'
import { ReactComponent as VIEW_SLASH } from './icons-2_export-VIEW-SLASH.svg'
import { ReactComponent as CREATOR } from './icons-2_export-CREATOR-42.svg'
import { ReactComponent as COLLABORATORS } from './icons-2_export-COLLABORATORS-43.svg'
import { ReactComponent as EVENTS } from './icons-2_export-EVENTS-44.svg'
import { ReactComponent as MESSAGES } from './icons-2_export-MESSAGES-45.svg'
import { ReactComponent as MESSAGES_UNREAD } from './icon-2_export-UNREAD-MESSAGE.svg'
import { ReactComponent as PIN } from './pin.svg'
import { ReactComponent as PIN_WHITE } from './pin-white.svg'
import { ReactComponent as UNPIN } from './unpin.svg'
import { ReactComponent as UNPIN_WHITE } from './unpin-white.svg'
import { ReactComponent as FULLSCREEN } from './fullscreen-black.svg'
import { ReactComponent as FULLSCREEN_WHITE } from './fullscreen-white.svg'
import { ReactComponent as EXIT_FULLSCREEN } from './exit-fullscreen-black.svg'
import { ReactComponent as EXIT_FULLSCREEN_WHITE } from './exit-fullscreen-white.svg'
import { ReactComponent as HOUR_GLASS } from './hourglass.svg'
import { ReactComponent as CONNECTION_POOR } from './connection-quality-1.svg'
import { ReactComponent as CONNECTION_GOOD } from './connection-quality-2.svg'
import { ReactComponent as CONNECTION_EXCELLENT } from './connection-quality-3.svg'
import { ReactComponent as USERS_GROUP } from './users-group.svg'
import { ReactComponent as facebook } from './facebook.svg'
import { ReactComponent as twitter } from './twitter.svg'
import { ReactComponent as linkedIn } from './linkedIn.svg'
import { ReactComponent as instagram } from './instagram.svg'
import { ReactComponent as RATING_STAR } from './beemg-rating-icon.svg'
import { ReactComponent as TICK_GREEN_STROKE } from './icon-2_export-TICK-GREEN-STROKE.svg'
import { ReactComponent as REPLY} from './Reply.svg'
import { ReactComponent as GOBACK} from './beemg-icons-goback-2023-65.svg'
import { ReactComponent as UP_ARROW} from './up-arrow.svg'
import { ReactComponent as SPEAKER} from './Speaker.svg'
import { ReactComponent as EARPIECE} from './Earpiece.svg'
import { ReactComponent as HEADPHONE} from './Headphone.svg'
import { ReactComponent as BLUETOOTH} from './Bluetooth.svg'
import { ReactComponent as CHECKMARK} from './CheckMark.svg'
import { ReactComponent as GRIDICON} from './GridIcon.svg'
import { ReactComponent as HOSTVIEW} from './HostView.svg'
import { ReactComponent as SCREENSHAREON} from './ScreenshareOn.svg'
import { ReactComponent as SCREENSHAREOFF} from './ScreenshareOff.svg'

export const icons = {
  ALERT_HANDSUP,
  ABORT,
  ADD,
  ALERT,
  ANONYMOUS,
  ATTACH,
  AUDIO_HEADSET,
  CALENDER_WHITE,
  CALENDER_BLACK,
  CALL,
  CAMERA,
  CIRCLE,
  CLOCK_BLACK,
  CLOCK_WHITE,
  CLOSE,
  CLOSE_RED,
  COLLABORATORS,
  CONNECTION_POOR,
  CONNECTION_GOOD,
  CONNECTION_EXCELLENT,
  CREATOR,
  CURRENCY,
  DOWN,
  EDIT,
  EVENTS,
  EXCHANGE,
  FACEBOOK,
  FAVOURITES,
  FAVOURITES_BLACK,
  FAVOURITES_WHITE,
  FAVOURITES_fill,
  FILTER,
  FULLSCREEN,
  FULLSCREEN_WHITE,
  HANGUP,
  HANDSUP,
  HANDSUP_FILLED,
  HD,
  IMAGE,
  INFO_BLACK,
  INFO_WHITE,
  INSTA,
  LANG_A,
  LANG_B,
  LEFT,
  LINKEDIN,
  LIVESTREAM,
  LOCKED,
  LOGOUT,
  MAIL,
  MESSAGES,
  MESSAGES_UNREAD,
  MESSAGES_video,
  MIC_OFF,
  MIC_OFF_RED,
  MIC_OFF_WHITE,
  MIC_ON,
  MIC_ON_RED,
  MOBILE,
  MYEVENTS,
  PRINT,
  PIN,
  PIN_WHITE,
  UNPIN,
  UNPIN_WHITE,
  QUESTION,
  REMINDER,
  REMINDER_FILLED,
  RIGHT,
  SCREEN,
  SCREENSHARE,
  SEARCH,
  SEND,
  SETTINGS,
  SHARE,
  SORTING,
  SOUND_MUTE,
  SOUND_ON_BLACK,
  SOUND_ON_WHITE,
  TICK,
  TICK_GREEN,
  TRASH_DELETE,
  TWITTER,
  UP,
  UPLOAD,
  USER_BLACK,
  USER_WHITE,
  USERS,
  USERS_GROUP,
  VIDEO_BLACK,
  VIDEO_WHITE,
  VIDEO_MUTED,
  VIEW,
  VIEW_SLASH,
  EXIT_FULLSCREEN,
  EXIT_FULLSCREEN_WHITE,
  VIDEO_MUTED_RED,
  HOUR_GLASS,
  facebook,
  twitter,
  linkedIn,
  instagram,
  RATING_STAR,
  TICK_GREEN_STROKE,
  REPLY,
  GOBACK,
  UP_ARROW,
  SPEAKER, 
  EARPIECE,
  HEADPHONE,
  BLUETOOTH,
  CHECKMARK,
  GRIDICON,
  HOSTVIEW,
  SCREENSHAREON,
  SCREENSHAREOFF

}
