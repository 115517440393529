import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../Components/base/button/button'
import Field from '../../Components/base/field/field'
import { toastSuccess } from '../../Components/toast'
import { useCreateRazorpayAccountMutation } from '../../Services/ledgerApi'
import { userApi } from '../../Services/userApi'
import { useAppDispatch } from '../../Store/hooks'
import { razorPayAccountDetailsValidationSchema } from '../../Utils/validation'
import razorPayLogo from '../../Assets/Images/Razorpay_logo.svg'

interface RazorpayForm {
  ifsc_code: string
  account_number: string
  beneficiary_name: string
  account_name: string
  business_name: string
  [x: string]: any;
}

function ActivateRazorpayAccount() {
  const defaultValues = {
    ifsc_code: '',
    account_number: '',
    beneficiary_name: '',
    account_name: '',
    business_name: '',
  }

  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(razorPayAccountDetailsValidationSchema)
  });

  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const [send] = useCreateRazorpayAccountMutation<any>()

  const onSubmit = (values: RazorpayForm | any) => {
    send(values).then((res: any) => {
      reset(defaultValues);
      appDispatch(userApi.endpoints.getCurrentUserDetails.initiate());
      toastSuccess(t('registeredSuccessfully'));
      navigate('/s/settings');
    })
  }

  return (
    <>
      <div className="container mx-auto px-4">
        <h1 className="text-center text-2xl font-bold">{t('registration')}</h1>
        <div className="mt-16 mb-3 lg:mx-40 sm:mx-2 ">
          <span className='flex justify-center text-sky-400 mt-2.5'>{t('registerFor')}</span>
          <img className="h-12 w-40 mx-auto" src={razorPayLogo} alt=""></img>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex justify-center'>
              <div className="flex flex-col mt-4 w-2/4 gap-1">
                {/* Account name */}
                <Field className='text-center'
                  {...register('account_name')}
                  error={errors?.account_name?.message}
                  name="account_name"
                  type="text"
                  data-testid="account_name"
                  placeholder={t('accountName') + '*'}
                />
                {/* Business name */}
                <Field className='text-center'
                  {...register('business_name')}
                  error={errors?.business_name?.message}
                  name="business_name"
                  type="text"
                  data-testid="business_name"
                  placeholder={t('businessName') + '*'}
                />
                {/* Beneficiary name */}
                <Field className='text-center'
                  {...register('beneficiary_name')}
                  error={errors?.beneficiary_name?.message}
                  name="beneficiary_name"
                  type="text"
                  data-testid="beneficiary_name"
                  placeholder={t('beneficiaryName') + '*'}
                />
                {/* IFSC Code */}
                <div className='flex flex-row gap-1'>
                  <div className='w-72'>
                    <Field className='text-center'
                      {...register('ifsc_code')}
                      error={errors?.ifsc_code?.message}
                      name="ifsc_code"
                      type="text"
                      data-testid="ifsc_code"
                      placeholder={t('IFSC') + '*'}
                    />
                  </div>
                  {/* Account Number */}
                  <div className='w-full'>
                    <Field className='text-center'
                      {...register('account_number')}
                      error={errors?.account_number?.message}
                      name="account_number"
                      type="text"
                      data-testid="account_number"
                      placeholder={t('accountNumber') + '*'}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-12">
              <Button className='w-64' color="save" submit>
                {t('save')}
              </Button>
            </div>
          </form>
        </div>
        {/* save button */}
      </div>
    </>
  )
}
export default ActivateRazorpayAccount
