import { getCurrentUserDetail } from './../Services/userReducer';
import { useSelector } from 'react-redux';
import { useCreateFeedbackMutation, useGetUserFeedbackByMeetingIdQuery } from '../Services/feedbackApi';
import { useGetMeetingQuery } from '../Services/meetingApi';
import { useState } from 'react';
import { Feedback } from '../models/feedback.model';
import { useForm } from 'react-hook-form';
import { feedbackValidationSchema } from '../Utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { MeetingType } from '../models/meeting.model';

export interface InitialFeedback {
  name: string;
  rating: number;
  review: string;
  email: string;
}

export const useMeetingFeedBack = ({ meetingId }: { meetingId: string }) => {

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<InitialFeedback>({
    mode: "onChange",
    resolver: yupResolver(feedbackValidationSchema)
  });

  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false)
  const [serverError, setServerError] = useState<string>('')

  const currentUserDetail = useSelector(getCurrentUserDetail);

  const { data: meeting, isLoading: isMeetingLoading } = useGetMeetingQuery(meetingId, { skip: !meetingId })
  const { data: alreadyGiven, isLoading: checkingIfAlreadyGiven, isSuccess: checkAlreadyGivenSuccess } = useGetUserFeedbackByMeetingIdQuery(meetingId, { skip: !meetingId })
  const [createFeedback, { isSuccess: feedbackSuccess }] = useCreateFeedbackMutation()
  const meetingHostedBy = meeting && meeting.type === MeetingType.Type2 ? `${meeting.user2.firstName} ${meeting.user2.lastName}` : meeting?.display_hostName;

  function submitFeedback(values: any) {

    const feedback: Partial<Feedback> = {
      guest: meeting?.type === MeetingType.Type2 ? meeting?.user2 : meeting?.user,
      name: values.name,
      review: values.review,
      rating: values.rating,
      meeting: meeting,
      meetingDetails: meeting?.meetingDetails
    }

    createFeedback(feedback).then((result: any) => {
      if (result?.error) {
        setSubmitSuccess(false)
        setServerError(result?.error?.data?.message)
      }
      else {
        setSubmitSuccess(true)
      }
    })
  }

  return {
    feedbackSuccess,
    alreadyGiven,
    checkAlreadyGivenSuccess,
    checkingIfAlreadyGiven,
    currentUserDetail,
    meeting,
    submitSuccess,
    submitFeedback,
    register,
    control,
    handleSubmit,
    getValues,
    serverError,
    errors,
    isDirty,
    isValid,
    isMeetingLoading,
    setValue,
    meetingHostedBy
  }
}