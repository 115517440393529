import { Invite } from './../models/invite.model';
import { formatDateToTimezone } from '../Utils/datetime';
import { allApis } from './allApis';

export type InviteMultipleResponse = {
    inviteEmail : string,
    success : boolean,
    error : string
}

export const inviteApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({

        createInvite: builder.mutation<Invite, Partial<Invite>>({
            query: (invite) => {
                return {
                    url: `invite`,
                    method: 'POST',
                    body: invite
                };
            },
            invalidatesTags: ['InviteList']
        }),

        createMultipleInvites: builder.mutation<InviteMultipleResponse[], string[]>({
            query: (emails) => {
                return {
                    url: `invite/multiple`,
                    method: 'POST',
                    body: emails
                };
            },
            invalidatesTags: ['InviteList']
        }),

        getInviteByUser: builder.query<Invite[], void>({
            query: () => `invite`,
            transformResponse: (response: Invite[]) => {
                const res = response.map<Invite>(invite => {
                    invite.display_createdAt = formatDateToTimezone(invite.createdAt.toString());
                    if (invite.updatedAt) {
                        invite.display_updatedAt = formatDateToTimezone(invite.updatedAt.toString());
                    }
                    return invite;
                });
                return res;
            },
            providesTags: ['InviteList']
        }),

        getInviteByToken: builder.query<Invite, string>({
            query: (token) => `invite/token/${token}`,
        }),

        resendInviteEmail: builder.mutation<Invite, any>({
            query: (inviteEmail) => {
                return {
                    url: `invite/resendMail/${inviteEmail}`,
                    method: 'POST'
                };
            },
            invalidatesTags: ['InviteList']
        }),

        deleteInvite: builder.mutation<boolean, string>({
            query: (inviteId) => {
                return {
                    url: `invite/${inviteId}`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: ['InviteList']
        })
    })
});

export const {
    useCreateInviteMutation, useResendInviteEmailMutation, useGetInviteByUserQuery, useDeleteInviteMutation, useGetInviteByTokenQuery, useCreateMultipleInvitesMutation
} = inviteApi;
