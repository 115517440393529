import { useState } from 'react'
import { Link } from 'react-router-dom'
import placeHolder from '../../../Assets/Images/beemg-icons-creator-camera-2023.svg'
import useCheckOnlineHook from '../../../Hooks/CheckOnlineHook'
import { useGetSettingValue } from '../../../Services/settingReducer'

interface Props {
  bgUrl: string
  name: string
  userId: string
  match: number
  cardTitle: string
  keyId: number
  id?: string
  catchPhrase?: string
}

export const CollaboratorsZoomCard = ({ bgUrl, name, match, userId, cardTitle, keyId, id, catchPhrase }: Props) => {
  const imageURL = useGetSettingValue('IMAGE_URL')

  const [isImageError, setIsImageError] = useState(false)

  const { isOnline } = useCheckOnlineHook(id ?? '')

  return ( 
    <div id={`cards-${cardTitle.split(" ").join("")}`} data-testid="zoomcard">
      <Link data-testid={`link_viewProfile${userId}`} id={`viewProfile${userId}`} to={`/s/viewProfile/${userId}`}>
        <div className="relative h-40 w-40  bg-center  bg-gray-200 rounded-full flex justify-center items-center">
          { isOnline && <div className='absolute rounded-full left-3 top-3 z-50 bg-white p-1' >
            <div id={`onlineStatus_${userId}`} className='bg-chat-accepted h-4 w-4 rounded-full' />
          </div>}
          {bgUrl ? (
            <img
              className={`transform duration-200 bg-cover rounded-full ${isImageError ? "h-28 w-28 px-6" : "h-40 w-40"}`}
              src={`${imageURL}/${bgUrl}`}
              id={`img-${cardTitle.split(" ").join("")}${keyId}`}
              data-testid="input_img"
              alt=""
              onError={(e) => {
                (e.target as any).src = placeHolder
                setIsImageError(true);
              }}
            />
          ) : (
            <div
              className="transform duration-200 h-28 w-28 px-6 rounded-full bg-40 bg-no-repeat bg-center"
              style={{ backgroundImage: `url(${placeHolder})` }}
              id={`img-${cardTitle.split(" ").join("")}${keyId}`}
            />
          )}
        </div>
        <div className="text-black text-lg font-medium flex flex-col leading-none w-40 justify-center items-center mt-2">
          <div className="flex items-center justify-center" id={`chk_name-${cardTitle.split(" ").join("")}${keyId}`} data-testid={`chk_name-${cardTitle.split(" ").join("")}${keyId}`}>
            <div className='truncate text-ellipsis text-lg font-normal capitalize'>
              {name.length > 8 ? name.substring(0, 8) + '...' : name}
            </div>
            <div className='ml-auto'>
              {match && <span className="text-sm font-normal p-1" id={`chk_match-${cardTitle.split(" ").join("")}${keyId}`} data-testid="input_match">({match}%)</span>}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
export default CollaboratorsZoomCard
