import type { CaptureOptionsBySource, ToggleSource } from '@livekit/components-core'
import * as React from 'react'
import { useTrackToggle } from '@livekit/components-react'
import { Track } from 'livekit-client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons'

/** @public */
export interface TrackToggleProps<T extends ToggleSource>
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onChange'> {
  source: T
  showIcon?: boolean
  initialState?: boolean
  /**
   * Function that is called when the enabled state of the toggle changes.
   * The second function argument `isUserInitiated` is `true` if the change was initiated by a user interaction, such as a click.
   */
  onChange?: (enabled: boolean) => void
  captureOptions?: CaptureOptionsBySource<T>
}

/**
 * With the `TrackToggle` component it is possible to mute and unmute your camera and microphone.
 * The component uses an html button element under the hood so you can treat it like a button.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <TrackToggle source={Track.Source.Microphone} />
 *   <TrackToggle source={Track.Source.Camera} />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function getSourceIcon(source: Track.Source, enabled: boolean) {
  switch (source) {
    case Track.Source.Microphone:
      return enabled ? (
        <FontAwesomeIcon icon={faMicrophone} id="btn_microphone" />
      ) : (
        <FontAwesomeIcon icon={faMicrophoneSlash} id="btn_microphone-slash" />
      )
    case Track.Source.Camera:
      return enabled ? (
        <FontAwesomeIcon icon={faVideo} id="btn_video" />
      ) : (
        <FontAwesomeIcon icon={faVideoSlash} id="btn_video-slash" />
      )
    default:
      return undefined
  }
}

export function PreJoinTrackToggle<T extends ToggleSource>({ showIcon, ...props }: TrackToggleProps<T>) {
  const { buttonProps, enabled } = useTrackToggle(props)
  buttonProps.className = props.className ?? buttonProps.className
  return (
    <button {...buttonProps}>
      {(showIcon ?? true) && getSourceIcon(props.source, enabled)}
      {props.children}
    </button>
  )
}
