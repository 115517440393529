import { useTranslation } from 'react-i18next';
import useCheckOnlineHook from '../Hooks/CheckOnlineHook';

interface _Props {
  id: string | undefined
  className?: string
}

function OnlineStatusText(props: _Props) {
  const { id, className } = props;
  const { t } = useTranslation()
  const { isOnline } = useCheckOnlineHook(id ?? "");

  return (
    <div className='leading-none'>{isOnline !== null && <div id={`onlineStatus${id}`} className={` ${isOnline ? 'text-chat-accepted' : 'text-chat-declined'} ${className ?? 'text-xxs'}`}>{isOnline ? t('online') : t('offline')}</div>}</div>
  )
}

export default OnlineStatusText