/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Icon from '../../Components/base/icon/icon'
import { Button } from '../../Components'
import { useTermsAndConditionsHooks } from '../../Hooks/TermsAndConditionsHook'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import Footer from '../Footer'
import { PageTitle } from '../../Components/pageTitle'
import { useAppSelector } from '../../Store/hooks'
import { getCurrentUser } from '../../Services/userReducer'
import { getSignUpToken, changeTermsAndCondition, getIsSignUpTermsAccepted } from '../../Services/signUpReducer'
import { useDispatch } from 'react-redux'
import { useUpdateUserTermsPolicyMutation } from '../../Services/userPolicyAgreementsApi'
import { useEffect, useState } from 'react'
import { toastSuccess } from '../../Components/toast'
import Header from '../Header'

function TermsAndConditions() {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useAppSelector(getSignUpToken);
  const currentUser = useAppSelector(getCurrentUser);

  const [showPrintIcon, setShowPrintIcon] = useState<boolean>(true);

  const { policies, navigateScreen, cancel, policyAccepted } = useTermsAndConditionsHooks()
  const [agreeTermsAndPolicies, { isSuccess: userPolicyAgreed, isLoading: userPolicyLoading }] = useUpdateUserTermsPolicyMutation()
  const termsAndConditionStatus = useAppSelector(getIsSignUpTermsAccepted)
  const [toasterView, setToasterView] = useState(false)

  if (navigateScreen === "NAVIGATE_TO_LOGIN") {
    navigate('/')
  }
  
  const printPageArea = () => {
  setShowPrintIcon(false)  
  }

  useEffect(()=>{
    if(!showPrintIcon){
    window.print()
   
    window.addEventListener('afterprint', () => {
      setShowPrintIcon(true);
    });
    }
  },[showPrintIcon])

  
  useEffect(()=>{
    if(termsAndConditionStatus && toasterView){
      toastSuccess(t('termsAndConditionsAcceptedSuccessfully'))
    }
  },[termsAndConditionStatus, toasterView])


  useEffect(() => {
    if (userPolicyAgreed) {
      dispatch(changeTermsAndCondition(true))
      toastSuccess(t('termsAndConditionsUpdatedSuccessfully'))
      navigate('/s/home')
    }
  }, [userPolicyAgreed])

  return (
    <div className="flex flex-col h-screen">
      {!currentUser && <Header />}
      <div className="flex-grow">
        <PageTitle title={t('termsAndConditions')}>
          <hr className="my-10 mx-auto block w-4/5" />
          <div id="printableArea" className="text-center">
            <div className="flex-row mx-[10%] justify-content">
              {showPrintIcon && (
                <div className="flex float-right justify-end" data-testid="printableArea" onClick={printPageArea}>
                  <ToolTip tip="print" keyId="print">
                    <Icon icon="PRINT" size="medium" height="large" />
                  </ToolTip>
                </div>
              )}
              {policies?.policyName} - {policies?.policyVersion}
              <span
                dangerouslySetInnerHTML={{
                  __html: policies ? policies?.description : '',
                }}
              ></span>
            </div>
          </div>
          <div className="flex">
            <span className="flex float-right justify-end mx-10 mt-5">
              {((!currentUser && policyAccepted) || token) && (
                <>
                  <Button
                    data-testid="btn_accept"
                    id="btn_acceptTerms"
                    disabled={userPolicyLoading}
                    onClick={() => {
                      if (currentUser) {
                        agreeTermsAndPolicies(currentUser);
                      } else {
                        dispatch(changeTermsAndCondition(true));
                        setToasterView(true);
                      }
                    }}
                  >
                    {t('accept')}
                  </Button>
                  <Button
                    data-testid="btn_cancel"
                    id="btn_cancelTerms"
                    onClick={cancel}
                  >
                    {t('cancel')}
                  </Button>
                </>
              )}
            </span>
          </div>
        </PageTitle>
      </div>
      {!currentUser && <Footer />}
    </div>
  );
}
export default TermsAndConditions
