import Icon from "../../Components/base/icon/icon";
import DisplayName from "../../Components/base/user-name/DisplayName";
import { User } from "../../models/user.model";

const UserNameAndShare = ({ user, checkVerified }: { user: User, checkVerified?: 'VERIFIED' | 'NOTVERIFIED' | undefined }) => {

  //  TODO why not showing name based on anonymous selection of host
  //  TODO share not in design, needs confirmation
  return (
    <div id="hostName" data-testid="hostName" className='flex flex-row gap-x-2 items-center font-semibold text-2xl leading-none capitalize'>

      <DisplayName user={user} hostAnonymous={false} />
      {checkVerified === 'VERIFIED' && <Icon icon="TICK_GREEN_STROKE" size="medium"/>}

      {/* <ToolTip tip='share' keyId="Share">
            <Share shareData={hostData?.firstName} sharingType="shareHostProfile" userId={hostData?.id} />
          </ToolTip> */}
    </div>
  )
}

export default UserNameAndShare;
