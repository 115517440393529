import { useEffect, useState } from "react"
import { RefundType } from "../models/cancellationRequest.model"
import { MeetingParticipant } from "../models/meetingParticipant.model"
import { useCheckCancellationRequestQuery, useCreateCancellationMutation } from "../Services/cancellationRequestApi"
import { useGetAllInvitedGuestsQuery } from "../Services/meetingParticipantApi"
import { Processed } from "../models/cancellationRequest.model"
import { useForm } from "react-hook-form"
import { cancellationFormValidationSchema } from "../Utils/validation"
import { yupResolver } from "@hookform/resolvers/yup"


type Props = {
  meeting: any,
  closeModal: (e: any) => void
}
export const useAttendingPartialCancellationHooks = ({ meeting, closeModal }: Props) => {

    const { data: inviteData, isSuccess: inviteSuccess } = useGetAllInvitedGuestsQuery(meeting?.meetingParticipant[0]?.transaction?.id)
    const { data: partialCancellationData, isSuccess: partialCancellationSuccess} = useCheckCancellationRequestQuery(meeting.id ?? '', { skip: !meeting.id })
    
    const [allInviteData, setAllInviteData] = useState<MeetingParticipant[]>([])
    const [selectedGuests, setSelectedGuests] = useState<MeetingParticipant[]>([])
    const [invitedGuest, setinvitedGuest] = useState<any>()
    const [createCancellation, { isSuccess: createPartialCancellationSuccess, isLoading: createPartialCancellationLoading }] = useCreateCancellationMutation()

    const { control, register, getValues,trigger,  formState: { errors, isValid } } = useForm<any>({ mode: 'onChange', resolver: yupResolver(cancellationFormValidationSchema) });
   
    useEffect(() => {
      if (inviteData) {
          setAllInviteData([...inviteData, meeting?.meetingParticipant[0]])
          inviteData.forEach(invite => {
              if (invite.cancelationRequest) {
                  setSelectedGuests([...selectedGuests, invite])
              }
          })
      }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteData, meeting])

    const handleSave = (e: any) => {

      // TODO: Optimize this API calls
      Promise.all(selectedGuests.map(async (meetingParticipant: MeetingParticipant) => {
          const args = { meeting, meetingParticipant, refundType: RefundType.Partial, cancellationReason: getValues('reason') }
          await createCancellation(args);
      })).then(() => {
          closeModal(e)
      })
  }
    const handleInvitedGuests = (id: string) => {
      setinvitedGuest(inviteData)
    }
    useEffect(() => {
      if (inviteData) {
        setinvitedGuest(inviteData)
      }
    }, [inviteData])

  const setChecked = (participant: MeetingParticipant) => {
      const contains = selectedGuests.find(guest => guest.id === participant.id)
      if (!contains) {
          setSelectedGuests([...selectedGuests, participant])
      } else {
          setSelectedGuests(selectedGuests.filter(guest => guest.id !== participant.id))
      }
  }
    return {
      allInviteData,
      setAllInviteData, 
      selectedGuests,
      setSelectedGuests,
      inviteData,
      inviteSuccess,
      createCancellation,
      setChecked,
      handleSave,
      register, errors,
      control,
      isValid,
      trigger,
      partialCancellationData,
      handleInvitedGuests,
      invitedGuest,
      partialCancellationSuccess,
      createPartialCancellationSuccess,
      createPartialCancellationLoading
    }
}

export const usePartialCancellationHook = ({inviteGuest}:{inviteGuest: MeetingParticipant}) => {
  
  const [partialCancel, setPartialCancel] = useState<'CANCELLED' | 'REQUESTED'>()
  useEffect(() => {
    if (inviteGuest?.cancelationRequest) {
      setPartialCancel('REQUESTED')
    }
    if (inviteGuest?.cancelationRequest?.processed === Processed.Yes ) {
      setPartialCancel('CANCELLED')
    }
  },[inviteGuest]);

  return {
    partialCancel
  }
}