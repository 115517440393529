import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { AccordionBorder, AccordionItem, AccordionPanel } from "../../Components/base/accordion/accordion"
import { useTransactionCheckHooks } from "../../Hooks/TransactionHook"
import { Ledger, TransactionType } from "../../models/ledger.model"
import CardImagePlaceHolder from "../EventsCard/CardImagePlaceHolder"
import CardTitle from "../EventsCard/CardTitle"
import { EventCard } from "../EventsCard/EventCard"
import InvitedGuests from "../EventsCard/InvitedGuest"
import DisplayTransactionDate from "./DisplayTransactionDate"
import DisplayTransactionDescription from "./DisplayTransactionDescription"
import DisplayTransactionStatus from "./DisplayTransactionStatus"
import StatusRed from "./StatusRed"
import TransactionAmount from "./TransactionAmount"
import { DisplayFormattedDate, DisplayFormattedTime } from "../../Components/formattedDateAndTime"
import { useInviteGuestHook } from "../../Hooks/InviteGuestHook"
import { Status } from "../../models/commonEntity.model"


export default function DebitedCard({ transaction }: { transaction: Ledger }) {
  const { t } = useTranslation()
  const { transactionType } = useParams()
  const { invitedguest, viewGuest, updateGuestLists } = useInviteGuestHook()
  const { invitePaidGuest, viewGuests } = useTransactionCheckHooks(transaction, invitedguest)


  if (transactionType === 'credit') {
    return null
  }

  function DebitedCardView() {
    return (
      <AccordionPanel id={`panel-${transaction.id}`} >
        <div className="w-full">
          <hr />
          <div className="flex flex-col w-full mx-auto items-center justify-between text-sm md:text-base">
            <div className={`flex flex-row text-black w-full justify-between items-center text-normal`}>
              <DisplayTransactionStatus transaction={transaction}></DisplayTransactionStatus>
              <div className="flex flex-col items-end pr-4 capitalize">
                <DisplayTransactionDescription transaction={transaction} cardType={TransactionType.Debit} />
                {viewGuests === 'HIDE' &&
                  <span data-testid={`btn_paidGuestCount-${transaction.id}`} className="cursor-pointer underline underline-offset-2">
                    {t('paidFor')} {invitedguest?.length > 0 ? invitedguest.length : transaction.guestCount - 1} {' '} {t('guest')}
                  </span>
                }
                {viewGuests === 'VIEW' && (
                  <span
                    data-testid={`btn_viewGuest-${transaction.id}`} onClick={() => updateGuestLists(transaction?.id)}
                    id={`btn_viewGuest-${transaction.id}`}
                    className="cursor-pointer underline underline-offset-2"
                  >
                    {t('paidFor')} {transaction.guestCount - 1} {' '}
                    {t('guest')} - <span className='underline '>{t('viewGuest')}</span>
                  </span>
                )}
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-4 text-black text-normal">
              {invitePaidGuest && (transaction.meeting.status == Status.Active) && (
                <span className="flex justify-center rounded-lg text-sm mx-8 mb-3 underline">
                  <span id={'btn_inviteguest'} data-testid={'btn_inviteguest'} className="bg-yellow-100 text-yellow-900 rounded-full p-1 mt-3 px-2 mx-auto text-center">
                    <Link to={`/s/payment/${transaction?.transactionId}`}>{t('inviteYourFriendsForThisEvent')}</Link>
                  </span>
                </span>
              )}
              {viewGuest && invitedguest?.length !== 0 && <InvitedGuests guests={invitedguest} transactionId={transaction?.id} editableOption={(transaction?.cancellation || transaction.meeting.status == Status.InActive) ? false : true}/>}
            </div>
          </div>
        </div>
      </AccordionPanel>
    )
  }

  return (
    <AccordionBorder
      keyId={transaction.id}
      id={transaction.id}
      key={transaction.id}>
      <AccordionItem
        keyId={transaction.id}
        toggle={`panel-${transaction.id}`}
        color="card">
        <EventCard keyId={transaction.id}>
          <EventCard.Image>
            <CardImagePlaceHolder
              images={transaction?.meeting?.meetingDetails?.image}
            />
          </EventCard.Image>
          <EventCard.Title>
            <CardTitle
              keyId={transaction.id}
              meetingTitle={
                transaction?.meeting?.meetingDetails?.title &&
                transaction?.meeting?.meetingDetails?.title.length > 40
                  ? `${transaction?.meeting?.meetingDetails?.title.slice(
                      0,
                      40,
                    )}...`
                  : transaction?.meeting?.meetingDetails?.title
              }
            />
          </EventCard.Title>
          <EventCard.SubTitle>
            <div className="font-normal">
              {t('eventOn')}{' '}
              <DisplayFormattedDate
                displayDate={transaction.display_meetingDate}
              />{' '}
              -{' '}
              <DisplayFormattedTime
                displayFromTime={transaction.meeting.fromDateTime}
                displayToTime={transaction.meeting.toDateTime}
              />
            </div>
          </EventCard.SubTitle>
          <EventCard.SubTitle1>
            <DisplayTransactionDate transaction={transaction} />
          </EventCard.SubTitle1>
          <EventCard.Actions>
            <div className="flex md:flex-col text-end items-end gap-3 md:gap-0">
              <StatusRed>
                <span className="flex">
                  <TransactionAmount transaction={transaction} symbol={'-'} />
                </span>
              </StatusRed>
            </div>
          </EventCard.Actions>
        </EventCard>
      </AccordionItem>
      <DebitedCardView />
    </AccordionBorder>
  )
}
