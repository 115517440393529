import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../Store'

const initialStateValue = {
  isConnected: false,
  isSocketLogined: false,
}

export const socketSlice = createSlice({
  name: 'socket',
  initialState: initialStateValue,
  reducers: {
    setSocketConnected: (state, action) => {
      if (action.payload && !state.isConnected) {
        state.isConnected = action.payload
      } else if (!action.payload && state.isConnected) {
        state.isConnected = false
        state.isSocketLogined = false
      }
    },
    setSocketLogined: (state, action) => {
      state.isSocketLogined = action.payload
    },
  },
})

export const isSocketConnected = (state: RootState) => state.socket?.isConnected
export const isSocketLogined = (state: RootState) => state.socket?.isSocketLogined
export const { setSocketConnected, setSocketLogined } = socketSlice.actions

export default socketSlice.reducer
