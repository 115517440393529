import { MouseEvent } from 'react'
import Icon from './base/icon/icon'

interface ChipProps {
  children?: React.ReactNode
  value: string
  isSelected?: boolean
  onClick?: (value: string) => void
  onClickClose?: (value: string) => void
  addCatId?: string
  removeCatId?: string
  className?: string
}

export default function Chip(props: ChipProps) {
  const { value, isSelected = false, onClick, onClickClose, addCatId, removeCatId, className, children } = props

  //Removing the Continuous white space from the string
  const trimmingSpace = value.trimStart()
  const chipStyle = className  ?? `${isSelected ? 'bg-primary' : 'bg-gray-200'} ${onClick ? 'cursor-pointer' : 'cursor-auto'}`

  const handleClose = (e: MouseEvent) => {
    e.preventDefault()
    onClickClose?.(value);
    return
  }

  const handleClick = (e: MouseEvent) => {
    e.preventDefault()
    onClick?.(value);
    return
  }

  return (
    <>
      {trimmingSpace.length > 0 && (
        <div
          onClick={handleClick}
          className={`px-3 pr-10 py-1 text-sm rounded-sm flex flex-row items-center justify-between relative ${chipStyle} `}>
          <div
            id={`${isSelected ? `${value.split(' ')[0]}` : `${addCatId}`}`}
            data-testid={`${
              isSelected ? `${value.split(' ')[0]}` : `${addCatId}`
            }`}>
            {children}
            {!children && <>{value}</>}
          </div>
          {isSelected ? (
            <div
              className="absolute right-4 cursor-pointer"
              id={removeCatId}
              data-testid={removeCatId}
              onClick={handleClose}>
              <Icon icon="CLOSE" size="x-small" />
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}
