import { useTranslation } from 'react-i18next'
import CollaboratorsCard from '../Components/Collaborators/collaboratorsCard'
import CollaboratorsImage from '../Components/Collaborators/collaboratorsImage'
import { useTitle } from './useTitle'

function Collaborators() {

  const { t } = useTranslation()
  useTitle(t('collaborators'))

  return (
    <>
      <CollaboratorsImage />
      <CollaboratorsCard />
    </>
  )
}

export default Collaborators
