import { allApis } from "./allApis";

export const settingApi = allApis.injectEndpoints({
    overrideExisting: false,
    
    endpoints: (builder) => ({
        getAllSettings: builder.query<any, any>({
            query: (key) => `setting/all/key`
            // keepUnusedDataFor: 300,
        }),
        getByCUserCurrency: builder.query<number, void>({
            query: () => `setting/stripe/stripeAmount`
        }),
        getServerDateTime: builder.query<string, void>({
            query: () => `setting/get/serverDateTime`,
            providesTags: ['ServerDateTime'],
        })
    })
});

export const { useGetAllSettingsQuery, useGetByCUserCurrencyQuery, useGetServerDateTimeQuery } = settingApi;