/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {toastSuccess} from '../../Components/toast'
import {
  getCurrentMeetingUser,
  getIsMeetingEnded,
  setMeetingMinimized,
  setShowStats,
} from '../../Services/livekitReducer'
import {useAppDispatch, useAppSelector} from '../../Store/hooks'
import LiveKitJoinView from './LiveKitJoinView'
import {useLeaveLiveKitHook} from './hooks/livekitWebOnlyHooks'

const LiveKitNavigation = () => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const {meetingId, stats} = useParams()

  const isMeetingEnded = useAppSelector(getIsMeetingEnded)
  const {handleLeaveMeeting} = useLeaveLiveKitHook()

  useEffect(() => {
    dispatch(setMeetingMinimized(false))

    if (stats === 'quality') {
      dispatch(setShowStats(true))
    } else {
      dispatch(setShowStats(false))
    }

    return () => {
      dispatch(setMeetingMinimized(true))
    }
  }, [])

  useEffect(() => {
    if (isMeetingEnded) {
      toastSuccess(t('meetingEnded'))
      handleLeaveMeeting()
    }
  }, [isMeetingEnded])

  return (
    <>
      {meetingId && <LiveKitJoinView meetingId={meetingId} />}
    </>
  )
}

export default React.memo(LiveKitNavigation)
