import placeHolder from '../../Assets/Images/beemg-icons-creator-camera-2023.svg'
import { Image } from '../../models/image.model'
import { useGetSettingValue } from '../../Services/settingReducer'

export function CardImagePlaceHolder({ imageName, images, keyId, avatar }: { imageName?: string, images?: Image[], keyId?: string, avatar?:string }) {
  const imageURL = useGetSettingValue('IMAGE_URL')

  if(avatar){
    return(
    <div id={`image-${avatar}`} data-testid={`image-${keyId}`}
        className="flex justify-center items-center rounded-full h-16 w-16">
        <img
          src={`${imageURL}/${avatar}`}
          alt="logo"
          className="object-cover border-white border-4 rounded-full h-16 w-16"
          onError={(e) => {
            (e.target as any).src = placeHolder
          }}
        />
      </div>)

  }

  if (!imageName && images && images.length > 0) {
    imageName = images[0].imageName
  }

  if (!imageName) {
    return (
      <div id={`logo-${placeHolder}`} data-testid={`logo-${placeHolder}`}
        className="flex justify-center items-center min-w-image border-white border-4 rounded-full bg-gray-200 h-16 w-16">
        <img src={placeHolder} alt="logo" className="md:h-8 xs:h-8" />{' '}
      </div>
    )
  } else {
    return (
      <div id={`image-${imageName}`} data-testid={`${imageName}-${keyId}`}
        className="flex justify-center items-center rounded-full h-16 w-16">
        <img
          src={`${imageURL}/${imageName}`}
          alt="logo"
          className="object-cover border-white border-4 rounded-full h-16 w-16"
          onError={(e) => {
            (e.target as any).src = placeHolder
          }}
        />
      </div>
    )
  }
}
export default CardImagePlaceHolder
