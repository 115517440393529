import { MeetingRequest } from './../../models/meetingRequest.model'
import { State, User } from '../../models/user.model'
import { CommonEntity } from '../../models/commonEntity.model'

export enum ChatMessageType {
  Text = 'Text',
  Video = 'Video',
}

export interface ChatMessage extends CommonEntity {
  toUser: User | null
  fromUser: User | null
  message: string
  dateSend: string
  dateSeen: string | null
  timeDisplay: string
  type: ChatMessageType
  meetingRequest: MeetingRequest
  messageId: string
  sent?: boolean
  isBlocked?: boolean
}

export interface ChatUser {
  id: string
  userId: string
  sub: string
  anonymous: number
  firstName: string
  nickName: string
  allowMessage: boolean
  avatar?: string
  state: State
  image?: any
  timezone?: string
  timezoneUtc?: string
}

export interface ChatUserData {
  chatUser: ChatUser
  message?: string
  dateSend: string
  unreadMessage?: number
}

export interface ChatDateLabel {
  messageId: string,
  type: string,
  date: string
}