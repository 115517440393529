import { useDisconnectButton } from '@livekit/components-react'
import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  useControlBarAccessHooks,
  useEndRoomHooks,
  useEndRoomReceiver,
  useMultiSessionCheckHooks,
} from '../livekitHooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { ToolTip } from 'base/tooltip/tooltip'
import { useCustomRoomContext } from 'livekit/room/CustomRoomContext'
import { useTranslation } from 'react-i18next'

/** @public */
export interface DisconnectButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  stopTracks?: boolean
  onLeave?: () => void
  onEnd?: () => void
  isPipEnabled?: boolean
}

/**
 * The DisconnectButton is a basic html button with the added ability to disconnect from a LiveKit room.
 * Normally, it is used by end-users to leave a video or audio call.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <DisconnectButton>Leave room</DisconnectButton>
 * </LiveKitRoom>
 * ```
 * @public
 */
export function CustomDisconnectButton(props: DisconnectButtonProps) {
  const { buttonProps } = useDisconnectButton(props)
  buttonProps.className = props.className ?? buttonProps.className
  const [isOpen, setIsOpen] = React.useState(false)
  const { isMultiSession } = useMultiSessionCheckHooks()
  const { isHostOrCoHostAvailableRoom } = useControlBarAccessHooks()
  const { endRoom } = useEndRoomHooks()
  const { isRoomEnded } = useEndRoomReceiver()
  const { shouldLeaveMeeting, isHostEnded } = useCustomRoomContext()
  const { t } = useTranslation()

  React.useEffect(() => {
    if (isMultiSession) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [isMultiSession])

  React.useEffect(() => {
    if (isRoomEnded || isHostEnded) {
      props.onEnd && props.onEnd()
      buttonProps.onClick()
    }
  }, [isRoomEnded, isHostEnded])

  React.useEffect(() => {
    if (shouldLeaveMeeting) {
      props.onLeave && props.onLeave()
    }
  }, [shouldLeaveMeeting])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <ToolTip tip={t('end')}>
        <button
          {...buttonProps}
          onClick={openModal}
          className={
            !props.isPipEnabled
              ? ' py-3 px-4 rounded-l-full rounded-r-full border font-medium bg-gray-200 text-gray-700  hover:bg-gray-50 transition-all text-sm'
              : ''
          }
        >
          <FontAwesomeIcon icon={faPhone} className="text-red-600" />
        </button>
      </ToolTip>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-1000" onClose={openModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed-bg-overlay" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center bg-transparent">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-semibold text-gray-900" id="confirmModal">
                    {isHostOrCoHostAvailableRoom && !isMultiSession
                      ? t('doYouNeedToEndTheMeeting')
                      : isMultiSession
                      ? t('multiSessionDoYouWantToLeave')
                      : t('doYouWantToLeaveTheMeeting')}
                  </Dialog.Title>
                  {/* <Dialog.Title as="h3" className="text-md font-medium text-gray-900 mt-2">
                    {name}
                  </Dialog.Title> */}
                  <div className="mt-6 flex flex-row items-center justify-end gap-x-3">
                    {isMultiSession && (
                      <button
                        id="btn_modalLeave"
                        type="button"
                        className="bg-gray-200 py-1 text-base uppercase hover:bg-amber-400 font-semibold rounded px-2 text-black"
                        onClick={() => {
                          buttonProps.onClick()
                          closeModal()
                          props.onLeave && props.onLeave()
                        }}
                      >
                        Leave
                      </button>
                    )}
                    {!isMultiSession && (
                      <>
                        {isHostOrCoHostAvailableRoom === true && (
                          <button
                            id="btn_modalEnd"
                            type="button"
                            className="bg-gray-200 py-1 text-base uppercase hover:bg-red-600 hover:text-white font-semibold rounded px-2 text-black"
                            onClick={() => {
                              closeModal()
                              endRoom()
                              props.onEnd && props.onEnd()
                            }}
                          >
                            {t('end')}
                          </button>
                        )}
                        <button
                          id="btn_modalLeave"
                          type="button"
                          className="bg-gray-200 py-1 text-base uppercase hover:bg-amber-400 font-semibold rounded px-2 text-black"
                          onClick={() => {
                            buttonProps.onClick()
                            closeModal()
                            props.onLeave && props.onLeave()
                          }}
                        >
                          {t('leave')}
                        </button>
                        <button
                          id="btn_modalClose"
                          type="button"
                          className="bg-gray-200 py-1 text-base uppercase hover:bg-amber-400 font-semibold rounded px-2 text-black"
                          onClick={closeModal}
                        >
                          {t('close')}
                        </button>
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
