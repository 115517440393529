import '@livekit/react-components/dist/index.css'
import './App.css'
import {Provider, useDispatch} from 'react-redux'
import {persistor, store} from './Store'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom'
import SignUp from './Containers/SignUps/SignUp'
import Home from './Containers/Home'
import Collaborators from './Containers/Collaborators'
import ChatBox from './Containers/Chat/ChatBox'
import Settings from './Containers/Settings/Settings'
import ActivateRazorpayAccount from './Containers/Settings/ActivateRazorpayAccount'
import TransactionList from './Containers/TransactionList'
import Invite from './Containers/Invite/Invite'
import MenuBar from './Containers/MenuBar'
import Events from './Containers/Events'
import ViewEvent from './Containers/ViewEvent'
import MyEvents from './Containers/MyEvents'
import AttendingEvents from './Containers/AttendingEvents'
import InterestedEvents from './Containers/InterestedEvents'
import Suggestions from './Containers/Suggestions'
import {Toaster} from 'react-hot-toast'
import EventDetails from './Containers/ScheduleEvents/EventDetails'
import PastEvents from './Containers/ScheduleEvents/PastEvents'
import Participants from './Containers/ScheduleEvents/Participants'
import Reviews from './Containers/ScheduleEvents/Reviews'
import Schedule from './Containers/ScheduleEvents/Schedule'
import ViewProfile from './Containers/ViewProfile'
import NewEvent from './Containers/ScheduleEvents/NewEvent'
import Sign from './Containers/Sign'
import SignIn from './Containers/SignIn'
import Notify from './Containers/Notify'
import PhoneVerification from './Containers/SignUps/PhoneVerification'
import ChooseInterests from './Containers/SignUps/ChooseInterest'
import ResetPassword from './Containers/ResetPassword'
import Feedback from './Containers/Feedback'
import PaymentSuccess from './Containers/PaymentSuccess'
import LanguageDetector from 'i18next-browser-languagedetector'
import {useTranslationHook} from './Hooks/Translations'
import About from './Containers/About'
import TermsAndConditions from './Containers/SignUps/TermsAndConditions'
import ScrollToTop from './Components/ScrollToTop'
import LoginSuccessful from './Containers/LoginSuccessFul'
import EventPolicy from './Containers/EventPolicy'
import LogInSuccess from './LogInSuccess'
import Version from './Containers/Version'
import VerifyEmail from './Containers/SignUps/VerifyEmail'
import {useEffect} from 'react'
import {useAppSelector} from './Store/hooks'
import {getCurrentUser} from './Services/userReducer'
import {useGetCognitoKeysQuery} from './Services/envApi'
import {getSettingByKey, useGetSettingValue} from './Services/settingReducer'
import {configureAWS} from './API/configureAWS'
import {Auth} from 'aws-amplify'
import {changeToken} from './Services/tokenReducer'
import EventEditor from './Containers/ScheduleEvents/EventEditor'
import EventCoHost from './Containers/ScheduleEvents/EventCoHost'
import ParticipantsList from './Containers/ScheduleEvents/ParticipantsList'
import CookieConsent from 'react-cookie-consent'
import {CreatorEventsFilter} from './Components/Creators/creatorEventsFilter'
import * as Sentry from '@sentry/react'
import LiveKitNavigation from './Containers/LiveKit/LiveKitNavigation'
import {LiveKitProvider} from './Services/livekit/contexts/liveKitContext'
import {useCheckOnlineStatus} from './Containers/Chat/CheckOnlineWebHook'
import {useAppRouting} from './Containers/Hook/AppRouting'
import PageNotFound from './Components/PageNotFound'
import {getCognitoKeys} from './Services/envReducer'
import RequestEventsFeedbacks from './Containers/RequestEvents/RequestEventsFeedbacks'
import RequestEventsWrapper from './Containers/RequestEvents/RequestEventsWrapper'
import SentRequests from './Containers/RequestEvents/SentRequests'
import ReceivedRequests from './Containers/RequestEvents/ReceivedRequests'
import UserFlow from './Containers/UserFlow'
import ViewHost from './Containers/Profile/HostProfile/ViewHost'
import UserProfile from './Containers/Profile/UserProfile/UserProfile'
import {ImageVideoModel} from './Containers/ScheduleEvents/ImageVideoModel'
import {useTranslation} from 'react-i18next'
import LiveKitMeeting from './Containers/LiveKit/LiveKitMeeting'
import { ImageVideoContextProvider } from './Hooks/MyEvents/ImageVideoContextHook'
import AttendingEventsWrapper from './Containers/AttendingEventsWrapper'
import AttendedPastEventRecords from './Containers/AttendedPastEventRecords'

function AppRouter() {
  useAppRouting()
  return (
    <>
      <Outlet />
    </>
  )
}

function AppLoginLoader() {
  useGetCognitoKeysQuery()
  useGetSettingValue('WEB_LINK')
  useCheckOnlineStatus()

  useTranslationHook(LanguageDetector)

  const currentUser = useAppSelector(getCurrentUser)
  const cognitoKeys = useAppSelector(getCognitoKeys)
  const linkData = useAppSelector(state => getSettingByKey(state, 'WEB_LINK'))

  const {t} = useTranslation()

  const token =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXRhZGF0YSI6IntcInVzZXJUeXBlXCI6XCJQYXJ0aWNpcGFudFwiLFwicHJvZmlsZUltYWdlVXJsXCI6XCJodHRwczovL2Nkbi5zYW5pdHkuaW8vaW1hZ2VzL2UzYTA3aWlwL3Byb2R1Y3Rpb24vOTk2MmY2ZmI3YmE5NDZjZTRlMTBkZGM5NzFiYmJmMDM4Zjg1MjAwNC0xMDgzeDEwODMucG5nXCJ9IiwibmFtZSI6ImhlbGxvIiwidmlkZW8iOnsicm9vbUpvaW4iOnRydWUsInJvb20iOiJCZWVtZyBsaXZla2l0IGRlbW8gMiByb29tIFVJIGNoYW5nZXMgZm9yIHVwZ3JhZGUifSwiaWF0IjoxNzAzMjIyNjk1LCJuYmYiOjE3MDMyMjI2OTUsImV4cCI6MTcwMzI0NDI5NSwiaXNzIjoiQVBJZGlSUmF2WXRtWFdIIiwic3ViIjoiaGVsbG8iLCJqdGkiOiJoZWxsbyJ9.a4wvtjHPPFEbqvJ9HlYOsCTHuRun0y5_qSdYMfOZfd8'

  const endTime = new Date()
  endTime.setHours(endTime.getHours() + 1)

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({id: currentUser.id})
    } else {
      Sentry.setUser(null)
    }
  }, [currentUser])

  useEffect(() => {
    if (cognitoKeys && linkData) {
      configureAWS(cognitoKeys, linkData)
    }
  }, [cognitoKeys, linkData])

  // if (!cognitoKeys || !linkData) {
  //   return <div className='h-screen flex flex-1 justify-center items-center'>
  //     <Loading />
  //   </div>
  // }

  return (
    <>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText={t('cookie.allow')}
        cookieName="beeMGCookieConsent"
        style={{background: '#f0f0f0', color: '#000000'}}
        buttonStyle={{
          background: '#d5d5d5',
          color: '#000000',
          fontSize: '13px',
          fontWeight: '600',
        }}
        declineButtonStyle={{
          background: '#d5d5d5',
          color: '#000000',
          fontSize: '13px',
        }}
        expires={150}
        declineButtonText={t('cookie.deny')}>
        {t('cookie.message')}
      </CookieConsent>

        <ScrollToTop />
        <Routes>
          <Route path="" element={<AppRouter />}>
            <Route path="/" element={<Sign />} />
            <Route path="signIn" element={<SignIn />} />
            <Route path="notify" element={<Notify />} />
            <Route
              path="resetPassword/:email/:medium"
              element={<ResetPassword />}
            />
            <Route path="loginSuccessful" element={<LoginSuccessful />} />
            <Route path="" element={<UserFlow />}>
              <Route path="signUp/:token" element={<SignUp />} />
              <Route path="verifyPhone" element={<PhoneVerification />} />
              <Route path="verifyEmail" element={<VerifyEmail />} />
              <Route path="about" element={<About />} />
            </Route>
            <Route path="termsAndConditions" element={<TermsAndConditions />} />
            <Route path="s" element={<MenuBar />}>
              <Route path="about" element={<About />} />
              <Route
                path="termsAndConditions"
                element={<TermsAndConditions />}
              />
              <Route path="home" element={<Home />} />
              <Route path="loggedIn" element={<LogInSuccess />} />
              <Route path="faceVerify" element={<></>} />
              <Route path="chooseInterests" element={<ChooseInterests />} />
              <Route path="creator" element={<CreatorEventsFilter />} />
              <Route path="collaborator" element={<Collaborators />} />
              <Route path="viewProfile/:userId" element={<ViewProfile />} />
              <Route path="viewHost/:id" element={<ViewHost />} />
              <Route path="meeting/:id" element={<ViewEvent />} />
              <Route path="events" element={<Events />}>
                <Route path="newEvent" element={<NewEvent />} />
                <Route path="imageVideoModel/:meetingDetailId" element={<ImageVideoContextProvider><ImageVideoModel /></ImageVideoContextProvider>} />
                <Route path="my-events" element={<MyEvents />}>
                  <Route path=":meetingDetailId" element={<Outlet />}>
                    <Route path="eventDetails" element={<EventDetails />} />
                    <Route path="schedule" element={<Schedule />}>
                      <Route path=":meetingId" element={<Outlet />}>
                        <Route path="edit" element={<EventEditor />} />
                        <Route path="participants" element={<Participants />} />
                        <Route path="cohosts" element={<EventCoHost />} />
                      </Route>
                    </Route>
                    <Route path="pastEvents" element={<PastEvents />}>
                      <Route path=":meetingId" element={<Outlet />}>
                        <Route path="participants" element={<Participants />} />
                        <Route path="reviews" element={<Reviews />} />
                      </Route>
                    </Route>
                    <Route path="participants" element={<ParticipantsList />}>
                      <Route path=":meetingId" element={<Participants />} />
                    </Route>
                    <Route path="reviews" element={<Reviews />} />
                  </Route>
                </Route>
                <Route path="attending-events" element={<AttendingEventsWrapper />} >
                  <Route path="upcomingEvents" element={<AttendingEvents />} />
                  <Route path="pastHistory" element={<AttendedPastEventRecords />} />
                </Route>
                <Route path="request-events" element={<RequestEventsWrapper />}>
                  <Route path="sentRequests" element={<SentRequests />} />
                  <Route
                    path="receivedRequests"
                    element={<ReceivedRequests />}
                  />
                  <Route
                    path="feedbacks"
                    element={<RequestEventsFeedbacks />}
                  />
                </Route>
                <Route
                  path="interested-events"
                  element={<InterestedEvents />}
                />
                <Route path="suggestions" element={<Suggestions />} />
              </Route>
              <Route path="chat-box" element={<ChatBox />}>
                <Route path=":id" element={<ChatBox />} />
              </Route>
              <Route path="profile" element={<UserProfile />} />
              <Route path="settings" element={<Settings />} />
              <Route
                path="feedback/:meetingId/:anonymous"
                element={<Feedback />}
              />
              <Route
                path="payment/:transactionId"
                element={<PaymentSuccess />}
              />
              <Route path="razorpay" element={<ActivateRazorpayAccount />} />
              <Route path="transaction-list" element={<TransactionList />} />
              <Route
                path="transaction-list/:transactionType"
                element={<TransactionList />}
              />
              <Route path="invite" element={<Invite />} />
              <Route path="eventPolicy" element={<EventPolicy />} />
              <Route path="ameeting" element={<LiveKitNavigation />}>
                <Route path=":meetingId" element={<ChatBox />} />
                <Route path=":meetingId/:stats" element={<ChatBox />} />
              </Route>
              <Route path="version" element={<Version />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      <AppAuthTokenUpdate />
    </>
  )
}

function AppAuthTokenUpdate() {
  const currentUser = useAppSelector(getCurrentUser)
  const dispatch = useDispatch()
  const MINUTE_MS = 1.05e7

  useEffect(() => {
    updateAccessToken()
    const interval = setInterval(() => {
      updateAccessToken()
    }, MINUTE_MS)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateAccessToken = async () => {
    if (currentUser) {
      Auth.currentAuthenticatedUser().then(cognitoUser => {
        Auth.currentSession().then(currentSession => {
          if (currentSession.getRefreshToken().getToken()) {
            cognitoUser.refreshSession(
              currentSession.getRefreshToken(),
              (err: any, session: any) => {
                const {accessToken} = session
                dispatch(changeToken(accessToken.jwtToken))
              },
            )
          }
        })
      })
    }
  }

  return <></>
}

function App() {
  return (
    <Provider store={store}>
      {/**
       * PersistGate delays the rendering of the app's UI until the persisted state has been retrieved
       * and saved to redux.
       * The `loading` prop can be `null` or any react instance to show during loading (e.g. a splash screen),
       * for example `loading={<SplashScreen />}`.
       * @see https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
       */}
      <PersistGate loading={null} persistor={persistor}>
        <Toaster />
        <BrowserRouter>
          <LiveKitProvider>
            {/* <LiveKitWrapper /> */}
            <LiveKitMeeting />
            <AppLoginLoader />
          </LiveKitProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
