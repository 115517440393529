import * as React from 'react'
import { buttonStyles } from './LivekitCustomControlBar'
import { DisplayIcon } from './LivekitCustomTrackToggle'
import { faHand } from '@fortawesome/free-solid-svg-icons'
import { useHandRaiseHook } from '../livekitHooks'
import { ToolTip } from 'base/tooltip/tooltip'
import { useTranslation } from 'react-i18next'

/** @public */
export interface HandRaiseToggleProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isPipEnabled: boolean | undefined
}

/**
 * The ChatToggle component toggles the visibility of the chat component.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <ToggleChat />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function CustomHandRaiseToggle(props: HandRaiseToggleProps) {
  // const { mergedProps } = useChatToggle({ props });
  // mergedProps.className = buttonStyles + ' rounded-full' ?? mergedProps.className;
  const { handRaise, isHandRaised } = useHandRaiseHook()
  const { t } = useTranslation()

  return (
    <>
      <ToolTip tip={t('handRaise')} theme="transparent-dark">
        <button
          className={!props.isPipEnabled ? buttonStyles + ' rounded-full' : ''}
          onClick={() => handRaise(!isHandRaised)}
        >
          <DisplayIcon icon={faHand} isAllowed={isHandRaised} id="btn_handRaise" isPipEnabled={props.isPipEnabled} />
        </button>
      </ToolTip>
    </>
  )
}
