import { UserPolicyAgreements } from "../models/userPolicyAgreements.model";
import { useGetEventCreationPolicyQuery } from "../Services/policyApi";
import { useAgreeEventPolicyMutation } from "../Services/userPolicyAgreementsApi";

export const useEventPolicyAgreementHooks = () => {
    const { data: policies, isSuccess: policiesSuccess } = useGetEventCreationPolicyQuery();
    const [agreeEventPolicy, {isSuccess: eventPolicyAgreed}] = useAgreeEventPolicyMutation();

    
  const agree = () => {
    const userPolicy = {} as UserPolicyAgreements;
    userPolicy.policies = policies;
    agreeEventPolicy(userPolicy)
  }

  return {
    policies, 
    eventPolicyAgreed, 
    agree, 
    policiesSuccess
  };
    
}