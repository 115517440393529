/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../Components/base/icon/icon';
import { toastSuccess } from '../../Components/toast';
import { useInviteCardHooks } from '../../Hooks/InviteHook';
import { useGetInviteByUserQuery } from '../../Services/inviteApi';
import { Invite } from '../../models/invite.model';
import { DisplayFormattedDate } from '../../Components/formattedDateAndTime';

function InviteRow({ invite, i, isLastItem }: { invite: Invite, i: number, isLastItem: boolean }) {
  const { t } = useTranslation();

  const { handleInviteDelete, handleInviteResend, deleteInviteSuccess, resendInviteSuccess } = useInviteCardHooks()
  useEffect(() => {
    if (resendInviteSuccess) {
      toastSuccess(t('resendInviteSentSuccessfully'))
    }
  }, [resendInviteSuccess, t])

  useEffect(() => {
    if (deleteInviteSuccess) {
      toastSuccess(t('deletedSuccessfully'))
    }
  }, [deleteInviteSuccess, t])

  return (
    <>
      <div className="px-4 md:grid grid-cols-2 justify-between items-center mb-4" key={invite.id} id="list_inviteCard">
        <div className="flex-grow">
          <div className="flex justify-start leading-[1.3rem] items-start flex-col text-gray-600 w-full">
            <div className="overflow-x-auto justify-start">
              <div className="flex-wrap" data-testid={`invite-${invite.id}`} >{invite.inviteEmail}</div>
            </div>
            <div className="flex items-start justify-left">
              {invite.display_createdAt && !invite.display_updatedAt && <div id={`chk_inviteDate${i}`} data-testid={`chk_inviteDate${i}`} className="py-1">{t('invitedOn')}{' '} {<DisplayFormattedDate displayDate={invite.createdAt}/>}</div>}
              {invite.display_createdAt && invite.display_updatedAt && <div id={`chk_resendDate${i}`} data-testid={`chk_resendDate${i}`} className="py-1">{t('invitedOn')}{' '} {<DisplayFormattedDate displayDate={invite.updatedAt}/>}</div>}
            </div>
          </div>
        </div>
        <div className="py-2 ml-20 ">
          <p className="flex items-center justify-end whitespace-no-wrap">
            {invite.join === 'yes' ?
              (<button className='cursor-default border border-gray-400 py-2 font-medium mr-6 text-gray-400 rounded-md text-xs w-56' data-testid={`chk_updatedDate${i}`}>{<DisplayFormattedDate displayDate={invite.updatedAt}/>}</button>
              ) :
              (<><button className='border border-gray-400 hover:border-primary py-2 hover:bg-primary font-medium mr-1 text-gray-400 hover:text-black rounded-md w-56 text-xs' onClick={() => handleInviteResend(invite.inviteEmail)} id={`btn_resend${i}`} data-testid={`resendInvite-${invite.id}`}>{t('resendInvitation')}</button>
                <span className='cursor-pointer' onClick={() => handleInviteDelete(invite.id)} id={`btn_delete${i}`} data-testid={`inviteDelete-${invite.id}`}>
                  <Icon icon={'TRASH_DELETE'} size="small" />
                </span></>)
            }

          </p>
        </div>
      </div>
      {!isLastItem && <hr className="mb-4" />}
      {/* <div className="flex justify-center rounded-lg mx-2 md:hidden mb-1 p-2 w-auto bg-transparent border">
        <div className="w-3/4 flex flex-col justify-around">
          <span className='text-xs sm:text-sm truncate '>{invite.inviteEmail}</span>
          <span className='text-xs sm:text-sm'>{t('invitedOn')}{' '}{invite.display_createdAt}</span>
        </div>
        <div className="w-1/4 text-center">
          {invite.join === 'yes' &&
            <span className='px-2 py-1 bg-green-500  rounded-full text-white text-xs sm:text-sm'>{t('joined')}</span>
          }
          {invite.join === 'no' &&
            <div>
              <span className='px-1.5 py-1 bg-red-600 rounded-full text-white text-xs sm:text-sm cursor-pointer' onClick={() => handleInviteResend(invite.inviteEmail)} id={`btn_resend${i}`} data-testid={`resendInvite-${invite.id}`} >{t('resend')} </span>
              <span className='cursor-pointer flex justify-center mt-1' onClick={() => handleInviteDelete(invite.id)} id={`btn_delete${i}`} data-testid={`inviteDelete-${invite.id}`}>
                <Icon icon={'TRASH_DELETE'} size="small" stroke='#535353' />
              </span>
            </div>
          }
        </div>
      </div> */}
    </>
  )
}
function Invites() {
  const { data: inviteData } = useGetInviteByUserQuery();
  const { t } = useTranslation();

  return (
    <>
    <div className='text-m md:text-xl font-medium flex justify-center mt-2 mb-8' data-testid='txt_yourInvitees' id="txt_yourInvitees">{t('yourInvitees')}
    </div>
    <div className='h-auto w-100 md:h-96 overflow-auto'>
      {inviteData?.map((invite, i) => {
        const isLastItem = i === inviteData.length - 1;
        return (
          <InviteRow invite={invite} key={invite.id} i={i} isLastItem={isLastItem} />
        );
      })}
    </div>
    </>
  );
}

function InvitationList() {
  const { data: inviteData } = useGetInviteByUserQuery();

  return (
    <>
      {(inviteData?.length !== 0 || !inviteData) &&
        <div className="md:flex justify-center mt-2 w-full">
          <div className="md:inline-block min-w-min rounded-lg overflow-hidden ">
            <Invites />
          </div>
        </div>
      }
    </>
  )
}
export default InvitationList
