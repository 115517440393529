import {useEffect} from 'react'
import {Accordion} from '../Components/base/accordion/accordion'
import {useAttendingHooks} from '../Hooks/AttendingHooks'
import {meetingComp} from './AttendingEvents'
import NoSession from '../Components/NoSession'
import Loading from '../Components/base/loading/loading'

function AttendedPastEventRecords() {
  const {
    scheduledLoading,
    scheduledDataEvents,
    setScheduledDataEvents,
    attendedData,
  } = useAttendingHooks()

  useEffect(() => {
    if (attendedData) {
      setScheduledDataEvents(attendedData)
    }
  }, [attendedData])

  if (scheduledLoading) {
    return <Loading />
  }

  if (scheduledDataEvents.length === 0 && !scheduledLoading) {
    return (
      <NoSession
        type="pastEvents"
        className="h-[60vh] flex items-center justify-center"
      />
    )
  }

  return (
    <div className="block lg:w-11/12 w-full px-3 mb-48 md:mb-36 lg:mb-28">
      <Accordion>{meetingComp(scheduledDataEvents, true)}</Accordion>
    </div>
  )
}

export default AttendedPastEventRecords
