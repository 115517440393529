import { createSlice} from "@reduxjs/toolkit";
import { RootState } from "../Store";

const initialStateValue = "";

export const redirectUrlSlice = createSlice({
    name: "redirecturl",
    initialState: { 
        value: initialStateValue
    },
    reducers: {
        changeRedirectUrl: (state, action) => {
            if (!state.value) {
                state.value = action.payload;
            }
        },
        resetRedirectUrl: (state) => {
            if (state.value) {
                state.value = initialStateValue;
            }
        }
    }
});

export const getRedirectUrl = (state: RootState): string => state.redirecturl?.value

export const { changeRedirectUrl, resetRedirectUrl } = redirectUrlSlice.actions;
export default redirectUrlSlice.reducer;
