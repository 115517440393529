import { Feedback } from './../../models/feedback.model';
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { feedbackApi, useGetByMeetingDetailsIdQuery, useGetFeedbackByMeetingIdQuery, useUpdateFeedbackMutation } from '../../Services/feedbackApi'
import { useGetSettingValue } from '../../Services/settingReducer'
import { replyValidationSchema } from '../../Utils/validation'
import { useAppDispatch } from '../../Store/hooks';

export const useEventReviewSelectHook = (id?: string) => {
  const limit      = useGetSettingValue('EVENT_FEEDBACK_LIMIT')
  
  let skip = 0
  const args = {
    limit: limit,
    skip: skip,
    meetingDetailsId: id,
  }

  const {
    data: feedbackData,
    isSuccess: feedbackDataSuccess,
    isLoading: feedbackDataLoading,
  } = useGetByMeetingDetailsIdQuery(args, {skip: !id})

  return {
    feedbackData,
    feedbackDataSuccess,
    feedbackDataLoading,
  }
}

export const useEventReviewsHook = ({meetingId, feedback, feedbacks}:{meetingId:string, feedback?:Feedback , feedbacks?:Feedback[]}) => {
  const dispatch = useAppDispatch();  
  const [showReply, setShowReply] = useState<boolean>(false)
  const [reply, setReply] = useState<string>('')
  const limit      = useGetSettingValue('EVENT_FEEDBACK_LIMIT')
  const [feedbackSkip, setFeedbackSkip] = useState(feedbacks)

  let skip = 0
  const args = {
    limit: limit,
    skip: skip,
    meetingId: meetingId,
  }

  const {data: feedbackData, isSuccess: feedbackSuccess, isLoading: feedbackLoading } = useGetFeedbackByMeetingIdQuery(args,  { skip: !args?.meetingId })
  const [updateReply,{isSuccess: replyFeedbackSuccess, isLoading: replyFeedbackLoading}] = useUpdateFeedbackMutation()
  const { register, control, handleSubmit, reset, setValue, trigger, getValues, formState: {errors, isDirty,isValid}} =  useForm<any>({ mode: 'onChange',
      resolver: yupResolver(replyValidationSchema) 
    });
    

  const getFeedback = (meetingId: string) => {
    if (feedbackSkip) {
      const args = {
        meetingId: meetingId,
        limit: limit,
        skip: feedbackSkip.length > 0 ? feedbackSkip.length : 0,
      }
      const res = dispatch(feedbackApi.endpoints.getFeedbackByMeetingId.initiate(args))
      res.then((val: any) => {
        const listed = [...feedbackSkip, ...val.data.results]
        setFeedbackSkip(listed)
      })
    }
  }

  const handleUpdateReply = (values: any) => {
    if(feedback){
      const inviteRes = Object.assign({}, feedback)
      inviteRes.reply = values.reply
      updateReply(inviteRes).then(() => {
        setShowReply(false)
      })
    }
  }

  return {
    showReply,setShowReply,handleSubmit,errors,isDirty,isValid,register,replyFeedbackSuccess,reset,
    reply,setReply, control, getValues,
    handleUpdateReply,setValue, trigger, getFeedback,
    feedbackData, feedbackSuccess, feedbackLoading, replyFeedbackLoading
  }
}
