import { ContactFeedbackAttachements } from '../models/contactFeedbackAttachements.model';
import { allApis } from './allApis';

export const contactFeedbackAttachementsApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        uploadFeedbackAttachments: builder.mutation<ContactFeedbackAttachements, any>({
            query: (arg) => {
                const { id, file } = arg;
                let testData: FormData = new FormData();
                testData.append('file', file);

                return {
                    url: `contactFeedBackAttachments/feedbackAttachmentsUpload/${id}`,
                    method: 'POST',
                    body: testData
                };
            }
        }),
        uploadFeedbackAttachmentsForNonLoginusers: builder.mutation<ContactFeedbackAttachements, any>({
            query: (arg) => {
                const { id, file } = arg;
                let testData: FormData = new FormData();
                testData.append('file', file);

                return {
                    url: `contactFeedBackAttachments/feedbackAttachmentsUploadForNonLoginUsers/${id}`,
                    method: 'POST',
                    body: testData
                };
            }
        })
    })
});
export const { useUploadFeedbackAttachmentsMutation, useUploadFeedbackAttachmentsForNonLoginusersMutation } = contactFeedbackAttachementsApi;
