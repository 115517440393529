import { useEffect, useState } from "react";
import { useAppSelector } from "../Store/hooks";
import { getCurrentUser } from "../Services/userReducer";
import { useBlockChatMutation, useGetBlockedChatUserByIdQuery, useUnBlockChatMutation } from "../Services/blockChatApi";
import { BlockedChats } from "../models/blockedChats.model";
import { SendWebCommand } from "../API/webCommands";
import { WebCommandTypes } from "../API/socketTypes";

export const useBlockCollaborator = (id: string) => {

    const [isBlocked, setIsBlocked] = useState<boolean>(false);
  
    const currentUser = useAppSelector(getCurrentUser)
  
    const { data: alreadyBlockedData, isLoading: alreadyBlockedLoading, isSuccess: alreadyBlockedSuccess, isError: alreadyBlockedError, } = useGetBlockedChatUserByIdQuery(id)
  
    const [blockChat, { isLoading: blockChatMutationLoading }] = useBlockChatMutation()
    const [unBlockChat, { isLoading: unBlockChatMutationLoading }] = useUnBlockChatMutation()
  
    const blockActivityDisabled = blockChatMutationLoading || unBlockChatMutationLoading || alreadyBlockedLoading
  
    useEffect(() => {
      if (alreadyBlockedError) {
        setIsBlocked(false)
        return;
      }
  
      if (alreadyBlockedData && alreadyBlockedSuccess) {
        setIsBlocked(true);
      } else {
        setIsBlocked(false);
      }
    }, [alreadyBlockedData, alreadyBlockedSuccess, alreadyBlockedError])
  
    const handleBlock = () => {
      if (id) {
        let blockedChat = {} as BlockedChats
        blockedChat.blockedChatUser = id
        blockedChat.user_id = currentUser.id
        blockChat(blockedChat).then(() => {
          SendWebCommand({ type: WebCommandTypes.BlockingUsersUpdate, fromUserId: currentUser.id, toUserId: id });
        })
      }
    }
  
    const handleUnBlock = (userBlockId: string) => {
      unBlockChat(userBlockId)
    }
  
    return { handleBlock, handleUnBlock, alreadyBlockedData, isBlocked, blockActivityDisabled }
  
  }