import { useEnsureParticipant, useParticipantContext, useParticipantInfo } from '@livekit/components-react'
import { UserType } from '../livekitTypes'
import AudioLevelIndicator from './audio_conference/AudioLevelIndicator'
import React, { useEffect, useState } from 'react'
import { defaultAvatar } from './audio_conference/CustomAudioParticipantPlaceholder'
import { useCustomGridContext } from './CustomGridContext'
import PermissionsToasterComp from './PermissionsToasterComp'
import { useTranslation } from 'react-i18next'
import { useCustomRoomContext } from './CustomRoomContext'

interface _customParticipantPlaceholderProps {
  height: number
  width: number
}

export function CustomParticipantPlaceholder(props: _customParticipantPlaceholderProps) {
  const { height, width } = props
  const { name, metadata } = useParticipantInfo()
  const participant = useEnsureParticipant()
  const [metaData] = useState(JSON.parse(metadata ?? '{}'))
  const participantNameStyle =
    'lg:text-xl md:text-sm sm:text-sm xs:text-xs text-black font-medium text-center justify-center text-clip overflow-hidden text-nowrap'
  const [isNullPresent, setIsNullPresent] = useState(false)
  const { pinnedParticipants } = useCustomGridContext()
  const { t } = useTranslation()
  const { isSpeaking } = useParticipantContext()
  const { allTracks } = useCustomRoomContext()

  useEffect(() => {
    if (metaData?.profileImageUrl && metaData?.profileImageUrl.includes('null')) {
      setIsNullPresent(true)
    }
  })

  const isPinned = pinnedParticipants.includes(participant.sid)
  const fullName = name ? name.replace(/\b\w/g, (c) => c.toUpperCase()) : ''
  const firstName = fullName.split(' ')[0]
  const truncatedFirstName = allTracks.length > 8 ? firstName.substring(0, 6) + '..' : firstName

  return (
    <>
      <PermissionsToasterComp />
      <div
        className={`flex flex-col h-full bg-gray-200 items-center justify-center relative rounded-md ${
          isSpeaking ? 'outline outline-green-400 outline-3' : ''
        }`}
      >
        <div className="relative m-1">
          <AudioLevelIndicator />
          {!isNullPresent ? (
            <div style={{ position: 'relative', zIndex: 1 }}>
              <img
                style={{
                  height: height + 'px',
                  width: width + 'px',
                  borderRadius: '50%',
                }}
                src={metaData.profileImageUrl}
                alt="avatar"
              />
            </div>
          ) : pinnedParticipants.length >= 0 ? (
            <div style={{ position: 'relative', zIndex: 1 }}>
              <img
                style={{
                  height: height + 'px',
                  width: width + 'px',
                  borderRadius: '50%',
                }}
                className="bg-gray-200 h-full w-full rounded-full"
                src={defaultAvatar}
                alt="avatar"
              />
            </div>
          ) : (
            <div style={{ position: 'relative', zIndex: 1 }}>
              <img
                style={{
                  height: height + 'px',
                  width: width + 'px',
                  borderRadius: '50%',
                }}
                className="bg-gray-200 h-full w-full rounded-full p-20"
                src={defaultAvatar}
                alt="avatar"
              />
            </div>
          )}
        </div>
        {metaData.userType === UserType.HOST ? (
          <div id={`Host_${participant.sid.slice(0, -5)}`} className={participantNameStyle}>
            {pinnedParticipants.length > 0 ? (isPinned ? fullName : truncatedFirstName) : fullName} ({t('host')})
          </div>
        ) : metaData.userType === UserType.CO_HOST ? (
          <div id={`CoHost_${participant.sid.slice(0, -5)}`} className={participantNameStyle}>
            {pinnedParticipants.length > 0 ? (isPinned ? fullName : truncatedFirstName) : fullName} ({t('coHost')})
          </div>
        ) : (
          <div id={`Participant_${participant.sid.slice(0, -5)}`} className={participantNameStyle}>
            {pinnedParticipants.length > 0 ? (isPinned ? fullName : truncatedFirstName) : fullName}
          </div>
        )}
      </div>
    </>
  )
}
