import { useEffect, useState } from "react";
import { UserPolicyAgreements } from "../models/userPolicyAgreements.model";
import { useGetTermsAndConditionsQuery } from "../Services/policyApi";
import { changeToken } from "../Services/signUpReducer";
import { awsLogout } from "../Services/tokenReducer";
import { useGetCurrentUserDetailsQuery } from "../Services/userApi";
import { useCheckUserTermsPolicyQuery, useCreateUserTermsMutation } from "../Services/userPolicyAgreementsApi";
import { useAppDispatch } from "../Store/hooks";

export const useTermsAndConditionsHooks = () => {
    const [createUserTerms, { isSuccess: acceptTermsSuccess }] = useCreateUserTermsMutation<any>();
    const dispatch = useAppDispatch();

    const [hidebutton, setHideButton] = useState(true);
    const [navigateScreen, setNavigateScreen] = useState<"NAVIGATE_TO_LOGIN" | "NAVIGATE_TO_HOME_SCREEN" | undefined>();

    const { data: policies, isSuccess: policiesSuccess } = useGetTermsAndConditionsQuery();
    const { data: policyAccepted } = useCheckUserTermsPolicyQuery();
    const { data: user } = useGetCurrentUserDetailsQuery();

    useEffect(() => {
        if (!policyAccepted && hidebutton) {
            setHideButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyAccepted])

    const save = () => {
        if (user) {
            const userPolicyAgreements: UserPolicyAgreements = {
                user: user
            } as UserPolicyAgreements;

            createUserTerms(userPolicyAgreements);
        }
    }

    const cancel = () => {

        dispatch(awsLogout());
        dispatch(changeToken(null))

        setNavigateScreen('NAVIGATE_TO_LOGIN');
    }

    return { hidebutton, policies, navigateScreen, policiesSuccess, save, cancel, acceptTermsSuccess, policyAccepted };
}