import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from '../../Components/_utils/useToggle';
import { AccordionBorder, AccordionItem } from '../../Components/base/accordion/accordion';
import Button from '../../Components/base/button/button';
import NewIcon from '../../Components/base/icon/newIcons';
import { Modal, ModalBody, ModalCloseButton, ModalFooter } from '../../Components/base/modal/modal';
import { toastError, toastSuccess } from '../../Components/toast';
import { useCancellationHooks } from '../../Hooks/AttendingHooks';
import { Meeting } from '../../models/meeting.model';
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder';
import CardTitle from '../EventsCard/CardTitle';
import { DisplayEventTypeIcons } from '../EventsCard/DisplayEventTypeIcons';
import { EventCard } from '../EventsCard/EventCard';
import { DisplayFormattedDateAndTime } from '../../Components/formattedDateAndTime';
import Field from '../../Components/base/field/field';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { refundRequestValidationSchema } from '../../Utils/validation';
import { ToolTip } from '../../Components/base/tooltip/tooltip';
import Icon from '../../Components/base/icon/icon';
import Chip from '../../Components/Chip';
import { useCreateRefundRequestMutation, useUploadRefundAttachmentsMutation } from '../../Services/refundRequestApi';
import { useGetAllRefundCategoryQuery } from '../../Services/refundCategoryApi';
import { RefundRequest } from '../../models/refundRequest.model';

export function AttendedEvents({ meeting }: { meeting: Meeting }) {
  const { t } = useTranslation();
  const { toggle, isOpen } = useToggle();
  const { meetingTitle, currentUser, } = useCancellationHooks({ meeting });
  const [fileValue, setFileValue] = useState<any[]>([])

  const [createRefundRequest] = useCreateRefundRequestMutation()
  const { data: refundCategoryData, isLoading: RefundCategoryListLoading } = useGetAllRefundCategoryQuery()

  const [uploadAttachment, { isSuccess: uploadSuccess }] = useUploadRefundAttachmentsMutation()
  const { register, control, handleSubmit, getValues, setValue, watch, trigger, formState: { errors }, reset } = useForm<RefundRequest>({
    resolver: yupResolver(refundRequestValidationSchema),
    defaultValues: {
      participantName: meeting.meetingParticipant[0]?.user.firstName || '',
      meetingId: meeting.id || '',
    }
  });

  const onSubmit = async (value: any) => {
    try {

      const createdRefundRequest = await createRefundRequest({
        participant: { id: meeting.meetingParticipant[0]?.id } as any,
        meeting: { id: value.meetingId } as any,
        refundCategory: value.refundCategory,
        refundReason: value.refundReason,
      }).unwrap();

      const createdRefundRequestId = createdRefundRequest?.id;
      toastSuccess(t('refundRequestCreatedSuccessfully'));
      if (fileValue.length > 0) {
        const formData = new FormData();
        fileValue.forEach((file) => {
          formData.append("files", file);
        });
        const args = { id: createdRefundRequestId, files: formData };
        try {
          await uploadAttachment(args).unwrap();
          toastSuccess(t('attachmentUploadedSuccessfully'));
        } catch (error) {
          toastError(t('attachmentUploadFailed'));
        }
      }

      resetForm();
    } catch (error) {
      toastError(t('refundRequestCreationFailed'));
    }
  };

  const resetForm = () => {

    toggle();
    reset();

  };

  const validateFormate = (event: any) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'png' && fileExtension !== 'jpeg' && fileExtension !== 'jpg') {
        toastError(t('attachmentShouldBePngOrJpeg'));
      } else {
        // setFileValue(Array.from(files));
        setFileValue((prevFiles) => [...prevFiles, files]);
      }
    } 
  };

  const truncatedTitle = {
    meetingTitle: meetingTitle.length > 25 ? meetingTitle.substring(0, 25) + '...' : meetingTitle
  }

  return (
    <>
      <AccordionBorder>
        <AccordionItem color="card" pointing={meeting.type === 'Type 1'}>
          <EventCard keyId={meeting.id} link={meeting.type === 'Type 1' ? `/s/meeting/${meeting.meetingDetails.id}` : ''}>
            {meeting.type === 'Type 1' && <EventCard.Image><CardImagePlaceHolder images={meeting?.meetingDetails?.image} /></EventCard.Image>}
            {meeting.type === 'Type 2' && meeting?.user?.id === currentUser.id
              ? <EventCard.Image><CardImagePlaceHolder images={meeting?.user2?.image} /></EventCard.Image>
              : meeting?.user2?.id === currentUser.id && <EventCard.Image><CardImagePlaceHolder images={meeting?.user?.image} /></EventCard.Image>}
            <EventCard.Title><CardTitle keyId={meeting.id} key={meeting.meetingDetails.id} meetingTitle={truncatedTitle.meetingTitle} /></EventCard.Title>
            <EventCard.SubTitle>
              <DisplayFormattedDateAndTime displayFromTime={meeting.fromDateTime} displayToTime={meeting.toDateTime} displayDate={meeting.fromDateTime} />
            </EventCard.SubTitle>
            <EventCard.Icons><DisplayEventTypeIcons meetingDetail={meeting.meetingDetails} meeting={meeting} /></EventCard.Icons>
            {/* {!meeting.meetingDetails.isFreeAudioMeeting && 
        new Date(meeting.fromDateTime).getTime() - new Date().getTime() > 24 * 60 * 60 * 1000 && ( */}
            <EventCard.Actions>
              <div className='flex justify-center mt-4'>
                <span id={`btn_cancelEvent${meeting.id}`} className="text-red-500" data-testid={`btn_cancelEvent${meeting.id}`} onClick={toggle}>
                  <NewIcon size="small" icon="CANCELEVENT" stroke='red' className="mb-1 justify-center items-center mx-auto " />
                  <span className='block'>{t('refundRequest')}</span>
                </span>
              </div>
            </EventCard.Actions>
            {/* )} */}
          </EventCard>
        </AccordionItem>
      </AccordionBorder>


      <form data-testid="form_submit" onSubmit={handleSubmit(onSubmit)}>
        <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
          <ModalBody>
            <div onClick={() => { resetForm() }}>
              <ModalCloseButton toggle={toggle} />
            </div>
            <div className="flex flex-col px-6">
              <span className="text-3xl flex items-center justify-center mt-0">
                {t(`Request a Refund for `)} {meeting.meetingDetails.title}!
              </span>
              <hr className='mt-4' />
              <div id="input_meetingId" className="col-span-6 flex justify-center mt-4">

                <div className="w-full justify-start items-start rounded-md">
                  <Field
                    {...register('meetingId')}
                    error={errors?.meetingId?.message}
                    id="input_meetingId"
                    data-testid="input_meetingId"
                    type="text"
                    disabled={true}
                    defaultValue={meeting.id}
                    placeholder={t('meetingId')}
                  />

                </div>

              </div>
              <div id="input_participantId" className="col-span-6 flex justify-center mt-4">
                <div className="w-full justify-start items-start rounded-md">
                  <Field
                    {...register('participantName')}
                    error={errors?.participantName?.message}
                    id="input_participantId"
                    data-testid="input_participantId"
                    type="text"
                    disabled={true}
                    // Set the participant ID as the value but display the user's firstname
                    // value={meeting.meetingParticipant[0]?.user?.firstname || ''}
                    defaultValue={meeting.meetingParticipant[0]?.user.firstName} // Keep the participant ID for submission
                    placeholder={t('participantName')}
                  />



                </div>
              </div>


              <div className={"mt-4"}>
                <div className="my-2">
                  <div className="col-span-6 w-full -mt-4">
                    <select
                      {...register('refundCategory')}
                      id="input_refundCategory"
                      data-testid="input_refundCategory"
                      name="refundCategory"
                      className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      defaultValue=""
                    >
                      <option value="" disabled>
                        {t('category') + ' *'}
                      </option>
                      {refundCategoryData && refundCategoryData.map((parentCategory: any) => {
                        const childCategories = refundCategoryData.filter((childCategory: any) => childCategory.parentId === parentCategory.id);

                        return childCategories.length > 0 ? (
                          <optgroup label={parentCategory.reasonForRefund} key={parentCategory.id}>
                            <option value={parentCategory.id} key={`${parentCategory.id}-parent`}>
                              {parentCategory.reasonForRefund} (Parent Category)
                            </option>
                            {childCategories.map((childCategory: any) => (
                              <option value={childCategory.id} key={childCategory.id}>
                                {childCategory.reasonForRefund}
                              </option>
                            ))}
                          </optgroup>
                        ) : (
                          <option value={parentCategory.id} key={parentCategory.id}>
                            {parentCategory.reasonForRefund}
                          </option>
                        );
                      })}

                    </select>

                    {errors?.refundCategory?.message && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors.refundCategory.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={"mt-4"}>

                <div className='my-4 mt-0'>
                  <Field
                    {...register('refundReason')}
                    error={(errors?.refundReason?.message)}
                    id="input_refundReason"
                    data-testid="input_refundReason"
                    name="refundReason"
                    type="textarea"
                    rows={7}
                    placeholder={t(`elaborateYourRefundReason`) + ' *'}
                  />
                </div>

                <div className="my-6 items-center cursor-pointer">
                  <div>
                    <label htmlFor="file-upload-contact" className='justify-center flex-1'>
                      <div
                        className="my-2 w-full flex justify-center p-2 border-2 border-dashed rounded-md cursor-pointer">
                        <div className="space-y-1 text-center">
                          <div id="input_file" className="flex w-full justify-center text-center text-xs text-gray-300 py-2">
                            <div>
                              <input
                                type="file"
                                id="file-upload-contact"
                                data-testid="file-upload-contact"
                                accept=".png,.jpeg,.jpg"
                                style={{ display: 'none' }}
                                onChange={validateFormate}
                                multiple 
                              />
                            </div >
                            <div className="flex items-center">
                              <ToolTip tip={t('attachmentShouldBePngOrJpeg')} keyId="feedback">
                                <div className="flex items-center">
                                  <Icon icon="ADD" size="x-small" height="small" stroke='#010101' />
                                  <span className="ml-2">{t('addAttachment')}</span>
                                </div>
                              </ToolTip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div>
                    {fileValue && Array.isArray(fileValue) && fileValue.length > 0 && (
                      <div>
                        {fileValue.map((file, index) => (
                          <div key={index} className='inline-block mr-2 mb-2'>
                            {file && file.name && (
                              <Chip
                                data-testid={`fileName-${file.name}`}
                                // onClickClose={() => removeFile(file.name)} // Pass file name as an argument
                                isSelected={true}
                                key={index}
                                removeCatId={`btn_removeCat_${index}`}
                                value={file.name}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="p-5 flex gap-2 -mt-2">
              <Button
                onClick={resetForm}
                color="transparent"
                id="btn_cancel"
                data-testid="btn_goBack"
                className='uppercase text-sm'
              >
                {t(`goBack`)}
              </Button>
              <Button
                submit autoFocus disabled={false}
                color="filteredData"
                id="btn_submit"
                data-testid="btn_submit"
              >
                {t(`confirm`)}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </form>
    </>
  )
}
