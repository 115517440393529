import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownLeftAndUpRightToCenter } from '@fortawesome/free-solid-svg-icons'
import { ToolTip } from 'base/tooltip/tooltip'
import { useTranslation } from 'react-i18next'

interface CustomPipButtonProps {
  onClick: any
  isPipEnabled?: boolean
}

export default function CustomPipButton(props: CustomPipButtonProps) {
  const { onClick, isPipEnabled } = props
  const { t } = useTranslation()

  return (
    <>
      <ToolTip tip={t('pip')} theme="transparent-dark">
        <button
          id="btn_pip"
          onClick={onClick}
          className={
            isPipEnabled
              ? ''
              : 'py-3 px-4 rounded-full border font-medium bg-gray-200 text-gray-700  hover:bg-gray-50 transition-all text-sm'
          }
        >
          <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
        </button>
      </ToolTip>
    </>
  )
}
