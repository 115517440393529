import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const EmptyChatView = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className={`text-gray-500 text-center text-2xl font-medium mb-5`}>
          {t('youDontHaveAnyMessages')}
      </div>
      <div className='h-10 w-11/12 rounded-lg text-base mx-5 bg-BeeMG-yellow' >
        <div id="chk_findPeople" data-testid="chk_findPeople" className='flex h-full justify-center items-center'>
          <Link to={`/s/collaborator`} className="p-2">
            {t('findSomeoneToTextTo')}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default EmptyChatView;
