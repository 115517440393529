import Field from '../../Components/base/field/field'
import { MeetingDetails } from '../../models/meetingDetails.model'

interface _EventTitleProps {
    meetingDetails: MeetingDetails | undefined
    register: any
    errors?: any
}

export default function EventTitle(props: _EventTitleProps) {
    const { meetingDetails, register, errors } = props
    
    return (
        <div>
            <Field
                {...register('title')}
                error={errors?.title?.message}
                name="title"
                data-testid="input_title"
                id="input_title"
                type="text"
                defaultValue={meetingDetails?.title}
            />
        </div>
    )
}