import { Policies } from "../models/policy.model"
import { CancellationPolicy } from "../models/cancellationPolicy.model"
import { allApis } from "./allApis";

export const policyApi =  allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getTermsAndConditions: builder.query<Policies, void>({
            query: () => "policies/terms/termsAndConditions"
        }),
        getEventCreationPolicy: builder.query<Policies, void>({
            query: () => "policies/event/eventCreationPolicy"
        }),
        getCancellationPolicy: builder.query<CancellationPolicy[], void>({
            query: () => "cancellationPolicy"
        }),
        // getCancellationPolicyByID: builder.query<CancellationPolicy, string>({
        //     query: (id) => `cancellationPolicy/${id}`
        // }),
    })
})

export const { useGetTermsAndConditionsQuery,useGetCancellationPolicyQuery, useGetEventCreationPolicyQuery } = policyApi;