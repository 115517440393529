import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSettingValue } from '../../Services/settingReducer'

const CreatorsImage: FC = () => {
  const { t } = useTranslation()
  const baseImageURL = useGetSettingValue('IMAGE_URL');

  const style = {
    image: `inline z-0 object-cover w-full h-full`,
    textOverlay1: `w-full text-white text-2xl lg:text-4xl font-semibold text-left`,
    textOverlay2: `w-full text-white text-sm lg:text-lg text-left font-normal`,
  }

  return (
    <>
      <div className="relative w-full overflow-hidden h-[10rem] md:h-[13rem]">
        <img
          className={style.image}
          src={`${baseImageURL}/new/creator-banner.webp`}
          alt="creatorsImage"></img>
        <div className='absolute bottom-4 flex flex-col px-4 lg:pl-[10%] '>
          <div className={style.textOverlay1}>
            {t('globalCreators')}{' '}
            {t('network')}
          </div>
          <div className={style.textOverlay2} >
            {t('engageDirectlyWithExperiencedPeople')}{' '}
            {t('ofAMultitudeOfASelectedCategory')}
          </div>
        </div>
      </div>
      <div className="divide-y divide-fuchsia-300"></div>
    </>
  )
}

export default React.memo(CreatorsImage)

