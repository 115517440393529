/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../Components'
import Icon from '../../../Components/base/icon/icon'
import Loader from '../../../Components/base/loader/loader'
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../Components/base/modal/modal'
import { ToolTip } from '../../../Components/base/tooltip/tooltip'
import { toastSuccess } from '../../../Components/toast'
import { useUploadCoverVideo } from '../../../Hooks/UserProfile'
import { useGetSettingValue } from '../../../Services/settingReducer'
import { getCurrentUser } from '../../../Services/userReducer'
import { useAppSelector } from '../../../Store/hooks'



interface UploadCoverVideoProps {
  videoModalOpen: boolean
  toggleVideoModalOpen: (value: boolean) => void
}

const UploadCoverVideo = (props: UploadCoverVideoProps) => {
  const { videoModalOpen, toggleVideoModalOpen } = props
  
  const currentUser = useAppSelector(getCurrentUser)
  
  const { t } = useTranslation()
  const imageURL = useGetSettingValue('IMAGE_URL')

  const {
    close,
    selectedFile,
    onFileSelected,
    deleteVideo,
    uploadVideo,
    videoError,
    videoSizeFormat,
    deleteSelectedVideo,
    deleteUserVideoLoading,
    uploadUserVideoLoading,
    isDeleteUserVideoSuccess,
    isUploadUserVideoSuccess,
    setVideoError
  } = useUploadCoverVideo({ toggleVideoModalOpen })

  useEffect(() => {
    if (isDeleteUserVideoSuccess) {
      toastSuccess(t('yourIntro/ProfleVideoDeletedSuccessfully'));
    }
  }, [isDeleteUserVideoSuccess])

  useEffect(() => {
    if (isUploadUserVideoSuccess) {
      toastSuccess(t('yourIntro/ProfleVideoUploadedSuccessfully'));
    }
  }, [isUploadUserVideoSuccess])

  useEffect(() => {
    if (!videoModalOpen) {
      setVideoError(false);
    }
  }, [videoModalOpen]);

  return (
    <Modal
      isOpen={videoModalOpen}
      toggle={() => {
        close()
      }}
      closeOnClickOutside={false}>
      <div className="p-3">
        <div className="flex flex-row justify-between">
          <ModalHeader>
            <div className="flex flex-row gap-x-2 items-center">
              <div>{t('coverVideo')}</div>
              <ToolTip tip='videoFormatsOf.MPG,.GIF,.WMV,.VOB,.WebVTTAnd.AVIAreNotSupported' theme="BeeMG-gray" keyId='video'>
                <Icon icon="QUESTION" size="x-small" />
              </ToolTip>
            </div>
          </ModalHeader>
          <ModalCloseButton
            toggle={() => {
              close()
            }}
          />
        </div>

        <ModalBody>
          <div className="flex flex-col gap-y-5 flex-1 min-h-[20rem] w-full relative max-h-[70vh]">
            {(deleteUserVideoLoading || uploadUserVideoLoading) && (
              <div className="absolute z-20 w-full h-full flex flex-1 justify-center items-center mb-10 -mt-7">
                <Loader />
              </div>
            )}

            <div
              data-testid="file-upload-video"
              className="flex flex-col justify-center items-center w-full min-h-[20rem]">
              {!currentUser.userVideo && !selectedFile?.name && (
                <div className="h-full w-full mt-1 flex flex-1 justify-center items-center p-1 py-6 border-2  border-BeeMG-yellow border-dashed rounded-md cursor-pointer relative mx-3">
                  <input
                    className="space-y-1 text-center flex text-lg text-black font-medium absolute w-full opacity-0 py-6"
                    type="file"
                    id="file-upload-video"
                    accept="video/*"
                    onChange={onFileSelected}
                  />
                  {t('(clickOrDropVideo)')}
                </div>
              )}

              {selectedFile?.name && (
                <video
                  controls
                  className="mt-1 flex flex-1 justify-center items-center rounded-md cursor-pointer relative max-h-[50vh]">
                  <source src={URL.createObjectURL(selectedFile)} />
                </video>
              )}

              {currentUser.userVideo && !selectedFile?.name && (
                <video
                  controls
                  className="mt-1 flex flex-1 justify-center items-center rounded-md cursor-pointer relative max-h-[50vh]">
                  <source
                    src={`${imageURL}/${currentUser.userVideo}`}
                    type="video/mp4"
                  />
                </video>
              )}
            </div>

            {selectedFile?.name && (
              <div className="flex flex-col gap-y-2">
                <div className="flex flex-col gap-y-1">
                  <p>
                    {t('fileName')}: {selectedFile?.name}
                  </p>
                  <p>
                    {t('fileType')}: {selectedFile?.type}
                  </p>
                  <p>
                    {t('size')}: {selectedFile?.size ? (selectedFile.size / 1048576).toFixed(2) : '0'} {t('mb')}
                  </p>
                </div>

                <div className="flex flex-row gap-x-3">
                  <Button
                    className="mx-0 font-medium normal-case"
                    id="btn_videoUpload"
                    disabled={uploadUserVideoLoading}
                    onClick={() => uploadVideo()}>
                    {t('upload')}
                  </Button>

                  <Button
                    onClick={() => deleteSelectedVideo()}
                    className="mx-0 font-medium normal-case"
                    disabled={uploadUserVideoLoading}
                    id="btn_removeVideo">
                    {t('cancel')}
                  </Button>
                </div>
              </div>
            )}

            {videoError && (
              <small id="error_videoMessage" className="text-red-600">
                {' '}
                * {t('warning!SizeExceedsThan')} {videoSizeFormat}
              </small>
            )}

            {currentUser.userVideo && (
              <div className="flex flex-row justify-center items-center">
                <Button
                  id="btn_deleteVideo"
                  className="mx-0 font-medium normal-case"
                  disabled={uploadUserVideoLoading}
                  onClick={() => deleteVideo()}>
                  {t('deleteVideo')}
                </Button>
              </div>
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default UploadCoverVideo
