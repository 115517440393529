import { Amplify } from 'aws-amplify';

export function configureAWS(cognitoKeys: any, linkData: string) {
    const oauth = {
        domain: cognitoKeys.domain,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: linkData + 'loginSuccessful/',
        redirectSignOut: linkData + 'signIn/',
        responseType: 'token'
    };
    
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            identityPoolId: cognitoKeys.identityPoolId,
            region: cognitoKeys.region,
            userPoolId: cognitoKeys.userPoolId,
            userPoolWebClientId: cognitoKeys.userPoolClientId,
            authenticationFlowType: 'USER_PASSWORD_AUTH',
            oauth: oauth
        }
    });
}