import { BlockedRequests } from "../models/blockedRequests.model";
import { allApis } from "./allApis";

export const blockRequestApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
      // block Request API
      blockRequest: builder.mutation<BlockedRequests, Partial<BlockedRequests>>({
        query: (blockedRequest) => ({
            url: `blockedRequests`,
            method: 'POST',
            body: blockedRequest
        }),
        invalidatesTags: ['blockedRequests']
    }),

    getBlockedUserById: builder.query<BlockedRequests, string>({
        query: (id) => `blockedRequests/${id}`
    }),
  })
});
export const { useBlockRequestMutation,
    useGetBlockedUserByIdQuery } = blockRequestApi;