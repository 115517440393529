import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { MeetingDetails } from "../models/meetingDetails.model"
import { useGetBlockedUserByIdQuery } from "../Services/blockedRequestApi"
import { useGetMeetingDetailsQuery } from "../Services/meetingDetailsApi"
import { useGetSettingValue } from "../Services/settingReducer"
import { getCurrentUser } from "../Services/userReducer"

export const useViewEventHook = (id?: string) => {

  const [loading, setLoading] = useState(false)
  const imageData = useGetSettingValue('IMAGE_URL')
  const videoData = useGetSettingValue('VIDEO_FILE_URL')

  const { data: meetingDetails, isSuccess: eventDataSuccess, isLoading: meetingDetailsLoading, refetch: eventViewRefetch, isFetching: eventViewRefetching } = useGetMeetingDetailsQuery(id ? id : '', { skip: !id })
  const [noData, setNoData] = useState<boolean>(false)

  if (!eventDataSuccess) {
    if (!loading) {
      setLoading(true)
    }
  }

  if (!id) {
    setNoData(true)
  }

  return {
    loading,
    setLoading,
    meetingDetails,
    imageData,
    videoData,
    meetingDetailsLoading,
    noData,
    eventViewRefetch,
    eventViewRefetching,
  }
}

export const useViewEventCheckHook = ({ hostDetails }: { hostDetails: MeetingDetails }) => {

  const currentUser = useSelector(getCurrentUser)
  const [displayEventHostName, setDisplayEventHostName] = useState<string>()
  const [displayEventAnonymous, setDisplayEventAnonymous] = useState<'ANONYMOUS' | 'NOTANONYMOUS'>()
  const [hostNavigation, setHostNavigation] = useState<'VIEWPROFILE' | 'VIEWHOST' | 'BLOCKEDFORRFM' | 'SELF'>()
  const { data: BlockedData, isSuccess: BlockedDataSuccess } = useGetBlockedUserByIdQuery(hostDetails?.host?.id ? hostDetails?.host?.id : '')


  useEffect(() => {
    if (hostDetails?.isAnonymous) {
      setDisplayEventHostName(hostDetails?.host.nickName)
      setDisplayEventAnonymous('ANONYMOUS')
    } else if (!hostDetails?.isAnonymous) {
      setDisplayEventHostName(hostDetails?.host?.firstName + ' ' + hostDetails?.host?.lastName)
      setDisplayEventAnonymous('NOTANONYMOUS')
    }
    if (currentUser?.id === hostDetails?.host?.id || hostDetails?.host?.anonymous !== hostDetails?.isAnonymous) {
      setHostNavigation('SELF');
    } else if (BlockedData) {
      setHostNavigation('BLOCKEDFORRFM')
    }
    else if (currentUser?.id !== hostDetails?.host?.id && hostDetails?.isAnonymous === false && !BlockedData) {
      setHostNavigation('VIEWHOST')
    } else if (currentUser?.id !== hostDetails?.host?.id && hostDetails?.isAnonymous === true && !BlockedData) {
      setHostNavigation('VIEWPROFILE')
    }

  }, [hostDetails, currentUser, BlockedDataSuccess, BlockedData]);

  return {
    displayEventHostName, displayEventAnonymous, hostNavigation,
  }
}