import { Preference } from '../../models/preference.model'
import { useTranslation } from 'react-i18next'
import NewIcon from '../../Components/base/icon/newIcons'
import { useCurrencyHook } from '../../Hooks/CurrencyHooks'

interface _TotalDebitCardProps {
    preference: Preference
    creditData: any
}

export default function TotalDebitedCard(props: _TotalDebitCardProps) {
    const { preference, creditData } = props
    const { t } = useTranslation()
    const { formatCurrency } = useCurrencyHook();
    return (
      <div>
        <div className="flex pl-12 p-8 border-b-2">
          <div className="flex flex-row items-center justify-between w-full gap-x-4">
            <div className="flex flex-col">
              <div className="text-sm font-semibold">
                {t('amountDebitedThisMonth')}
              </div>
              <div className="text-2xl font-medium text-red-700">
                {formatCurrency(creditData?.totalDebit, preference.currency)}
              </div>
              <div className="flex text-sm font-medium gap-x-1">
                <div className="text-red-700">
                  {`${
                    creditData?.debitPercentage === undefined
                      ? '0'
                      : creditData?.debitPercentage
                  }`}
                  {'%'}
                </div>
                <div>{t('higherThanLastMonth')}</div>
              </div>
            </div>
            <NewIcon icon="REDDOWN_ARROW" size="large" stroke="none" />
          </div>
        </div>
      </div>
    )
}
