import { format, isEqual } from 'date-fns';
import { isSameSecond, isAfter, isBefore } from 'date-fns'

export const sameOrBefore = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : isBefore(d1, d2) ? true : false
}

export const sameOrAfter = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : isAfter(d1, d2) ? true : false
}

export const isRangeBetween = (
  [r1a, r1b] = [new Date(), new Date()],
  [r2a, r2b] = [new Date(), new Date()],
  equalCounts = true,
) => {
  return equalCounts
    ? sameOrBefore(r2a, r1a) && sameOrAfter(r2b, r1b)
    : isBefore(r2a, r1a) && isAfter(r2b, r1b)
}

export const isBetween = (date: Date, from: Date, to: Date, isBeforeEqual = false, isAfterEqual = false) => {
  return (isBeforeEqual ? (isEqual(from, date) || isBefore(from, date)) : isBefore(from, date)) &&
    (isAfterEqual ? (isEqual(to, date) || isAfter(to, date)) : isAfter(to, date));
};

/// converting to toISOString
export function getUTCDateTime(date: Date | string) {
  if (typeof date === 'string') {
    return new Date(date).toISOString();
  }

  return date.toISOString();
}
export function getUTCDateTimeFormatted(date?: string) {
  let formatDate = '';
  if (date) {
    formatDate = date.slice(0, 5) + ' ' + date.slice(6, 8);
  }

  const dateTime = new Date('27 july 2022 ' + formatDate);
  return dateTime.toUTCString().split(' ')[4];
}
/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
 * @returns formattedDate Dec 08, 2021
 * 
 * https://github.com/prantlf/date-fns-timezone/blob/HEAD/docs/API.md#api-reference
 */

export function formatDateToTimezone(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "MMM d, yyyy";
  return format(new Date(dateTimeUTC), pattern);
}

export function formatDateToTimezone2(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "MMM d yyyy";
  return format(new Date(dateTimeUTC), pattern);
}

export function formatMonthDateToTimezone(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "MMM d";
  return format(new Date(dateTimeUTC), pattern);
}

/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
 * @param timezone  "Asia/Tokyo"
 * @returns formattedTime 11:59 AM
 */

export function formatTimeToTimezone(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "h:mm a"
  return format(new Date(dateTimeUTC), pattern);
}

/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
 * @param timezone  "Asia/Tokyo"
 * @returns formattedDateTime 08/12/2021 at 11:59 AM
 */

export function formatDateTimeToTimezone(dateTimeUTC: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "dd/MM/yyyy 'at' h:mm a";
  return format(new Date(dateTimeUTC), pattern);
}

/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
 * @param timezone  "Asia/Tokyo"
 * @returns formattedDateTime 08/12/21
 */

export function formatDateTimeToTimezoneMobile(dateTimeUTC: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "dd/MM/yy";
  return format(new Date(dateTimeUTC), pattern);
}

export function formatTimeToTimezoneMobile(dateTimeUTC: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "HH:mm";
  return format(new Date(dateTimeUTC), pattern);
}

export function formatDateTimeFullToTimezone(dateTimeUTC: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "yyyy-MM-dd HH:mm:ss.SSS";
  return format(new Date(dateTimeUTC), pattern);
}

export function secondsToTime(secs: number): { days: number, hours: number, minutes: number, seconds: number } {
  const days = Math.floor(secs / (3600 * 24))

  const divisor_for_hours = secs % (3600 * 24)
  const hours = Math.floor(divisor_for_hours / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return {
    days,
    hours,
    minutes,
    seconds
  };
}
export function getTimeDifference(startDateTime: any, endDateTime: any) {
  const timeDiff = new Date(endDateTime).getTime() - new Date(startDateTime).getTime()
  return Math.round(timeDiff / 60000)
}
