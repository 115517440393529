import { useTranslation } from "react-i18next"
import { Ledger, TransactionType } from "../../models/ledger.model"

export default function DisplayTransactionDescription({ transaction, cardType }: { transaction: Ledger, cardType?: TransactionType }) {
    const { t } = useTranslation()

    return (
        <>
            <span  id={`card_description-${transaction.id}`}  data-testid={`card_description-${transaction.id}`}>{t('transactionDescription')}{":"}{' '}{transaction.transactionDescription}</span>
            {transaction.meeting && transaction.meeting.reason && cardType === (TransactionType.Credit || TransactionType.Debit) &&
                <div id={`card_reason-${transaction.id}`} data-testid={`card_reason-${transaction.id}`}>{t('Reason')}{":"}{' '}{transaction.meeting.reason}</div>}
        </>

    )
}