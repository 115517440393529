import { MeetingDetails } from './../models/meetingDetails.model'
import { formatDateToTimezone, formatTimeToTimezone } from '../Utils/datetime'
import { meetingsTransform } from './meetingApi'
import { allApis } from './allApis'
import { PagedData } from '../models/pageData.model'

const meetingResponseTransform = (response: MeetingDetails) => {
  if (response?.meetings) {
    response.meetings = meetingsTransform(response.meetings)
  }

  if (response) {
    response.display_isAudioMeeting = response.videoType === 'Audio'
  }

  return response
}

const meetingDetailTransform = (meetingDetail: MeetingDetails) => {
  if (meetingDetail?.meetings) {
    meetingDetail.meetings = meetingDetail.meetings.map((meeting: any) => {
      meeting.display_date = formatDateToTimezone(meeting.fromDateTime)
      meeting.display_time = formatTimeToTimezone(meeting.fromDateTime) + ' - ' + formatTimeToTimezone(meeting.toDateTime)
      return meeting
    })
  }
  return meetingDetail;
}

const pageDataTransform = <T>(response: PagedData<T>, dataTransformer: any) => {
  if (response?.data) {
    response.data = response.data.map(dataTransformer);
  }
  response.page = {
    pageIndex: parseInt(String(response.page.pageIndex)) - 1,
    pageSize: parseInt(String(response.page.pageSize)),
    totalElements: parseInt(String(response.page.totalElements)),
    totalPages: parseInt(String(response.page.totalPages)),
    sortBy: response.page.sortBy ?? '',
  }

  return response;
}

export const meetingDetailsApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createMeetingDetails: builder.mutation<MeetingDetails, Partial<MeetingDetails>>({
      query: meetingDetails => {
        return {
          url: `meetingDetails`,
          method: 'POST',
          body: meetingDetails,
        }
      },
      invalidatesTags: ['MeetingDetails'],
    }),
    uploadEventVideo: builder.mutation<MeetingDetails, any>({
      query: (args) => {
        const { file, id } = args;
        let testData: FormData = new FormData();
        testData.append('file', file, file.name);

        return {
          url: `meetingDetails/videoUpload/` + id,
          method: 'POST',
          body: testData
        }
      },
      invalidatesTags: ['EventVideoUpload'],
    }),
    updateMeetingDetails: builder.mutation<MeetingDetails, Partial<MeetingDetails>>({
      query: meetingDetails => {
        return {
          url: `meetingDetails`,
          method: 'PUT',
          body: meetingDetails,
        }
      },
      invalidatesTags: (result, error, meetingDetails) => [{ type: 'MeetingDetail', id: meetingDetails?.id }],
    }),

    getMeetingDetails: builder.query<MeetingDetails, string>({
      query: meetingDetailsId => `meetingDetails/${meetingDetailsId}`,
      transformResponse: (res: MeetingDetails) => meetingResponseTransform(res),
      providesTags: (result, error, meetingDetailsId) => [{ type: 'MeetingDetail', id: meetingDetailsId },
      { type: 'MeetingRegister', id: meetingDetailsId },
        'MeetingFavorite',  'AddMeetingDetailsImage', 'UpdateMeetingDetailsImage', 'EventVideoUpload'],
    }),
    getMeetingDetailsByUser: builder.query<MeetingDetails[], string>({
      query: userId => `meetingDetails/user/${userId}`,
      transformResponse: (res: MeetingDetails[]) => res.map(meetingResponseTransform),
      providesTags: ['MeetingDetails', 'MeetingFavorite',],
    }),
    // checkUserAlreadyCreatedEvent: builder.query<boolean, string>({
    //   query: userId => `meetingDetails/checkUser/user/${userId}`,
    // }),
    getAllMeetingDetails: builder.query<PagedData<MeetingDetails>, MeetingDetails>({
      query: (page) => {
        return {
          url: `meetingDetails`,
          params: page,
        }
      },
      transformResponse: (response: PagedData<MeetingDetails>) => {
        return pageDataTransform(response, meetingDetailTransform)
      },
      providesTags: ['MeetingDetails', 'AddMeetingDetailsImage'],
    }),
    // getVideo: builder.query<MeetingDetails[], any>({
    //   query: videoName => `meetingDetails/video/${videoName}`,
    // }),
    deleteEventVideo: builder.mutation<boolean, MeetingDetails>({
      query: meetingDetails => {
        return {
          url: `meetingDetails/${meetingDetails.id}/${meetingDetails.video}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, meetingDetails) => [{ type: 'MeetingDetail', id: meetingDetails?.id }],
    }),
    deleteMeetingDetails: builder.mutation<boolean, MeetingDetails>({
      query: meetingDetails => {
        return {
          url: `meetingDetails/${meetingDetails.id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['MeetingDetails'],
      
    }),
  }),
})
export const {
  useGetAllMeetingDetailsQuery,
  useGetMeetingDetailsQuery,
  useGetMeetingDetailsByUserQuery,
  useCreateMeetingDetailsMutation,
  useUpdateMeetingDetailsMutation,
  useDeleteMeetingDetailsMutation,
  useUploadEventVideoMutation,
  useDeleteEventVideoMutation
} = meetingDetailsApi
