import { Meeting, MeetingType } from './../models/meeting.model';
import { getCurrentUser, getPreference } from './../Services/userReducer';
import { useState, useEffect } from "react";
import { secondsToTime } from "../Utils/datetime";
import { useSelector } from 'react-redux';
import { Verified } from '../models/user.model';
import { useAppSelector } from '../Store/hooks';
import { getTimezoneOffsetString } from '../Utils/helper';
import { useGetUpcomingMeetingOfUserQuery } from '../Services/meetingApi';
import { getIsMeetingEnded } from '../Services/livekitReducer';

type InitDisplayTimer = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
};

export const useCountDownHook = ({ meetingObj, next6Hour }: { meetingObj?: Meeting, next6Hour: boolean }) => {
    const [displayTimerFormatted, setDisplayTimerFormatted] = useState<string>();
    const [nextEventFromDateTime, setNextEventFromDateTime] = useState<Date>();
    const [nextEventToDateTime, setNextEventToDateTime] = useState<Date>();
    const [nextEventExpectedEndDateTime, setNextEventExpectedEndDateTime] = useState<Date>();

    const [navigateTo, setNavigateTo] = useState<'Attending' | 'MyEvents'>();
    const [offsetString, setOffsetString] = useState<string>('');

    const currentUser = useSelector(getCurrentUser)
    const preference = useAppSelector(getPreference)
    const isMeetingEnded = useAppSelector(getIsMeetingEnded)

    const [showJoinButton, setShowJoinButton] = useState(false);
    const [showCountDown, setShowCountDown] = useState(false);
    const [meeting, setMeeting] = useState<Meeting>()
    const [headerNavigate, setHeaderNavigate] = useState<'ALLOWED' | 'NOTALLOWED'>();
    const { data: upcomingMeeting, refetch: upcomingMeetingRefetch } = useGetUpcomingMeetingOfUserQuery()

    useEffect(() => {
        if (preference && preference.timezoneUtc) {
            setOffsetString(getTimezoneOffsetString({ timezoneUtc: preference.timezoneUtc }))
        }
    }, [preference])

    useEffect(() => {
        if (next6Hour && meetingObj) {
            setMeeting(meetingObj)
        }
        else if (!next6Hour && upcomingMeeting) {
            setMeeting(upcomingMeeting)
        }

    }, [upcomingMeeting, meetingObj, next6Hour, isMeetingEnded])

    const setDisplayTimerFormat = ({ days, hours, minutes, seconds }: InitDisplayTimer) => {
        let daysFormatted = days > 0 ? days + 'd: ' : '';

        if (days > 0 || hours > 0) {
            daysFormatted += hours + 'h: '
        }

        if (days > 0 || hours > 0 || minutes > 0) {
            daysFormatted += minutes + 'm: '
        }

        if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
            daysFormatted += seconds + 's'
        }
        setDisplayTimerFormatted(daysFormatted);
    };

    useEffect(() => {
        if (meeting && Object.keys(meeting).length > 0) {
            setNextEventFromDateTime(new Date(meeting.fromDateTime));
            setNextEventToDateTime(new Date(meeting.toDateTime));
            setNextEventExpectedEndDateTime(new Date(meeting.expectedEndAt));

            if (currentUser?.id === meeting.user?.id && meeting.type === MeetingType.Type1) {
                setNavigateTo('MyEvents');
            } else {
                setNavigateTo('Attending');
            }
        }
        if (currentUser?.phoneVerified === Verified.NotComplete || currentUser?.emailVerified === Verified.NotComplete) {
            setHeaderNavigate('NOTALLOWED');
        } else if (currentUser?.phoneVerified === Verified.Complete && currentUser?.emailVerified === Verified.Complete) {
            setHeaderNavigate('ALLOWED');
        }
    }, [meeting, currentUser]);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (nextEventFromDateTime) {
                const timeDiff = nextEventFromDateTime.getTime() - new Date().getTime();
                if (timeDiff <= 0) {
                    clearInterval(myInterval);

                    if (nextEventToDateTime) {
                        let toTimeDiff = nextEventToDateTime.getTime() - new Date().getTime();

                        if (nextEventExpectedEndDateTime) {
                            const expectTimeDiff = nextEventExpectedEndDateTime.getTime() - new Date().getTime();
                            toTimeDiff = expectTimeDiff > toTimeDiff ? expectTimeDiff : toTimeDiff;
                        }

                        if (toTimeDiff > 0) {
                            !showJoinButton && setShowJoinButton(true);
                            showCountDown && setShowCountDown(false);
                        } else {
                            showJoinButton && setShowJoinButton(false);
                            showCountDown && setShowCountDown(false);
                        }
                    }

                } else {
                    const diff = secondsToTime(timeDiff / 1000);

                    if (diff.seconds === 0 && diff.minutes === 0 && diff.hours === 0 && diff.days === 0) {
                        clearInterval(myInterval);
                    } else {
                        upcomingMeetingRefetch()
                        setDisplayTimerFormat(diff);
                        showJoinButton && setShowJoinButton(false);
                        !showCountDown && setShowCountDown(true);
                    }
                }
            } else {
                clearInterval(myInterval);
                showJoinButton && setShowJoinButton(false);
                showCountDown && setShowCountDown(false);
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    return {
        nextEventFromDateTime,
        meeting,
        showJoinButton,
        showCountDown,
        displayTimerFormatted,
        navigateTo,
        headerNavigate,
        timeZone: preference?.timezone,
        offsetString
    }
}