import React, { useState } from "react";
import { useDidUpdateEffect } from "./use-did-update-effect";

import Tag from "./tags";

export interface TagsInputProps {
  name?: string;
  placeHolder?: string;
  value?: string[];
  onChange?: (tags: string[]) => void;
  error?: any;
  onBlur?: any;
  separators?: string[] | undefined;
  disableBackspaceRemove?: boolean;
  onExisting?: (tag: string) => void;
  onRemoved?: (tag: string) => void;
  disabled?: boolean;
  isEditOnRemove?: boolean;
  beforeAddValidate?: (tag: string, existingTags: string[]) => boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  classNames?: {
    input?: string;
    tag?: string;
  };
}

const defaultSeparators = ["Enter"];

export const TagsInput = ({
  name,
  placeHolder,
  value,
  onChange,
  error,
  onBlur,
  separators = [],
  disableBackspaceRemove,
  onExisting,
  onRemoved,
  disabled,
  isEditOnRemove,
  beforeAddValidate,
  onKeyUp,
  classNames,
}: TagsInputProps) => {
  const [tags, setTags] = useState<string[]>(value || []);

  useDidUpdateEffect(() => {
    onChange && onChange(tags);
  }, [tags]);
  
  useDidUpdateEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      setTags(value as string[]);
    }
  }, [value]);

  const parseTextToTags = (text: string) => {
    if (!text) return;
  
    const separators = [',', ';', ' '];
    const newTags = text.split(new RegExp(separators.join('|'), 'g')).map(newTag => newTag.trim());
  
    const uniqueNewTags = Array.from(new Set(newTags));
  
    const newTagsToAdd = uniqueNewTags.filter(newTag => {
      if (!newTag) return false;
      if (tags.includes(newTag)) return false;
      if (beforeAddValidate && !beforeAddValidate(newTag, tags)) return false;
      return true;
    });
  
    setTags([...tags, ...newTagsToAdd]);
  };
  

  const handleOnKeyUp = (e: any) => {
    e.stopPropagation();

    const text = e.target.value;

    // backspace remove
    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      e.key === "Backspace"
    ) {
      e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : "";
      setTags([...tags.slice(0, -1)]);
    }

    // add
    if (text && ([...separators, ...defaultSeparators]).includes(e.key)) {
      e.preventDefault();

      parseTextToTags(text);
      e.target.value = "";
    }
  };

  const onTagRemove = (text: string) => {
    setTags(tags.filter(tag => tag !== text));
    onRemoved && onRemoved(text);
  };

  const style= {
    border: tags.length>0 ?'1px solid #ccc':'',
    borderRadius: tags.length>0 ?'10px':'',
    padding: tags.length>0 ?'10px':'',
    margin:tags.length>0 ? '5px':'',
    display: tags.length>0 ?'block':'',
  }

  // TODO : need to remove excess invites
  
  return (
    <div
      aria-labelledby={name}
      className="chips"
      style={style}
    >
      {tags.map((tag: string, index) => (
        <Tag
          key={tag}
          className={classNames?.tag}
          text={tag}
          remove={onTagRemove}
          error={error ? error[index] : null} 
          keyId={index} />
      ))}

      <input
        className={`leading-5 text-sm relative flex-1 w-full rounded-md p-input text-gray-400 md:text-base placeholder:text-sm placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent ring-1 ring-gray-300 border-solid border border-gray-300 h-8 ${disabled ? "bg-gray-200 border-none ring-0" : ""}`}
        type="text"
        autoFocus
        id={`inputEmail`}
        data-testid='inputEmail'
        name={name}
        placeholder={placeHolder}
        onKeyDown={handleOnKeyUp}
        onBlur={(e) => {
          parseTextToTags(e.target.value);  
          onBlur && onBlur(e);
          e.target.value = "";
        }}
        disabled={disabled}
        onKeyUp={onKeyUp}
        style={{
          border: 'none',
          appearance: 'none',
        }}
      />
    </div>
  )
};
