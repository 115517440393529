import { allApis } from './../Services/allApis';
import { userCategoryApi } from './../Services/userCategoryApi';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { policyApi } from './../Services/policyApi';
import socketReducer from '../Services/socketReducer';
import tokenReducer from '../Services/tokenReducer';
import userReducer from '../Services/userReducer';
import chatReducer from './Chat/chat';
import redirectUrlReducer from '../Services/rediretUrlReducer';
import { meetingParticipantApi } from './../Services/meetingParticipantApi';
import { meetingRequestApi } from '../Services/meetingRequestApi';
import { userPolicyAgreementsApi } from './../Services/userPolicyAgreementsApi';
import { preferenceApi } from './../Services/preferenceApi';
import { combineReducers, AnyAction, Reducer } from 'redux'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { meetingApi } from '../Services/meetingApi';
import { ledgerApi } from '../Services/ledgerApi';
import { settingApi } from '../Services/setting.service';
import { userApi } from '../Services/userApi';
import { inviteApi } from './../Services/inviteApi';
import { messageApi } from '../Services/messageApi';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { meetingNotficationListApi } from '../Services/meetingNotificationListApi';
import { videoMeetingApi } from '../Services/videoMeetingApi'
import { allOthersApi } from '../Services/allOthersApi';
import { meetingDetailsApi } from '../Services/meetingDetailsApi';
import { meetingNotificationRemainderApi } from '../Services/meetingNotificationRemainderApi';
import currencyListReducer from '../Services/currencyListReducer';
import utilReducer from '../Services/utilReducer';
import onlineUsersReducer from '../Services/onlineUsersReducer';
import meetingReducer from '../Services/meetingReducer';
import livekitReducer from '../Services/livekitReducer';
import signUpReducer from '../Services/signUpReducer';
import { envApi } from '../Services/envApi';
import envReducer from '../Services/envReducer';
import settingReducer from '../Services/settingReducer';
import creatorReducer from '../Services/creatorReducer';
import myEventsReducer from '../Services/myEventsReducer';

const reducers = combineReducers({
  [allApis.reducerPath]: allApis.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [preferenceApi.reducerPath]: preferenceApi.reducer,
  [userPolicyAgreementsApi.reducerPath]: userPolicyAgreementsApi.reducer,
  [inviteApi.reducerPath]: inviteApi.reducer,
  [messageApi.reducerPath]: messageApi.reducer,
  [meetingApi.reducerPath]: meetingApi.reducer,
  [meetingRequestApi.reducerPath]: meetingRequestApi.reducer,
  [ledgerApi.reducerPath]: ledgerApi.reducer,
  [policyApi.reducerPath]: policyApi.reducer,
  [meetingNotficationListApi.reducerPath]: meetingNotficationListApi.reducer,
  [meetingParticipantApi.reducerPath]: meetingParticipantApi.reducer,
  [videoMeetingApi.reducerPath]: videoMeetingApi.reducer,
  [allOthersApi.reducerPath]: allOthersApi.reducer,
  [meetingDetailsApi.reducerPath]: meetingDetailsApi.reducer,
  [meetingNotificationRemainderApi.reducerPath]: meetingNotificationRemainderApi.reducer,
  [userCategoryApi.reducerPath]: userCategoryApi.reducer,
  [envApi.reducerPath]: envApi.reducer,
  user: userReducer,
  token: tokenReducer,
  chatmessage: chatReducer,
  socket: socketReducer,
  redirecturl: redirectUrlReducer,
  currency: currencyListReducer,
  util: utilReducer,
  onlineUsers: onlineUsersReducer,
  meetings: meetingReducer,
  livekit: livekitReducer,
  signUp: signUpReducer,
  env: envReducer,
  settings: settingReducer,
  creator: creatorReducer,
  myEvents: myEventsReducer,
})

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    //clear everything but not the env and settings state.
    Object.keys(state).forEach((key) => {
      if (key !== 'env' && key !== 'settings') {
        delete state[key];
      }
    })
  }
  return reducers(state, action)
}

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['theme', 'user', 'token', 'redirecturl', 'currency', 'env'],
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key', // To Do
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(allApis.middleware)
      .concat(settingApi.middleware)
      .concat(userApi.middleware)
      .concat(preferenceApi.middleware)
      .concat(userPolicyAgreementsApi.middleware)
      .concat(inviteApi.middleware)
      .concat(messageApi.middleware)
      .concat(meetingApi.middleware)
      .concat(meetingRequestApi.middleware)
      .concat(ledgerApi.middleware)
      .concat(meetingNotficationListApi.middleware)
      .concat(meetingParticipantApi.middleware)
      .concat(allOthersApi.middleware)
      .concat(videoMeetingApi.middleware)
      .concat(meetingDetailsApi.middleware)
      .concat(policyApi.middleware)
      .concat(meetingNotificationRemainderApi.middleware)
      .concat(userCategoryApi.middleware)
      .concat(envApi.middleware);


    // TODO - enable for mobile development
    // if (__DEV__ && !process.env.JEST_WORKER_ID) {
    //   const createDebugger = require('redux-flipper').default
    //   middlewares.push(createDebugger())
    // }

    return middlewares
  },
})

const persistor = persistStore(store)
setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export { store, persistor }
