/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NoSession from '../../Components/NoSession';
import { Accordion } from '../../Components/base/accordion/accordion';
import Loading from '../../Components/base/loading/loading';
import { useRequestEventsHook } from '../../Hooks/RequestEventsHooks';
import { Request } from '../../models/meeting.model';
import { meetingComp } from '../AttendingEvents';

function ReceivedRequests() {
  const { search } = useLocation();
  const { requests, receivedRequests, requestsEventsLoading, requestsEventsSuccess, filterLatestRequests } = useRequestEventsHook();

  useEffect(() => {
    if (requests) {
      if (search && search === '?latest') {
        filterLatestRequests({ requestType: Request.ReceivedRequests, latest: true });
      } else {
        filterLatestRequests({ requestType: Request.ReceivedRequests, latest: false });
      }
    }
  }, [search, requests])

  if (requestsEventsLoading) {
    return <Loading />
  }

  if (receivedRequests.length === 0 || !requestsEventsSuccess) {
    return <NoSession type="events" className="h-[60vh] items-center justify-center" />
  }

  return (
    <div className="block lg:w-11/12 w-full px-3 mb-48 md:mb-36 lg:mb-28">
      <Accordion>
        {meetingComp(receivedRequests)}
      </Accordion>
    </div>
  )
}

export default ReceivedRequests