/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Icon from '../../Components/base/icon/icon'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import { toastError } from '../../Components/toast'
import { useChatErrorAndAllowed, useChatUserBlockedHook, useCheckOfflineMessages } from '../../Hooks/MessageHooks'
import { getCurrentChatUser, getSendVideoChatRequest, sendTextMessage, sendVideoRequest, setSendVideoChatRequest } from '../../Store/Chat/chat'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import NewIcon from '../../Components/base/icon/newIcons'

function MessageTypeArea() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()

  const currentChatUser = useAppSelector(getCurrentChatUser)
  const sendVideoChatRequest = useAppSelector(getSendVideoChatRequest)

  const { showUpdatePreferences, showCannotSend, handleMessageSize, maxTextSize, videoLimitExceeded, hasActiveVideoRequest } = useChatErrorAndAllowed()
  const { isBlockedByMe, isBlockedMe } = useChatUserBlockedHook()
  const { register, handleSubmit, resetField, getValues } = useForm()
  const isSendDisabled = ((!showUpdatePreferences && !showCannotSend) === false || isBlockedByMe(currentChatUser?.id))

  useCheckOfflineMessages()

  useEffect(() => {
    if (sendVideoChatRequest && currentChatUser && videoLimitExceeded !== undefined && videoLimitExceeded === false) {
      if (hasActiveVideoRequest) {
        dispatch(setSendVideoChatRequest(false))
        handleActiveVideoRequest()
        return;
      }
      dispatch(sendVideoRequest({ toUserId: currentChatUser.id, isBlocked: isBlockedMe(currentChatUser?.id) }))
      dispatch(setSendVideoChatRequest(false))
    }
  }, [currentChatUser, sendVideoChatRequest, videoLimitExceeded, hasActiveVideoRequest])

  useEffect(() => {
    if (currentChatUser && getValues('message') && getValues('message')?.length > 0) {
      resetField('message')
      handleMessageSize("")
    }
  }, [currentChatUser])

  const onSubmit = (data: any) => {

    if (!isSendDisabled && data.message && data.message?.trim() && data.message?.trim().length <= maxTextSize) {
      dispatch(sendTextMessage({ messageText: (data?.message).trim(), isBlocked: isBlockedMe(currentChatUser?.id) }))

      resetField('message')
      handleMessageSize("")
    }
  }

  const sendVideoRequestToServer = () => {
    if (currentChatUser) {
      if (videoLimitExceeded) {
        return;
      }
      dispatch(sendVideoRequest({ toUserId: currentChatUser.id, isBlocked: isBlockedMe(currentChatUser?.id) }))
    }
  }

  const handleActiveVideoRequest = () => {
    toastError(t('youHaveAnActiveRequestAlready'))
    return;
  }

  return (
    <div className="w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-row h-12 py-1">
        <input
          maxLength={parseInt(maxTextSize) + 1}
          className={`block w-full p-1 pl-3 mr-2 text-xs outline-none font-normal focus:border-orange-200 ${(showUpdatePreferences || showCannotSend) && `bg-gray-200`}`}
          style={{
            border: '1px solid #ced4da',
            borderRadius: '5px',
          }}
          type={''}
          {...register('message', {
            onChange: (e) => {
              handleMessageSize(e.target.value)
            },
            required: 'message is required',
          })}
          id="input_typeMessage"
          data-testid="input_typeMessage"
          placeholder={t('startTyping')}
          name="message"
          disabled={isSendDisabled}
        />
        <div
          className="flex flex-row gap-5">
          <button id="btn_sendMessage" data-testid="btn_sendMessage" type="submit" className="p-1 block md:hidden" disabled={isSendDisabled}>
            <div>
              <ToolTip tip='send' keyId='send'>
                <Icon icon="SEND" size="medium" height="small" className='cursor-pointer' />
              </ToolTip>
            </div>
          </button>
          <button
            id="btn_sendVideoRequest"
            data-testid="btn_sendVideoRequest"
            disabled={isSendDisabled || videoLimitExceeded}
            onClick={(e) => {
              e.preventDefault()
              if (hasActiveVideoRequest) {
                handleActiveVideoRequest();
                return;
              }
              sendVideoRequestToServer()
            }}
            className="p-1">
            <div>
              <ToolTip tip='chatVideoRequest' keyId='chatVideoRequest'>
                <NewIcon icon="VIDEO_CAM" size="medium" height="small" className='cursor-pointer' />
              </ToolTip>
            </div>
          </button>
        </div>
      </form>
    </div>
  )
}

export default MessageTypeArea
