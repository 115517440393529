import { createSlice } from "@reduxjs/toolkit";
import { RootState } from '../Store';
import { Meeting } from '../models/meeting.model';
import { meetingApi } from "./meetingApi";
import { CreatorLocalFilter, CreatorServerFilter } from "../models/creatorFilter.model";

interface CreatorState {
  creatorEvents: { [key: string]: Meeting },
  creatorFilter: CreatorLocalFilter,
  creatorServerFilter: CreatorServerFilter
}

const initialStateValue: CreatorState = {
  creatorEvents: {},
  creatorFilter: {} as CreatorLocalFilter,
  creatorServerFilter: {} as CreatorServerFilter
};

export const creatorSlice = createSlice({
  name: "creator",
  initialState: initialStateValue,
  reducers: {
    resetCreators: () => initialStateValue,
    changeCreatorFilter: (state, action) =>{
      state.creatorFilter = action.payload
    },
    changeCreatorServerFilter: (state, action) => {
      state.creatorServerFilter = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(meetingApi.endpoints.getAllMeetings.matchFulfilled, (state, action) => {
      action.payload.results.forEach((meeting) => state.creatorEvents[meeting.id] = meeting)
    });
    builder.addMatcher(meetingApi.endpoints.getMeeting.matchFulfilled, (state, action) => {
      if (action.payload) {
        state.creatorEvents[action.payload.id] = Object.assign(state.creatorEvents[action.payload.id] ?? {}, action.payload)
      }
    });
  }
});

export const getCreatorEvents = (state: RootState): { [key: string]: Meeting } => state.creator.creatorEvents;
export const getCreatorEventById = (state: RootState, id: string): Meeting => state.creator.creatorEvents[id]
export const getCreatorFilter= (state: RootState): CreatorLocalFilter => state.creator.creatorFilter as CreatorLocalFilter
export const getCreatorServerFilter= (state: RootState): CreatorServerFilter => state.creator.creatorServerFilter as CreatorServerFilter


export const { resetCreators, changeCreatorFilter, changeCreatorServerFilter } = creatorSlice.actions;
export default creatorSlice.reducer;
