import { format } from "date-fns";
import { useTranslation } from "react-i18next"
import { ChatDateLabel } from "../../Store/Chat/ChatModel"
import { DisplayFormattedDate } from "../../Components/formattedDateAndTime";

const DateLabel = ({ msg }: { msg: ChatDateLabel }) => {
  const { t } = useTranslation()

  const isToday = () => format(new Date(), "MMMM d, yyyy") === msg.date;

  return (
    <div className="flex flex-row gap-0 items-center justify-center">
      <div className="h-[0.5px] opacity-20 bg-gray-400 flex flex-1" />
      <div className="my-3 mx-auto leading-none px-2 py-1.5 text-xxs text-gray-600 font-medium shadow-sm">
        {isToday() ? t('today') : <DisplayFormattedDate displayDate={msg.date}/>}
      </div>
      <div className="h-[0.5px] opacity-20 bg-gray-400 flex flex-1" />
    </div>
  )
}

export default DateLabel;