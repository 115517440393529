/* eslint-disable react-hooks/exhaustive-deps */
import { InputHTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface AutoOptions {
  label: string
  value: string
}

interface AutocompleteProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  suggestions?: Array<AutoOptions>;
  defaultValue?: string
  onSelection: (value?: AutoOptions | unknown) => void;
  onChange?: (value?: string | unknown) => void;
  notFound?: string | ReactNode;
  error?: string | any;
  className?: string;
}

const style = {
  container: `relative mb-1`,
  default: `leading-5 text-base relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-400 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300`,
  disabled: `cursor-not-allowed`,
  label: `text-gray-700`,
  suggestion: {
    list: `shadow-xl rounded-md absolute top-full left-0 right-0 border w-auto md:max-w-full overflow-y-auto max-h-80 mt-2 bg-white p-2 z-20`,
    item: `rounded-md p-2 focus text-sm text-gray-700 cursor-pointer hover:bg-gray-200`,
    activeItem: 'bg-gray-300',
  },
  iconContainer: `absolute flex border border-transparent  rounded-md right-0 top-0 h-8 w-10`,
  icon: `flex items-center justify-center rounded-tr-md rounded-br-md text-gray-400 text-lg h-full w-full bg-white `,
  error: `ring-red-500 ring-1`,
  errorMessage: `text-sm text-red-500 mt-2`,
};

const Autocomplete = ({
  name,
  label,
  suggestions,
  defaultValue,
  onSelection,
  onChange,
  notFound,
  error,
  className,
  ...rest
}: AutocompleteProps) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<AutoOptions[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [value, setValue] = useState<string | AutoOptions | undefined>(defaultValue);
  const [displayLabel, setDisplayLabel] = useState('');
  const [selectedValue, setSelectedValue] = useState<AutoOptions>();

  const ref = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValue !== value && defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [defaultValue])

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (!(ref.current as any)?.contains(event.target)) {
        if (!showSuggestions) return;
        setShowSuggestions(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [showSuggestions, ref]);

  const handleChange = useCallback(
    (e: any) => {
      const userInput = e.currentTarget.value;
      let filteredSuggestions;

      if (userInput) {
        filteredSuggestions = suggestions?.filter((suggestion) => suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1) ?? [];
      } else {
        filteredSuggestions = suggestions ?? [];
      }

      setActiveSuggestion(0);
      setFilteredSuggestions(filteredSuggestions ?? []);
      setShowSuggestions(true);
      setValue(e.currentTarget.value);
    },
    [onSelection, suggestions],
  );

  const onClick = (e: any) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setValue(e.currentTarget.innerText);
  };

  const onKeyDown = (e: any) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setValue(filteredSuggestions[activeSuggestion].label);
    }

    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    }

    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  useEffect(() => {
    if (value) {
      let selectedOption = undefined;

      if (typeof value === 'string') {
        selectedOption = suggestions?.find((suggestion) => suggestion.label.toLowerCase() === value?.toLowerCase());
        setDisplayLabel(value);

        if (onChange) {
          onChange(value);
        }
      } else {
        selectedOption = value;
        setDisplayLabel(value.label);

        if (onChange) {
          onChange(value.label);
        }
      }

      if (selectedOption && selectedOption.value !== selectedValue?.value) {
        setSelectedValue(selectedOption);
        onSelection(selectedOption);
      }
    } else {
      setDisplayLabel('');

      if (onChange) {
        onChange('');
      }

      setSelectedValue(undefined);
      onSelection(undefined);
    }
  }, [value])

  let suggestionsListComponent;

  if (showSuggestions) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className={style.suggestion.list}>
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === activeSuggestion) {
              className = `${style.suggestion.item} ${style.suggestion.activeItem}`;
            }
            if (index !== activeSuggestion) {
              className = style.suggestion.item;
            }
            return (
              <li className={className} key={suggestion.value} onClick={onClick}>
                {suggestion.label}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="text-gray-700 text-sm">
          <em>{notFound}</em>
        </div>
      );
    }
  }

  return (
    <><div data-testid="txt_nickName" className={style.container}>
      {label && <label htmlFor={name} className={style.label}>
        {label}
      </label>}
      <input
        id={`input_${name}`}
        autoComplete="off"
        aria-invalid={!!error}
        className={`${style.default} ${error && style.error} ${className ? className : ''}`}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onFocus={() => setShowSuggestions(true)}
        value={displayLabel}
        {...rest}
      />
      <div data-testid="txt_suggestion" className={style.iconContainer} onClick={() => setShowSuggestions(!showSuggestions)}>
        <div className={style.icon} >
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
        </div>
      </div>
      {suggestionsListComponent}
    </div>
      {error && (
        <div role="alert" data-testid={error} id="error_message" className={style.errorMessage}>
          {t(error)}
        </div>
      )}
    </>
  );
};

export default Autocomplete;