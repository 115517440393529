import { Auth } from "aws-amplify";
import { useState } from "react";
import { allOthersApi } from "../Services/allOthersApi";
import { useGetCognitoKeysQuery } from "../Services/envApi";
import { preferenceApi } from "../Services/preferenceApi";
import { useGetSettingValue } from "../Services/settingReducer";
import { changeCognitoModelObject } from "../Services/signUpReducer";
import { changeToken } from "../Services/tokenReducer";
import { useCreateUserSSOMutation, userApi } from "../Services/userApi";
import { useCheckUserTermsPolicyQuery } from "../Services/userPolicyAgreementsApi";
import { changeCurrentUserDetail } from "../Services/userReducer";
import { useAppDispatch } from "../Store/hooks";
import { User, Verified } from "../models/user.model";
import { boolean } from "yup";

export const useLoginSuccessfulHook = () => {
  const { data: cognitoKeys, isSuccess: cognitoKeysSuccess } = useGetCognitoKeysQuery()
  const { data: userPolicyAgreementsData, isLoading: userPolicyAgreementsLoading } = useCheckUserTermsPolicyQuery();
  const linkData = useGetSettingValue('WEB_LINK');
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [navigateScreenSignIn, setNavigateScreenSignIn] = useState(false);
  const [isSSoUser, setIsSSoUser] = useState<boolean>(false);

  if (!cognitoKeysSuccess || !linkData) {
    if (loading) {
      setLoading(false);
    }
  }

  const handleSSOUserName = (name: string) => {  // Finds if the user is SSO or not
    if (name.includes('google')) {
      setIsSSoUser(true); 
    }
    else {
      setIsSSoUser(false);
    }
  }
  const getUser = () => {
    Auth.currentAuthenticatedUser().then((resultCurrent) => {
      const userData = {
        address: resultCurrent.signInUserSession.idToken.payload.address?.formatted,
        birthdate: resultCurrent.signInUserSession.idToken.payload.birthdate,
        email: resultCurrent.signInUserSession.idToken.payload.email,
        phone_number: resultCurrent.signInUserSession.idToken.payload.phone_number
      };
      if (userData.address === null) {
        delete userData.address;
      }

      if (userData.birthdate === null) {
        delete userData.birthdate;
      }

      if (userData.email === null) {
        delete userData.email;
      }

      if (userData.phone_number === null) {
        delete userData.phone_number;
      }
            
      const model = {} as User;
      model.firstName = resultCurrent.signInUserSession.idToken.payload.given_name;
      model.lastName = resultCurrent.signInUserSession.idToken.payload.family_name;
      model.token = resultCurrent.signInUserSession.idToken.payload.email;
      model.sub = resultCurrent.signInUserSession.idToken.payload.sub;

      if (resultCurrent.signInUserSession.idToken.payload.email_verified) {
        model.emailVerified = Verified.Complete;
      }

      createUserSSO(model).then((res: any) => {
        dispatch(changeToken(resultCurrent.signInUserSession.accessToken.jwtToken));
        resultCurrent.attributes = userData;
        dispatch(preferenceApi.endpoints.getUserPreference.initiate());
        dispatch(userApi.endpoints.getCurrentUserDetails.initiate());
        dispatch(allOthersApi.endpoints.getAllCurrency.initiate());
        dispatch(userApi.endpoints.getUserDetail.initiate(res.data.sub)).then((userDetail:any)=>{
          dispatch(changeCognitoModelObject(userDetail.data))
         handleSSOUserName(userDetail.data.username)
        })
        dispatch(changeCurrentUserDetail(resultCurrent.attributes));
          
      })
    }).catch(error => {
      console.log("----SSOERROR", error)
      if (!navigateScreenSignIn) {
        setNavigateScreenSignIn(true);
      }
    });
  }

  const [createUserSSO] = useCreateUserSSOMutation<any>();

  return { 
    loading,
    navigateScreenSignIn, 
    cognitoKeys, 
    linkData, 
    getUser, 
    isSSoUser,
    userPolicyAgreementsData, 
    userPolicyAgreementsLoading };
}