import { allApis } from './allApis';
import { CancellationRequest } from '../models/cancellationRequest.model';

export const cancellationRequestApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        createCancellation: builder.mutation<CancellationRequest, Partial<CancellationRequest>>({
            query: (cancellationRequest) => ({
                
                url: `cancellationRequest`,
                method: 'POST',
                body: cancellationRequest
            }),
            // invalidatesTags: ['CancellationRequest']
            invalidatesTags: (result, error, cancellationRequest) => [{ type: 'CancellationRequest', id: cancellationRequest?.meeting?.id }, { type: 'CancellationRequest', id: cancellationRequest?.meetingParticipant?.transaction?.id }],
        }),
        checkCancellationRequest: builder.query<CancellationRequest, string>({
            query: (meetingId) => `cancellationRequest/meeting/${meetingId}`,
            providesTags: (result, error, meetingId) => [{ type: 'CancellationRequest', id: meetingId }],
        }),
        // checkCancellationRequestByParticipant: builder.query<CancellationRequest, any>({
        //     query: (arg) => {
        //         const { meetingId, participantId } = arg;
        //         return `cancellationRequest/checkCancellation/${meetingId}/${participantId}`
        //     },
        //     providesTags: ['CancellationRequest']
        // })
    })
});
export const {useCreateCancellationMutation, useCheckCancellationRequestQuery } = cancellationRequestApi