import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocalParticipantContext } from "../Services/livekit/contexts/liveKitContext";
import { isHostOrCohost } from "../Services/livekit/functions/liveKitHelpers";
import {
  getCurrentMeetingUser,
  getIsAnonymous,
  getMeetingConnected,
  getMeetingInfo,
  getMeetingJoined,
  getRemoteMeetingUsers,
} from "../Services/livekitReducer";

import { useGetMeetingQuery } from "../Services/meetingApi";
import {
  useGetUserPreferenceQuery,
  useUpdatePreferenceMutation,
} from "../Services/preferenceApi";
import { useGetUserByUserIdQuery } from "../Services/userApi";
import { getCurrentUser, getPreference } from "../Services/userReducer";
import {
  useGetMeetingTokenQuery,
  useUpdateEndTimeMutation,
  useUpdateLeaveTimeMutation,
} from "../Services/videoMeetingApi";
import { useAppSelector } from "../Store/hooks";
import { LiveKitUser, MetaDataType, UserType } from "../models/livekitTypes";
import { Meeting, MeetingType } from "../models/meeting.model";
import { showAudioGuide } from "../models/preference.model";

/**
 * LiveKit update Leave || End info hook
 * @returns
 */
export const useUpdateEndInfoApi = () => {
  const [endTimeMutation] = useUpdateEndTimeMutation();
  const [leaveTimeMutation] = useUpdateLeaveTimeMutation();

  async function updateEndTime(args: any) {
    return await endTimeMutation(args)
      .unwrap()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async function updateLeaveTime(args: any) {
    return await leaveTimeMutation(args)
      .unwrap()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  return {
    updateEndTime,
    updateLeaveTime,
  };
};

/**
 * Meeting Waiting for host page hook
 * @returns
 */
export const useWaitForHostHook = (meetingId?: string) => {
  //TODO : Need to check this meetingId
  const [showJoin, setShowJoin] = useState(false);

  const isMeetingJoined = useAppSelector(getMeetingJoined);
  const isMeetingConnected = useAppSelector(getMeetingConnected);
  const currentUser = useAppSelector(getCurrentUser);
  const isAnonymous = useAppSelector(getIsAnonymous);

  const {
    error,
    isSuccess,
    isLoading,
    isFetching,
    data: meetingInfo,
    refetch: refetchMeetingInfo,
    isUninitialized,
  } = useGetMeetingTokenQuery({ meetingId: meetingId ?? "", anonymous: isAnonymous} as any);

  const { data: meeting } = useGetMeetingQuery(meetingId ?? "", {
    skip: !meetingId,
  });

  let getMeetingError = error as any;

  useEffect(() => {
    let interval: any;

    interval = setInterval(() => {
      if (!isUninitialized) {
        if (!isMeetingJoined && !isMeetingConnected) {
          if (isSuccess && meetingInfo) {
            setShowJoin(true);
            clearInterval(interval);
          }
          if (getMeetingError?.data?.message === "BeeMG-ERR056") {
            refetchMeetingInfo();
            setShowJoin(false);
          } else if (
            getMeetingError?.data ||
            getMeetingError?.data?.message === "BeeMG-ERR055"
          ) {
            clearInterval(interval);
            setShowJoin(false);
          }
        }
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [
    meetingInfo,
    getMeetingError,
    isSuccess,
    isMeetingJoined,
    isMeetingConnected,
  ]);

  if (!meetingId) {
    getMeetingError = { data: { message: "BeeMG-ERR001" } };
  }

  return {
    getMeetingError,
    showJoin,
    isLoading,
    meetingInfo,
    meeting,
    isFetching,
    refetchMeetingInfo,
    isUninitialized,
    currentUser,
  };
};

export const useShowAudioGuide = () => {
  const [updatePreference] = useUpdatePreferenceMutation();
  const preference = useAppSelector(getPreference);
  const { refetch } = useGetUserPreferenceQuery();

  const updateShowAudioGuide = () => {
    const preferenceValue = Object.assign({}, preference);
    preferenceValue.showAudioGuide = showAudioGuide.false;
    updatePreference(preferenceValue).then(() => refetch());
  };

  return { preference, updateShowAudioGuide };
};

/* test cases
1. meeting with three types a, b (host and participant side - 6 cases) - both audio and video

 */
/**
 * Get Meeting Title for meetings.
 * @returns meetingTitle
 */
export const useGetMeetingTitle = (meetingId?: string) => {
  const { t } = useTranslation();
  const [meetingTitle, setMeetingTitle] = useState<string>("");
  const [withUserId, setWithUserId] = useState<string>("");

  const meetingInfo = useSelector(getMeetingInfo);
  const currentUser = useSelector(getCurrentUser);

  const { data: withUser } = useGetUserByUserIdQuery(withUserId, {
    skip: !withUserId || withUserId === "",
  });
  const { data: meeting } = useGetMeetingQuery(
    meetingId ?? meetingInfo?.meetingId,
    {
      skip: !(meetingId || meetingInfo?.meetingId),
    }
  );

  function setMeetingWithUserName() {
    if (meeting && meeting.type === MeetingType.Type2) {
      if (meeting.user2 && meeting.user2?.userId === currentUser.userId) {
        if (meeting.user?.anonymous) {
          setMeetingTitle(`${t("meetingWith")} ${meeting.user?.nickName}`);
        } else {
          setMeetingTitle(
            `${t("meetingWith")} ${meeting.user?.firstName} ${
              meeting.user?.lastName
            }`
          );
        }
      } else {
        if (meeting.user2?.anonymous) {
          setMeetingTitle(`${t("meetingWith")} ${meeting.user2?.nickName}`);
        } else {
          setMeetingTitle(
            `${t("meetingWith")} ${meeting.user2?.firstName} ${
              meeting.user2?.lastName
            }`
          );
        }
      }
      return;
    }

    if (meeting?.user.userId === currentUser.userId) {
      setWithUserId(meeting.user2.userId);
      if (withUser) {
        if (withUser?.anonymous) {
          setMeetingTitle(`${t("meetingWith")} ${withUser?.nickName}`);
        } else {
          setMeetingTitle(
            `${t("meetingWith")} ${withUser?.firstName} ${withUser?.lastName}`
          );
        }
      }
    } else if (meeting?.user2.userId === currentUser.userId) {
      setWithUserId(meeting.user.userId);
      if (withUser) {
        if (withUser?.anonymous) {
          setMeetingTitle(`${t("meetingWith")} ${withUser?.nickName}`);
        } else {
          setMeetingTitle(
            `${t("meetingWith")} ${withUser?.firstName} ${withUser?.lastName}`
          );
        }
      }
    }
  }

  useEffect(() => {
    if (Object.keys(meetingInfo).length !== 0 && meeting) {
      if (meeting.type === MeetingType.ChatVideoRequest) {
      } else if (meeting.type === MeetingType.Type1) {
        if (meetingInfo.meetingTitle || meeting.meetingDetails?.title) {
          setMeetingTitle(
            meetingInfo.meetingTitle ?? meeting.meetingDetails?.title
          );
        }
      } else {
        setMeetingWithUserName();
      }
    }

    return () => {};
  }, [meeting, meetingInfo, withUser]);

  return { meetingTitle };
};

/**
 * LiveKit audioMeeting participant
 * @param participant : participant object
 */

export const useAudioMeetingParticipantHook = (participant: any) => {
  const [localParticipant] = useLocalParticipantContext();

  const isAudioMuted = !participant.isMicrophoneEnabled;
  const isVideoMuted = !participant?.isCameraEnabled;
  const [_, __, room, ___] = useLocalParticipantContext();
  const currentMeetingUser = useAppSelector(getCurrentMeetingUser);
  const remoteMeetingUsers = useAppSelector(getRemoteMeetingUsers);

  const [metaData, setMetaData] = useState<MetaDataType | undefined>();
  const [remoteUser, setRemoteUser] = useState<LiveKitUser | undefined>();
  const [menuVisible, setMenuVisible] = useState(false);
  const meetingInfo = useAppSelector(getMeetingInfo);
  const [audioLevelScale, setAudioLevelScale] = useState<number>(1);
  const [hostCohostCheck, setHostCohostCheck] = useState<boolean>(false);

  const showHostControls =
    meetingInfo.meetingType !== MeetingType.ChatVideoRequest &&
    !participant.isLocal &&
    isHostOrCohost(currentMeetingUser) &&
    (room?.localParticipant.metadata as any)?.userType !== UserType.Participant;

  useEffect(() => {
    if (
      currentMeetingUser.userType === UserType.Host ||
      currentMeetingUser.userType === UserType.CoHost
    ) {
      setHostCohostCheck(true);
    }
  }, [currentMeetingUser]);

  useEffect(() => {
    if (participant.audioLevel === 0) {
      setAudioLevelScale(1);
    } else {
      setAudioLevelScale(participant.audioLevel + 1);
    }
  }, [participant.audioLevel]);

  useEffect(() => {
    if (participant.sid) {
      const remoteUser = Object.values(remoteMeetingUsers).find(
        (value) => value.participantId === participant.sid
      );
      if (remoteUser) {
        setRemoteUser(remoteUser);
      } else {
        setRemoteUser(undefined);
      }
    }
  }, [participant.sid, remoteMeetingUsers]);

  useEffect(() => {
    if (participant.metadata) {
      const parsedMetadata = JSON.parse(participant.metadata);
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        userId: participant.sid,
        userType: parsedMetadata.userType,
        avatar: parsedMetadata.avatar,
        profileImageUrl: parsedMetadata.profileImageUrl ,
        displayName: participant.name,
      }));
    }
  }, [participant.metadata]);

  return {
    remoteUser,
    localParticipant,
    isAudioMuted,
    showHostControls,
    menuVisible,
    setMenuVisible,
    metaData,
    audioLevelScale,
    isVideoMuted,
    hostCohostCheck,
  };
};

export enum MeetingStatus {
  NotRegistered = "NotRegistered",
  Registered = "Registered",
  OwnMeeting = "OwnMeeting",
  LimitReached = "LimitReached",
}
export const useCheckRegisteredHook = (meeting: Meeting) => {
  const [meetingStatus, setMeetingStatus] = useState<MeetingStatus>(
    MeetingStatus.NotRegistered
  );

  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (meeting && currentUser) {
      if (meeting.meetingDetails?.host?.id === currentUser?.id) {
        setMeetingStatus(MeetingStatus.OwnMeeting);
        return;
      }

      if (
        meeting.meetingParticipant.find(
          (participant) => participant?.user?.id === currentUser?.id
        )
      ) {
        setMeetingStatus(MeetingStatus.Registered);
        return;
      }

      if (meeting?.seatsAvailable <= 0) {
        setMeetingStatus(MeetingStatus.LimitReached);
        return;
      }

      setMeetingStatus(MeetingStatus.NotRegistered);
    }
  }, [meeting, currentUser]);

  return { meetingStatus };
};
