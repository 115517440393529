import { Currency } from './../models/currency.model';
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from '../Store';
import { allOthersApi } from './allOthersApi';

interface CurrencyState {
    currencyList: Currency[] | null;
}

const initialStateValue: CurrencyState = {
    currencyList: null
};

export const currencySlice = createSlice({
    name: "currency",
    initialState: initialStateValue,
    reducers: {
        changeToInitial: (state) => {
            state.currencyList = null;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(allOthersApi.endpoints.getAllCurrency.matchFulfilled, (state, action) => {
            state.currencyList = action.payload;
        });
    }
});

export const getCurrencyList = (state: RootState): Currency[] => state.currency.currencyList;
export const { changeToInitial } = currencySlice.actions;
export default currencySlice.reducer;
