import React from 'react'
import { useToggleDropdown } from '../navbar/navbar'

interface Props {
  children: React.ReactNode
  variant?: 'default' | 'menu' | 'reminder' | 'sort'
  isAbsolute?: boolean
  keyId?: string
  dropDownMenuStyle?: React.CSSProperties
}

interface DropdownItemProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
}

const useToggle = () => {
  const [show, setShow] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const toggle = React.useCallback(() => {
    setShow(prevState => !prevState)
  }, [])

  // close dropdown when you click outside
  React.useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (!ref.current?.contains(event.target)) {
        console.log('clicked outside', show)
        if (!show) return
        setShow(false)
      }
    }
    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [show])

  // // close dropdown when you click on "ESC" key
  React.useEffect(() => {
    const handleEscape = (event: any) => {
      if (!show) return

      if (event.key === 'Escape') {
        setShow(false)
      }
    }
    document.addEventListener('keyup', handleEscape)
    return () => document.removeEventListener('keyup', handleEscape)
  }, [show])

  return {
    show,
    toggle,
    ref,
  }
}

const style = {
  menu: `absolute right-0 py-2 px-2 bg-white rounded-lg lg:w-44 xs:w-52 mt-0.5 mb-0 mx-0 bg-clip-padding w-auto`,
  sort: `absolute py-2 px-2 bg-white rounded-lg w-auto mt-0.5 mb-0 mx-0 bg-clip-padding`,
  item: `block w-full flex mb-1 rounded-md text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer`,
}

export function Dropdown({ children, keyId }: Props) {
  const { show, toggle } = useToggle()
  /* First child contains the dropdown toggle */
  // @ts-ignore
  const dropdownToggle = children[0]

  /* Second child contains the dropdown menu */
  // @ts-ignore
  const dropdownMenu = children[1]

  return (
    <>
      <button
        onClick={toggle}
        type="button"
        className="text-gray-400 font-normal flex items-center"
        id={`dropMenu_lang_${keyId}`}
        aria-expanded="true"
        data-testid="drop_down"
        aria-haspopup="true">
        {dropdownToggle}
      </button>
      {show && <>{dropdownMenu}</>}
    </>
  )
}

export function DropdownToggle({ children }: Props) {
  return <>{children}</>
}

export function DropdownMenu({ children, variant, isAbsolute = false, dropDownMenuStyle }: Props) {
  const currentLocation = window.location.pathname
  let flag = false
  if (
    currentLocation === '/' ||
    currentLocation === '/signIn' ||
    currentLocation === '/notify' ||
    currentLocation === '/signUp'
  ) {
    flag = true
  }
  const dropdownStyle = {
    transform: 'translate3d(0px, 3px, 0px)',
    right: '-70px',
    marginTop: '2rem',
  }
  return (
    <div
      className={` z-40 ${isAbsolute ? ' absolute ' : ' relative '} bg-white`}>
      <div
        style={{
          ...(flag
            ? dropdownStyle
            : {
              transform: 'translate3d(0px, 3px, 0px)',
            }),
          ...dropDownMenuStyle,
        }}
        className={`${variant === 'sort' ? style.sort : style.menu} border`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu">
        {children}
      </div>
    </div>
  )
}

/* You can wrap the a tag with Link if you are using either Create-React-App, Next.js or Gatsby */
export function DropdownItem({ children }: DropdownItemProps) {
  const { toggleDropDown } = useToggleDropdown()
  return (
    <div tabIndex={0} className={style.item} role="menuitem" onClick={() => toggleDropDown && toggleDropDown()}>
      {children}
    </div>
  )
}

export function DropdownDivider() {
  return <hr />
}

