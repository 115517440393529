import { Preference } from './../models/preference.model';
import { allApis } from './allApis';

export const preferenceApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getUserPreference: builder.query<Preference, void>({
            query: () => `preference`,
            providesTags: ['Preference']
        }),
        getPreferenceById: builder.query<Preference, { id: string }>({
            query: ({ id }) => `preference/user/${id}`,
            providesTags: ['Preference']
        }),
        updatePreference: builder.mutation<Preference, Partial<Preference>>({
            query: (preference) => {
                return {
                    url: `preference/${preference.id}`,
                    method: 'PUT',
                    body: preference
                };
            },
            invalidatesTags: ['Preference']
        })
    })
});

export const { useGetUserPreferenceQuery, useGetPreferenceByIdQuery, useUpdatePreferenceMutation } = preferenceApi;