import { useNavigate, useParams } from 'react-router-dom'
import Icon from '../../Components/base/icon/icon'
import { Image } from '../../models/image.model'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetMeetingDetailsQuery } from '../../Services/meetingDetailsApi'
import { useGetSettingValue } from '../../Services/settingReducer'
import React from 'react'

export function ViewImage() {
  const { meetingDetailId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [images, setImages] = useState<Image[]>([])
  const [video, setVideo] = useState<string | undefined>(undefined)
  const imageURL = useGetSettingValue('IMAGE_URL')
  const { data: meetingDetails } = useGetMeetingDetailsQuery(
    meetingDetailId ? meetingDetailId : '',
    { skip: meetingDetailId ? false : true },
  )

  useEffect(() => {
    const newImages = [] as Image[]
    meetingDetails?.image.forEach(image => {
      newImages[image.orderNumber] = image
    })
    setImages(newImages)
    setVideo(meetingDetails?.video ?? undefined)
  }, [meetingDetails, meetingDetailId])

  return (
    <>
      <div className="flex space-x-2">
        {[...Array(5)].map((_, index) => (
          <React.Fragment key={`image_${index}`}>
            {images.length !== 0 && (
              <img
                src={imageURL + '/' + images[index + 1]?.imageName}
                alt="EventImage"
                key={'img1x' + index}
                className={
                  'h-24 w-28 md:h-24 md:w-28 lg:h-24 lg:w-28 border-2  rounded-xl  flex flex-col items-center justify-center cursor-pointer mt-6'
                }
                width={300}
                style={{ objectFit: 'cover' }}
                onClick={() =>
                  navigate('/s/events/imageVideoModel/' + meetingDetailId)
                }
              />
            )}

            {images.length === 0 && (
              <div
                id={`addImage_${index}`}
                key={index}
                className="h-24 w-24 md:h-24 md:w-24 lg:h-24 lg:w-24 border-2 text-xs text-gray-300 border-dashed rounded-xl border-gray-300 flex flex-col items-center justify-center cursor-pointer px-2 py-2 mt-6"
                onClick={() =>
                  navigate('/s/events/imageVideoModel/' + meetingDetailId)
                }>
                <Icon icon="ADD" size="medium" stroke="red" />
                <span className="mt-1 text-xs">{t('addImage')}</span>
              </div>
            )}
          </React.Fragment>
        ))}
        {!video && (
          <div
            className="h-24 w-40 md:h-24 md:w-40 lg:h-24 lg:w-40 border-2 text-xs text-gray-300 border-dashed rounded-xl border-gray-300 flex flex-col items-center justify-center cursor-pointer px-2 py-2 mt-6 mr-2"
            onClick={() =>
              navigate('/s/events/imageVideoModel/' + meetingDetailId)
            }>
            <Icon icon="ADD" size="medium" />
            <span className="mt-1 text-xs">{t('addVideo')}</span>
          </div>
        )}
        <span
          onClick={() =>
            navigate('/s/events/imageVideoModel/' + meetingDetailId)
          }>
          {video && <VideoDisplayView videoFile={video} />}
        </span>
      </div>
    </>
  )
}

export function VideoDisplayView({ videoFile }: { videoFile: string }) {
  const imageURL = useGetSettingValue('IMAGE_URL')

  return (
    <>
      <video
        key={videoFile}
        controls
        muted
        loop
        autoPlay
        className="flex rounded-xl h-24 w-40 md:h-24 md:w-40 lg:h-24 mt-6"
        style={{ objectFit: 'cover' }}>
        <source
          data-testid={`video-${videoFile}`}
          src={`${imageURL}/${videoFile}`}
          type="video/mp4"
        />
      </video>
    </>
  )
}

export default VideoDisplayView
