/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const [previousLocation, setPreviousLocation] = useState<string>();
  const { pathname } = useLocation();
  useEffect(() => {
    if (previousLocation !== pathname) {
      setTimeout(() => {
        if (pathname.includes("/s/events/my-events/") && previousLocation?.includes("/s/events/my-events/")) {
        } else {
          window.scrollTo(0, 0);
        }
        setPreviousLocation(pathname);
      }, 300);
    }
  }, [pathname]);

  return <></>;
}
