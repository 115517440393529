/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../Components'
import Field from '../Components/base/field/field'
import { PhoneNumberField } from '../Components/base/phone-number/PhoneNumberField'
import { useNotifyHook } from '../Hooks/NotifyHook'
import { getCurrentUser } from '../Services/userReducer'
import { useAppSelector } from '../Store/hooks'
import InitialPage from './InitialPage'
import { useTitle } from './useTitle'
import NewIcon from '../Components/base/icon/newIcons'

function Notify() {
  const { t } = useTranslation();
  useTitle(t('register'))
  const navigate = useNavigate()
  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (currentUser) {
      navigate('/s/home')
    }
  }, [currentUser])

  const {
    serverError,
    onSubmit,
    handleSubmit,
    register,
    errors,
    control,
    registrationDone
  } = useNotifyHook();

  return (
    <InitialPage>
      <div className="flex justify-center md:justify-start items-center h-screen">
        <span
          className="flex items-center flex-col p-3 rounded-lg md:opacity-90 bg-none mt-32 md:mt-0"
          style={{ maxWidth: "30rem" }}
        >
          <NewIcon icon="BEEMG_LOGO" size="xxl-large" stroke='none' className='mb-4'/>
          {!registrationDone &&
            <>
              <span className="text-4xl sm:text-normal uppercase font-bold mb-2">{t('register')}</span>
              <span className="flex flex-col items-center mb-4">
                <span className="text-sm md:text-2xl font-normal">{t('enterYourEmailToReceive')}</span>
                <span className="text-sm md:text-2xl font-normal">{t('launchNotification')}</span>
              </span>
            </>
          }
          {serverError &&
            <span
              id="error_LaunchNotify" data-testid="error_LaunchNotify"
              className="bg-red-100 text-yellow-900 rounded-full py-1 mt-3 px-10 mx-auto text-center"
            >
              {t(serverError)}
            </span>
          }
          {registrationDone &&
            <h6 id="txt_success">{t('thankYouForProvidingTheDetails.We’llNotifyYouAboutThePublicLaunchThroughTheRegisteredEmail')}</h6>
          }
          {!registrationDone && <form data-testid="onSubmit" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-2">
              <div className="md:grid grid-cols-6 gap-2 sm:flex sm:flex-col">

                <div className="col-span-6 sm:col-span-3">
                  <Field
                    {...register('firstName')} error={errors?.firstName?.message} data-testid="input_firstName" name="firstName"
                    type="text" placeholder={t('firstName') + ' *'}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Field
                    {...register('lastName')} error={errors?.lastName?.message} data-testid="input_lastName" name="lastName" type="text"
                    placeholder={t('lastName') + ' *'}
                  />
                </div>
                <div className="col-span-6 w-full">
                  <Field
                    {...register('email')} error={errors?.email?.message} data-testid="input_email" name="email" type="email"
                    placeholder={t('enterValidEmailID') + ' *'}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Controller
                    control={control} name={"phoneNo"} render={({ field: { onChange, value, name, ref } }) => (
                      <PhoneNumberField
                        value={value} placeholder={t('phoneNo')}  onChange={(value: any)=> onChange(value)} error={errors?.phoneNo?.message}
                      />
                    )}
                  />
                  {errors?.phoneNo && <span data-testid="error_phoneNo" id="error_phoneNo" className="text-red-500 text-sm mt-2">{t((errors.phoneNo as any).message)}</span>}
                </div>
              </div>
            </div>
            <div className='flex justify-center'>
              {' '}
              <Button className="w-64 my-4" color="save" data-testid="input_submit" id="btn_save" submit>
                <div>{t('submit')}</div>
              </Button>
            </div>
          </form>}
          <span className="hover:underline cursor-pointer font-semibold" id="btn_back">
            <Link to="/signIn">{t('back')}</Link>
          </span>
        </span>
      </div>
    </InitialPage>
  )
}

export default Notify
