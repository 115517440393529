import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../Store'
import { Meeting } from '../models/meeting.model';

interface MeetingReducerStateType {
  upcomingMeeting: Meeting | null;
  meetingNotificationCount: number;
}

const initialStateValue: MeetingReducerStateType = {
  upcomingMeeting: null,
  meetingNotificationCount: 0
}

export const meetingReducerSlice = createSlice({
  name: 'meetings',
  initialState: initialStateValue,
  reducers: {
    resetMeetings: () => initialStateValue,
    updateUpcomingMeeting: (state, action) => {
      if (action.payload && action.payload !== null && state.upcomingMeeting !== action.payload) {
        if (Object.entries(action.payload).length === 0) { 
          state.upcomingMeeting = null
          return;
        }  
        state.upcomingMeeting = action.payload
      }
    },
    updateMeetingNotificationCount: (state, action: PayloadAction<number>) => {
      if (action.payload !== null && state.meetingNotificationCount !== action.payload) {
        state.meetingNotificationCount = action.payload
      }
    },
  },
})

export const getUpcomingMeeting = (state: RootState): Meeting => state.meetings.upcomingMeeting
export const getMeetingNotificationCount = (state: RootState): number => state.meetings.meetingNotificationCount

export const { resetMeetings, updateUpcomingMeeting, updateMeetingNotificationCount } = meetingReducerSlice.actions

export default meetingReducerSlice.reducer