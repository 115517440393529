import { Category } from "../models/category.model";
import { allApis } from "./allApis";

export const categoryApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCategoriesMain: builder.query<Category[], void>({
      query: () => "category",
      // providesTags: ['Category']
    }),
    getSubCategory: builder.query<Category[], string>({
      query: (id) => `category/subCategory/${id}`,
      // providesTags: ['Category']
    }),
    // getUserCategories: builder.query<Category, void>({
    //   query: () => "category/user",
    //   providesTags: ['Category']
    // })
  })
});
export const { useGetCategoriesMainQuery, useGetSubCategoryQuery } = categoryApi;