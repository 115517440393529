import { useForm } from "react-hook-form";
import { User } from "../models/user.model";
import { useCreateReportMutation } from "../Services/allOthersApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { reportValidationSchema } from "../Utils/validation";

interface InitialReport {
    reason: any;
}

export const useViewUserReportHook = (userData: User) => {

    const { control, register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm<InitialReport>({ mode: 'onChange', resolver: yupResolver(reportValidationSchema) });
  
    const [sendReport, { isSuccess: reportSuccess, isLoading: reportLoading }] = useCreateReportMutation()

    const handleReport = (values: any) => {
      const args = { reportedUser: userData, reason: values.reason }
      sendReport(args)
    }
  
    return { handleReport, reportSuccess, control, register, handleSubmit, errors, reset, isDirty, isValid, reportLoading }
  }