import { setHours, addMinutes, format, differenceInMinutes } from "date-fns";
import { useState, useEffect } from "react";
import { set } from "lodash";

export const useMeetingTimerHook = ({ duration }: { duration?: number }) => {

    const [showDate, setShowDate] = useState(false)
    const [showStartTime, setShowStartTime] = useState(false)
    const [showEndTime, setShowEndTime] = useState(false)

    let [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [startTime, _setStartTime] = useState('00:00')
    const [endTime, _setEndTime] = useState('00:00')

    const [startDateTimeIn, setStartDateTimeIn] = useState<string>()
    const [endDateTimeIn, setEndDateTimeIn] = useState<string>()

    const [startDateTimeString, setStartDateTimeString] = useState<string>()
    const [endDateTimeString, setEndDateTimeString] = useState<string>()
    const [selectedDuration, setSelectedDuration] = useState<number>(duration ?? 0)
    const [isTwoDays, setIsTwoDays] = useState<boolean>(false)

    // useEffect(() => {
    //     if (endTime === '05:00') {
    //         setFiveMinAlert(true)
    //     }

    //     if (endTime === '02:00') {
    //         setTwoMinAlert(true)
    //     }
    //     else {
    //         setFiveMinAlert(false)
    //         setTwoMinAlert(false)
    //     }
    // }, [endTime])

    // Runs when initalizing start and end date

    useEffect(() => {
        const _startDate = startDateTimeIn ? new Date(startDateTimeIn) : undefined;
        let _endDate;
    
        if (endDateTimeIn) {
            _endDate = new Date(endDateTimeIn);
        } else if (_startDate) {
            _endDate = addMinutes(_startDate, duration ?? 60);
        } else {
            _endDate = undefined;
        }
    
        _startDate?.setSeconds(0, 0);
        _endDate?.setSeconds(0, 0);
    
        if (_startDate !== startDate || _endDate !== endDate) {
            setStartDate(_startDate);
            setEndDate(_endDate);
            _setStartTime(startDateTimeIn && _startDate ? format(_startDate, 'HH:mm') : '00:00');
            _setEndTime(endDateTimeIn && _endDate ? format(_endDate, 'HH:mm') : '01:00');
    
            setIsTwoDays(_startDate?.getDate() !== _endDate?.getDate());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateTimeIn, endDateTimeIn]);
    

    // Needed for future updates if any
    // const updateDuration = () => {
    //     if (startDate && endDate) {
    //         const diff = differenceInMinutes(endDate, startDate)
    //         if (diff !== selectedDuration) {
    //             setSelectedDuration(diff)
    //         } 
    //     }
    // }

    const setStartTime = (time: string) => {
        
        const hh = parseInt(time.split(':')[0])
        const mm = parseInt(time.split(':')[1])

        const _startDate = setHours(startDate ?? new Date(), hh);
        _startDate.setMinutes(mm);
        _startDate.setSeconds(0, 0);

        if (_startDate !== startDate) {
            setStartDate(_startDate)
            _setStartTime(time)
            const _endDate = addMinutes(_startDate, selectedDuration)
            if (_endDate !== endDate) {
                setEndTime(format(_endDate, 'HH:mm'), _startDate)
            }
        }
    }
    const setEndTime = ((time: string, startDateUpdates?: Date) => {
        startDateUpdates = startDateUpdates ?? startDate;
        if (!startDateUpdates) {
            return;
        }

        const hh = parseInt(time.split(':')[0])
        const mm = parseInt(time.split(':')[1])
        let _endDate = setHours(startDateUpdates, hh);

        if (_endDate < startDateUpdates) {
            _endDate = setHours(startDateUpdates, hh + 24);
        }

        if (time < startTime) { // 00:30 <  23:30

            const _tempEndDate = setHours(startDateUpdates, hh + 24);
            const diff = Math.abs(differenceInMinutes(_tempEndDate, startDateUpdates));

            if (diff <= (duration ?? 60)) {
                _endDate = _tempEndDate;
            }
        }
        _endDate.setMinutes(mm);
        _endDate.setSeconds(0, 0);

        if (_endDate !== endDate) {
            setEndDate(_endDate)
            _setEndTime(time)
            setIsTwoDays(startDateUpdates?.getDate() !== _endDate?.getDate())
        }
    })

    const setStartAndEndDateTime = (startDateTime: string, endDateTime: string) => {
        setStartDateTimeIn(startDateTime)
        setEndDateTimeIn(endDateTime)
    }

    const onStartDateChange = (date: Date | [Date | null, Date | null] | null) => {
        if (date instanceof Date && startDate !== date) {
            
            let diff = duration ?? 60;
            if(startDate && endDate){
                diff = Math.abs(differenceInMinutes(startDate, endDate));
            }
            const _startDateNew = startDate ? new Date(startDate): new Date()
            _startDateNew.setFullYear(date.getFullYear())
            _startDateNew.setMonth(date.getMonth())
            _startDateNew.setDate(date.getDate());
            _startDateNew.setHours(0, 0);

            setStartDate(_startDateNew)
            const _endDate = addMinutes(_startDateNew, diff)
            setEndDate(_endDate)
            setIsTwoDays(_startDateNew?.getDate() !== _endDate?.getDate())

        }
    }

    return {
        showDate,
        setShowDate,
        showStartTime,
        setShowStartTime,
        showEndTime,
        setShowEndTime,
        startDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        startDateTimeString,
        endDateTimeString,
        onStartDateChange,
        setStartAndEndDateTime,
        setSelectedDuration,
        endDate,
        setStartDateTimeString,
        setEndDateTimeString,
        isTwoDays
    }

};