import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { logout } from '../API/logoutAWS';
import { RootState } from "../Store";

export const awsLogout = createAsyncThunk(
    'logout',
    async (thunkAPI) => {
        return await logout();
    }
);

const initialStateValue = "";

export const tokenSlice = createSlice({
    name: "token",
    initialState: { 
        value: initialStateValue
    },
    reducers: {
        changeToken: (state, action) => {
            state.value = action.payload;
        },
        logoutToken: (state) => {
            state.value = initialStateValue;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(awsLogout.fulfilled, (state, action) => {
            state.value = initialStateValue;
        })
    }
});

export const getToken = (state: RootState): boolean => state.token?.value

export const { changeToken, logoutToken } = tokenSlice.actions;
export default tokenSlice.reducer;
