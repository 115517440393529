import { HTMLAttributes, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends HTMLAttributes<HTMLDivElement> {
  type?: string,
  children?: ReactNode
  className?: string
}

export function NoSession({ type, children, className = "justify-center" }: Props) {
  const { t } = useTranslation()

  let displayText = children;

  if (type === 'events') {
    displayText = (<div className='text-gray-400'>{t('youHaveNoActiveEvents')} </div>)
  } else if (type === 'creators') {
    displayText = (
      <div className='flex flex-col gap-y-2 items-start justify-start'>
        <div className="text-2xl font-medium text-gray-500">{t('noUpcomingEvents')}</div>
        <div className="text-sm font-medium text-gray-400">{t('youWillStartSeeingYourEventsAsSoonAsSomethingComesUp')}</div>
      </div>
    );
  } else if (type === 'transactions') {
    displayText = t('noTransactions')
  } else if (type === 'noDebit') {
    displayText = `${t('no')} ${t('debit')} ${t('transactions')}`
  }else if (type === 'noCredit') {
    displayText = `${t('no')} ${t('credit')} ${t('transactions')}`
  }else if (type === 'feedbacks') {
    displayText = (<div className='text-gray-400 my-52'>{`${t('no')} ${t('feedbacks')}`}</div>)
  }
  else if (type === 'pastEvents') {
    displayText = (<div className='text-gray-400'>{t('youHaveNoPastEvents')} </div>)
  }
  else if (type === 'collobraters') {
    displayText = (
    <div className='flex flex-col gap-y-2 justify-start items-start md:-ml-36'>
      <div className="text-2xl font-medium text-gray-500">{t('noResultsFoundForTheName')}</div>
      <div data-testid="txt_collabraters" className="text-sm font-medium text-gray-400">{t('pleaseTryADifferentSearchTerm')}</div>
    </div>
  );
  }

  return (
    <div data-testid="no_meetings" id="txt_noSessions" className={`flex text-xl font-medium my-6 ${className}`}>
      {displayText}
    </div>
  )
}
export default NoSession
