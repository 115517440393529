import {useTranslation} from 'react-i18next'

function LogInSuccess() {
  const {t} = useTranslation()

  return (
    <>
      <div className="flex justify-center text-3xl mt-4">
        {t('loginSuccessful')}
      </div>
    </>
  )
}

export default LogInSuccess
