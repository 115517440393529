import React from 'react'
import Icon from '../icon/icon'
import { ToolTip } from '../tooltip/tooltip'

interface Props {
  children: React.ReactNode
  keyId?: string
}

interface ModalProps extends Props {
  isOpen: boolean
  position?: 'default' | 'large' | 'semiLarge' | 'extraLarge' | 'contact' | 'jitsiGuide' | 'termsAndConditions'
  toggle: (isOpen?: boolean) => void
  closeOnClickOutside: boolean
  animate?: boolean
}

const style = {
  animate: 'animate-modal',
  body: `flex-shrink flex-grow`,
  headerTitle: `text-2xl font-semibold`,
  header: `flex items-center justify-between mb-4`,
  container: `fixed top-0 left-0 z-1000 w-full h-full mt-3 overflow-y-auto flex flex-col my-auto `,
  overlay: `fixed top-0 left-0 z-1000 w-screen h-screen bg-black opacity-50`,
  footer: `flex flex-wrap items-center justify-end gap-3`,
  content: {
    default: `relative flex flex-col bg-white pointer-events-auto rounded-lg p-4`,
    large: `relative flex flex-col bg-white pointer-events-auto rounded-lg mx-4 p-4`,
    semiLarge: `relative flex flex-col bg-white pointer-events-auto rounded-lg mx-4 p-4`,
    extraLarge: `relative flex flex-col bg-white pointer-events-auto rounded-lg mx-4 p-4`,
    contact: `relative flex flex-col bg-white pointer-events-auto rounded-lg mx-4 mt-16 p-2 overflow-y-scroll h-screen md:h-auto md:overflow-y-hidden`,
    jitsiGuide: 'relative flex flex-col bg-white pointer-events-auto rounded-lg mx-4 p-4 overflow-y-auto max-h-fit',
    termsAndConditions: `relative flex flex-col bg-white pointer-events-auto rounded-lg mx-4 p-4`,
  },
  orientation: {
    default:
      'mt-12 mx-8 pb-6 md:m-auto md:w-6/12 lg:w-4/12 md:pt-12 focus:outline-none',
    large:
      'mt-12 mx-8 pb-6 md:m-auto md:w-8/12 lg:w-8/12 md:pt-12 focus:outline-none',
    semiLarge:
      'mt-12 mx-8 pb-6 md:m-auto md:w-3/6 lg:w-3/6 md:pt-12 focus:outline-none',
    extraLarge:
      'mt-12 mx-8 pb-6 md:w-12/12 md:pt-12 focus:outline-none',
    contact:
      ' mx-3 pb-6 md:mx-auto md:w-3/4 lg:w-3/6 md:pt-2 focus:outline-none ',
    jitsiGuide:
      'mt-12 mx-8 pb-6 md:m-auto md:w-3/6 lg:w-3/6 md:pt-12 focus:outline-none',
    termsAndConditions: 'mt-2 mx-8 pb-6 md:m-auto md:w-8/12 lg:w-8/12 md:pt-12 focus:outline-none h-screen overflow-y-auto'
  },
}

export function Modal({
  isOpen,
  toggle,
  children,
  animate = false,
  closeOnClickOutside,
  position = 'default',
}: ModalProps) {
  const ref = React.useRef<HTMLDivElement>(null)

  // close modal when you click outside the modal dialogue
  React.useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (closeOnClickOutside && !ref.current?.contains(event.target)) {
        if (!isOpen) return
        toggle(false)
      }
    }
    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [closeOnClickOutside, isOpen, ref, toggle])

  // close modal when you click on "ESC" key
  React.useEffect(() => {
    const handleEscape = (event: any) => {
      if (!isOpen) return
      if (event.key === 'Escape') {
        toggle(false)
      }
    }
    document.addEventListener('keyup', handleEscape)
    return () => document.removeEventListener('keyup', handleEscape)
  }, [isOpen, toggle])

  // Put focus on modal dialogue, hide scrollbar and prevent body from moving when modal is open
  React.useEffect(() => {
    if (!isOpen) return

    ref.current?.focus()

    const html = document.documentElement
    const scrollbarWidth = window.innerWidth - html.clientWidth

    html.style.overflow = 'hidden'
    html.style.paddingRight = `${scrollbarWidth}px`

    return () => {
      document.documentElement.style.overflow = ''
      document.documentElement.style.paddingRight = ''
    }
  }, [isOpen])

  return (
    <div>
      {isOpen && (
        <>
          <div className={style.overlay} />
          <div className={style.container}>
            <div
              className={style.orientation[position]}
              ref={closeOnClickOutside ? ref : null}
              role="dialog"
              aria-modal={true}>
              <div
                className={`${style.content[position]} ${animate ? style.animate : ''}`}>

                {children}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export const ModalCloseButton = ({ toggle }: { toggle: (isOpen?: boolean) => void }) => {

  return (
    <div data-testid="btn_closeModal" className='flex justify-end cursor-pointer' onClick={() => { toggle(false) }}>
      <ToolTip tip='close' keyId="close" >
        <Icon icon="CLOSE" />
      </ToolTip>
    </div>
  )
}


export function ModalHeader({ children, keyId }: Props) {
  return (
    <div className={style.header}>
      <h4 id={`${keyId}_modal`} data-testid={`${keyId}_modal`} className={style.headerTitle}>{children}</h4>
    </div>
  )
}

export function ModalBody({ children }: Props) {
  return <div id={`modal_${children}`} className={style.body}>{children}</div>
}

export function ModalFooter({ children }: Props) {
  return <div className={style.footer}>{children}</div>
}
