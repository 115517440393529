import { Socket, io } from "socket.io-client";
import { setSocketConnected } from "../Services/socketReducer";
import { store } from "../Store";
import addSocketListeners from "./socketListeners";
import { GetUserStatusMessage, Message, MessageType } from "./socketTypes";

let socket: Socket;

class SocketClient {
  messageListners: { [key: string]: (message: Message) => void } = {}
  isUserAdded = false

  connect(connectUrl: string) {
    socket = io(connectUrl, { autoConnect: false, transports: ['websocket', 'polling'], path: '/socket' });
    socket.connect();

    socket.on("connect", () => {
      console.log("Socket Connected.")
      store.dispatch(setSocketConnected(true))
      this._setupListeners();
      addSocketListeners()
      this.sendAddUser()
    })

    socket.on("disconnect", () => {
      console.log("Socket disconnected.")
      store.dispatch(setSocketConnected(false))
    })
  }

  _setupListeners() {
    socket.on("message", (message: Message) => {
      const callback = this.messageListners[message.type]
      if (callback) {
        callback(message)
      } else {
        console.log('No callback for message type: ' + message.type)
      }
    });
  }

  addListener(key: MessageType, callback: (message: Message) => void) {
    this.messageListners[key] = callback
  }

  removeListener(key: MessageType) {
    delete this.messageListners[key]
  }

  disconnect() {
    socket?.removeAllListeners()
    socket?.disconnect()
    socket?.close()
  }

  sendAddUser() {
    const { token, user } = store.getState()
    if (user?.currentUser?.id && token && !this.isUserAdded) {
      const message = {
        type: MessageType.AddUser,
        message: {
          userId: user?.currentUser?.id,
          token: token,
        },
      }
      this.send(message)
    }
  }

  getUserStatus({ fromUserId, userId }: GetUserStatusMessage) {
    const message: Message = {
      type: MessageType.GetUserStatus,
      message: {
        fromUserId,
        userId,
      } as GetUserStatusMessage,
    }
    this.send(message)
  }

  send(message: Message) {
    if (socket?.connected) {
      socket?.send(message)
    }
  }

}

export const socketClient = new SocketClient();