import { useEffect, useState } from "react"
import { useGetSettingValue } from "../Services/settingReducer"
import { useCreateMultipleInvitesMutation, useDeleteInviteMutation, useGetInviteByUserQuery, useResendInviteEmailMutation } from "../Services/inviteApi"
import { t } from "i18next"

interface InvitedUser {
    inviteEmail: string
}

export const useInviteHooks = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [serverError, setServerError] = useState<any>();
    const [invitationsLeft, setInvitationsLeft] = useState(0)
    const [newEmails, setNewEmails] = useState<string[]>([]);
    const [showToastMessage, setShowToastMessage] = useState(false);

    const [uniqueEmailError, setUniqueEmailError] = useState<(null | { message: string })[]>([]);

    const { data: invitedUsers, isLoading: usersLoading } = useGetInviteByUserQuery()
    const [sendInvite, { isSuccess: sendInviteSuccess, isLoading: sendInviteLoading }] = useCreateMultipleInvitesMutation()
    

    const inviteLimit = useGetSettingValue('INVITE_LIMIT')

    useEffect(() => {
        if (!invitedUsers || !inviteLimit) {
            if (!isLoading) {
                setIsLoading(true)
            }
        } else {
            let remainingInvites: any = parseInt(inviteLimit || '0') - 
            (invitedUsers ? invitedUsers.length : 0);

            if (invitationsLeft !== remainingInvites) {
                setInvitationsLeft(remainingInvites);
            }
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitedUsers, inviteLimit])

    useEffect(() => {

        if (newEmails && invitedUsers) {
            const uniqueEmailsError = [] as any;

            newEmails.forEach((email: string, index) => {
                const isEmailInvited = invitedUsers.some((user: any) => user.inviteEmail === email);
                if (isEmailInvited) {
                    uniqueEmailsError[index] = { message: t('alreadyInvited') }
                }
                if (email.length > 50) {
                    uniqueEmailsError[index] = { message: t('emailCanBeMax50CharactersLong') }
                }
                return null;
            });    
            setUniqueEmailError(uniqueEmailsError);        
        }
    }, [newEmails, invitedUsers]);

    useEffect(() => {
        if (sendInviteSuccess === true) {
            setNewEmails([]);
        }
    }, [sendInviteSuccess]);
    

    const onSubmit = () => {
        if (!newEmails || newEmails.length === 0) {
            setServerError('');
            return;
        }

        sendInvite(newEmails).then((res : any ) => {
            const onlyErrors = res.data?.filter((d: any) => d?.error);

            setServerError(onlyErrors);
            
            if(onlyErrors.length === 0)
            {
            if (!showToastMessage) {
                setShowToastMessage(true);
            }
        }
        })                 
    };

    return {
        invitedUsers,
        invitationsLeft,
        isLoading,
        usersLoading,
        serverError,
        sendInviteSuccess,
        sendInviteLoading,
        setServerError,
        newEmails,
        setNewEmails,
        uniqueEmailError,
        onSubmit,
        setShowToastMessage,
        showToastMessage
    }
}

export const useInviteCardHooks = () => {
    const [deleteInvite, { isSuccess: deleteInviteSuccess }] = useDeleteInviteMutation()
    const [resendInvite, { isSuccess: resendInviteSuccess }] = useResendInviteEmailMutation()

    const handleInviteResend = (invite: string) => { resendInvite(invite) }
    const handleInviteDelete = (invite: string) => { deleteInvite(invite) }

    return {
        handleInviteResend,
        handleInviteDelete,
        deleteInviteSuccess,
        resendInviteSuccess
    }
}
