import { useTranslation } from "react-i18next";
import Chip from "../../Components/Chip";
import Field from "../../Components/base/field/field";
import Button from "../../Components/base/button/button";

function MeetingCategories({ meetingCategory, handleRemoveCategory, meetingCategoryListSuccess, showOtherCategory, register, setValue, handleAddCategoryList, meetingCategoryList, setShowOtherCategory, watch }: any) {

    const { t } = useTranslation()
  
    return (
      <div className="my-4 flex flex-col gap-4 md:w-[40rem]">
        <div className="text-lg font-semibold leading-none">{t("interests")}</div>
         <div className={`flex flex-wrap gap-1 ${meetingCategory && meetingCategory.length > 0 ? 'mb-3' : '-mb-3'}`}>
          {meetingCategory && meetingCategory.length > 0 && (
            <>
              {meetingCategory.map((item: string, index: number) => (
                <Chip
                  isSelected={true}
                  key={index}
                  removeCatId={`btn_removeCat_${index}`}
                  value={t(`meetingCategories.${item}`) !== `meetingCategories.${item}` ? t(`meetingCategories.${item}`) : item}
                  onClickClose={e => handleRemoveCategory(item)}
                />
              ))}
            </>
          )}
        </div>
        <div>
          {meetingCategoryListSuccess && (
            <>
              {showOtherCategory &&
                <div className="flex gap-2">
                  <div>
                    <Field id="input_catOthers" type='text' className="m-1" {...register('otherCategory')} maxLength={50} />
                  </div>
                  <Button
                    id="btn_addCatOthers"
                    size="sm"
                    className='m-1 rounded'
                    color="save"
                    onClick={() => {
                      const trimmedValue = watch('otherCategory').trim();
                      if (trimmedValue !== '') {
                        handleAddCategoryList(trimmedValue);
                        setShowOtherCategory(false);
                      }
                      // Toggling setShowOtherCategory to true if the user clicks on the add button without entering any value
                      if (trimmedValue.length === 0) {
                        setShowOtherCategory(true);
                      }
                      setValue('otherCategory', '');
                    }}
                  >
                    {t('add')}
                  </Button>
                  <Button id="btn_cancelCatOthers" size="sm" className='m-1 rounded' color="save" onClick={() => { setShowOtherCategory(false); setValue('otherCategory', '') }} >{t('cancel')}</Button>
                </div>
              }
              {!showOtherCategory &&
                <div className="flex flex-wrap gap-1">
                  {meetingCategoryList?.map((CategoryList: any, index: number) => (
                    <Chip
                      key={CategoryList.id}
                      addCatId={`btn_addCat_${index}`}
                      onClick={e => handleAddCategoryList(CategoryList.categoryName)}
                      value={t(`meetingCategories.${CategoryList.categoryName}`)}
                    />
                  ))}
                  <Chip
                  addCatId="btn_addOthers"
                  onClick={() => setShowOtherCategory(true)}
                  value={t('others')}
                  />
                </div>
              }
            </>
          )}
        </div>
      </div>
    )
  }

  export default MeetingCategories