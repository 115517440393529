import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCustomRoomContext } from './room/CustomRoomContext'
import { addSecondsToDate, calculateTimeRemaining } from './LiveKit.utils'
import {
  TrackReference,
  TrackReferenceOrPlaceholder,
  useDataChannel,
  useEnsureParticipant,
  useEnsureRoom,
  useLocalParticipant,
  useParticipantInfo,
  useParticipants,
  useRemoteParticipants,
  useRoomContext,
} from '@livekit/components-react'
import { CustomTrack, LiveKitEndAllowUnmute, LiveKitEndRoom, LiveKitHandRaised, UserType } from './livekitTypes'
import { ConnectionState, DataPublishOptions, LocalParticipant, RemoteParticipant, Track } from 'livekit-client'
import { useCustomGridContext } from './room/CustomGridContext'
import { UserLayoutPosition, getGridAspect, calculateBoxPositionsForEachUser } from './liveKitHelpers'

export const useGetIsLocalParticipantHostOrCohostHooks = () => {
  const { localParticipant } = useLocalParticipant()

  const [isLocalParticipantHost, setIsLocalParticipantHost] = useState(false)
  const [isLocalParticipantCohost, setIsLocalParticipantCohost] = useState(false)

  useEffect(() => {
    if (localParticipant && localParticipant.metadata) {
      const userType = JSON.parse(localParticipant.metadata).userType
      setIsLocalParticipantHost(userType === UserType.HOST)
      setIsLocalParticipantCohost(userType === UserType.CO_HOST)
    }
  }, [localParticipant])

  return {
    isLocalParticipantHost,
    isLocalParticipantCohost,
    isLocalParticipantHostOrCohost: isLocalParticipantHost || isLocalParticipantCohost,
  }
}

export const useGetHostAndCoHost = () => {
  const [hostAndCoHosts, setHostAndCoHosts] = useState<string[]>([])
  const [hostAndCohostIdentities, setHostAndCohostIdentities] = useState<string[]>([])
  const participants = useParticipants()
  const [host, setHost] = useState<string[]>([])
  const [coHost, setCoHost] = useState<string[]>([])

  useEffect(() => {
    const hosts = participants.filter((p) => {
      if (p.metadata) {
        try {
          const metadata = JSON.parse(p.metadata)
          return metadata.userType === UserType.HOST
        } catch (e) {
          return false
        }
      }
      return false
    })
    const coHosts = participants.filter((p) => {
      if (p.metadata) {
        try {
          const metadata = JSON.parse(p.metadata)
          return metadata.userType === UserType.CO_HOST
        } catch (e) {
          return false
        }
      }
      return false
    })
    const hostCoHosts = participants.filter((p) => {
      if (p.metadata) {
        try {
          const metadata = JSON.parse(p.metadata)
          return metadata.userType === UserType.HOST || metadata.userType === UserType.CO_HOST
        } catch (e) {
          return false
        }
      }
      return false
    })

    const sids = hostCoHosts.map((p) => p.sid).sort()
    const identities = hostCoHosts.map((p) => p.identity).sort()
    if (sids.join(',') !== hostAndCoHosts.join(',')) {
      setHostAndCoHosts(sids)
    }

    const hostSid = hosts.map((p) => p.sid).sort()
    if (hostSid.join(',') !== host.join(',')) {
      setHost(hostSid)
    }

    const coHostSid = coHosts.map((p) => p.sid).sort()
    if (coHostSid.join(',') !== coHost.join(',')) {
      setCoHost(coHostSid)
    }

    if (identities.join(',') !== hostAndCohostIdentities.join(',')) {
      setHostAndCohostIdentities(identities)
    }
  }, [participants, hostAndCoHosts, hostAndCohostIdentities, host, coHost])

  return {
    hostAndCoHosts,
    hostAndCohostIdentities,
    host,
    coHost,
  }
}

export const useIsPinnedTrackHook = (trackReference: TrackReferenceOrPlaceholder) => {
  const { pinnedParticipants } = useCustomGridContext()
  const sidSource =
    trackReference?.source === Track.Source.ScreenShare
      ? trackReference.participant.sid + '-' + trackReference?.source
      : trackReference.participant.sid

  return pinnedParticipants.includes(sidSource)
}

// For Audio meeting
export const useCustomStagedParticipantHooks = (tracks: CustomTrack[]) => {
  const { stagedParticipants, handRaisedParticipants } = useCustomRoomContext()
  const [stagedTracks, setStagedTracks] = useState<CustomTrack[]>([])
  const [otherTracks, setOtherTracks] = useState<CustomTrack[]>([])
  const [moderatorTracks, setModeratorTracks] = useState<CustomTrack[]>([])
  const [handRaisedTracks, setHandRaisedTracks] = useState<CustomTrack[]>([])
  const { hostAndCoHosts, host, coHost } = useGetHostAndCoHost()

  // Compute stagedParticipantsCount based on tracks
  const stagedParticipantsCount = useMemo(() => {
    return tracks.filter((track) => stagedParticipants.includes(track.participant.sid)).length
  }, [stagedParticipants, tracks])

  useEffect(() => {
    // Filter tracks that are for staged participants
    const newStagedTracks = tracks.filter(
      (track) => !hostAndCoHosts.includes(track.participant.sid) && stagedParticipants.includes(track.participant.sid)
    )
    setStagedTracks(newStagedTracks)

    // Filter tracks that are not host or co-host tracks and also not in stagedParticipants
    const otherTracksNonSorted = tracks.filter(
      (track) => !hostAndCoHosts.includes(track.participant.sid) && !stagedParticipants.includes(track.participant.sid)
    )

    // Sort hand-raised participants to be first
    const newOtherTracks = otherTracksNonSorted.sort((a, b) => {
      const aHandRaised = handRaisedParticipants.includes(a.participant.sid) ? 1 : 0
      const bHandRaised = handRaisedParticipants.includes(b.participant.sid) ? 1 : 0
      return bHandRaised - aHandRaised
    })

    setOtherTracks(newOtherTracks)

    // Filter tracks that are host or co-host tracks
    const newModeratorTracks = tracks.filter((track) => hostAndCoHosts.includes(track.participant.sid))
    const moderatorsFiltered = newModeratorTracks.sort((a, b) => {
      const aIsHost = host.includes(a.participant.sid) ? -1 : 0
      const bIsHost = host.includes(b.participant.sid) ? -1 : 0
      const aIsCoHost = coHost.includes(a.participant.sid) ? 1 : 0
      const bIsCoHost = coHost.includes(b.participant.sid) ? 1 : 0
      return aIsHost - bIsHost || aIsCoHost - bIsCoHost
    })
    setModeratorTracks(moderatorsFiltered)

    const newHandRaisedTracks = tracks.filter(
      (track) =>
        !hostAndCoHosts.includes(track.participant.sid) &&
        (stagedParticipants.includes(track.participant.sid) || handRaisedParticipants.includes(track.participant.sid))
    )
    setHandRaisedTracks(newHandRaisedTracks)
  }, [hostAndCoHosts, stagedParticipants, handRaisedParticipants, tracks])

  const otherCount = otherTracks.length

  return {
    stagedTracks,
    otherTracks,
    moderatorTracks,
    stagedParticipants,
    stagedParticipantsCount,
    moderatorCount: moderatorTracks.length,
    otherCount,
    handRaisedTracks,
    handraisedCount: handRaisedTracks.length,
  }
}

export const useMultiSessionCheckHooks = () => {
  const remoteParticipants = useRemoteParticipants()
  const { localParticipant } = useLocalParticipant()

  const [isMultiSession, setIsMultiSession] = useState<boolean>(false)
  const [sameIdentity, setSameIdentity] = useState<boolean>(false)

  useEffect(() => {
    const localIdWithoutLastFourDigits = localParticipant?.identity.substring(
      0,
      localParticipant?.identity.lastIndexOf('-')
    )
    const hasOtherParticipants = remoteParticipants.length >= 1

    const otherParticipant = remoteParticipants.find((p) => {
      const remoteIdWithoutLastFourDigits = p.identity.substring(0, p.identity.lastIndexOf('-'))
      if (remoteIdWithoutLastFourDigits === localIdWithoutLastFourDigits) {
        setSameIdentity(true)
      } else {
        setSameIdentity(false)
      }
      return remoteIdWithoutLastFourDigits === localIdWithoutLastFourDigits
    })
    console.log('otherParticipant', otherParticipant)

    if (hasOtherParticipants && sameIdentity && !isMultiSession) {
      setIsMultiSession(true)
    } else if (!hasOtherParticipants || (!sameIdentity && isMultiSession)) {
      setIsMultiSession(false)
    }
  }, [remoteParticipants.length, localParticipant, sameIdentity, isMultiSession])
  return { isMultiSession }
}

/**
 * use this hook to handle hand raise in the control bar
 *
 * @returns
 */

export const useHandRaiseHook = () => {
  const { send: sendHandRaise } = useDataChannel('handRaise')
  const { message: messageUnmute } = useDataChannel('allowUnmute')
  const [isAllowedUnmute, setIsAllowedUnmute] = useState<boolean>(false)
  const { localParticipant } = useLocalParticipant()
  const {
    setIsUnmuteAllowed,
    setStagedParticipants,
    stagedParticipants,
    isHostOrCohostAvailable,
    isHandRaised,
    setIsHandRaised,
  } = useCustomRoomContext()
  const [isHostUnmuted, setIsHostUnmuted] = useState<boolean>(false)

  const handRaise = (handRaised: boolean) => {
    const message: LiveKitHandRaised = {
      // currentUserId used for sending
      handRaised,
    }
    if (isHandRaised !== handRaised) {
      setIsHandRaised(handRaised)
    }

    const options: DataPublishOptions = {
      reliable: true,
    }
    sendHandRaise(new TextEncoder().encode(JSON.stringify(message)), options)
    setIsHostUnmuted(true)
  }

  const addOrRemoveAllowUnmuteParticipants = (participantId: string, allowUnmute: boolean) => {
    if (participantId) {
      if (allowUnmute) {
        setStagedParticipants([...stagedParticipants, participantId])
      } else {
        setStagedParticipants(stagedParticipants.filter((p) => p !== participantId).filter((p) => p !== ''))
      }
    }
  }

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined
    if (!isHostOrCohostAvailable) {
      timer = setTimeout(() => {
        if (!isHostOrCohostAvailable) {
          setIsHandRaised(false)
        }
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [isHostOrCohostAvailable])

  useEffect(() => {
    if (messageUnmute) {
      const data = JSON.parse(new TextDecoder().decode(messageUnmute.payload)) as LiveKitEndAllowUnmute
      if (data.allowUnmute && data.participantId === localParticipant?.sid) {
        handRaise(false)
      }
      if (isAllowedUnmute !== data.allowUnmute && data.participantId === localParticipant?.sid) {
        setIsAllowedUnmute(data.allowUnmute)
        setIsUnmuteAllowed(data.allowUnmute)
      }
      addOrRemoveAllowUnmuteParticipants(data.participantId, data.allowUnmute)
    }
  }, [messageUnmute])

  return {
    handRaise,
    isHandRaised,
    isAllowedUnmute,
    isHostUnmuted,
    setIsHostUnmuted,
  }
}

/**
 * Use this hook to check if the participant user is hand raised
 * Only visible to the host and co-host
 */
export const useHandRaiseReceivedHook = () => {
  const { message } = useDataChannel('handRaise')
  const { send: sendUnmute } = useDataChannel('allowUnmute')
  const { isLocalParticipantHost, isLocalParticipantCohost } = useGetIsLocalParticipantHostOrCohostHooks()
  const p = useEnsureParticipant()
  const {
    stagedParticipants,
    setStagedParticipants,
    handRaisedParticipants,
    setHandRaisedParticipants,
    isParticipantHandRaised,
    setIsParticipantHandRaised,
  } = useCustomRoomContext()
  const [participantName, setParticipantName] = useState<string>('')
  const [localHandRaised, setLocalHandRaised] = useState<boolean>(false)
  const { muteAll } = useMuteAllHooks()

  useEffect(() => {
    if (!isLocalParticipantCohost && !isLocalParticipantHost) {
      return
    }
    if (message && message.payload?.byteLength > 0) {
      const data = JSON.parse(new TextDecoder().decode(message.payload)) as LiveKitHandRaised
      const participantId = message.from?.sid

      if (participantId) {
        if (data.handRaised) {
          setHandRaisedParticipants((prevParticipants: string[]) => [...prevParticipants, participantId])
        } else {
          setHandRaisedParticipants((prevParticipants: string[]) =>
            prevParticipants.filter((id) => id !== participantId)
          )
        }

        if (participantId === p.sid) {
          setIsParticipantHandRaised(data.handRaised)
          setLocalHandRaised(data.handRaised)
          setParticipantName(p.name ? p.name : '')
        }
      }
    }
  }, [message, p])

  const addAllParticipantsToStagedParticipants = useCallback(
    (participantId: any) => {
      if (!stagedParticipants.includes(participantId)) {
        setStagedParticipants([...stagedParticipants, participantId])
      }
    },
    [stagedParticipants.length, setStagedParticipants]
  )

  const muteAllOtherParticipants = (currentParticipantId: string) => {
    const otherParticipants = handRaisedParticipants.filter((id) => id !== currentParticipantId)
    otherParticipants.forEach((participantId) => {
      const message: LiveKitEndAllowUnmute = {
        allowUnmute: false,
        participantId: participantId,
      }
      sendUnmute(new TextEncoder().encode(JSON.stringify(message)), { reliable: true })
    })
  }

  const allowUnmute = () => {
    const message: LiveKitEndAllowUnmute = {
      // currentUserId used for sending
      allowUnmute: true,
      participantId: p.sid,
    }
    const options: DataPublishOptions = {
      reliable: true,
    }
    sendUnmute(new TextEncoder().encode(JSON.stringify(message)), options)
    addAllParticipantsToStagedParticipants(p.sid)
    setIsParticipantHandRaised(false)
    setLocalHandRaised(false)
    setHandRaisedParticipants(handRaisedParticipants.filter((id) => id !== p.sid))
  }

  const unmuteCurrentOnly = () => {
    muteAll()

    setTimeout(() => {
      const message: LiveKitEndAllowUnmute = {
        allowUnmute: true,
        participantId: p.sid,
      }
      const options: DataPublishOptions = {
        reliable: true,
      }
      sendUnmute(new TextEncoder().encode(JSON.stringify(message)), options)
      addAllParticipantsToStagedParticipants(p.sid)
      setIsParticipantHandRaised(false)
      setLocalHandRaised(false)
      setHandRaisedParticipants(handRaisedParticipants.filter((id) => id !== p.sid))
      muteAllOtherParticipants(p.sid)
    }, 200)
  }

  return {
    isParticipantHandRaised,
    allowUnmute,
    unmuteCurrentOnly,
    participantName,
    handRaisedParticipants,
    setHandRaisedParticipants,
    localHandRaised,
  }
}

/*
This hook is used to mute all participants in the meeting from the host or co-host to all participants in the meeting
*/

export const useMuteAllHooks = () => {
  const { send: sendMuteAll, message: messageMuteAll } = useDataChannel('muteAll')
  const { isLocalParticipantCohost, isLocalParticipantHost } = useGetIsLocalParticipantHostOrCohostHooks()
  const isLocalHostAndCoHosts = isLocalParticipantCohost || isLocalParticipantHost
  const room = useRoomContext()
  const { setIsUnmuteAllowed, setStagedParticipants, setHandRaisedParticipants } = useCustomRoomContext()
  const [isMutedAll, setIsMutedAll] = useState<boolean>(false)
  const { setIsHostUnmuted, handRaise } = useHandRaiseHook()

  const muteAll = () => {
    const message = {
      muteAll: true,
    }
    const options: DataPublishOptions = {
      reliable: true,
    }

    sendMuteAll(new TextEncoder().encode(JSON.stringify(message)), options)
    setStagedParticipants([]) // This is for local user to empty staged participants
    setIsHostUnmuted(false)
    setHandRaisedParticipants([])
  }

  useEffect(() => {
    if (isLocalHostAndCoHosts) {
      return
    }
    if (messageMuteAll && messageMuteAll.payload) {
      setStagedParticipants([]) // This is for remote user to empty staged participants
      room.localParticipant.setCameraEnabled(false)
      room.localParticipant.setMicrophoneEnabled(false)
      room.localParticipant.setScreenShareEnabled(false)
      setIsUnmuteAllowed(false)
      setIsHostUnmuted(false)
      setIsMutedAll(true)
      handRaise(false)
      setHandRaisedParticipants([])
    }
  }, [messageMuteAll, isLocalHostAndCoHosts, setIsUnmuteAllowed, setIsMutedAll, setIsHostUnmuted])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isMutedAll === true) {
        setIsMutedAll(false)
      }
    }, 3000)

    return () => clearTimeout(timeout)
  }, [isMutedAll])

  return {
    muteAll,
    isMutedAll,
    setIsMutedAll,
  }
}

export const useUnMuteAllHooks = () => {
  const { send: sendUnmuteAll, message: messageUnmuteAll } = useDataChannel('unmuteAll')
  const { isLocalParticipantCohost, isLocalParticipantHost } = useGetIsLocalParticipantHostOrCohostHooks()
  const isLocalHostAndCoHosts = isLocalParticipantCohost || isLocalParticipantHost
  const { isUnmuteAllowed, setIsUnmuteAllowed } = useCustomRoomContext()
  const { handRaise } = useHandRaiseHook()
  const allParticipants = useParticipants()
  const { setStagedParticipants } = useCustomRoomContext()
  const { hostAndCoHosts } = useGetHostAndCoHost()
  const [isUnmutedAll, setIsUnmutedAll] = useState<boolean>(false)

  const addAllParticipantsToStagedParticipants = useCallback(() => {
    const otherParticipants = allParticipants
      .filter((p) => p.sid !== '' && !hostAndCoHosts.includes(p.sid))
      .map((p) => p.sid)
      .filter((p) => p !== '')

    setStagedParticipants(otherParticipants)
    setIsUnmutedAll(true)
  }, [allParticipants, hostAndCoHosts, setStagedParticipants])

  const unmuteAll = () => {
    const message = {
      unmuteAll: true,
    }

    const options: DataPublishOptions = {
      reliable: true,
    }
    sendUnmuteAll(new TextEncoder().encode(JSON.stringify(message)), options)
    addAllParticipantsToStagedParticipants()
  }

  useEffect(() => {
    if (isLocalHostAndCoHosts) {
      return
    }
    if (messageUnmuteAll && messageUnmuteAll.payload) {
      addAllParticipantsToStagedParticipants()
      if (!isUnmuteAllowed) {
        setIsUnmuteAllowed(true)
        handRaise(false)
      }
    }
  }, [messageUnmuteAll, setIsUnmuteAllowed])

  return {
    unmuteAll,
    isUnmuteAllowed,
    isUnmutedAll,
    setIsUnmutedAll,
  }
}

export const useHostCoHostAvailabilityHook = () => {
  const participants = useParticipants()
  const { isHostOrCohostAvailable, setIsHostOrCohostAvailable, setStagedParticipants } = useCustomRoomContext()
  const [hostNotAvailableSinceDateTime, setHostNotAvailableSinceDateTime] = useState<string | undefined>(undefined)
  const { muteAll } = useMuteAllHooks()

  const setHostNotAvailable = () => {
    if (isHostOrCohostAvailable) {
      setIsHostOrCohostAvailable(false)
      setHostNotAvailableSinceDateTime(new Date().toISOString())
    }
  }
  useEffect(() => {
    if (!isHostOrCohostAvailable) {
      setHostNotAvailableSinceDateTime(new Date().toISOString())
    }
  }, [isHostOrCohostAvailable])

  useEffect(() => {
    if (!participants || participants.length === 1) {
      setHostNotAvailable()
      return
    }

    const remoteParticipants = participants.filter((p) => p instanceof RemoteParticipant)
    if (remoteParticipants.length === 0) {
      return
    }

    const host = participants.find((p) => {
      if (p.metadata) {
        try {
          const metadata = JSON.parse(p.metadata)
          return metadata.userType === UserType.HOST || metadata.userType === UserType.CO_HOST
        } catch (e) {
          return false
        }
      }
      return false
    })

    if (host) {
      if (!isHostOrCohostAvailable) {
        setIsHostOrCohostAvailable(true)
        setHostNotAvailableSinceDateTime(undefined)
      }
    } else if (!host && participants.length > 0) {
      setHostNotAvailable()
      setStagedParticipants([])
      muteAll()
    }
  }, [participants.length])

  return {
    isHostOrCohostAvailable,
    hostNotAvailableSinceDateTime,
  }
}

export const useMeetingEndTimerHook = () => {
  const {
    waitingTimeForHostOrCoHost,
    meetingEndTime,
    alertsBeforeMeetingEnds,
    isHostOrCohostAvailable,
    setShouldLeaveMeeting,
  } = useCustomRoomContext()
  const { hostNotAvailableSinceDateTime } = useHostCoHostAvailabilityHook()
  const { state: connectionState } = useRoomContext()
  const participants = useParticipants()

  const [timeLeftString, setTimeLeftString] = useState<string>()
  const [showPing, setShowPing] = useState<boolean>(false)
  const [showAlertFiveMin, setShowAlertFiveMin] = useState(false)
  const [twoMinAlert, setTwoMinAlert] = useState(false)

  const hostOrCoHost = participants.find((p) => {
    if (p.metadata) {
      try {
        const metadata = JSON.parse(p.metadata)
        return metadata.userType === UserType.HOST || metadata.userType === UserType.CO_HOST
      } catch (e) {
        return false
      }
    }
    return false
  })

  useEffect(() => {
    let timer: any
    let endTime = new Date(meetingEndTime)
    if (meetingEndTime && connectionState === ConnectionState.Connected) {
      timer = setInterval(() => {
        if (!isHostOrCohostAvailable && hostNotAvailableSinceDateTime && !hostOrCoHost) {
          const hostNotAvailableEndTime = addSecondsToDate(
            new Date(hostNotAvailableSinceDateTime),
            waitingTimeForHostOrCoHost
          )
          endTime = endTime < hostNotAvailableEndTime ? endTime : hostNotAvailableEndTime
        }
        const { timeLeftString, minutes, timeLeftInSeconds } = calculateTimeRemaining(endTime)
        setTimeLeftString(timeLeftString)
        setShowPing(minutes < 5)

        // if timeleft in seconds is 300, show alert
        if (
          alertsBeforeMeetingEnds.includes(timeLeftInSeconds) &&
          timeLeftInSeconds === alertsBeforeMeetingEnds[0] &&
          !showAlertFiveMin
        ) {
          setShowAlertFiveMin(true)
          setTimeout(() => {
            setShowAlertFiveMin(false)
          }, 5000)
        }
        if (
          alertsBeforeMeetingEnds.includes(timeLeftInSeconds) &&
          timeLeftInSeconds === alertsBeforeMeetingEnds[1] &&
          !twoMinAlert
        ) {
          setTwoMinAlert(true)
          setTimeout(() => {
            setTwoMinAlert(false)
          }, 5000)
        }

        if (timeLeftInSeconds <= 0) {
          setShouldLeaveMeeting(true)
        }
      }, 1000)
    }

    return () => clearInterval(timer)
  }, [
    connectionState,
    isHostOrCohostAvailable,
    hostNotAvailableSinceDateTime,
    meetingEndTime,
    alertsBeforeMeetingEnds,
    showAlertFiveMin,
    twoMinAlert,
    waitingTimeForHostOrCoHost,
    setShouldLeaveMeeting,
    hostOrCoHost,
  ])

  return {
    timeLeftString,
    showPing,
    showAlertFiveMin,
    twoMinAlert,
  }
}

export const usePermissionCheckHooks = () => {
  const [isAudioPermissionGrantedError, setIsAudioPermissionGrantedError] = useState<boolean>(false)
  const [isVideoPermissionGrantedError, setIsVideoPermissionGrantedError] = useState<boolean>(false)

  async function checkAudioPermissions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getTracks().forEach((track) => track.stop())
      setIsAudioPermissionGrantedError(false)
    } catch (error: any) {
      console.error(error)
      if (error.name === 'NotAllowedError' || error.name === 'NotFoundError') {
        setIsAudioPermissionGrantedError(true)
      }
    }
  }
  async function checkVideoPermissions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      stream.getTracks().forEach((track) => track.stop())
      setIsVideoPermissionGrantedError(false)
    } catch (error: any) {
      console.error(error)
      if (error.name === 'NotAllowedError' || error.name === 'NotFoundError') {
        setIsVideoPermissionGrantedError(true)
      }
    }
  }
  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', checkAudioPermissions)
    navigator.mediaDevices.addEventListener('devicechange', checkVideoPermissions)
  }, [])

  useEffect(() => {
    if (isAudioPermissionGrantedError) {
      return
    }
    checkAudioPermissions()
    checkVideoPermissions()
  }, [isAudioPermissionGrantedError, isVideoPermissionGrantedError])

  return {
    isAudioPermissionGrantedError,
    isVideoPermissionGrantedError,
  }
}

export const useControlBarAccessHooks = () => {
  const participants = useParticipants()

  const isHostOrCoHostAvailableRoom = participants.some((participant) => {
    if (participant instanceof LocalParticipant && participant.metadata) {
      const userType = JSON.parse(participant.metadata).userType
      return userType === UserType.HOST || userType === UserType.CO_HOST
    }
    return false
  })

  return {
    isHostOrCoHostAvailableRoom,
  }
}

export const useOutsideClickDetectionHook = ({ initialValue }: { initialValue: boolean }) => {
  const [isParticipantMenuOpen, setIsParticipantMenuOpen] = useState(initialValue)
  const ref = useRef<any>(null)

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsParticipantMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return { ref, isParticipantMenuOpen, setIsParticipantMenuOpen }
}

export const useEndRoomHooks = () => {
  const { send: sendEndRoom } = useDataChannel('endRoom')
  const { localParticipant } = useLocalParticipant()
  const { setIsHostEnded } = useCustomRoomContext()

  const message: LiveKitEndRoom = {
    currentUserId: localParticipant?.sid,
  }

  const endRoom = () => {
    const options: DataPublishOptions = {
      reliable: true,
    }
    sendEndRoom(new TextEncoder().encode(JSON.stringify(message)), options)
    setTimeout(() => {
      setIsHostEnded(true)
    }, 5000)
  }

  return { endRoom }
}

export const useEndRoomReceiver = () => {
  const { message } = useDataChannel('endRoom')
  const [isRoomEnded, setIsRoomEnded] = useState(false)

  useEffect(() => {
    if (message && message.payload) {
      const decodedMessage = JSON.parse(new TextDecoder().decode(message.payload)) as LiveKitEndRoom
      if (decodedMessage) {
        setIsRoomEnded(true)
      }
    }
  }, [message])

  return { isRoomEnded }
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const useScreenDimensions = (tracks: TrackReferenceOrPlaceholder[]) => {
  const gridWrapperRef = createRef<HTMLDivElement>()
  const { width: screenWidth, height: screenHeight } = useWindowDimensions()
  const { gridDimensions, setGridDimensions } = useCustomRoomContext()

  const scaledPixelRatio = window.devicePixelRatio

  useEffect(() => {
    if (gridWrapperRef.current) {
      if (
        gridDimensions.width !== gridWrapperRef.current.offsetWidth ||
        gridDimensions.height !== gridWrapperRef.current.offsetHeight / 1.1
      ) {
        setGridDimensions({
          width: gridWrapperRef.current.offsetWidth,
          height: gridWrapperRef.current.offsetHeight / 1.1,
        })
      }
    }
  }, [screenHeight, screenWidth, gridWrapperRef, gridDimensions])

  return {
    gridWrapperRef,
    gridDimensions,
    scaledPixelRatio,
    screenHeight,
    screenWidth,
  }
}

interface StagedParticipantsMessage {
  stagedParticipants: string[]
}

export const useSendStagedParticipantsList = () => {
  const { send: sendStagedParticipantsList } = useDataChannel('stagedParticipantsList')
  const room = useEnsureRoom()
  const { stagedParticipants } = useCustomRoomContext()
  const { localParticipant } = useLocalParticipant()

  const sendStagedParticipants = useCallback(
    (participant: RemoteParticipant) => {
      const isHostOrCohost =
        JSON.parse(localParticipant?.metadata ?? '{}').userType === UserType.HOST ||
        JSON.parse(localParticipant?.metadata ?? '{}').userType === UserType.CO_HOST
      if (!isHostOrCohost) {
        return
      }
      const message: StagedParticipantsMessage = {
        stagedParticipants,
      }
      const options: DataPublishOptions = {
        reliable: true,
        destinationIdentities: [participant.identity],
      }
      const encodedMessage = new TextEncoder().encode(JSON.stringify(message))
      setTimeout(() => {
        sendStagedParticipantsList(encodedMessage, options)
      }, 3500)
    },
    [localParticipant?.metadata, sendStagedParticipantsList, stagedParticipants]
  )

  useEffect(() => {
    room?.addListener('participantConnected', (newParticipant) => {
      sendStagedParticipants(newParticipant)
    })
  }, [room, sendStagedParticipants])
}

export const useReceiveStagedParticipantsList = () => {
  const { message } = useDataChannel('stagedParticipantsList')
  const { setStagedParticipants } = useCustomRoomContext()
  useEffect(() => {
    if (!message) {
      return
    }
    const messageData = JSON.parse(new TextDecoder().decode(message.payload))
    setStagedParticipants(messageData.stagedParticipants)
  }, [message, setStagedParticipants])
}

export const useGetUserPosition = (trackReferene: TrackReference | TrackReferenceOrPlaceholder) => {
  const [userPinnedPosition, setUserPinnedPosition] = useState(-1) // current user position
  const [userUnpinnedPosition, setUserUnpinnedPosition] = useState<number>(-1) // current user position
  const { pinnedParticipants } = useCustomGridContext()
  const allParticipants = useParticipants()
  const participant = useEnsureParticipant()
  const { viewType, stagedParticipants, handRaisedParticipants, allTracksSids, allTracks } = useCustomRoomContext()
  const [unPinnedCount, setUnPinnedCount] = useState<number>(0)
  const [camEnabledUserCount, setCamEnabledUserCount] = useState<number>(0)
  const { hostAndCoHosts } = useGetHostAndCoHost()

  const sidSource =
    trackReferene?.source === Track.Source.ScreenShare ? participant.sid + '-' + trackReferene?.source : participant.sid

  const pinnedParticipantTrack = allTracksSids.filter((p) => pinnedParticipants.includes(p))
  useEffect(() => {
    if (pinnedParticipantTrack.length === 0) {
      setUserPinnedPosition(-1)
    } else {
      setUserPinnedPosition(pinnedParticipantTrack.findIndex((p) => p === sidSource))
    }
  }, [pinnedParticipants, sidSource, allTracks])

  useEffect(() => {
    if (allTracksSids.length === 0) {
      setUserUnpinnedPosition(-1)
      setCamEnabledUserCount(0)
    } else {
      let unPinnedParticipants = allTracksSids.filter((p) => !pinnedParticipants.includes(p))

      const cameraEnabledParticipants = allParticipants
        .filter((p) => p.isCameraEnabled || p.isScreenShareEnabled)
        .map((p) => p.sid)
      const screenSharedTracks = allTracks
        .filter((track: { source: Track.Source }) => track.source === Track.Source.ScreenShare)
        .map((track: { participant: { sid: string }; source: string }) => track.participant.sid + '-' + track?.source)

      if (viewType === 'hostHighlighted') {
        if (unPinnedParticipants.length > 0) {
          unPinnedParticipants = unPinnedParticipants.filter(
            (p) =>
              handRaisedParticipants.includes(p) ||
              screenSharedTracks.includes(p) ||
              cameraEnabledParticipants.includes(p) ||
              hostAndCoHosts.includes(p)
          )
        }

        if (screenSharedTracks.length > 0) {
          unPinnedParticipants = unPinnedParticipants.filter((p) => !screenSharedTracks.includes(p)) || hostAndCoHosts
        }
      }
      setUserUnpinnedPosition(unPinnedParticipants.findIndex((p) => p === sidSource))
      setUnPinnedCount(unPinnedParticipants.length)
      setCamEnabledUserCount(cameraEnabledParticipants.length)
    }
  }, [
    allTracksSids,
    participant?.sid,
    pinnedParticipants,
    handRaisedParticipants,
    stagedParticipants,
    viewType,
    allParticipants,
  ])

  return {
    userPinnedPosition,
    userUnpinnedPosition,
    pinnedParticipantCount: pinnedParticipantTrack.length,
    unpinnedParticipantCount: unPinnedCount,
    camEnabledUserCount,
  }
}

export const useParticipantTilePosition = (trackReference: TrackReference | TrackReferenceOrPlaceholder) => {
  const { name } = useParticipantInfo()
  const { gridDimensions, viewType, allTracks } = useCustomRoomContext()
  const [isHiddenParticipant, setIsHiddenParticipant] = useState<boolean>(false)
  const { addPinnedParticipant, removePinnedParticipant } = useCustomGridContext()
  const { userPinnedPosition, userUnpinnedPosition, pinnedParticipantCount, unpinnedParticipantCount } =
    useGetUserPosition(trackReference)

  const [userPosition, setUserPosition] = useState<UserLayoutPosition>({
    w: 0,
    h: 0,
    row: 0,
    col: 0,
    top: 0,
    left: 0,
  })
  const roundSize = Math.min(userPosition.h, userPosition.w)
  const fullName = name ? name.replace(/\b\w/g, (c) => c.toUpperCase()) : ''

  const prevParticipantsRef = useRef(allTracks)

  useEffect(() => {
    const prevParticipants = prevParticipantsRef.current
    if (prevParticipants.length !== allTracks.length) {
      const leftParticipants = prevParticipants.filter(
        (participant: { sid: string }) => !allTracks.some((p: { sid: string }) => p.sid === participant.sid)
      )
      leftParticipants.forEach((participant: { sid: string }) => removePinnedParticipant(participant.sid))
    }
    prevParticipantsRef.current = allTracks
  }, [allTracks, removePinnedParticipant])

  useEffect(() => {
    const rightGridWidth = gridDimensions.width / 5 < 150 ? 150 : gridDimensions.width / 5
    const centerGridWidth =
      (pinnedParticipantCount > 0 && unpinnedParticipantCount === 0) ||
      (pinnedParticipantCount === 0 && unpinnedParticipantCount > 0)
        ? gridDimensions.width
        : gridDimensions.width - rightGridWidth

    const isCenter =
      pinnedParticipantCount + unpinnedParticipantCount === 1 ||
      (pinnedParticipantCount > 0 && unpinnedParticipantCount === 0) ||
      (pinnedParticipantCount === 0 && unpinnedParticipantCount > 0) ||
      (pinnedParticipantCount > 0 && unpinnedParticipantCount > 0 && userPinnedPosition > -1)

    const size = isCenter
      ? getGridAspect(
          100,
          centerGridWidth * 0.98,
          gridDimensions.height,
          pinnedParticipantCount || unpinnedParticipantCount,
          centerGridWidth,
          '16:9'
        )
      : getGridAspect(
          100,
          rightGridWidth * 0.98,
          gridDimensions.height,
          unpinnedParticipantCount,
          rightGridWidth,
          '16:9'
        )

    if (size && isCenter) {
      const userLayoutPositions = calculateBoxPositionsForEachUser(
        size.boxH,
        size.boxW,
        pinnedParticipantCount || unpinnedParticipantCount,
        centerGridWidth,
        gridDimensions.height,
        0
      )

      const position = userPinnedPosition > -1 ? userPinnedPosition : userUnpinnedPosition
      if (userLayoutPositions[position]) {
        setUserPosition(userLayoutPositions[position])
      }
    } else if (size && !isCenter) {
      const userLayoutPositions = calculateBoxPositionsForEachUser(
        size.boxH,
        size.boxW,
        unpinnedParticipantCount,
        rightGridWidth,
        gridDimensions.height,
        0,
        0
        // centerGridWidth
      )

      if (userLayoutPositions[userUnpinnedPosition]) {
        const positionSet = userLayoutPositions[userUnpinnedPosition]
        positionSet.left = positionSet.left + centerGridWidth
        setUserPosition(positionSet)
      }
    }
  }, [
    gridDimensions.height,
    gridDimensions.width,
    pinnedParticipantCount,
    unpinnedParticipantCount,
    userPinnedPosition,
    userUnpinnedPosition,
    viewType,
  ])

  useEffect(() => {
    if (userPinnedPosition < 0 && userUnpinnedPosition < 0) {
      setIsHiddenParticipant(true)
    } else {
      setIsHiddenParticipant(false)
    }
  }, [userPinnedPosition, userUnpinnedPosition])

  return {
    userPosition,
    fullName,
    roundSize,
    isHiddenParticipant,
    addPinnedParticipant,
    removePinnedParticipant,
  }
}
