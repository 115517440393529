import { useEffect } from "react";
import { initReactI18next } from "react-i18next";
import { getPreference } from "../Services/userReducer";
import { store } from "../Store";
import i18n from "../Translations";
import { resources } from "../Translations/resources";

export const useTranslationHook = (languageDetector?: any) => {
    const preference = getPreference(store.getState());
  
    useEffect(() => {
        if (languageDetector) {
            i18n.use(initReactI18next).use(languageDetector).init({
                resources,
                lng: preference?.language,
                fallbackLng: "en",
                interpolation: {
                  escapeValue: false // react already safes from xss
                }
              });
        } else {
            i18n.use(initReactI18next).init({
                resources,
                lng: preference?.language,
                fallbackLng: "en",
                interpolation: {
                  escapeValue: false // react already safes from xss
                }
              });
        }

    }, [preference, languageDetector])

    return {}
}
