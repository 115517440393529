import {useEffect} from 'react'
import {NavLink, Outlet, useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

function AttendingEventsWrapper() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {pathname} = useLocation()

  const style = {
    events: `flex text-gray-400 cursor-pointer font-normal text-lg hover:text-black hover:font-medium sm:text-sm sm:font-semibold`,
    navLink: `xs:text-xs sm:text-sm md:text-base font-normal hover:text-black hover:font-semibold flex flex-row gap-x-2 items-center`,
    active: `xs:text-sm sm:text-base md:text-lg text-black font-semibold px-2 py-1 rounded-lg flex flex-row gap-x-2 items-center`,
  }

  useEffect(() => {
    if (
      pathname.endsWith('/s/events/attending-events') ||
      pathname.endsWith('/s/events/attending-events/')
    ) {
      navigate({pathname: '/s/events/attending-events/upcomingEvents'})
    }
  }, [navigate, pathname])

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-center w-full items-center md:space-x-5 gap-2 mb-4 mt-2">
        <div className={style.events}>
            <NavLink
              id="btn_upcomingEvents"
              data-testid="btn_upcomingEvents"
              to={{
                pathname: 'upcomingEvents',
              }}
              className={(navData: any) =>
                navData.isActive ? style.active : style.navLink
              }>
              {t('upcomingEvents')}
            </NavLink>
        </div>
        <div className={style.events}>
            <NavLink
              id="btn_receivedRequests"
              data-testid="btn_receivedRequests"
              to={{
                pathname: 'pastHistory',
              }}
              className={(navData: any) =>
                navData.isActive ? style.active : style.navLink
              }>
              {t('pastEvents')}
            </NavLink>
        </div>
      </div>
      <div className={`content w-full flex justify-center`}>
        <Outlet />
      </div>
    </div>
  )
}

export default AttendingEventsWrapper
