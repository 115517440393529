import { formatDateToTimezone, formatTimeToTimezone } from '../Utils/datetime';
import { format } from '../Utils/format';
import { Ledger } from './../models/ledger.model';
import { allApis } from './allApis';

import { getCurrency, getUserPreferenceCurrency } from '../Utils/index.web';
import { RazorpayAccount } from '../models/razorpay.model';

const ledgerResponseTransform = (response: Ledger[]) => {
    const preferenceCurrency = getUserPreferenceCurrency()
    const currencyList = getCurrency();
    const filteredValue = currencyList?.filter(currency => currency.code === preferenceCurrency)

    if (response) {
        response = response.map((transaction: any) => {

            transaction.transactionAmount = format(filteredValue[0]?.formatType, transaction.transactionAmount)
            transaction.costLocal = format(filteredValue[0]?.formatType, transaction.costLocal)
            transaction.display_createdAt = formatDateToTimezone(transaction.createdAt.toString())
            transaction.originalTransactionAmount = transaction?.previousTransactions?.transactionAmount
            transaction.display_createdAtTime = formatTimeToTimezone(transaction?.transactionDateTime?.toString())
            transaction.display_meetingTime = formatTimeToTimezone(transaction?.meeting?.fromDateTime) + ' - ' + formatTimeToTimezone(transaction?.meeting?.toDateTime)
            transaction.display_meetingDate = formatDateToTimezone(transaction?.meeting?.fromDateTime?.toString())
            if (transaction.transactionDetails) {
                transaction.transactionDetails = JSON.parse(transaction.transactionDetails)
            }
            if (transaction.meetingRequest) {
                transaction.meetingRequest.display_date = formatDateToTimezone(transaction.meetingRequest.createdAt?.toString())
                transaction.meetingRequest.display_time = formatTimeToTimezone(transaction?.meetingRequest.createdAt?.toString())
            }

            return transaction

        })
    }
    return response
}

export const ledgerApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        payment: builder.query<any, any>({
            query: (args) => {
                const { id, meetingDetailsId, guestCount, joinAnonymous } = args;
                return `ledger/pay/${meetingDetailsId}/${id}/${guestCount}/${joinAnonymous}`;
            },
            // providesTags: ['Ledger']
        }),
        paymentRequest: builder.query<any, any>({
            query: (args) => {
                const { requestId, guestCount } = args;
                return `ledger/payRequest/${requestId}/${guestCount}`;
            },
            // providesTags: ['Ledger']
        }),
        razorPaySuccess: builder.mutation<any, any>({

            query: (args) => {
                return {
                    url: `ledger/razorpay/success`,
                    method: 'POST',
                    body: args
                };
            },
            // invalidatesTags: (result, error, arg) => {
            //     return error ? [] : ['razorPaySuccess']
            // }
        }),
        razorPayFail: builder.query<any, any>({
            query: (args) => {
                const { orderId, paymentId } = args;
                return `ledger/fail/${orderId}/${paymentId}`;
            },
            // providesTags: ['Ledger']
        }),
        getLedger: builder.query<Ledger, string>({
            query: (transactionId) => `ledger/transactionDetails/${transactionId}`,
            transformResponse: (response: Ledger) => {
                response.display_createdAt = formatDateToTimezone(response.transactionDateTime?.toString())
                response.display_createdAtTime = formatTimeToTimezone(response.transactionDateTime?.toString())
                response.meeting.display_FromTime = formatTimeToTimezone(response.meeting?.fromDateTime) + ' - ' + formatTimeToTimezone(response.meeting?.toDateTime)
                response.meeting.display_date = formatDateToTimezone(response.meeting?.dateCheck?.toString() || response.meeting?.createdAt?.toString() || response.meeting?.fromDateTime?.toString())
                return response;
            },
            // providesTags: ['Ledger']
        }),
        getAllTransactions: builder.query<Ledger[], void>({
            query: () => `ledger`,
            transformResponse: (res: any) => ledgerResponseTransform(res),
            // providesTags: ['PaidInviteList'] // to be discussed
        }),
        getAllCredits: builder.query<any, void>({
            query: () => `ledger/credits`,
        }),
        createAccount: builder.query<any, void>({
            query: () => `ledger/createAccount`,
            // providesTags: ['Ledger']
        }),
        createLink: builder.query<any, void>({
            query: () => `ledger/createLink`,
            // providesTags: ['Ledger']
        }),
        checkAccount: builder.query<any, void>({
            query: () => `ledger/checkAccount`,
            // providesTags: ['Ledger']
        }),
        paymentMobile: builder.mutation<any, any>({
            query: (paymentDetail) => {
                return {
                    url: `ledger/payment`,
                    method: 'POST',
                    body: paymentDetail
                };
            }
        }),
        paymentRequestMobile: builder.mutation<any, any>({
            query: (paymentDetail) => {
                return {
                    url: `ledger/paymentRequest`,
                    method: 'POST',
                    body: paymentDetail
                };
            }
        }),
        // RazorpayAccountAPI
        createRazorpayAccount: builder.mutation<RazorpayAccount, Partial<RazorpayAccount>>({
            query: (razorpay) => {
                return {
                    url: `razorpay`,
                    method: 'POST',
                    body: razorpay
                };
            },
            invalidatesTags: ['RazorpayAccount'],
        }),

    })

});

export const {
    useGetAllTransactionsQuery,
    usePaymentQuery,
    usePaymentMobileMutation,
    usePaymentRequestMobileMutation,
    useGetLedgerQuery,
    usePaymentRequestQuery,
    useCreateAccountQuery,
    useCreateLinkQuery,
    useRazorPaySuccessMutation,
    useCheckAccountQuery,
    useCreateRazorpayAccountMutation,
    useGetAllCreditsQuery,
} = ledgerApi


