import PropTypes from "prop-types";
import { NewIcons } from '../../../Assets/NewIcons/index';

const NewIcon = ({ size, bgColor, stroke = "#010101", icon, onClick, className }: { size?: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large' | 'xl-large' | 'xxl-large', bgColor?: string, stroke?: string, icon: string, onClick?: any, className?: string }) => {
    let sizeWidth: string
    sizeWidth = size === 'x-small' ? '15' : size === 'small' ? '20' : size === 'medium' ? '30' : size === 'large' ? '40' : size === 'xl-large' ? '60' : size === 'xxl-large' ? '110' : '10'
    const IconComp = NewIcons[icon as keyof typeof NewIcons];
    return <IconComp fill={bgColor} stroke={stroke} width={sizeWidth} height={sizeWidth} onClick={onClick} className={className} />

};

NewIcon.defaultProps = {
    size: 'small',
    height: 'small',
    bgColor: "transparent",
};

NewIcon.propTypes = {
    size: PropTypes.oneOf([ 'x-small', 'small', 'medium', 'large', 'xl-large', 'xxl-large']),
    color: PropTypes.string,
    icon: PropTypes.string.isRequired,
};

export default NewIcon;
