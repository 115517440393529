/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import useToggle from '../../Components/_utils/useToggle'
import Button from '../../Components/base/button/button'
import Icon from '../../Components/base/icon/icon'
import { Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '../../Components/base/modal/modal'
import { PageTitle } from '../../Components/pageTitle'
import { toastSuccess } from '../../Components/toast'
import { useEventDetailsHook } from '../../Hooks/MyEvents/EventDetailsHook'
import { getCurrentUser } from '../../Services/userReducer'
import { PaymentAccountActive } from '../../models/user.model'
import BeemgSwitch from '../MyEventsDetails/BeemgSwitch'
import EventTitle from '../MyEventsDetails/EventTitle'
import MeetingCategories from '../MyEventsDetails/MeetingCategories'
import ReactQuillEditor from '../MyEventsDetails/ReactQuillEditor'

function NewEvent() {
  const navigate = useNavigate()
  const currentUser = useSelector(getCurrentUser)
  const { t } = useTranslation()
  const refFrom = useRef(null)
  const { meetingDetailId } = useParams()
  const { toggle, isOpen } = useToggle()
  const { toggle: deleteToggle, isOpen: deleteisOpen } = useToggle()
  const [accordionOpen, setAccordionOpen] = useState(false)

  const {
    meetingCategory,
    showOtherCategory,
    setShowOtherCategory,
    onMeetingSubmit,
    createMeetingDetailsSuccess,
    handleAddCategoryList,
    handleRemoveCategory,
    register,
    trigger,
    handleSubmit,
    submitted,
    setValue,
    errors,
    watch,
    getValues,
    isLoading,
    meetingDetails,
    setShowAnonymous,
    ShowAnonymous,
    meetingCategoryList,
    meetingCategoryListSuccess,
    handleMeetingDelete,
    cancellationPolicies,
    createdMeetingDetails,
  } = useEventDetailsHook({ meetingDetailId: meetingDetailId ?? '' })

  useEffect(() => {
    if (currentUser.paymentAccountActive === PaymentAccountActive.No) {
      setValue("display_isAudioMeeting", true)
      setValue("isFreeAudioMeeting", true)
    }
  }, [currentUser])
  
  useEffect(()=>{
    if(watch('description')){
      trigger('description')
    }
  },[watch('description')])

  useEffect(() => {
    createMeetingDetailsSuccess && toastSuccess(t('eventCreatedSuccessfully'))
  }, [createMeetingDetailsSuccess, t])

  useEffect(() => {
    if (createdMeetingDetails && accordionOpen) {
      navigate(`${'/s/events/imageVideoModel/' + createdMeetingDetails.id}`)
    }
  }, [createdMeetingDetails, accordionOpen])

  useEffect(() => {
    if (createMeetingDetailsSuccess && !accordionOpen) {
      navigate('/s/events/my-events')
    }
  }, [createMeetingDetailsSuccess])

  return (
    <>
      <div className='flex w-full justify-center'>
        <div className='flex flex-col items-center'>
          <button
            data-testid='btn_goBack'
            className="flex mt-4 mb-4"
            onClick={() => navigate(`/s/events/my-events/${meetingDetailId}`)}
          >
            <Icon icon="GOBACK" size="small" />
            <span className="font-semibold pl-1">{t('goBack')}</span>
          </button>

          <div className="flex justify-center">
            <PageTitle title="createYourEvent" width='100px'>
              <hr className='md:min-w-[30rem] mx-auto pb-4' />
              <form ref={refFrom} onSubmit={handleSubmit(onMeetingSubmit)}>
                <div className="flex w-full">
                  <div className="min-h-eventDetails mx-2 gap-y-4 ">
                    <div className="flex items-center mt-4 gap-x-4">
                      <div className="font-medium text-md">{t('enterEventTitle')}</div>
                      <div className="flex-1">
                        <EventTitle
                          meetingDetails={undefined}
                          register={register} />
                      </div>
                      {errors?.title?.message && <span id="error_message" data-testid="error_message" className='text-red-500 text-sm'>{t(errors?.title?.message)}</span>}
                    </div>
                    <div className="h-6" />
                    <ReactQuillEditor
                      meetingDetails={undefined}
                      errors={errors}
                      watch={watch}
                      setValue={setValue}
                      editorRequired={true} />
                    <div className="h-6" />
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                      <div className='w-full xs:w-auto lg:w-3/4'>
                        {/* youngnest */}
                        <BeemgSwitch
                          errors={errors?.kidFriendly?.message}
                          register={register}
                          checked={meetingDetails?.kidFriendly}
                          htmlFor={meetingDetails?.id ? undefined : 'kidFriendly'}
                          beemgSwitchText={'youngnest'}
                          name="kidFriendly"
                          registerValue="kidFriendly"
                          onClick={(e: any) => {
                            setValue('kidFriendly', e.target.checked ? true : false)
                          }}
                          toolTipText={t('suitableForAgeGroupsFrom6-12&AdultsAsWell')} />
                        {/* littlescape */}
                        <BeemgSwitch
                          errors={errors?.kidsOnly?.message}
                          register={register}
                          checked={meetingDetails?.kidsOnly}
                          htmlFor={meetingDetails?.id ? undefined : 'kidsOnly'}
                          beemgSwitchText={'littlescape'}
                          name="kidsOnly"
                          registerValue="kidsOnly"
                          onClick={(e: any) => {
                            setValue('kidsOnly', e.target.checked ? true : false)
                            setValue('kidFriendly', e.target.checked ? true : false)
                          }}
                          toolTipText={t('suitableForAgeGroupsFrom6-12')}
                        />
                        {/* autoDelete */}
                        <BeemgSwitch
                          errors={errors?.autoDelete?.message}
                          register={register}
                          checked={meetingDetails?.autoDelete}
                          htmlFor={'autoDelete'}
                          beemgSwitchText={'autoDelete'}
                          name="autoDelete"
                          registerValue="autoDelete"
                          onClick={(e: any) => {
                            setValue('autoDelete', e.target.checked ? true : false)
                          }}
                          toolTipText={t('autoDeleteOnMinimumLimitNotReached')}
                        />
                      </div>

                      <div className='w-full md:w-auto'>
                        {/* hostAnonymously */}
                        <BeemgSwitch
                          errors={errors?.isAnonymous?.message}
                          register={register}
                          checked={meetingDetails?.isAnonymous}
                          htmlFor={meetingDetails?.id ? undefined : 'input_hostAnonymously'}
                          beemgSwitchText={'hostAnonymously'}
                          name="isAnonymous"
                          registerValue="isAnonymous"
                          onClick={(e: any) => {
                            setValue('isAnonymous', e.target.checked ? true : false)
                            if (e.target.checked === true && !currentUser.anonymous) {
                              setShowAnonymous(true)
                            } else {
                              setShowAnonymous(false)
                            }
                          }}
                          showAnonymous={ShowAnonymous}
                        />
                        {ShowAnonymous && (
                          <Button
                            size="sm"
                            rounded
                            color="join"
                            onClick={() => navigate('/s/profile', { state: { isAnon: true } })}
                          >
                            <p
                              id="btn_anonymousProfile"
                              data-testid="btn_anonymousProfile"
                              className="text-xs md:text-sm ">
                              {t('updateAnonymousProfile')}
                            </p>
                          </Button>
                        )}
                        {currentUser.paymentAccountActive ===
                          PaymentAccountActive.Yes && (
                            <BeemgSwitch
                              errors={errors?.display_isAudioMeeting?.message}
                              register={register}
                              checked={meetingDetails?.display_isAudioMeeting}
                              htmlFor={meetingDetails?.id ? undefined : 'display_isAudioMeeting'}
                              beemgSwitchText={'makeThisEventAsAudioOnly'}
                              name="display_isAudioMeeting"
                              registerValue="display_isAudioMeeting"
                              onClick={(e: any) => {
                                setValue('display_isAudioMeeting', e.target.checked)
                                setValue('isFreeAudioMeeting', e.target.checked)
                              }}
                              disabled={meetingDetails?.id ? true : false} />
                          )}
                        {currentUser.paymentAccountActive === PaymentAccountActive.No && (
                          <BeemgSwitch
                            errors={errors?.display_isAudioMeeting?.message}
                            register={register}
                            checked={meetingDetails?.display_isAudioMeeting
                              ? meetingDetails?.display_isAudioMeeting
                              : true}
                            htmlFor={meetingDetails?.id ? undefined : 'display_isAudioMeeting'}
                            beemgSwitchText={'makeThisEventAsAudioOnly'}
                            name="display_isAudioMeeting"
                            registerValue="display_isAudioMeeting"
                            onClick={(e: any) => {
                              setValue('display_isAudioMeeting', e.target.checked)
                              setValue('isFreeAudioMeeting', e.target.checked)
                            }}
                            disabled={meetingDetails?.id
                              ? true
                              : false ||
                              currentUser.paymentAccountActive ===
                              PaymentAccountActive.No} />
                        )}

                        {/* Show free event checkbox */}
                        {(watch('display_isAudioMeeting') ||
                          getValues('display_isAudioMeeting')) && (
                            <>
                              {currentUser.paymentAccountActive ===
                                PaymentAccountActive.Yes && (
                                  <BeemgSwitch
                                    errors={errors?.isFreeAudioMeeting?.message}
                                    register={register}
                                    checked={meetingDetails?.isFreeAudioMeeting}
                                    htmlFor={meetingDetails?.id ? undefined : 'isFreeAudioMeeting'}
                                    beemgSwitchText={'isFreeAudioMeeting'}
                                    name="isFreeAudioMeeting"
                                    registerValue="isFreeAudioMeeting"
                                    onClick={(e: any) => setValue('isFreeAudioMeeting', e.target.checked)}
                                    disabled={meetingDetails?.id ? true : false} />
                                )}
                            </>
                          )}

                        {currentUser.paymentAccountActive === PaymentAccountActive.No && (
                          <BeemgSwitch
                            errors={errors?.isFreeAudioMeeting?.message}
                            register={register}
                            checked={meetingDetails?.isFreeAudioMeeting
                              ? meetingDetails?.isFreeAudioMeeting
                              : true
                            }
                            htmlFor={
                              meetingDetails?.id ? undefined : 'isFreeAudioMeeting'}
                            beemgSwitchText={'isFreeAudioMeeting'}
                            name="isFreeAudioMeeting"
                            registerValue="isFreeAudioMeeting"
                            onClick={(e: any) =>
                              setValue('isFreeAudioMeeting', e.target.checked)
                            }
                            disabled={
                              meetingDetails?.id
                                ? true
                                : false ||
                                currentUser.paymentAccountActive ===
                                PaymentAccountActive.No
                            }
                          />
                        )}
                      </div>
                      <div className='h-4' />
                      {(!watch('isFreeAudioMeeting') || !getValues('isFreeAudioMeeting')) && (
                        <div className="md:col-span-2 flex items-center md:justify-start my-3 md:my-0 gap-x-2">
                          <div className="flex gap-3">
                            <div className="font-semidome" data-testid="txt_policyChosen">
                              {t('cancellationPolicyChosen')}: <span id="policyType" className='font-semibold'>{watch('cancellationPolicy')?.policyType}</span>
                            </div>

                            {!meetingDetailId && (
                              <div data-testid="btn_editPolicy" id="btn_editPolicy" className="cursor-pointer" onClick={toggle}>
                                <Icon size="small" icon="EDIT" />
                              </div>
                            )}
                          </div>
                          {(errors?.cancellationPolicy as any)?.message && (
                            <span role="alert" className={`text-sm text-red-500`}>
                              {t((errors?.cancellationPolicy as any).message)}
                            </span>
                          )}
                        </div>
                      )}
                      <CancellationPolicyModal />
                      {meetingDetails &&
                        <Modal isOpen={deleteisOpen} toggle={deleteToggle} closeOnClickOutside={false}>
                          <ModalCloseButton toggle={deleteToggle} />
                          <ModalHeader>{t('deleteEvent')}</ModalHeader>
                          <ModalBody>
                            <ModalBody>
                              <div className="text-red-500 text-lg font-bold text-center">
                                {t('areYouSureToDeleteThisEvent')} <br /> {`" `}{meetingDetails?.title}{` "`} ?
                              </div>
                              <br />
                            </ModalBody>
                          </ModalBody>
                          <ModalFooter>
                            <Button id="btn_cancel" onClick={deleteToggle} color="footerButton">
                              {t(`cancel`)}
                            </Button>
                            <Button submit
                              onClick={e => { deleteToggle(e); handleMeetingDelete(meetingDetails) }}
                              id="btn_ok"
                              data-testid="btn_ok"
                              autoFocus
                              color="footerButton">
                              {t(`ok`)}
                            </Button>
                          </ModalFooter>
                        </Modal>}
                    </div>
                    <MeetingCategories
                      meetingCategory={meetingCategory}
                      handleRemoveCategory={handleRemoveCategory}
                      meetingCategoryListSuccess={meetingCategoryListSuccess}
                      showOtherCategory={showOtherCategory}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      handleAddCategoryList={handleAddCategoryList}
                      meetingCategoryList={meetingCategoryList}
                      setShowOtherCategory={setShowOtherCategory}
                      watch={watch} />
                    <div className='h-4' />
                    <div className="flex items-center justify-between ">
                      <div className='flex flex-col justify-start gap-y-1'>
                        <div className='text-xxs text-gray-300 font-medium'>{t('youCanChooseToCreateTheEventWithoutAddingAnyImage')}</div>
                        <div className='text-xxs text-gray-300 font-medium'>{t('orIfYouDoWantToAddThenClick')}</div>
                      </div>
                      <div className='flex items-center gap-x-4'>
                        <Button
                          className='normal-case'
                          size="md"
                          data-testid="btn_saveNext"
                          id="btn_saveNext"
                          color="transparent" submit
                          onClick={() => setAccordionOpen(true)}
                          disabled={isLoading || submitted || ShowAnonymous}>
                          {t('next')}
                        </Button>

                        <Button className='normal-case'
                          size="md"
                          data-testid="btn_createEvent"
                          id="btn_createEvent"
                          color="save" submit
                          disabled={isLoading || submitted || ShowAnonymous}>
                          {t('createEvent')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </PageTitle>
          </div>
        </div>
      </div>
    </>
  )
  function CancellationPolicyModal() {

    return (
      <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
        <ModalCloseButton toggle={toggle} />
        <ModalBody>
          <div className="text-xl font-semibold mb-3">
            {t('cancellationPolicy')}
          </div>
          <div className="flex flex-col m-3 gap-2">
            {cancellationPolicies?.map((policy, index) => (
              <div className="flex " key={`policy-${policy.id}`}>
                <div>
                  <input
                    type="radio"
                    name="cancellationPolicy"
                    id={`policy${index}`}
                    data-testid={`policy${index}`}
                    checked={policy.id === watch('cancellationPolicy')?.id}
                    value={policy.id}
                    onChange={() => {
                      setValue('cancellationPolicy', policy)
                      trigger()
                    }}
                  />
                </div>
                <span className="ml-2">
                  <span className='font-semibold'>{policy.policyType}</span>
                  <span>: {policy.chargePeriodFromTimeInMinute === "0" && t('noCancellationCharge')}
                    {policy.chargePeriodFromTimeInMinute !== "0" &&
                      <>
                        {t('cancellationBetween')}  {policy.chargePeriodFromTime}{' '}{t('hours')} ~ {Math.floor(Number(policy.chargePeriodToTime))}{' '}{t('hours')} is {policy.deductionPercentage}% {t('chargeable')} and before {policy.chargePeriodFromTime}{' '}{t('hours')}  ~ is 100% {t('chargeable')}
                      </>}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button data-testid="btn_closePolicy" id="btn_closePolicy" onClick={toggle} color="footerButton" autoFocus>{t(`close`)}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default NewEvent
