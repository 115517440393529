import { useSearchParams } from 'react-router-dom'

const PageAndSortHook = () => {
    const [searchParams] = useSearchParams();

    const sort = searchParams.has('sort')
    const page = searchParams.has('page')

    const sortValue = searchParams.get('sort') ?? 'upcomingMeeting'
    const pageValue = searchParams.get('page') ?? '1'    
    
    if (sort && page) {
        return `?page=${pageValue}&sort=${sortValue}`
    } else if (sort) {
        return `?sort=${sortValue}`
    } else if (page) {
        return `?page=${pageValue}`
    } else {
        return '?page=1'
    }
}

export default PageAndSortHook