import { FC } from 'react'
import useWindowDimensions from '../../Components/base/carousel/whirligig/utils'
interface CardTitleProps {
  meetingTitle: string
  keyId?: number | string
}
const CardTitle: FC<CardTitleProps> = ({ meetingTitle, keyId }: CardTitleProps) => {
  const { width } = useWindowDimensions()
  let className: string
  if (width > 1050) {
    className = `font-bold text-[1rem] text-base truncate`
  } else {
    className = `font-bold text-[1rem] text-base truncate`
  }
  return <span data-testid={`txt_title${keyId}`} id={`txt_title${keyId}`} className={className}>{meetingTitle}</span>
}
export default CardTitle
