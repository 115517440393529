/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../Components'
import useToggle from '../../../Components/_utils/useToggle'
import Autocomplete from '../../../Components/base/autocomplete/autocomplete'
import AvatarImage from '../../../Components/base/avatar/avatar'
import Field from '../../../Components/base/field/field'
import Loading from '../../../Components/base/loading/loading'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../Components/base/modal/modal'
import { toastSuccess } from '../../../Components/toast'
import { useUserAnonymousEditHook } from '../../../Hooks/UserProfile'
import { getCurrentUser } from '../../../Services/userReducer'
import { useAppSelector } from '../../../Store/hooks'
import { Avatar } from '../../../models/avatar.model'
import ImageCropperAvatar from '../../ImageCropperAvatar'
import EditWrapper from './EditWrapper'
import UserAnonymous from './UserAnonymous'
import { useLocation, useNavigate } from 'react-router-dom'

const UserAnonymousEditor = () => {
  const { isOpen: isEditorOpen, toggle: toggleIsEditorOpen } = useToggle()
  const { avatarListLoading, nickNamesLoading,setValue } = useUserAnonymousEditHook()
  const currentUser = useAppSelector(getCurrentUser)

  const location = useLocation()
  const  navigate  = useNavigate()
  const {state} = location

  useEffect(() => {
    if (state?.isAnon) {
      // Update the route state and open the editor
      navigate(location.pathname, { state: { isAnon: undefined }, replace: true });
      toggleIsEditorOpen();

      // Set the value of the checkbox to true
      setValue('anonymous', true);
    }
}, [state?.isAnon]);
  
  const handleEditClick = () => {
    if (!isEditorOpen) toggleIsEditorOpen()
  }

  if (nickNamesLoading || avatarListLoading) {
    return <Loading />
  }

  return (
    <EditWrapper keyId="userAnonymous" onEditClick={handleEditClick}>
      <UserAnonymous />
      {isEditorOpen && (
        <AnonymousEditor
          isEditorOpen={isEditorOpen}
          toggleIsEditorOpen={toggleIsEditorOpen}
        />
      )}
    </EditWrapper>
  )
}

export default UserAnonymousEditor

interface UserAnonymousEditorProps {
  isEditorOpen: boolean
  toggleIsEditorOpen: () => void
}

const AnonymousEditor = (props: UserAnonymousEditorProps) => {
  const { isEditorOpen, toggleIsEditorOpen } = props
  const { t } = useTranslation()
  const {
    avatarList,
    profileUpdateSuccess,
    profileUpdateLoading,
    avatarListLoading,
    nickNamesLoading,
    autoCompletionNicknames,
    register,
    control,
    setValue,
    getValues,
    errors,
    watch,
    submit,
    trigger,
  } = useUserAnonymousEditHook()

  const saveRef = useRef(null)
  const currentUser = useAppSelector(getCurrentUser)

  const callSave = (e:any) => {
    e.stopPropagation()
    e.preventDefault()

    //manually validate the form because of enter key submition on complete in modal.
    trigger().then(valid => {
      if (valid) {
        submit(getValues())
      }
    })
    const isCustomAvatar =  watch('avatar') === 'customAvatar'

    if (saveRef.current && isCustomAvatar) {
      (saveRef.current as any).saveProcess()
    }
  }

  useEffect(() => {
    if (profileUpdateSuccess) {
      toastSuccess(t('anonymousProfileUpdatedSuccessfully'))
      toggleIsEditorOpen()
    }
  }, [profileUpdateSuccess])

  const handleCloseEditor = () => {
    toggleIsEditorOpen()
  }

  const handleAvatarClick = (avatar: Avatar) => {
    setValue('avatar', avatar.avatarName)
  }

  if (nickNamesLoading || avatarListLoading) {
    return <Loading />
  }

  return (
    <Modal
      isOpen={isEditorOpen}
      toggle={() => {
        handleCloseEditor()
      }}
      closeOnClickOutside={false}>
      <div className="p-3 max-h-[80vh] overflow-y-scroll">
        <div className="flex flex-row justify-between">
          <ModalHeader keyId="anonymousProfile">
            <div>{t('anonymousProfile')}</div>
          </ModalHeader>
          <ModalCloseButton
            toggle={() => {
              handleCloseEditor()
            }}
          />
        </div>
        <ModalBody>
          <div className="flex flex-col gap-y-3 flex-1 min-h-[20rem] w-full relative">
            {/* Toggles and form inputs */}
            <div className="flex flex-col gap-y-2">
              {/* Nick Name */}
              <div className="flex flex-col gap-y-1 w-full">
                <div className="font-medium text-base">
                  {t('nickName') + ' *'}
                </div>
                <div
                  className="flex flex-col"
                  id="input_nickName"
                  data-testid="input_nickName">
                  {autoCompletionNicknames && (
                    <>
                      <Controller
                        control={control}
                        name={'nickName'}
                        defaultValue={currentUser.nickName}
                        render={({field: {onChange, value, name, ref}}) => (
                          <Autocomplete
                            onSelection={() => {}}
                            onChange={(value: any) => onChange(value)}
                            name={name}
                            defaultValue={value}
                            suggestions={autoCompletionNicknames}
                            placeholder={t('nickName') + ' *'}
                            error={
                              errors?.nickName || getValues('anonymous')
                                ? errors?.nickName?.message
                                : ''
                            }
                          />
                        )}
                      />
                    </>
                  )}
                </div>

                {/* Catch Phrase */}
                <div className="flex flex-col gap-y-2 mt-2 w-full">
                  <div className="font-medium text-base">
                    {t('catchPhrase') + ' *'}
                  </div>
                  <Field
                    {...register('catchPhrase')}
                    data-testid="input_catchPhrase"
                    defaultValue={currentUser.catchPhrase}
                    error={errors?.catchPhrase?.message}
                    name="catchPhrase"
                    type="text"
                    placeholder={t('catchPhrase') + ' *'}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-row items-center">
                  <Field
                    {...register('anonymous')}
                    type="checkbox"
                    name="anonymous"
                    data-testid="input_anonymous"
                    defaultChecked={!!currentUser.anonymous}
                    onChange={(e: any) => {
                      setValue('anonymous', e.target.checked)
                    }} 
                    />
                  <label className="ml-1" htmlFor="anonymous">{t(`anonymous`)}</label>
                </div>
                {watch('anonymous') && (
                  <div
                    id="chk_anonymous"
                    data-testid="chk_anonymous"
                    className={`italic text-gray-400 text-left`}>
                    {t('yourProfileWillBeAnonymousToOtherUsers')}
                  </div>
                )}
              </div>
            </div>

            {/* Choose Avatar */}
            <div className="flex flex-col gap-y-3">
              <div className="font-medium text-base">
                {t('chooseYourAvatar')}
              </div>
              <div className="flex flex-row flex-wrap gap-3">
                {avatarList &&
                  avatarList.length > 0 &&
                  avatarList.map((avatar, index) => {
                    const isSelected = avatar.avatarName === watch('avatar')
                    const isCustomAvatar = watch('avatar') === 'customAvatar'

                    return (
                      <div className='flex row gap-4' key={`avatar${avatar.id}`}>
                        <div
                          id={`img_avatar${avatar.id}`}
                          data-testid={`img_avatar${avatar.id}`}
                          className={`hover:scale-105 transition-all ease-in-out p-1 rounded-full cursor-pointer items-center ${
                            isSelected ? 'bg-primary' : 'bg-none'
                          }`}
                          onClick={() => {
                            handleAvatarClick(avatar)
                          }}
                          key={`avatar-${avatar.id}`}>
                          <AvatarImage
                            anonymous={true}
                            avatar={avatar.avatarName}
                          />
                        </div>
                        {index === avatarList.length - 1 && (
                          <div className="justify-center my-auto" key={`custom-avatar-${avatar.id}`}>
                            <ImageCropperAvatar
                              ref={saveRef}
                              isSelected={!isSelected && isCustomAvatar}
                              selectAvatar={isSelected => {
                                handleAvatarClick({
                                  avatarName: 'customAvatar',
                                } as Avatar)
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>

            <div className="self-center">
              <Button
                onClick={(e: any) => {
                  callSave(e)
                }}
                disabled={profileUpdateLoading}
                id="btn_saveAnonymous"
                className="mx-0">
                {t('save')}
              </Button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )

}
