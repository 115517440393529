import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "aws-amplify";
import { format } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { User } from "../models/user.model";
import { UserDetail } from "../models/userDetail.model";
import { useGetSettingValue } from "../Services/settingReducer";
import { changeCognitoModelObject } from "../Services/signUpReducer";
import { useCreateUserMutation } from "../Services/userApi";
import { useAppDispatch } from "../Store/hooks";
import { signUpValidationSchema } from "../Utils/validation";

interface Initial {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  password: string
  gender: string,
  selectedCountryCode: string,
  dateOfBirth: Date
  ageLimit?: number
}

export const useSignUpContentHook = (formVal: any, userData?: UserDetail, ipAddressData?: any, validationSchema?: any) => {
  let maxDob: Date, minDob: Date;
  const [serverError, setServerError] = useState();
  const {
    register, control, handleSubmit, getValues, watch, formState: { errors }, setValue
  } = useForm<Initial>({ defaultValues: formVal, resolver: yupResolver(validationSchema ?? signUpValidationSchema) })

  const ageLimit = Number(useGetSettingValue('AGE_LIMIT'));
  maxDob = new Date();
  maxDob.setFullYear(maxDob.getFullYear() - ageLimit);
  minDob = new Date();
  minDob.setFullYear(minDob.getFullYear() - 122.5);

  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const dispatch = useAppDispatch();

  const [createUser] = useCreateUserMutation()

  const onChange = (value: any) => {
    if (!userData) {
      if (value) {
        if (!recaptchaValue) {
          setRecaptchaValue(true);
        }
      } else {
        if (recaptchaValue) {
          setRecaptchaValue(false);
        }
      }
    }
  }

  const signUp = async (getValues: any) => {
    console.log('signup content ipAddress', ipAddressData.ip)
    console.log('signup content countryName', ipAddressData.country_name)
    console.log('signup content cityName', ipAddressData.city)
    
    // if (termsStatus) {
      const values = getValues;
      const model = {} as User;
      const cognitoModel = {} as UserDetail;
      cognitoModel.email = values.email?.trim();
      cognitoModel.password = values.password;
      cognitoModel.birthdate = format(new Date(values.dateOfBirth), 'yyyy-MM-dd');
      model.firstName = values.firstName?.trim();
      model.lastName = values.lastName?.trim();
      model.token = cognitoModel.email;
      model.gender = values.gender;
      model.ipAddress = ipAddressData?.ip;
      model.country = ipAddressData?.country_name;
      model.city = ipAddressData?.city;
    model.isUserTermsPolicyStatus = true;
    cognitoModel.phone_number = values.phoneNumber.includes('+') ? values.phoneNumber : '+' + values.phoneNumber;
      try {
        const signUpResult = await Auth.signUp({
          username: cognitoModel.email,
          password: cognitoModel.password,
          attributes: {
            email: cognitoModel.email,
            phone_number: cognitoModel.phone_number,
            birthdate: cognitoModel.birthdate
          }
        });
        model.sub = signUpResult.userSub;
        cognitoModel.sub = signUpResult.userSub;

        createUser(model).then(async (res: any) => {
          console.log('res', res)
          console.log('model', model)
          console.log('cognitoModel', cognitoModel)   
          
          localStorage.setItem('SIGNIN_HELPER_COGNITOMODEL', JSON.stringify(cognitoModel));
          dispatch(changeCognitoModelObject(cognitoModel));
        })
      } catch (e: any) {
        console.log('signUpContent error', e)
        let errorObj = e.message.substring(0, e.message.lastIndexOf('.'))
        errorObj = JSON.parse(errorObj.split('PreSignUp failed with error ')[1]);
        setServerError(errorObj.message);
      }
    // }
  }

  return {
    recaptchaValue,
    serverError,
    onChange,
    signUp,
    maxDob,
    minDob,
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue
  };
}