import React from 'react'
import { NavLink } from 'react-router-dom'
import BeeMG from '../Assets/Images/logo.svg'

const PageNotFound = () => {

    return (
        <div className={`flex items-center justify-center h-screen bg-cover bg-center`} >
            <div className='flex flex-col gap-y-3 text-center items-center'>
                <NavLink to='/s/home' >
                    <img
                        src={BeeMG}
                        className={`px-4 h-16`}
                        alt="BeeMG"
                    />
                </NavLink>
                <span className='text-xl md:text-3xl text-TT-gray font-semibold'>404 | Page Not Found</span>
            </div>

        </div>
    )
}

export default PageNotFound