import { MeetingNotificationRemainder } from "../models/meetingNotificationRemainder.model";
import { allApis } from "./allApis";

export const meetingNotificationRemainderApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        
        createRemainder: builder.mutation<MeetingNotificationRemainder, Partial<MeetingNotificationRemainder>>({
            query: (meetingNotificationRemainder) => ({
                url: `meetingNotificationRemainder`,
                method: 'POST',
                body: meetingNotificationRemainder
            }),
            invalidatesTags: (result, error, meetingNotificationRemainder) => [{ type: 'MeetingNotificationRemainder', id: meetingNotificationRemainder.meeting?.id }],
        }),

        getMeetingNotificationTime: builder.query<MeetingNotificationRemainder[], string>({
            query: (meetingId) => `meetingNotificationRemainder/${meetingId}`,
            providesTags: (result, error, meetingId) => [{ type: 'MeetingNotificationRemainder', id: meetingId }],
        }),

        deleteRemainder: builder.mutation<MeetingNotificationRemainder, {meetingNotificationRemainderId: string, meetingId: string}>({
            query: ({meetingNotificationRemainderId,meetingId}) => ({
                url: `meetingNotificationRemainder/${meetingNotificationRemainderId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, {meetingNotificationRemainderId,meetingId}) => [{ type: 'MeetingNotificationRemainder', id: meetingId  }],
        })
    })
})
export  const { useGetMeetingNotificationTimeQuery, useDeleteRemainderMutation, useCreateRemainderMutation  } = meetingNotificationRemainderApi;