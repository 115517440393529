import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import { Meeting } from "../../models/meeting.model";
import { useHandleMeetingNavigation } from "../LiveKit/hooks/livekitWebOnlyHooks";

export function StartMeetingButton({ meeting }: { meeting: Meeting }) {
  const { t } = useTranslation()
  const { joinMeeting } = useHandleMeetingNavigation()

  return <Button size="sm" rounded color="join" className="w-24" onClick={() => joinMeeting(meeting.id)}>{t('startMeeting')}</Button>
}