import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../Components'
import Loader from '../../../Components/base/loader/loader'
import Loading from '../../../Components/base/loading/loading'
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../Components/base/modal/modal'
import { toastSuccess } from '../../../Components/toast'
import { formatBytes, useUploadUserImage } from '../../../Hooks/UserProfile'
import { userApi } from '../../../Services/userApi'
import { getCurrentUser } from '../../../Services/userReducer'
import { useAppDispatch, useAppSelector } from '../../../Store/hooks'
import { Image } from '../../../models/image.model'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

interface UserAvatarVideoProps {
  imageModalOpen: boolean
  toggleImageModalOpen: (value: boolean) => void
}

const UploadUserImage = (props: UserAvatarVideoProps) => {
  const { imageModalOpen, toggleImageModalOpen } = props
  const currentUser = useAppSelector(getCurrentUser)
  const cropperRef = useRef<any>(null)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [image, setImage] = useState<Image>()
  const [imageError, setImageError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [upImg, setUpImg] = useState<any>(null)
  const [croppedBlob, setCroppedBlob] = useState(null)
  const [imageLoaded, setImageLoaded] = useState(false)

  const {
    deleteImage,
    addImage,
    profileImageRemoveLoading,
    profileImageAddLoading,
    imageURL,
    imageSizeData,
  } = useUploadUserImage()

  const closeModal = () => {
    setImageError(false)
    setIsFilePicked(false)
    setUpImg(null)
    toggleImageModalOpen(false)
  }

  const closeOnSave = () => {
    setImageError(false)
    setIsFilePicked(false)
    setUpImg(null)
    toggleImageModalOpen(false)
  }

  const cancelSelectedImage = () => {
    setImageError(false)
    setIsFilePicked(false)
    setUpImg(null)
    setCroppedBlob(null)
  }

  let imageSize = Number(imageSizeData)
  let imageSizeFormat = formatBytes(imageSize)

  useEffect(() => {
    if (currentUser) {
      if (currentUser.image && currentUser.image.length > 0) {
        setImage(currentUser.image[0])
      } else {
        setImage(undefined)
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (croppedBlob) {
      saveImage()
    }
  }, [croppedBlob])

  const getCropData = () => {
    if (cropperRef.current?.cropper) {
      cropperRef.current.cropper
        .getCroppedCanvas({
          width: 650,
          height: 650,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
        })
        .toBlob((blob: any) => {
          if (blob) {
            setCroppedBlob(blob)
          }
        })
    }
  }

  const saveImage = () => {
    if (!croppedBlob) {
      return
    }
    if (!profileImageAddLoading) {
      let file = new File([croppedBlob], 'cropped.jpg', { type: 'image/jpeg' })

      addImage({ file, userId: currentUser.id }).then((response : any) => {
        if (response.data) {
          dispatch(userApi.util.invalidateTags(['CurrentUser']))
          toastSuccess(t('profileImageUploadedSuccessfully'))
          closeOnSave()
          setCroppedBlob(null)
        }
      })
    }
  }

  const remove = (removeImage: Image) => {
    if (removeImage.id && !profileImageRemoveLoading) {
      deleteImage({ imageId: removeImage.id, fileName: removeImage.imageName }).then((response : any) => {
        if (response.data) {
          dispatch(userApi.util.invalidateTags(['CurrentUser']))
          toastSuccess(t('profileImageDeletedSuccessfully'))
          setImageLoaded(false)
          closeModal()
        }
      }).catch((error) => {
        // TODO: handle error
        console.log(error)
      })
    }
  }

  const onUploadFileSelected = (event : any) => {
    if (imageSize > event.target.files[0].size && event.target.files[0].type.match('image.*')) {
      setIsFilePicked(true)
      setImageError(false)
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      reader.readAsDataURL(event.target.files[0])
    } else {
      setImageError(true)
    }
  }

  if (!currentUser) {
    return <Loading />
  }

  return (
    <Modal
      isOpen={imageModalOpen}
      toggle={closeModal}
      closeOnClickOutside={false}
    >
      <div className="p-3">
        <div className="flex flex-row justify-between">
          <ModalHeader>
            <div>{t('profileImage')}</div>
          </ModalHeader>
          <ModalCloseButton toggle={closeModal} />
        </div>
        <ModalBody>
          <div className="flex flex-col gap-y-5 flex-1 min-h-[20rem] w-full relative">
            {!image && !isFilePicked && (
              <div
                data-testid="file-upload-image"
                className="h-full w-full flex flex-1 justify-center items-center p-1 py-6 border-2 border-BeeMG-yellow border-dashed rounded-md cursor-pointer relative"
              >
                <input
                  id="file-upload-image"
                  className="space-y-1 text-center flex text-lg text-black font-medium absolute w-full opacity-0 py-6"
                  type="file"
                  accept="image/*"
                  onChange={onUploadFileSelected}
                />
                {t('(clickOrDropImage)')}
              </div>
            )}

            {image && !isFilePicked && (
              <div className="flex flex-1 flex-col items-center gap-y-2">
                <img
                  src={imageURL + '/' + image.imageName}
                  onLoad={() => setImageLoaded(true)}
                  alt="profile"
                  className="rounded-full"
                  width={250}
                  height={250}
                />
                {imageLoaded && (
                  <Button
                    disabled={profileImageRemoveLoading || profileImageAddLoading}
                    size="filter"
                    className="mt-1 cursor-pointer normal-case justify-end"
                    id="btn_imageEdit"
                    data-testid={`remove_image-${image}`}
                    onClick={() => remove(image)}
                  >
                    {t('remove')}
                  </Button>
                )}
              </div>
            )}

            {isFilePicked && (
              <div className="flex flex-col justify-center items-center p-3">
                <Cropper
                  ref={cropperRef}
                  style={{ width: '300px', height: '300px' }}
                  initialAspectRatio={1}
                  src={upImg ?? ''}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  checkOrientation={false}
                  guides={true}
                  autoCropArea={1}
                  aspectRatio={1}
                />

                {profileImageAddLoading && (
                  <div className="absolute mb-6">
                    <Loader />
                  </div>
                )}
                <div className="flex justify-center mt-5">
                  <Button
                    disabled={profileImageAddLoading}
                    id="btn_ImageCropSave"
                    size="sm"
                    color="footerButton"
                    className="mr-2"
                    data-testid="input_save"
                    onClick={getCropData}
                  >
                    {t('cropAndSave')}
                  </Button>
                  <Button
                    id="btn_ImageCancel"
                    size="sm"
                    color="footerButton"
                    onClick={cancelSelectedImage}
                  >
                    {t('cancel')}
                  </Button>
                </div>
              </div>
            )}
            {!imageError && !isFilePicked && (
              <small id="txt_imageSizeLimit" className="text-red-600">
                * {t('warning!SizeExceedsThan')} {imageSizeFormat}
              </small>
            )}
            {imageError && !isFilePicked && (
              <small id="error_imageSize" className="text-red-600">
                * {t('warning!SizeExceedsThan')} {imageSizeFormat}
              </small>
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default UploadUserImage
