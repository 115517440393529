import { HTMLAttributes, ReactNode } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export function TitleComponent({ children }: Props) {
  return (
    <div id="txt_title" data-testid={`title_${children}`} className="text-xl md:text-2xl font-medium flex justify-center mt-2 mb-8">
      {children}
    </div>
  )
}
export default TitleComponent
