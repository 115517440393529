import { Country } from './../models/country.model';
import { getPreference } from './userReducer';
import { store } from '../Store';
import { allApis } from './allApis';

export const countryApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllCountries: builder.query<Country[], void>({
            query: () => `country`,
            transformResponse: (response: Country[]) => {
                const preference = getPreference(store.getState());
                let res;

                if (preference?.language === 'ja') {
                    res = response.map<Country>(country => {
                        country.displayCountryCode = "+" + country.callingCodes[0];
                        country.displayName = country.translations.ja;
                        return country;
                    });
                } else {
                    res = response.map<Country>(country => {
                        country.displayCountryCode = "+" + country.callingCodes[0];
                        country.displayName = country.name;
                        return country;
                    });
                }

                return res;
            },
            // providesTags: ['Country']
        }),
        // getCountryByCode: builder.query<Country, string>({
        //     query: (countryCode) => `country/${countryCode}`,
        //     // providesTags: ['Country']
        // })
    })
});

export const { useGetAllCountriesQuery } = countryApi;
