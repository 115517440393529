import { getMeetingConnected, getMeetingJoined, resetLiveKitState } from './../Services/livekitReducer';
import { socketClient } from './../API/socketio';
import { meetingApi } from './../Services/meetingApi';
import { useAppDispatch, useAppSelector } from './../Store/hooks'
import { changeToInitial } from '../Services/userReducer'
import { awsLogout, logoutToken } from '../Services/tokenReducer'
import { useLocalParticipantContext } from '../Services/livekit/contexts/liveKitContext';

const useSignOutHook = () => {
  const dispatch = useAppDispatch()
  const isMeetingConnected = useAppSelector(getMeetingConnected)
  const isMeetingJoined = useAppSelector(getMeetingJoined)
  
  // eslint-disable-next-line
  const [_, setLocalParticipant, room, setRoom] = useLocalParticipantContext()

  const signOutActions = () => {

    //disconnect from room upon logout if user is in a meeting.
    if ((isMeetingConnected || isMeetingJoined) && room) {
      room.disconnect()
      room.localParticipant.trackPublications.forEach((track) => track.track?.stop())
      setRoom(undefined)
      setLocalParticipant(undefined)
    }

    dispatch(resetLiveKitState())
    dispatch(changeToInitial())
    dispatch(logoutToken())
    dispatch(meetingApi.util.resetApiState()); // disable upcoming meeting api calls (polling) when user is signed out 

    dispatch(awsLogout())

    dispatch({ type: 'USER_LOGOUT' })
    socketClient.disconnect()
  }

  return {
    signOutActions,
  }
}

export default useSignOutHook
