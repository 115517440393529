import { useTranslation } from "react-i18next"
import { Ledger } from "../../models/ledger.model"

export default function DisplayTransactionStatus({ transaction }: { transaction: Ledger }) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-start items-start my-2 pl-[125px]">
      <span id={`txt_status-${transaction.id}`} data-testid={`txt_status-${transaction.id}`}>
        {t('transactionStatus')}
      </span>
      <span
        id={`card_status-${transaction.id}`}
        data-testid={`card_status-${transaction.id}`}
        className={transaction.transactionStatus === 'Completed' ? 'font-semibold uppercase text-green-500' : 'font-semibold uppercase'}
      >
        {t(transaction.transactionStatus)}
      </span>
    </div>

  )
}