import { Feedback } from './../models/feedback.model'
import { Pagination } from '../models/pagination.model'
import { formatDateToTimezone } from '../Utils/datetime'
import { allApis } from './allApis'

const feedbackResponseTransform = (response: Pagination<Feedback>) => {

  if (response.results) {
    response.results = response.results.map(feedback => {
      feedback.display_createdAt = formatDateToTimezone(feedback.createdAt.toString())
      return feedback
    })
  }
  return response
}
export const feedbackTransform = (feedbacks: Feedback[]) => {
  if (feedbacks) {
    feedbacks = feedbacks.map(feedbackList => {
      return feedbackCheckTransform(feedbackList)
    })
  }
  return feedbacks;
}

const feedbackCheckTransform = (feedback: Feedback) => {
  feedback.display_createdAt = formatDateToTimezone(feedback.createdAt.toString())
  return feedback;
}

export const feedbackApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createFeedback: builder.mutation<Feedback, Partial<Feedback>>({
      query: feedback => {
        return {
          url: `feedback`,
          method: 'POST',
          body: feedback,
        }
      },
      invalidatesTags: (result, error, feedback) => [{ type: 'FeedbackMeeting', id: feedback?.meeting?.id },
      ], // TODO: check if this is correct
    }),

    updateFeedback: builder.mutation<Feedback, Partial<Feedback>>({
      query: feedback => {
        return {
          url: `feedback`,
          method: 'PUT',
          body: feedback,
        }
      },
      invalidatesTags: ['FeedbackMeetingDetails', 'FeedbackMeeting', 'PastEventMeeting', 'rfmMeetingFeedback'],
      // invalidatesTags: (result, error, feedback) => {
      //   return [{ type: 'FeedbackMeeting', id: feedback?.meeting?.id }, 
      //   { type: 'FeedbackMeetingDetails', id: feedback?.meetingDetails?.id }]
      // }, // TODO: check if this is correct
    }),

    getFeedbackByMeetingId: builder.query<Pagination<Feedback>, any>({
      query: args => {
        const { meetingId, limit, skip } = args
        return `feedback/allReview/${meetingId}/${limit}/${skip}`
      },
      providesTags: ['PastEventMeeting'],
    }),

    getFeedbackByUserId: builder.query<Feedback[], { userId: string }>({
      query: args => {
        const { userId } = args
        return `feedback/userReview/${userId}`
      },
      providesTags:['rfmMeetingFeedback'],
      transformResponse: (response: Feedback[]) => feedbackTransform(response)
    }),

    getByMeetingDetailsId: builder.query<Pagination<Feedback>, any>({
      query: args => {

        const { meetingDetailsId, limit, skip } = args
        return `feedback/meetingDetails/${meetingDetailsId}/${limit}/${skip}`
      },
      transformResponse: (res: any) => feedbackResponseTransform(res),
      providesTags: (result, error, meetingDetailsId) => [{ type: 'FeedbackMeetingDetails', id: meetingDetailsId }],
    }),

    getFeedbackCountAndAverage: builder.query<any, { meetingDetailsId: string }>({
      query: args => {
        const { meetingDetailsId } = args
        return `feedback/meetingDetails/feedbackCountAndAverage/${meetingDetailsId}`
      },
    }),

    getUserFeedbackByMeetingId: builder.query<Feedback, string>({
      query: meetingId => `feedback/user/${meetingId}`,
      providesTags: (result, error, meetingId) => [{ type: 'FeedbackMeeting', id: meetingId }],
    }),
  })
})

export const { useGetByMeetingDetailsIdQuery, useUpdateFeedbackMutation, useGetUserFeedbackByMeetingIdQuery, useCreateFeedbackMutation, useGetFeedbackByMeetingIdQuery, useGetFeedbackByUserIdQuery, useGetFeedbackCountAndAverageQuery } = feedbackApi
