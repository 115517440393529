
const avatarColors = [
  '#D18000',
  '#D1413F',
  '#DD1576',
  '#C233A0',
  '#6163E1',
  '#C4D6EA',
  '#73B5BD',
  '#7BA100',
  '#9355D2',
  '#627A89',
]

export const useAvatarColors = () => {

  const getBackground = (name?: string | null) => {
    if (!name) { return avatarColors[0] }
    else {
      return avatarColors[name.length % avatarColors.length]
    }
  }

  return { getBackground }
}


