import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "../Containers/useTitle";
import TitleComponent from "./titleComponent";

export function PageTitle({children, title="", subTitle="", width="w-full lg:w-9/12"}: {children: ReactNode, title?: string, subTitle?: string, width?: string}) {
  
  
  const {t} = useTranslation()
  useTitle(t(title) + t(subTitle));
  
  return (
    <div className="w-full flex flex-col items-center overflow-y-auto justify-center">
      <div className={`mb-8 gap-2 ${width} flex place-items-center`}>
        <div className="w-full mt-10 xs:mt-0 sm:mt-0 items-center">
          {title && <TitleComponent>{t(title)}</TitleComponent>}
            {children}
        </div>
      </div>
    </div>
  )
}