import { useState } from 'react';

const useToggle = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = (e?: any) => {
    try {
      e?.stopPropagation()
    } catch (error) {}
    
    setIsOpen(!isOpen)
  }
  return { toggle, isOpen };
};

export default useToggle;
