import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../Components/pageTitle'
import InvitationList from './InvitationList'
import StartInviting from './StartInviting'
import Loading from '../../Components/base/loading/loading'
import { useGetInviteByUserQuery } from '../../Services/inviteApi'

function Invite({ serverError }: { serverError?: string }) {
  const { t } = useTranslation()
  const { isLoading: inviteLoading } = useGetInviteByUserQuery();

  if (inviteLoading) {
    return <Loading />;
  }

  return (
    <>
      {!serverError &&
        <PageTitle title='invitations'>
          <StartInviting />
          <InvitationList />
        </PageTitle>
      }
    </>
  )
}
export default Invite
