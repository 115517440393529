import PropTypes from "prop-types";
import { icons } from '../../../Assets/icons/index';

const Icon = ({ size, bgColor, stroke = "#010101", icon, onClick, className }: { size: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large', bgColor?: string, stroke?: string, icon: string, onClick?: any, className?: string }) => {
  let sizeWidth: string

  sizeWidth = size === 'x-small' ? '15' : size === 'small' ? '20' : size === 'medium' ? '30' : size === 'large' ? '40' : '10'
  const IconComp = icons[icon as keyof typeof icons];
  return <IconComp fill={bgColor} stroke={stroke} width={sizeWidth} height={sizeWidth} onClick={onClick} className={className} />

};

Icon.defaultProps = {
  size: 'small',
  height: 'small',
  bgColor: "transparent",
};

Icon.propTypes = {
  size: PropTypes.oneOf(['xx-small', 'x-small', 'small', 'medium', 'large']),
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default Icon;
