import * as React from 'react'
import { LiveKitConfig } from './CustomLivekitRoom'
import { UserLayoutPosition } from '../liveKitHelpers'

type CustomRoomContextTypes = {
  title: string
  isAudioOnly: boolean
  isMinimized: boolean

  isUnmuteAllowed: boolean

  unpinnedLayoutPositions: UserLayoutPosition[]
  stagedLayoutPositions: UserLayoutPosition[]
  otherLayoutPositions: UserLayoutPosition[]

  stagedParticipants: string[]

  isHostOrCohostAvailable: boolean
  gridDimensions: { width: number; height: number }
  shouldLeaveMeeting: boolean
  handRaisedParticipants: string[]
  isParticipantHandRaised: boolean
  isHandRaised: boolean
  isHostEnded: boolean

  viewType: 'grid' | 'hostHighlighted'

  allTracks: any
  allTracksSids: string[]

  setIsMinimized: (isMinimized: boolean) => void
  setStagedParticipants: (stagedParticipant: string[]) => void
  setUnpinnedLayoutPositions: (unpinnedLayoutPositions: UserLayoutPosition[]) => void
  setIsUnmuteAllowed: (isUnmuteAllowed: boolean) => void
  setStagedLayoutPositions: (stagedLayoutPositions: UserLayoutPosition[]) => void
  setOtherLayoutPositions: (otherLayoutPositions: UserLayoutPosition[]) => void
  onMessage?: (message: string, type: 'error' | 'info' | 'success') => void
  setIsHostOrCohostAvailable: (isHostOrCohostAvailable: boolean) => void
  setGridDimensions: (dimensions: { width: number; height: number }) => void
  setShouldLeaveMeeting: (shouldLeaveMeeting: boolean) => void
  setHandRaisedParticipants: React.Dispatch<React.SetStateAction<string[]>>
  setIsParticipantHandRaised: (isParticipantHandRaised: boolean) => void
  setIsHandRaised: (isHandRaised: boolean) => void
  setIsHostEnded: (isHostEnded: boolean) => void
  setViewType: (viewType: 'grid' | 'hostHighlighted') => void
  setAllTracks: React.Dispatch<React.SetStateAction<any>>
  setAllTracksSids: React.Dispatch<React.SetStateAction<string[]>>
}

/** @public */
export const CustomRoomContext = React.createContext<(CustomRoomContextTypes & LiveKitConfig) | undefined>(undefined)

/**
 * Ensures that a room is provided via context.
 * If no room is provided, an error is thrown.
 * @public
 */
export function useCustomRoomContext() {
  const ctx = React.useContext(CustomRoomContext)
  if (!ctx) {
    throw Error('tried to access room context outside of livekit room component')
  }
  return ctx
}

/**
 * Returns the room context if it exists, otherwise undefined.
 * @public
 */
export function useMaybeCustomRoomContext() {
  return React.useContext(CustomRoomContext)
}

// /**
//  * Ensures that a room is provided, either via context or explicitly as a parameter.
//  * If no room is provided, an error is thrown.
//  * @public
//  */
// export function useEnsureRoom(room?: Room) {
//   const context = useMaybeCustomRoomContext();
//   const r = room ?? context;
//   if (!r) {
//     throw new Error(
//       'No room provided, make sure you are inside a Room context or pass the room explicitly',
//     );
//   }
//   return r;
// }
