import { ChatMessage } from './../Store/Chat/ChatModel';
import { Chat } from "../models/chat.model";
import { Pagination } from './../models/pagination.model';
import { allApis } from "./allApis";

export const messageApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        // create: builder.mutation<Chat, Partial<Chat>>({
        //     query: (message) => {
        //         return {
        //             url: `message`,
        //             method: 'POST',
        //             message
        //         };
        //     }
        // }),
        getMessagesByUser: builder.query<Pagination<Chat>, any>({
            query: (args) => {
                const { userId, limit, skip, seenOnly } = args;
                return `message/${userId}/${limit}/${skip}/?seenOnly=${seenOnly}`;
            }
        }),
        getUnReadMessageCount: builder.query<number, null>({
            query: () => `message/count/unReadMessage`,
            providesTags: ['UnreadChatCount']
        }),
        getMessagesByMeetingRequestId: builder.query<Chat, string>({
            query: (meetingRequestId) => `message/meetingRequest/${meetingRequestId}`
        }),
        getAllChatUsers: builder.query<any[], void>({
            query: () => `message/all/users`
        }),
        getTodayMeetingRequestCount: builder.query<number, void>({
            query: () => `message/count/todayMeetingRequest`,
            providesTags: [
                'VideoRequest'
            ],
        }),
        updateSeen: builder.mutation<ChatMessage, Partial<ChatMessage>>({
            query: (message) => {
                return {
                    url: `message/${message.messageId}`,
                    method: 'PUT',
                    body: message
                };
            },
            invalidatesTags: ['UnreadChatCount']
        })
    })
})

export const { useGetMessagesByUserQuery, useGetUnReadMessageCountQuery, useGetMessagesByMeetingRequestIdQuery, useGetAllChatUsersQuery, useGetTodayMeetingRequestCountQuery, useUpdateSeenMutation } = messageApi;
