import EditIcon from "../EditIcon"

interface EditWrapperProps {
  children: React.ReactNode
  onEditClick: () => void
  className?: string
  keyId?: string
}

const EditWrapper = (props: EditWrapperProps) => {
  const { children, onEditClick, className, keyId } = props

  return <div id={`${keyId}_detail`} data-testid={`${keyId}_detail`}className={`p-[1rem] flex flex-col gap-y-4 justify-center items-center rounded-xl transition-all duration-100 ease-in-out group relative ${className}`}>
    <div className="absolute hidden group-hover:block top-3 right-3"><EditIcon keyId={keyId} onClick={onEditClick} /></div>
    {children}
  </div>
}

export default EditWrapper
