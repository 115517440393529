import { useGetSettingValue } from '../Services/settingReducer';

export function VideoDisplay({videoFile}: {videoFile: string}) {
  const imageURL = useGetSettingValue('IMAGE_URL')

  return (
    <>
      <video
        key={videoFile}
        controls
        muted
        loop
        autoPlay
        className="flex rounded-xl h-40 w-64 md:h-40 md:w-64 lg:h-40 lg:w-64"
        style={{objectFit: 'cover'}}>
        <source
          data-testid={`video-${videoFile}`}
          src={`${imageURL}/${videoFile}`}
          type="video/mp4"
        />
      </video>
    </>
  )
}

export default VideoDisplay
