import { useTranslation } from 'react-i18next'
import { useVideoMeetingStatus } from '../../Hooks/MessageHooks'
import { ChatMessage } from '../../Store/Chat/ChatModel'

const VideoMeetingStatus = ({ message }: { message: Partial<ChatMessage> }) => {
    const { t } = useTranslation()
    const { status, minutes } = useVideoMeetingStatus({ message })

    return (
        <>
            {status && <div className="flex flex-row items-center my-1 text-sm">
                <div
                    id={`chk_meetingCompleted${message?.messageId}`}
                    className="flex flex-row items-center py-1">
                    {t(status)}
                    {minutes && status === 'meetingDuration' && (
                        <>{` : ${minutes} ${t('min')
                            }`}</>
                    )}
                </div>
            </div>}
        </>
    )
}

export default VideoMeetingStatus
