import InterestedAndSuggestions from './InterestedAndSuggestions'
import { useGetAllMeetingsQuery } from '../Services/meetingApi'
import { getPreference } from '../Services/userReducer'
import { useSelector } from 'react-redux'
import Loading from '../Components/base/loading/loading'
import NoSession from '../Components/NoSession'

function InterestedEvents() {
  const preference = useSelector(getPreference)
  const { data: meetingFavorite, isSuccess: favDataSuccess, isLoading: favDataLoading } = useGetAllMeetingsQuery({ type: 'favorite' ?? '' }, { skip: !preference?.currency })

  if (favDataLoading) {
    return <Loading />
  }

  if (meetingFavorite?.results.length === 0 || !favDataSuccess) {
    return <NoSession type="events" className="h-[60vh] items-center justify-center" />
  }

  return <>
    {meetingFavorite?.results && <InterestedAndSuggestions meetings={meetingFavorite?.results} />}
  </>
}

export default InterestedEvents
