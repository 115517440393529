// src/hooks/useLanguage.ts
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const useLanguage = () => {
  const { i18n } = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleChange = (lng: string) => {
    i18n.changeLanguage(lng)
    setIsDropdownOpen(false)
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        (event.target as HTMLElement).id !== 'dropdownDefaultButton'
      ) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return {
    isDropdownOpen,
    toggleDropdown,
    handleChange,
    dropdownRef,
    currentLanguage: i18n.language,
  }
}

export default useLanguage
